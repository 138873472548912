import PropTypes from 'prop-types';
import { RoundedButton } from '../../../../components/form/Button';
import TextArea from '../../../../components/form/TextArea';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner';

export const MAX_COMMENT_LENGTH = 600;
export const commentContainerClasses = 'bg-gray-outline p-1 border rounded-lg';

export function CommentInput({
    value,
    onValueChange,
    onAction,
    actionButtonLabel,
    onCancel,
    isAdding = true,
    error,
    isLoading = false,
}) {
    return (
        <>
            <div className={`${isAdding ? '' : commentContainerClasses}`}>
                {!isAdding && <p className="text-left text-oslo-gray">{gettext('EDITING')}</p>}
                <TextArea
                    value={value}
                    onValueChange={onValueChange}
                    maxLength={MAX_COMMENT_LENGTH}
                />
            </div>
            <div className="mt-2 space-x-3">
                <RoundedButton type="button" onClick={onAction} variant="big" color="primary" flex>
                    {isLoading ? <LoadingSpinner size="lg" /> : actionButtonLabel}
                </RoundedButton>
                <RoundedButton
                    type="button"
                    onClick={onCancel}
                    variant="big"
                    color="secondary"
                    flex
                >
                    {gettext('CANCEL')}
                </RoundedButton>
                {error && <p>{error}</p>}
            </div>
        </>
    );
}
CommentInput.propTypes = {
    value: PropTypes.string.isRequired,
    onValueChange: PropTypes.func.isRequired,
    onAction: PropTypes.func.isRequired,
    actionButtonLabel: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    isAdding: PropTypes.bool,
    error: PropTypes.string,
    isLoading: PropTypes.bool,
};

export default CommentInput;
