export const DOWNLOAD_STATUS = Object.freeze({
    IN_PROGRESS: 'IN_PROGRESS',
    READY: 'READY',
    FAILED: 'FAILED',
    DOWNLOADED: 'DOWNLOADED',
});

export const DOWNLOAD_STATUS_TRANSLATIONS = Object.freeze({
    [DOWNLOAD_STATUS.READY]: gettext('READY'),
    [DOWNLOAD_STATUS.IN_PROGRESS]: gettext('IN_PROGRESS'),
    [DOWNLOAD_STATUS.FAILED]: gettext('FAILED'),
    [DOWNLOAD_STATUS.DOWNLOADED]: gettext('DOWNLOADED'),
});

export const DOWNLOAD_CATEGORY = Object.freeze({
    REPORT: 'REPORT',
    DATA_EXPORT: 'DATA_EXPORT',
});

export const DOWNLOAD_CATEGORY_TRANSLATIONS = Object.freeze({
    [DOWNLOAD_CATEGORY.REPORT]: gettext('REPORT'),
    [DOWNLOAD_CATEGORY.DATA_EXPORT]: gettext('DATA_EXPORT'),
});
