import { map, of, switchMap, tap } from 'rxjs';
import {
    DataTypes,
    dustGraphDataTypes,
    dustGraphDataTypesWithoutPM10,
    dustGraphDataTypesWithoutPM2p5,
} from '../data-types';
import {
    BaseGraphElement,
    filterElementsOnAxesWithLegend,
    filterElementsOnAxesWithObservable,
    sortAxis,
    valueNotNull,
} from './base-provider';
import { SamplesGraphElementsProvider } from './samples-provider';

export const dustDataTypes = ['pm10', 'pm2p5'].flatMap((pmType) =>
    ['15m', '60m', '1440m', '1m', '600m'].flatMap((aggType) => pmType + aggType)
);

export class BaseDustGraphElement extends BaseGraphElement {
    constructor(record, axis) {
        super(record);
        this.axis = axis;
    }

    get value() {
        return this.record[this.axis];
    }
}

export class DustSampleGraphElement extends BaseDustGraphElement {
    static createCollectionFromRecords(records) {
        return records.flatMap((record) =>
            dustDataTypes
                .map((type) => new this(record, type))
                .filter(valueNotNull)
                .sort(sortAxis)
        );
    }
}

export class DustMetaGraphElement extends BaseDustGraphElement {
    static createCollectionFromRecords(records, dataType) {
        return records.map((record) => new this(record, dataType)).filter(valueNotNull);
    }
}

/**
 * Observables flow diagram: https://miro.com/app/board/uXjVPSSWJQ0=/
 */
export class DustSamplesGraphElementsProvider extends SamplesGraphElementsProvider {
    constructor(graph) {
        super(graph);

        // Update the availability of different PM types when
        // the dust samples get updated.
        const legendUpdater$ = this.dataSet.dustDataAvailable$.pipe(
            tap((dustDataAvailable) => {
                graph.legendData.entries
                    .filter((entry) => entry.type.match(/^pm10/) || entry.type.match(/^pm2p5/))
                    .forEach((entry) => {
                        entry.available = dustDataAvailable.includes(entry.type);
                    });

                graph.legendData.updated$.next();
            })
        );

        this.subscription.add(legendUpdater$.subscribe());
    }

    static createGraphElementsObservable(dataSet, legendData) {
        return dataSet.dataType$.pipe(
            switchMap((dataType) => {
                if (
                    dustGraphDataTypesWithoutPM10.includes(dataType) &&
                    dustGraphDataTypesWithoutPM2p5.includes(dataType)
                ) {
                    return dataSet.dustMeta$.pipe(
                        map((records) =>
                            DustMetaGraphElement.createCollectionFromRecords(records, dataType)
                        ),
                        filterElementsOnAxesWithLegend(legendData)
                    );
                }

                if ([DataTypes.PM10, DataTypes.PM2P5].includes(dataType)) {
                    const filteredDustDataAvailable$ = dataSet.dustDataAvailable$.pipe(
                        map((dustDataAvailable) =>
                            dustDataAvailable.filter((dustDataType) =>
                                dustDataType.startsWith(dataType.toLowerCase())
                            )
                        )
                    );

                    return dataSet.dustData$.pipe(
                        map((records) =>
                            DustSampleGraphElement.createCollectionFromRecords(records)
                        ),
                        filterElementsOnAxesWithLegend(legendData),
                        filterElementsOnAxesWithObservable(filteredDustDataAvailable$)
                    );
                }

                return of([]);
            })
        );
    }

    getD3IdentifierCssClass() {
        return 'dust';
    }

    getShouldCalculateElementPositionsObservable() {
        return this.dataSet.dataType$.pipe(
            map((dataType) => dustGraphDataTypes.includes(dataType))
        );
    }
}
