import { zoom as d3Zoom, zoomIdentity } from 'd3-zoom';
import { active } from 'd3-transition';
import EventEmitter from 'eventemitter3';
import { defaultTo } from 'lodash-es';
import { getYearDays, oneDay } from './graph-helpers';
import { CursorFunction } from './cursor-functions';

const defaultZoomTransitionDuration = 250; // ms

export class ZoomHelper extends EventEmitter {
    constructor(graph, maxZoom, zoomY, restrict, zoomTransitionDuration) {
        super();

        this.graph = graph;
        this.maxZoom = maxZoom;
        this.zoomY = zoomY;
        this.zoomTransitionDuration = zoomTransitionDuration;
        this.updateXAxis = true;

        if (restrict) {
            this.extent = [
                [0, 0],
                [this.graph.graphWidth, this.graph.graphHeight],
            ];

            this.translateExtent = [
                [0, 0],
                [this.graph.graphWidth, this.graph.graphHeight],
            ];
        }

        // Zoom for the graph.
        this.zoom = d3Zoom().scaleExtent([1, this.maxZoom]);

        if (this.extent) {
            this.zoom.extent(this.extent);
        }

        if (this.translateExtent) {
            this.zoom.translateExtent(this.translateExtent);
        }

        this.setInitialZoomLevel();

        // Only allow zooming when we are in zoom mode.
        this.zoom.filter((event) => {
            // Ignore right mouse clicks.
            if (event.button === 2) {
                return false;
            }

            return this.graph.cursorFunction$.getValue() === CursorFunction.ZOOM;
        });

        this.zoom.on('zoom.graph', this.onZoom.bind(this));

        this.graph.zoomElement.call(this.zoom);

        this.resetZoomScale = 1;
    }

    setInitialZoomLevel() {}

    onZoom(event) {
        if (event.sourceEvent && event.sourceEvent.type === 'brush') {
            // Ignore zoom-by-brush.
            return;
        }

        const t = event.transform;

        if (this.updateXAxis) {
            // Re-scale x and y axis during zoom.
            this.graph.xAxis.scale.domain(t.rescaleX(this.graph.xAxis.originalScale).domain());

            if (this.zoomY) {
                this.graph.yAxis.scale.domain(t.rescaleY(this.graph.yAxis.originalScale).domain());
            }
        }

        this.emit('zoomed', { inTransition: !!active(this.graph.zoomElement.node()) });
    }

    zoomTransition() {
        return this.graph.zoomElement
            .transition()
            .duration(defaultTo(this.zoomTransitionDuration, defaultZoomTransitionDuration))
            .on('end', () => {
                this.emit('transition-end');
            });
    }

    resetZoom() {
        this.zoom.scaleTo(this.zoomTransition(), this.resetZoomScale);
    }
}

export class ValueTimeGraphZoomHelper extends ZoomHelper {
    constructor(...args) {
        super(...args);

        this.zoom.on('end', () => {
            this.updateXAxis = true;
        });
    }

    setZoomToXAxis() {
        if (this.graph.animate) {
            this.updateXAxis = false;

            const min = this.graph.xAxis.getMin();
            const max = this.graph.xAxis.getMax();
            const diff = max - min;
            const scale = getYearDays() / (diff / oneDay);
            const identity = zoomIdentity
                .scale(scale)
                .translate(-this.graph.xAxis.originalScale(min), 0);

            this.zoom.transform(this.graph.zoomElement, identity);
        }
    }

    setInitialZoomLevel() {
        this.setZoomToXAxis();
        this.updateXAxis = true;
    }

    zoomToScale(days) {
        this.resetZoomScale = getYearDays() / days;
        this.resetZoom();
    }
}
