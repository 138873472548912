import { isNil } from 'lodash-es';
import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import i18n from './translations/i18n';
import { getBrowserTimezone } from './utils/formatting';

/**
 * @typedef {Object} ResellerData
 * @property {boolean} isActive - Indicates whether the reseller is active.
 * @property {string} [topLogoUrl] - The URL of the top logo.
 * @property {string} [companyName] - The name of the company.
 */

export class Profile {
    use24HourNotation = true;

    userId = null;

    languageCode = 'en';

    isProfileIncomplete = false;

    isAuthenticated = false;

    isReseller = false;

    isCustomer = false;

    /**
     * @type {ResellerData | null}
     */
    resellerData = null;

    readyReportsCount = 0;

    csrfToken = null;

    preferredTimezone = null;

    load(options) {
        // If there's no preferred timezone yet, set the browser timezone.
        if (isNil(options?.preferredTimezone)) {
            options.preferredTimezone = getBrowserTimezone();
        }

        // Assign all the options coming from Django or Storybook to the active profile instance.
        Object.assign(this, options);
    }
}

const profileInstance = new Profile();

export const profileModule = ({ options }) => {
    i18n.changeLanguage(options.languageCode);

    profileInstance.load(options);

    // Trigger event for SWARM Sound related survey in Hotjar on the Overview Page.
    if (window.location.pathname === '/measuring_point/' && typeof window.hj === 'function') {
        window.hj('event', 'open_sound_survey');
    }
};

export default profileInstance;

const ProfileContext = createContext();

export function useProfile() {
    const context = useContext(ProfileContext);

    if (!context) {
        throw new Error('No profile context available.');
    }

    return context;
}

export function ProfileProvider({ profile, children }) {
    return <ProfileContext.Provider value={profile}>{children}</ProfileContext.Provider>;
}
ProfileProvider.propTypes = {
    profile: PropTypes.instanceOf(Profile).isRequired,
    children: PropTypes.node.isRequired,
};
