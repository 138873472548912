import { confirm, ConfirmType } from './components/confirm/Confirm';

const accountingSubscriptionsModule = ({ options }) => {
    const subscriptionForm = document.getElementById('subscription_form');

    const getCheckedDevices = () => {
        const devices = [];
        Array.from(document.getElementsByClassName('action-select')).forEach((element) => {
            if (element.checked)
                devices.push({
                    name: element.value,
                    lastInvoiceDate: element?.dataset?.date || options.notInvoicedText,
                });
        });
        return devices;
    };

    const getDeviceNames = ({ checkedDevices, addInvoiceDate = false }) => {
        let checkedDeviceList = '';
        checkedDevices.forEach((device) => {
            checkedDeviceList += addInvoiceDate
                ? `${device?.name} - ${device?.lastInvoiceDate}\n`
                : `${device.name}\n`;
        });
        return checkedDeviceList;
    };

    const setElementAttributes = (element, name) => {
        element.setAttribute('type', 'hidden');
        element.setAttribute('name', name);
        element.setAttribute('id', name);
        subscriptionForm.appendChild(element);
    };

    [
        {
            button: document.getElementById('submit_activate_subscriptions'),
            action: 'activate',
            createMessage: (checkedDevices) => {
                const deviceNames = getDeviceNames({ checkedDevices });
                if (checkedDevices.length > 1)
                    return interpolate(gettext('ACTIVATE_MULTIPLE_SUBSCRIPTION'), [deviceNames]);
                return interpolate(gettext('ACTIVATE_SUBSCRIPTION'), [deviceNames]);
            },
        },
        {
            button: document.getElementById('submit_end_subscriptions'),
            action: 'end',
            createMessage: (checkedDevices) => {
                if (checkedDevices.length > 1) {
                    const deviceNamesDate = getDeviceNames({
                        checkedDevices,
                        addInvoiceDate: true,
                    });
                    return interpolate(gettext('CANCEL_MULTIPLE_SUBSCRIPTION'), [deviceNamesDate]);
                }
                const deviceNames = getDeviceNames({ checkedDevices });
                return interpolate(gettext('CANCEL_SUBSCRIPTION'), [
                    deviceNames,
                    checkedDevices[0]?.lastInvoiceDate,
                ]);
            },
        },
    ].forEach(({ button, action, createMessage }) => {
        button.addEventListener('click', (e) => {
            e.preventDefault();
            const actionInput = document.createElement('input');
            setElementAttributes(actionInput, action);
            const checkedDevices = getCheckedDevices();
            if (checkedDevices.length < 1)
                return confirm({
                    message: gettext('NO_SWARM_DETECTED'),
                    convertMarkdown: true,
                    type: ConfirmType.INFO,
                });
            return confirm({
                message: createMessage(checkedDevices),
                convertMarkdown: true,
                onConfirm: () => subscriptionForm.submit(),
            });
        });
        const actionInput = document.getElementById(action);
        if (actionInput) subscriptionForm.removeChild(actionInput);
    });
};

export default accountingSubscriptionsModule;
