/* eslint-disable no-param-reassign */
function urlConvert(url, was, is, sensorIds) {
    was.forEach((val, idx) => {
        url = url.replace(`/${val}/`, `/${is[idx]}/`);
    });

    if (sensorIds) {
        url += `?sensors=${sensorIds}`;
    }

    return url;
}

export default urlConvert;
