import { isNull } from 'lodash-es';
import tippy from 'tippy.js';
import { asyncScheduler, observeOn } from 'rxjs';
// eslint-disable-next-line import/no-relative-packages
import { composeRender } from '../../../../../node_modules/@observablehq/plot/src/mark';

// Based on https://github.com/observablehq/plot/blob/main/src/interactions/pointer.js.

const states = new WeakMap();

function highlightTransform({
    fy,
    render,
    highlighted$,
    tooltipContentRender,
    onClick,
    ...options
} = {}) {
    return {
        fy,
        ...options,
        render: composeRender(function factory(index, scales, values, dimensions, context, next) {
            const svg = context.ownerSVGElement;
            const { data } = context.getMarkState(this);
            const fyMap = fy.transform(data);

            let state = states.get(svg);
            if (!state)
                states.set(svg, (state = { subscriptions: new Map(), tippyInstances: new Map() }));

            const faceted = index.fi != null;

            let currentDate;
            let currentDataIndex;
            let currentElement;

            function innerRender(date) {
                if (currentDate === date) return; // The date hasn’t changed.
                currentDate = date;

                // Find the data index of the date we want to highlight.
                const found = data.findIndex((entry) => entry.date === date);
                currentDataIndex = found === -1 ? null : found;

                const isWithinFacet = fyMap[currentDataIndex] === index.fy;

                const cells = !isNull(currentDataIndex) && isWithinFacet ? [currentDataIndex] : [];

                if (faceted) {
                    cells.fx = index.fx;
                    cells.fy = index.fy;
                    cells.fi = index.fi;
                }
                const nextElement = next(cells, scales, values, dimensions, context);

                if (currentElement) {
                    // When faceting, preserve swapped mark and facet transforms; also
                    // remove ARIA attributes since these are promoted to the parent. This
                    // is perhaps brittle in that it depends on how Plot renders facets,
                    // but it produces a cleaner and more accessible SVG structure.
                    if (faceted) {
                        const parent = currentElement.parentNode;
                        const facetTransform = currentElement.getAttribute('transform');
                        const markTransform = nextElement.getAttribute('transform');

                        if (facetTransform) {
                            nextElement.setAttribute('transform', facetTransform);
                        } else {
                            nextElement.removeAttribute('transform');
                        }

                        if (markTransform) {
                            parent.setAttribute('transform', markTransform);
                        } else {
                            parent.removeAttribute('transform');
                        }

                        nextElement.removeAttribute('aria-label');
                        nextElement.removeAttribute('aria-description');
                        nextElement.removeAttribute('aria-hidden');
                    }

                    // Destroy the currently rendered tippy.
                    let currentTippy = state.tippyInstances.get(index.fi);
                    if (currentTippy) {
                        currentTippy.destroy();
                        state.tippyInstances.delete(index.fi);
                    }

                    if (nextElement.firstChild) {
                        currentTippy = tippy(nextElement.firstChild, {
                            content: tooltipContentRender(data[currentDataIndex]),
                            theme: 'omnidots-white-shadow',
                            showOnCreate: true,
                            trigger: 'manual',
                            hideOnClick: false,
                            allowHTML: true,
                            appendTo: () => document.body,
                        });

                        state.tippyInstances.set(index.fi, currentTippy);
                    }

                    window.test = currentTippy;

                    currentElement.replaceWith(nextElement);
                }

                // Store the rendered mark.
                currentElement = nextElement;
            }

            innerRender(null);

            state.subscriptions.set(
                index.fi,
                highlighted$
                    .pipe(
                        // Defer the start of the subscription to the next tick. This is to
                        // make sure our `currentElement` gets attached to the SVG before we start
                        // doing our own rendering.
                        observeOn(asyncScheduler)
                    )
                    .subscribe((incoming) => {
                        innerRender(incoming?.date || null);
                    })
            );

            // This `pointerdown` event also overrides the normal sticky behavior of pointer.js.
            svg.addEventListener('pointerdown', (event) => {
                onClick(data[currentDataIndex]);
                event.stopImmediatePropagation();
            });

            // Add a method that cleans up the subscriptions and tippy instances
            // right before the next render of this plot.
            svg.cleanup = () => {
                // Stop all the subscriptions.
                state.subscriptions.forEach((value) => {
                    value.unsubscribe();
                });

                // Remove all the tippy tooltips.
                state.tippyInstances.forEach((value) => {
                    value.destroy();
                });
            };

            return currentElement;
        }, render),
    };
}

export function highlight(options) {
    return highlightTransform(options);
}
