import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { omit, mapValues, isObject, isEmpty, upperFirst, isArray, range } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useEventListener from '@use-it/event-listener';
import { useObservable, useObservableState, useSubscription } from 'observable-hooks';
import { map } from 'rxjs';
import { createQueriesForSwarmType, getSwarmTypeById, usePageTitle } from './utils';
import { useTranslatedFormSchema } from './schema-utils';
import { setNamingConventionsToGuideline } from '../../../utils/naming-conventions';
import GraphQLDataLink from '../../reactForm/GraphQLDataLink';
import AlarmRecipientsField from '../../reactForm/fields/AlarmRecipientsField';
import LocationField from '../../reactForm/fields/LocationField';
import ProjectField from '../../reactForm/fields/ProjectField';
import { FormContext } from '../../reactForm/utils';
import Form from '../../reactForm/Form';
import Layout from '../../reactForm/Layout';
import { NOTIFICATION_VARIANT, useNotifications } from '../../notifications/Notifications';
import ElementRefPropType from '../../utils';
import { confirm, ConfirmType } from '../../confirm/Confirm';
import { ConfigHelper, ProjectConfigHelper } from './configHelper';
import { switchHead } from '../../../utils/rxjs';
import { SwarmType } from '../../../enums';
import SoundDailyValueSettingsField from '../../reactForm/fields/soundDailyValueSettingsField/SoundDailyValueSettingsField';
import { AlarmLevelSettingsField } from '../../reactForm/fields/alarmLevelSettingsField/AlarmLevelSettingsField';
import LeqAlarmLevelSettingsField from '../../reactForm/fields/leqAlarmSettingsField/LeqAlarmSettingsField';
import WifiPasswordField from '../../reactForm/fields/WifiPasswordField';
import { GenericFormContextProvider } from './GenericFormContext';
import LdenGuidelinePreviewField from '../../reactForm/fields/LdenGuidelinePreviewField';
import BasePage from '../base-page';
import Button, { ButtonContainer } from '../../form/Button';
import { PERMISSION } from '../sharing/utils';

const projectIgnoreFields = [/^name$/, /^deviceName$/, /^project$/, /^location$/];

const removeTypeName = (object) => omit(object, ['__typename']);

const removeTypeNameRecursive = (incoming) => {
    if (isArray(incoming)) {
        return incoming.map((entry) => removeTypeNameRecursive(entry));
    }

    if (isObject(incoming)) {
        return mapValues(removeTypeName(incoming), (value) => removeTypeNameRecursive(value));
    }

    return incoming;
};

const convertDataIntoFormValues = (data) => {
    const formValues = omit(removeTypeNameRecursive(data), ['overriddenFields']);

    if (['SoundMeasuringPointType', 'SoundProjectType'].includes(data.__typename)) {
        formValues.alarmLevelSettings = range(3)
            .map((index) => {
                const level = index + 1;

                const valueKey = `alarmLevel${level}`;
                const nameKey = `${valueKey}Name`;

                const entry = {
                    name: formValues[nameKey],
                    value: formValues[valueKey],
                };
                delete formValues[nameKey];
                delete formValues[valueKey];
                return entry;
            })
            .filter((item) => item.value !== 0 || item.name !== '');
    }

    return formValues;
};

const TopSaveButtonHandler = ({ saveButtonRef }) => {
    const { triggerSubmit } = useContext(FormContext);

    useEventListener('click', triggerSubmit, saveButtonRef.current);

    return null;
};
TopSaveButtonHandler.propTypes = {
    saveButtonRef: ElementRefPropType,
};

const DetachSensorHandler = ({ detachButtonRef, swarmType }) => {
    const { setValue, watch } = useFormContext();

    if (detachButtonRef.current) {
        // Remove the Detach button if the deviceName field is empty.
        detachButtonRef.current.style.display = watch('deviceName') ? null : 'none';
    }

    const emptyDeviceName = useCallback(() => {
        setValue('deviceName', null);
        setTimeout(() => {
            confirm({
                message: interpolate(gettext('SWARM_DECOUPLED_AFTER_SAVE'), [
                    upperFirst(swarmType),
                ]),
                type: ConfirmType.INFO,
            });
        }, 1);
    }, [setValue, swarmType]);

    useEventListener('click', emptyDeviceName, detachButtonRef.current);

    return null;
};
DetachSensorHandler.propTypes = {
    detachButtonRef: ElementRefPropType,
    swarmType: PropTypes.oneOf(Object.values(SwarmType)).isRequired,
};

const UpdateNamingConventions = () => {
    const { watch } = useFormContext();

    useEffect(() => {
        const subscription = watch((values, { name }) => {
            if (name === 'guideLine') {
                setNamingConventionsToGuideline(values[name]);
            }
        });
        return subscription.unsubscribe;
    }, [watch]);

    return null;
};

export const createEditUrl = (id, swarmType, isProject) => {
    const prefix = isProject ? '/project' : '/measuring_point';
    return `${prefix}/edit/${swarmType}/${id}`;
};

const formFieldOverrides = {
    alarmRecipients: AlarmRecipientsField,
    location: LocationField,
    project: ProjectField,
    dailyValueSettings: SoundDailyValueSettingsField,
    alarmLevelSettings: AlarmLevelSettingsField,
    leqAlarmSettings: LeqAlarmLevelSettingsField,
    deviceWifiPassword: WifiPasswordField,
    ldenGuidelinePreview: LdenGuidelinePreviewField,
};

function translateFieldAndOption(formSchema, fieldName, optionName) {
    const field = formSchema.fields.getByName(fieldName);
    return `${field.label} ${field.options.get(optionName)}`;
}

function DisabledFeaturesWarning({ configHelper, formSchema }) {
    const bannerFeatures$ = useObservable(
        (inputs$) =>
            inputs$.pipe(
                switchHead(),
                // Map the incoming 'internal' config into a 'public' config.
                map((disabledFeatures) =>
                    disabledFeatures.flatMap(({ sensorName, unavailableFeatures }) =>
                        unavailableFeatures
                            .filter(({ showBanner }) => showBanner)
                            .map(({ field, option }) => [
                                sensorName,
                                translateFieldAndOption(formSchema, field, option),
                            ])
                    )
                )
            ),
        [configHelper.swarmFeatures$]
    );

    const bannerFeatures = useObservableState(bannerFeatures$);

    const { addNotification } = useNotifications();

    useEffect(() => {
        if (isEmpty(bannerFeatures)) {
            return null;
        }

        const { remove } = addNotification({
            message: (
                <>
                    <h1 className="text-xl">{gettext('UPDATE_YOUR_SWARMS')}</h1>
                    <ul>
                        {bannerFeatures.map(([deviceName, feature]) => (
                            <li key={[deviceName, feature]}>
                                {`${interpolate(
                                    gettext('FEATURE_NOT_SUPPORTED_BY'),
                                    { feature },
                                    true
                                )} ${deviceName}`}
                            </li>
                        ))}
                    </ul>
                    <p>{gettext('FIRMWARE_UPDATE_NOTIFICATION')}</p>
                </>
            ),
            variant: NOTIFICATION_VARIANT.ERROR,
        });

        return remove;
    }, [bannerFeatures, addNotification, formSchema]);

    return null;
}
DisabledFeaturesWarning.propTypes = {
    configHelper: PropTypes.instanceOf(ConfigHelper).isRequired,
    formSchema: PropTypes.object.isRequired,
};

function DeviceNameCorrection({ configHelper }) {
    const { setValue } = useFormContext();

    useSubscription(configHelper.deviceNameCorrection$, (nameCorrection) => {
        setValue('deviceName', nameCorrection);
    });

    return null;
}
DeviceNameCorrection.propTypes = {
    configHelper: PropTypes.instanceOf(ConfigHelper).isRequired,
};

function PermissionChecker({ setShowSaveButton }) {
    const { watch } = useFormContext();

    useEffect(() => {
        const subscription = watch((values, { name }) => {
            if (name === 'permission') {
                setShowSaveButton(values[name] === PERMISSION.FULL_ACCESS);
            }
        });
        return subscription.unsubscribe;
    }, [watch, setShowSaveButton]);

    return null;
}
PermissionChecker.propTypes = {
    setShowSaveButton: PropTypes.func.isRequired,
};

function GenericForm({ swarmType, isProject }) {
    const { id: rawId } = useParams();
    const { t } = useTranslation();

    const id = parseInt(rawId, 10);
    const isEditMode = !!id;
    const navigate = useNavigate();
    const { addNotification } = useNotifications();
    const queries = createQueriesForSwarmType(swarmType)[isProject];
    const formSchema = useTranslatedFormSchema(swarmType, isProject);
    const title = usePageTitle(swarmType, isProject, isEditMode);

    const saveButtonRef = useRef();
    const detachButtonRef = useRef();

    // Show the save button by default when in create mode. Hide the save button by default
    // when in edit mode. When in edit mode, as soon as the query containing the measuring
    // point or project permissions comes in, we decide if we want to show it.
    const [showSaveButton, setShowSaveButton] = useState(!isEditMode);

    useEffect(() => {
        // Can be undefined when left out of the URL.
        if (!id) {
            return;
        }

        // We query the API to determine the swarm type associated with the
        // given ID. If it doesn't correspond to the swarm type in the URL,
        // we courteously auto-redirect the user to the correct URL.
        getSwarmTypeById(id, isProject).then((swarmTypeResult) => {
            if (swarmTypeResult !== swarmType) {
                navigate(createEditUrl(id, swarmTypeResult, isProject));
            }
        });
    }, [navigate, id, isProject, swarmType]);

    const [fields, layout] = useMemo(() => {
        const newLayout = formSchema.layout.slice();
        const newFields = formSchema.fields.slice();

        if (!isProject) {
            // Add the wifi password field.
            newFields.insertAfter('project', {
                name: 'deviceWifiPassword',
            });
        }

        if (swarmType === SwarmType.SOUND) {
            const alarmLevelsIndex = newLayout.indexOf(
                newLayout.find(([name]) => name === 'alarmLevels')
            );

            // Change alarmLevel1Name to pseudo field (alarmLevelSettings).
            newLayout[alarmLevelsIndex][1] = 'Lmax alarm settings';
            newLayout[alarmLevelsIndex][2] = 'alarmLevelSettings';

            // Remove alarmLevel fields.
            newFields
                .filter((field) => field.name.startsWith('alarmLevel'))
                .forEach((field) => {
                    newFields.removeByName(field.name);
                });

            // Add alarmLevelSettings to formSchema fields.
            newFields.insertAfter('measuringSchedule', {
                name: 'alarmLevelSettings',
                type: 'char',
                default: [],
                required: false,
                internalKey: 'alarm_level_settings',
                label: 'Alarm level settings',
            });

            // Add alarmLevelSettings to formSchema fields.
            newFields.insertAfter('ldenGuidelineChoiceType', {
                name: 'ldenGuidelinePreview',
            });

            return [newFields, newLayout];
        }

        return [newFields, newLayout];
    }, [formSchema.layout, formSchema.fields, swarmType, isProject]);

    const configHelper = useMemo(
        () => (isProject ? new ProjectConfigHelper(swarmType, id) : new ConfigHelper(swarmType)),
        [swarmType, isProject, id]
    );

    const InjectableComponent = useCallback(
        () => (
            <>
                <PermissionChecker setShowSaveButton={setShowSaveButton} />
                <UpdateNamingConventions />
                {saveButtonRef.current && <TopSaveButtonHandler saveButtonRef={saveButtonRef} />}
                {!isProject && (
                    <DetachSensorHandler detachButtonRef={detachButtonRef} swarmType={swarmType} />
                )}
                <DisabledFeaturesWarning configHelper={configHelper} formSchema={formSchema} />
                {!isProject && <DeviceNameCorrection configHelper={configHelper} />}
            </>
        ),
        [saveButtonRef, detachButtonRef, configHelper, formSchema, isProject, swarmType]
    );

    const onSubmitSuccessful = useCallback(
        ({ id: idFromAPI }) => {
            if (!isEditMode) {
                navigate(createEditUrl(idFromAPI, swarmType, isProject));
            }

            addNotification({
                message: gettext('SAVED_SUCCESSFULLY'),
                variant: NOTIFICATION_VARIANT.SUCCESS,
                scrollToTop: true,
            });
        },
        [addNotification, isEditMode, isProject, swarmType, navigate]
    );
    const [searchParams] = useSearchParams();

    const defaultValues = useMemo(() => {
        const values = Object.fromEntries(
            fields
                // Filter out all the fields without defaults.
                .filter((field) => 'default' in field)
                // Create the mapping.
                .map((field) => [field.name, field.default])
        );

        const swarmName = searchParams.get('SWARM');

        if (swarmName) {
            values.deviceName = swarmName;
        }

        return values;
    }, [fields, searchParams]);

    const readOnlyFields = useMemo(
        () => fields.filter((field) => field.readOnly).map((field) => field.name),
        [fields]
    );

    const dataLinkProps = useMemo(
        () => ({
            queries,
            pk: id,
            onSubmitSuccessful,
            readOnlyFields,
            convertDataIntoFormValues,
        }),
        [queries, id, onSubmitSuccessful, readOnlyFields]
    );

    const thisProjectIgnoreFields = useMemo(
        () => [...projectIgnoreFields, ...readOnlyFields.map((name) => new RegExp(name))],
        [readOnlyFields]
    );

    return swarmType && formSchema ? (
        <BasePage
            title={title}
            besideTitle={
                <ButtonContainer>
                    {!isProject && <Button ref={detachButtonRef}>{t('Detach')}</Button>}
                    {showSaveButton && <Button ref={saveButtonRef}>{gettext('SAVE')}</Button>}
                </ButtonContainer>
            }
        >
            <GenericFormContextProvider swarmType={swarmType}>
                <Form
                    projectIgnoreFields={thisProjectIgnoreFields}
                    information={formSchema.information}
                    defaultValues={defaultValues}
                    InjectableComponent={InjectableComponent}
                    DataLink={GraphQLDataLink}
                    dataLinkProps={dataLinkProps}
                    configHelper={configHelper}
                    showSaveButton={showSaveButton}
                >
                    <Layout
                        fields={fields}
                        layout={layout}
                        formFieldOverrides={formFieldOverrides}
                    />
                </Form>
            </GenericFormContextProvider>
        </BasePage>
    ) : (
        <div className="main-container-new">{gettext('LOADING')}</div>
    );
}

GenericForm.propTypes = {
    swarmType: PropTypes.oneOf(Object.values(SwarmType)).isRequired,
    isProject: PropTypes.bool.isRequired,
};

export default GenericForm;
