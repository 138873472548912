import filesize from 'file-size';
import moment from 'moment';
import jstz from 'jstz';
import { BehaviorSubject } from 'rxjs';
import { useObservableEagerState } from 'observable-hooks';
import { useCallback } from 'react';
import { formatInTimeZone } from 'date-fns-tz';

// TIME_FORMAT is set from the base_includes.html template

export function getBrowserTimezone() {
    // Using jsTimezoneDetect instead of Moment.js to determine the timezone
    // because of this bug: https://github.com/moment/moment-timezone/issues/559
    let timezone = jstz.determine().name();

    // Fallback on UTC when timezone could not be determined.
    if (!timezone) {
        timezone = 'UTC';
    }

    return timezone;
}

const defaultFormatOptions = {
    timezone: null,
    useBrowserTimezone: false,
    hideTime: false,
    hideSeconds: false,
    dateFormat: 'dd-MM-yyyy',
};

// This method adjusts the time format tokens from moment.js to those used by date-fns.
// The original `TIME_FORMAT` used tokens from moment.js, which were based on
// the unstandardized PHP format. The date-fns library’s tokens are influenced
// by the Unicode Technical Standard #35, specifically the Unicode Locale Data
// Markup Language (LDML). As we transition from the deprecated moment.js towards
// date-fns, we currently need to maintain compatibility with both. Here, we adapt
// the tokens: `A` (AM/PM in moment.js) is replaced with `aa` (AM/PM in date-fns)
// as specified by LDML.
function adjustTimeFormatForCompatibility(timeFormat) {
    // Replace moment.js 'A' token with date-fns 'aa' for AM/PM.
    return timeFormat.replace('A', 'aa');
}

export function format(date, optionsWithoutDefaults) {
    const options = {
        ...defaultFormatOptions,
        ...optionsWithoutDefaults,
    };

    if (!options.timezone && !options.useBrowserTimezone) {
        throw Error('No timezone provided.');
    }

    const timezone = options.timezone ?? getBrowserTimezone();

    const formattedParts = [];

    formattedParts.push(formatInTimeZone(date, timezone, options.dateFormat));

    if (!options.hideTime) {
        // Adjust time format for compatibility between moment.js and date-fns.
        const adjustedTimeFormat = adjustTimeFormatForCompatibility(TIME_FORMAT);

        // Optionally remove seconds component.
        const timeFormat = options.hideSeconds
            ? adjustedTimeFormat.replace(':ss', '')
            : adjustedTimeFormat;

        formattedParts.push(formatInTimeZone(date, timezone, timeFormat));
    }

    return formattedParts.join(' ');
}

export function formatInUtc(date, formatStr, options) {
    return formatInTimeZone(date, 'UTC', formatStr, options);
}

/**
 * Formats the time to either 24h format or 12h format.
 * Format is decided by the user profile settings
 *
 * @param {string} timezone
 * @param {*} value MomentInput
 * @returns {string}
 */
export function formatTimeTz(timezone, value) {
    return moment(value).tz(timezone).format(TIME_FORMAT);
}

export function formatTime(value) {
    return formatTimeTz(getBrowserTimezone(), value);
}

export function formatDateTz(timezone, value) {
    return moment(value).tz(timezone).format('DD-MM-YYYY');
}

/**
 * Formats the given time to either 24h or 12h with date as well.
 * Format is decided by the user profile settings.
 *
 * @param {string} timezone
 * @param {*} value MomentInput
 * @returns {string}
 */
export function formatDatetimeTz(timezone, value) {
    return `${formatDateTz(timezone, value)} ${formatTimeTz(timezone, value)}`;
}

export function formatDatetime(value) {
    return formatDatetimeTz(getBrowserTimezone(), value);
}

/**
 * Expects a value in mm.
 * Will return the value as expected by the user profile.
 *
 * @param {number} distanceInMm
 * @returns {number}
 */
export function distanceConverter(distanceInMm) {
    return distanceInMm / DISTANCE_CONVERSION_DIVIDER;
}

export function revertDistanceConverter(value) {
    // All values are multiplied in that function so we can
    // divide them to get the original value (more or less).
    return value * DISTANCE_CONVERSION_DIVIDER;
}

export const distanceUnit$ = new BehaviorSubject(DISTANCE_UNIT);

export function getDistanceUnit() {
    return distanceUnit$.getValue();
}

export function replaceDistanceUnit(string) {
    return string.replace('mm', getDistanceUnit());
}

// Returns the `replaceDistanceUnit` function and triggers a rerender
// every time the distance unit changes.
export function useReplaceDistanceUnit() {
    const distanceUnit = useObservableEagerState(distanceUnit$);
    // Return the same function with a new reference to trigger a React rerender.
    // We've disabled exhaustive-deps because we're using distanceUnit as a dependency,
    // even though we're not actually utilizing it; it's merely to prompt a React rerender.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback((...args) => replaceDistanceUnit(...args), [distanceUnit]);
}

export function roundToTwo(num) {
    return +`${Math.round(`${num}e+2`)}e-2`;
}

export function lcFirst(input) {
    return input.charAt(0).toLowerCase() + input.slice(1);
}

export function formatFileSize(bytes) {
    return bytes !== null ? filesize(bytes, { fixed: 0 }).human('si') : '';
}
