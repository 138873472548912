import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import Tooltip from '../../utils/tooltip';
import { useNamingConventions } from '../../utils/naming-conventions';
import markdown from '../../utils/markdown';

const InformationTooltip = ({ information: rawInformation, className = '' }) => {
    const information = useNamingConventions(rawInformation);

    const content = useMemo(() => markdown(information), [information]);

    return (
        <Tooltip dangerousInput={content}>
            <span className={className}>
                <FontAwesomeIcon icon={faCircleInfo} />
            </span>
        </Tooltip>
    );
};
InformationTooltip.propTypes = {
    information: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default InformationTooltip;
