import PropTypes from 'prop-types';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { RoundedButton } from '../form/Button';
// eslint-disable-next-line
import '@splidejs/react-splide/css';
import './slider-styling.scss';

const defaultOptions = {
    type: 'loop',
    autoplay: true,
    interval: 10000, // 10 seconds.
};

const ArrowButton = ({
    buttonClasses,
    icon = faChevronRight,
    iconClasses = 'text-primary',
    size = 'xl',
}) => (
    <button className={buttonClasses}>
        <FontAwesomeIcon icon={icon} className={iconClasses} size={size} />
    </button>
);
ArrowButton.propTypes = {
    buttonClasses: PropTypes.string.isRequired,
    icon: PropTypes.object,
    iconClasses: PropTypes.string,
    size: PropTypes.string,
};

const Slider = ({ slides, options = defaultOptions }) => (
    <Splide hasTrack={false} options={options} aria-label="Image Slider">
        <SplideTrack>
            {slides.map((slide, index) => (
                <SplideSlide key={index}>
                    <div className="slide-main">
                        <img src={slide.src} alt={`Slider Image ${index}`} />
                        <div className="slide-content">
                            <h2 className="text-2xl font-semibold">{slide.title}</h2>
                            <p className="text-xs font-normal">{slide.description}</p>
                            {slide.button && (
                                <RoundedButton
                                    type="button"
                                    onClick={() => window.open(slide.button.link, '_blank')}
                                    variant="icon"
                                    flex
                                >
                                    {slide.button.text}
                                </RoundedButton>
                            )}
                        </div>
                    </div>
                </SplideSlide>
            ))}
        </SplideTrack>

        <div className="splide__arrows">
            <ArrowButton buttonClasses="splide__arrow splide__arrow--prev" />
            <ArrowButton buttonClasses="splide__arrow splide__arrow--next" />
        </div>
    </Splide>
);
Slider.propTypes = {
    slides: PropTypes.arrayOf(PropTypes.object).isRequired,
    options: PropTypes.object,
};

export default Slider;
