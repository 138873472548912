import PropTypes from 'prop-types';
import { faArrowRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useContext, createContext, useCallback, useEffect } from 'react';
import { isFunction, isObject } from 'lodash-es';

export const NOTIFICATION_VARIANT = Object.freeze({
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
});

const variantStyleMap = {
    [NOTIFICATION_VARIANT.SUCCESS]: 'bg-success text-white',
    [NOTIFICATION_VARIANT.ERROR]: 'bg-error text-white',
    [NOTIFICATION_VARIANT.WARNING]: 'bg-warning text-white',
};

const NotificationsContext = createContext();

export const addNotificationOptionTypes = {
    message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    variant: PropTypes.oneOf(Object.values(NOTIFICATION_VARIANT)),
    href: PropTypes.string,
    scrollToTop: PropTypes.bool,
};

// Temporary dirty hack to use the new React notification system outside
// of React (for example on the not yet converted overview page).
// Issue about converting the overview page:
// https://github.com/omnidots/website/issues/5579
let addNotificationRef = null;
export const addNotificationHack = (...args) => {
    isFunction(addNotificationRef) && addNotificationRef(...args);
};

function useNotificationsProvider() {
    const [notifications, setNotifications] = useState([]);

    const removeNotification = useCallback(
        (toBeRemoved) => {
            setNotifications((current) =>
                current.filter((notification, index) =>
                    isObject(toBeRemoved)
                        ? // Filter out the given notification object.
                          notification !== toBeRemoved
                        : // Filter out the given index.
                          index !== toBeRemoved
                )
            );
        },
        [setNotifications]
    );

    const addNotification = useCallback(
        (notification) => {
            const notificationWithDefaults = {
                variant: NOTIFICATION_VARIANT.SUCCESS,
                scrollToTop: false,
                ...notification,
            };
            PropTypes.checkPropTypes(
                addNotificationOptionTypes,
                notificationWithDefaults,
                'option',
                'addNotification'
            );
            setNotifications((current) => [...current, notificationWithDefaults]);

            if (notificationWithDefaults.scrollToTop) {
                window.scrollTo(0, 0);
            }

            return {
                remove: () => removeNotification(notificationWithDefaults),
            };
        },
        [setNotifications, removeNotification]
    );

    // Part of the hack mentioned above.
    useEffect(() => {
        addNotificationRef = addNotification;

        return () => {
            addNotificationRef = null;
        };
    }, [addNotification]);

    const clearNotifications = useCallback(() => {
        setNotifications((current) => current.filter((_, _index) => false));
    }, [setNotifications]);

    return {
        notifications,
        addNotification,
        removeNotification,
        clearNotifications,
    };
}

export function useNotifications() {
    const context = useContext(NotificationsContext);

    if (!context) {
        throw new Error('No notification context available.');
    }

    return context;
}

export function NotificationsProvider({ children }) {
    const fact = useNotificationsProvider();
    return <NotificationsContext.Provider value={fact}>{children}</NotificationsContext.Provider>;
}
NotificationsProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export const Notification = ({ message, variant, href, index, textSize = 'text-sm' }) => {
    const { removeNotification } = useNotifications();
    const buttonProps = href ? { href } : { onClick: () => removeNotification(index) };
    const buttonIcon = href ? faArrowRight : faXmark;
    const Button = href ? 'a' : 'button';

    return (
        <li
            className={`mb-2 rounded ${variantStyleMap[variant]} a-underline flex justify-between gap-x-4 p-1.5 ${textSize}`}
        >
            <div>{message}</div>
            <Button className="flex items-center" {...buttonProps}>
                <FontAwesomeIcon icon={buttonIcon} className="h-4 w-4 fill-current" />
            </Button>
        </li>
    );
};
Notification.propTypes = {
    message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    variant: PropTypes.oneOf(Object.values(NOTIFICATION_VARIANT)),
    href: PropTypes.string,
    index: PropTypes.number.isRequired,
    textSize: PropTypes.string,
};

export const NotificationsContainer = ({ notificationProps = {} }) => {
    const { notifications } = useNotifications();

    return (
        <ul className="mt-2 list-none">
            {notifications.map((notification, index) => (
                <Notification key={index} index={index} {...notification} {...notificationProps} />
            ))}
        </ul>
    );
};
NotificationsContainer.propTypes = {
    notificationProps: PropTypes.object,
};

export const ConvertDjangoNotifications = () => {
    const { addNotification } = useNotifications();

    useEffect(() => {
        // #notifications is a script element filled with notifications rendered by Django.
        const notificationsTag = document.getElementById('notifications');

        if (!notificationsTag) {
            return;
        }

        const fragment = document
            .createRange()
            .createContextualFragment(notificationsTag.textContent);

        Array.from(fragment.querySelectorAll('.messagelist'))
            .flatMap((list) =>
                Array.from(list.querySelectorAll('li')).map((item) => {
                    const variant = item.getAttribute('class');

                    const notification = {
                        // Django has the `info` and `success` notification types which have the
                        // exact same appearance. Here we convert the info type into the success
                        // type in order to prevent unnecessary duplication on the JS side.
                        variant: variant === 'info' ? 'success' : variant,
                    };

                    const button = item.getElementsByTagName('button')[0];
                    const buttonA = button.getElementsByTagName('a')[0];

                    if (buttonA) {
                        notification.href = buttonA.getAttribute('href');
                    }

                    button.remove();

                    notification.message = (
                        <span dangerouslySetInnerHTML={{ __html: item.innerHTML }} />
                    );

                    return notification;
                })
            )
            .forEach((notification) => addNotification(notification));
    }, [addNotification]);

    return null;
};
