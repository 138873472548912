import { fromEvent, startWith, map, BehaviorSubject } from 'rxjs';
import { useObservableEagerState } from 'observable-hooks';
import {
    formatDuration,
    intervalToDuration,
    secondsToMilliseconds,
    setYear,
    startOfYear,
} from 'date-fns';
import i18n from '../translations/i18n';

// DATE_FNS_FILE_MAPPING is replaced by webpack.DefinePlugin at compile time.
// We predefine dateFnsFileMapping because the DefinePlugin is unable to
// replace DATE_FNS_FILE_MAPPING within template literals.
// eslint-disable-next-line no-undef
const dateFnsFileMapping = DATE_FNS_FILE_MAPPING;

const getLocale = (language) =>
    // Ignoring this no-dynamic-require rule as we cannot use a dynamic import here as
    // that would be async and the datepicker package cannot handle promises. So to please
    // the datepicker package we use the sync require.
    // eslint-disable-next-line import/no-dynamic-require, global-require
    require(`date-fns/locale/${dateFnsFileMapping[language]}/index.js`);

export default getLocale;

export const locale$ = new BehaviorSubject(getLocale(i18n.language));

export const language$ = fromEvent(i18n, 'languageChanged').pipe(startWith(i18n.language));

// Listen for updates on language$.
language$
    .pipe(
        // Map those language changes to an locale.
        map((language) => getLocale(language))
    )
    // Push updates to `locale$`.
    .subscribe(locale$);

export const useLocale = () => useObservableEagerState(locale$);

export function getWeekDayWide(locale, weekDay, width = 'wide') {
    return locale.localize.day(weekDay, { width });
}

export const startOfOmnidots = startOfYear(setYear(new Date(), 2017));

export const secondsToDurationText = (seconds, locale) => {
    const duration = intervalToDuration({ start: 0, end: secondsToMilliseconds(seconds) });
    return formatDuration(duration, { locale });
};
