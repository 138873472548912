import { bisector } from 'd3-array';
import { Delaunay } from 'd3-delaunay';
import { line } from 'd3-shape';
import { select, pointer } from 'd3-selection';
import { first, flatMap, flatten, isEmpty, property, sortBy } from 'lodash-es';

import {
    BehaviorSubject,
    EMPTY,
    ReplaySubject,
    combineLatest,
    map,
    of,
    switchMap,
    tap,
} from 'rxjs';
import { NotImplementedError } from '../utils/errors';
import { BaseGraphElementsProvider } from './graph-elements/base-provider';
import { shareReplayRefCount } from '../utils/rxjs';
import { EventGraphElement } from './graph-elements/events-provider';

const positionsToPoints = (positions) => flatMap(positions, (record) => record.getToolTipPoints());

export class BaseTooltipHelper {
    constructor(graph, element, width, height) {
        this.graph = graph;
        this.element = element;
        this.width = width;
        this.height = height;

        this.dataSet = graph.data;

        this.plot = element
            .append('rect')
            .attr('class', 'tooltipHome')
            .attr('data-testid', 'tooltip-home')
            .attr('width', width)
            .attr('height', height);

        this.tooltip = select('body')
            .append('div')
            .attr('class', 'graphTooltip hide')
            .attr('data-testid', 'tooltip-balloon');

        this.graph.unmountPromise.then(() => {
            // Cleanup the tooltip balloon we attached to the document body.
            this.tooltip.remove();
        });

        this.event$ = new ReplaySubject(1);

        this.mouse$ = this.event$.pipe(map((event) => pointer(event, this.plot.node())));

        this.isMouseInGraph$ = new BehaviorSubject(false);

        /**
         * @type {Observable<GraphElementPosition>}
         */
        this.data$ = this.isMouseInGraph$.pipe(
            switchMap((mouseInGraph) =>
                mouseInGraph ? this.getDataForMouseLocation$() : of(null)
            ),
            shareReplayRefCount()
        );

        this.plot
            .on('mouseover', () => this.isMouseInGraph$.next(true))
            .on('mouseout', () => this.isMouseInGraph$.next(false))
            .on('mousemove', (event) => this.event$.next(event))
            .on('click', () => {
                const traceUrls = this.tooltip.select('a');

                if (!traceUrls.empty()) {
                    window.open(traceUrls.node().href, '_blank');
                }
            });

        this.positions$ = this.graph.activePlugins$.pipe(
            map((providers) =>
                providers
                    // Collect all the plugins that provide element positions.
                    .filter((plugin) => plugin instanceof BaseGraphElementsProvider)
                    // Map those plugin into an array of `graphElementPositions$`'s.
                    .map((provider) => provider.graphElementPositions$)
            ),
            switchMap((positionObservables) => {
                // Is there are no `graphElementPositions$`'s, default to an empty
                // array of positions.
                if (isEmpty(positionObservables)) {
                    return of([]);
                }

                // If there are `graphElementPositions$`'s, then start listening to
                // them all and combine them into a single array.
                return combineLatest(positionObservables).pipe(
                    map((positionArrays) => flatten(positionArrays))
                );
            }),
            shareReplayRefCount()
        );

        this.onlyShowToolTipForEvents$ = this.dataSet.dataTypeSettings$.pipe(
            map((x) => x.onlyShowToolTipForEvents ?? false)
        );

        this.initToolTipLineRenderer();
        this.initToolTipBalloonRenderer();
        this.initToolTipCirclesRenderer();
    }

    getDataForMouseLocation$() {
        throw new NotImplementedError();
    }

    getVperPeriodForTimestamp(timestamp) {
        return this.dataSet.vperPeriods.find((period) => {
            const { from, to } = period.timespan;

            return from <= timestamp && timestamp <= to;
        });
    }

    initToolTipCirclesRenderer() {
        const circles$ = this.data$.pipe(
            switchMap((data) => (data ? data.getToolTipCircles$(this.positions$) : of([])))
        );

        circles$
            .pipe(
                tap((circles) => {
                    this.element
                        .selectAll('.tooltip-circle')
                        .data(circles)
                        .join('circle')
                        .attr('class', (d) => `tooltip-circle ${d.type}`)
                        .attr('r', 4)
                        .attr('transform', (d) => d.transform);
                })
            )
            .subscribe();
    }

    initToolTipLineRenderer() {
        const linesFromEventAndScale$ = combineLatest([
            this.event$,
            this.graph.yAxis.scaleUpdated$,
        ]).pipe(
            map(([event, scale]) => {
                const domain = scale.domain();
                const plotRect = this.plot.node().getBoundingClientRect();
                const x = event.pageX - plotRect.left;

                return [
                    // The tooltip line:
                    [
                        // Start point:
                        [x, scale(domain[0])],
                        // End point:
                        [x, scale(domain[1])],
                    ],
                ];
            })
        );

        const drawLine = line();

        const followMouseWithLine$ = this.dataSet.dataTypeSettings$.pipe(
            map((x) => x.followMouseWithLine ?? false)
        );

        const isActive$ = followMouseWithLine$.pipe(
            switchMap((followMouseWithLine) =>
                followMouseWithLine ? this.isMouseInGraph$ : of(false)
            )
        );

        isActive$
            .pipe(
                switchMap((isActive) =>
                    isActive
                        ? // When we are active, start listening to the
                          // `linesFromEventAndScale` observable.
                          linesFromEventAndScale$
                        : // When we are inactive we will return an empty
                          // array of 'lines'. This will make D3 remove the line.
                          of([])
                ),
                tap((lines) => {
                    this.element
                        .selectAll('.tooltip-line')
                        .data(lines)
                        .join('svg:path')
                        .attr('class', 'tooltip-line')
                        .attr('d', drawLine);
                })
            )
            .subscribe();
    }

    getTooltipText(data) {
        return data.element.getToolTipText(this.dataSet, this.graph);
    }

    initToolTipBalloonRenderer() {
        const data$ = this.onlyShowToolTipForEvents$.pipe(
            switchMap((onlyShowToolTipForEvents) =>
                onlyShowToolTipForEvents
                    ? this.data$.pipe(
                          map((data) => (data?.element instanceof EventGraphElement ? data : null))
                      )
                    : this.data$
            )
        );

        data$
            .pipe(
                switchMap((data) => {
                    // When there is no data, hide the tooltip ans stop this stream.
                    if (!data) {
                        this.tooltip.classed('hide', true);
                        return EMPTY;
                    }

                    // When there is data, update the text and also start listening to the event$.
                    const tooltipText = this.getTooltipText(data);
                    this.tooltip.html(tooltipText);
                    return combineLatest([of(data), this.event$]);
                }),
                tap(([data, event]) => {
                    const { x } = first(data.getToolTipPoints());

                    // Contains the left offset of .tooltipHome relative to the document.
                    const tooltipHomeOffset =
                        this.plot.node().getBoundingClientRect().left + window.pageXOffset;

                    const offsetLeft = tooltipHomeOffset + x + 5;

                    this.tooltip
                        .style('top', `${event.pageY - 8 - 13 * 7}px`)
                        .style('left', `${offsetLeft}px`)
                        .classed('hide', false);
                })
            )
            .subscribe();
    }
}

/**
 * Tooltip helper that finds the best tooltip data to be shown
 * for the current mouse location trough a bisect on the x axis.
 */
export class BisectTooltipHelper extends BaseTooltipHelper {
    constructor(...args) {
        super(...args);

        this.propertyX = property(this.getBisectPropertyNameX());
    }

    getBisectPropertyNameX() {
        throw new NotImplementedError();
    }

    bisectX(positions, mouse) {
        const bisectorX = bisector(this.propertyX).left;
        return bisectorX(positions, mouse);
    }

    getDataForMouseLocation$() {
        return combineLatest([this.mouse$, this.positions$]).pipe(
            map(([mouse, positions]) => {
                const mouseX = mouse[0];

                const points = sortBy(positionsToPoints(positions), 'x');

                // Convert the mouse position to data positions.
                const i = this.bisectX(points, mouseX);
                const recordBeforeMouse = points[i - 1];
                const recordAfterMouse = points[i];
                let d = null;

                if (recordBeforeMouse && recordAfterMouse) {
                    const diffBeforeMouse = mouseX - this.propertyX(recordBeforeMouse);
                    const diffAfterMouse = this.propertyX(recordAfterMouse) - mouseX;
                    d = diffBeforeMouse > diffAfterMouse ? recordAfterMouse : recordBeforeMouse;
                } else if (recordAfterMouse) {
                    d = recordAfterMouse;
                } else {
                    // Last element.
                    d = points[points.length - 1];
                    if (!d) return null;
                }

                return d.position;
            })
        );
    }
}

export class TooltipHelperTimeGraph extends BisectTooltipHelper {
    getBisectPropertyNameX() {
        return 'x';
    }
}

/**
 * Tooltip helper that finds the best tooltip data to be shown
 * for the current mouse location trough Delaunay triangulation.
 */
export class DelaunayTooltipHelper extends BaseTooltipHelper {
    getDataForMouseLocation$() {
        return combineLatest([this.mouse$, this.positions$]).pipe(
            map(([mouse, positions]) => {
                const points = positionsToPoints(positions);

                const delaunay = Delaunay.from(
                    points,
                    (p) => p.x, // X
                    (p) => p.y // Y
                );
                const index = delaunay.find(...mouse);

                return points[index]?.position;
            })
        );
    }
}

export class TooltipHelperVeffDayGraph extends DelaunayTooltipHelper {}

export class TooltipHelperFrequencyGraph extends DelaunayTooltipHelper {}
