import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
    defaultDataIdFromObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { matchesProperty, memoize, negate } from 'lodash-es';
import { relayStylePagination } from '@apollo/client/utilities';
import fetch from 'cross-fetch';
import { createMockClient as _createMockClient } from 'mock-apollo-client';
import possibleTypes from '../possible-types.json';
import warning from './logger';
import { checkResponseForHttpErrors, IgnorableHttpError } from '../graph/http-helper';

// Hides the 'Download the Apollo DevTools for a better development experience...' console message.
if (!window.__APOLLO_DEVTOOLS_GLOBAL_HOOK__) {
    window.__APOLLO_DEVTOOLS_GLOBAL_HOOK__ = true;
}

const filterIgnoredErrors = negate(matchesProperty('extensions.code', 'NO_MEASUREMENTS'));

export const graphQLErrorHandler = ({ graphQLErrors, networkError, response, operation }) => {
    // Will hold all the `graphQLErrors` that where not ignored by us.
    let errors = [];

    if (graphQLErrors) {
        errors = graphQLErrors.filter(filterIgnoredErrors);

        errors.forEach(({ message, locations, path }) => {
            warning(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        });

        if (response) {
            // Ignore errors down the chain.
            // https://www.apollographql.com/docs/link/links/error/#ignoring-errors
            response.errors = errors;
        }
    }

    // NetworkError's without a response are not worth logging in as they never left the
    // user's computer due to no internet or canceled by the browser.
    if (networkError?.response) {
        try {
            checkResponseForHttpErrors(networkError.response);
        } catch (e) {
            if (!(e instanceof IgnorableHttpError)) {
                warning(`[Network error]: ${networkError}`, {
                    status: networkError.response.status,
                    url: networkError.response.url,
                    body: networkError.bodyText,
                    operation,
                });
            }
        }
    }
};

export const createClientConfig = (mock) => {
    const config = {
        cache: new InMemoryCache({
            possibleTypes,
            typePolicies: {
                Query: {
                    fields: {
                        customAlarms: relayStylePagination(),
                    },
                },
                Walkthrough: {
                    keyFields: ['name'],
                },
                ShareType: {
                    keyFields: ['entityId', 'entityType', 'forUser'],
                },
                Config: {
                    keyFields: (object) => {
                        // We do not want configs with no id in our cache.
                        if (!object.id) {
                            return false;
                        }

                        return defaultDataIdFromObject(object);
                    },
                },
            },
        }),
        connectToDevTools: true,
    };

    if (!mock) {
        config.link = ApolloLink.from([
            onError(graphQLErrorHandler),
            new HttpLink({
                uri: '/graphql/',
                credentials: 'same-origin',
                fetch,
            }),
        ]);
    }

    return config;
};

export const getClient = memoize(() => new ApolloClient(createClientConfig(false)));

export const createMockClient = () => _createMockClient(createClientConfig(true));

// Checks if given string is a type identifier provided by GraphQL.
export const isTypeIdentifier = (string) => string === '__typename';
