import { gql } from '@apollo/client';
import { uniq, upperFirst } from 'lodash-es';
import { DataTypes } from './data-types';

const VIBRATION_CONFIG_FIELDS_FRAGMENT = gql`
    fragment VibrationConfigFields on VibrationConfig {
        id
        sensorPk
        category
        dataSaveLevel
        measurementDuration
        traceSaveLevel
        tracePreTrigger
        tracePostTrigger
        measuringType
        concreteSettingLevel
        vibrationSource
        vibrationType
        guideLine
        flatLevel
        buildingState
        zetGevLaagdikte
        buildingLevel
        selfDefinedAlarmLine
        pipelineType
        liningMaterial
        constructionType
        vectorEnabled
        vdvPeriod
        vdvX
        vdvY
        vdvZ
        kbfSource
        kbfRailTraffic
        veffBuildingType
        veffCircumstance
        veffSituation
        veffLowerProtection
        veffVmaxCompliedBeforeChange
        veffDurationOfActivities
        atopEnabled
        vdvEnabled
        veffEnabled
        vtopEnabled
        swarmType
    }
`;
const AIR_CONFIG_FIELDS_FRAGMENT = gql`
    fragment AirConfigFields on AirConfig {
        id
        sensorPk
        swarmType
        disableLed
        logFlushInterval
        timezone
        autoZeroInterval
        setPointRh
        kFactor
        pm2p5Factor
    }
`;
const SOUND_CONFIG_FIELDS_FRAGMENT = gql`
    fragment SoundConfigFields on SoundConfig {
        id
        sensorPk
        swarmType
        disableLed
        logFlushInterval
        timezone
        soundGuideline
        frequencyWeighting
        timeWeighting
        lxEqInterval
        lXtMinMaxInterval
        lxPeakInterval
        lXnInterval
        soundMeasurementCorrectionOption
        soundMeasurementCorrectionValue
    }
`;

export const dataTypeQueryMap = [
    [[DataTypes.VTOP, DataTypes.FDOM], true],
    [[DataTypes.ATOP], true],
    [[DataTypes.VEFF], false],
    [[DataTypes.VDV], false],
];

const dataTypeIncludesVector = (dataType) =>
    dataTypeQueryMap.find(([dataTypes, _includeVector]) => dataTypes.includes(dataType))[1];

export const buildDataTypeSampleQuery = (
    includedDataTypes,
    operationName = `samples${includedDataTypes.map(upperFirst).join('')}`
) =>
    // OperationName is also used as cache key prefix.
    gql`
    query ${operationName}(
        $measuringPointId: Int!
        $startTime: BigInteger!
        $endTime: BigInteger!
    ) {
        rangedSamples(
            measuringPointId: $measuringPointId
            startTime: $startTime
            endTime: $endTime
        ) @connection(
            key: "${operationName}",
            filter: ["measuringPointId", "startTime", "endTime"]
        ) {
            timezone
            timespan {
                from
                to
            }
            ... on SampleBucket {
                ${includedDataTypes.map(
                    (dataType) =>
                        `${dataType} {timestamp x y z ${
                            dataTypeIncludesVector(dataType) ? 'vector' : ''
                        } } `
                )}
            }
        }
    }
`;

export const sampleQueries = Object.fromEntries(
    dataTypeQueryMap.flatMap(([includedDataTypes, _includeVector]) => {
        const query = buildDataTypeSampleQuery(includedDataTypes);
        return includedDataTypes.map((dataType) => [dataType, query]);
    })
);

export const samplesQuery = sampleQueries[DataTypes.VTOP];

export const vperPeriodsQuery = gql`
    query vperPeriods($measuringPointId: Int!, $startTime: BigInteger!, $endTime: BigInteger!) {
        rangedSamples(
            measuringPointId: $measuringPointId
            startTime: $startTime
            endTime: $endTime
        ) @connection(key: "vperPeriods", filter: ["measuringPointId", "startTime", "endTime"]) {
            timestamp
            timezone
            timespan {
                from
                to
            }

            ... on VperBucket {
                vmax {
                    x
                    y
                    z
                }
                vper {
                    x
                    y
                    z
                }
                period
                periodCompleteness {
                    completeness
                }
                limits {
                    A1
                    A2
                    A3
                    complies
                }
            }
            ... on KBFTrBucket {
                vmax {
                    x
                    y
                    z
                }
                vper {
                    x
                    y
                    z
                }
                period
                periodCompleteness {
                    completeness
                }
                limits {
                    A1
                    A2
                    A3
                    complies
                }
            }
        }
    }
`;

export const eventsQuery = gql`
    query events($measuringPointId: Int!, $startTime: BigInteger!, $endTime: BigInteger!) {
        events(measuringPointId: $measuringPointId, startTime: $startTime, endTime: $endTime) {
            name
            timestamp
            timezone

            ... on EventStart {
                startMessage
                config {
                    ... on VibrationConfig {
                        ...VibrationConfigFields
                    }
                    ... on AirConfig {
                        ...AirConfigFields
                    }
                    ... on SoundConfig {
                        ...SoundConfigFields
                    }
                }
            }

            ... on EventStop {
                stopCause
            }

            ... on EventServerContact {
                uploading
            }

            ... on EventLastRecord {
                uploading
            }
        }
    }
    ${VIBRATION_CONFIG_FIELDS_FRAGMENT}
    ${AIR_CONFIG_FIELDS_FRAGMENT}
    ${SOUND_CONFIG_FIELDS_FRAGMENT}
`;

export const tracesQuery = gql`
    query traces($measuringPointId: Int!, $startTime: BigInteger!, $endTime: BigInteger!) {
        traces(measuringPointId: $measuringPointId, startTime: $startTime, endTime: $endTime) {
            timestamp
            start
            end
        }
    }
`;

export const configQuery = gql`
    query config(
        $measuringPointId: Int!
        $startTime: BigInteger!
        $endTime: BigInteger!
        $dataTab: DataTab
    ) {
        config(
            measuringPointId: $measuringPointId
            startTime: $startTime
            endTime: $endTime
            dataTab: $dataTab
        ) {
            ... on VibrationConfig {
                ...VibrationConfigFields
            }
            ... on AirConfig {
                ...AirConfigFields
            }
            ... on SoundConfig {
                ...SoundConfigFields
            }
        }
    }
    ${VIBRATION_CONFIG_FIELDS_FRAGMENT}
    ${AIR_CONFIG_FIELDS_FRAGMENT}
    ${SOUND_CONFIG_FIELDS_FRAGMENT}
`;

export const dustQuery = gql`
    query dustSamples($measuringPointId: Int!, $startTime: BigInteger!, $endTime: BigInteger!) {
        dustSamples(measuringPointId: $measuringPointId, startTime: $startTime, endTime: $endTime) {
            containsPm101m
            containsPm1015m
            containsPm1060m
            containsPm101440m
            pm101m
            pm1015m
            pm1060m
            pm101440m
            containsPm2p51m
            containsPm2p515m
            containsPm2p560m
            containsPm2p51440m
            pm2p51m
            pm2p515m
            pm2p560m
            pm2p51440m
            timestamp
        }

        dustMeta(measuringPointId: $measuringPointId, startTime: $startTime, endTime: $endTime) {
            timestamp
            flow
            relativeHumidity
            temperature
        }
    }
`;

export const soundQuery = gql`
    query soundSamples($measuringPointId: Int!, $startTime: BigInteger!, $endTime: BigInteger!) {
        soundSamples(
            measuringPointId: $measuringPointId
            startTime: $startTime
            endTime: $endTime
        ) {
            lXtMin {
                timestamp
                value
            }
            lXtMax {
                timestamp
                value
            }
            lXEq {
                timestamp
                value
            }
            lXn {
                timestamp
                value {
                    value
                    timePercent
                }
            }
            lXPeak {
                timestamp
                value
            }
        }
    }
`;

export const dailyValuesQuery = gql`
    query dailyValues($measuringPointId: Int!) {
        dailyValues(measuringPointId: $measuringPointId) {
            values {
                date
                lXEq
                timeSpan {
                    from
                    to
                }
            }
            timeSpan {
                from
                to
            }
            settings {
                timeSettings {
                    start
                    end
                }
                triggers {
                    trigger
                    days
                }
            }
        }
    }
`;

export const ldenValuesQuery = gql`
    query ldenValues($measuringPointId: Int!, $startTime: BigInteger!, $endTime: BigInteger!) {
        ldenValues(measuringPointId: $measuringPointId, startTime: $startTime, endTime: $endTime) {
            values {
                timeWindow {
                    startDay
                    startTime

                    endDay
                    endTime

                    name

                    warningLevel
                    alarmLevel
                    correction
                }

                value
                timeSpan {
                    from
                    to
                }
            }
            timeSpan {
                from
                to
            }
            settings {
                startDay
                startTime
                endDay
                endTime
                name
                warningLevel
                alarmLevel
                correction
            }
        }
    }
`;

export const leqAlarmTableQuery = gql`
    query leqAlarmTable($measuringPointId: Int!, $startTime: BigInteger!, $endTime: BigInteger!) {
        leqAlarmTable(
            measuringPointId: $measuringPointId
            startTime: $startTime
            endTime: $endTime
        ) {
            data {
                startTime
                endTime
                name
                alarmInterval
                alarmLevel
                value
            }
        }
    }
`;

export const HighestVtopQuery = gql`
    query highestVtopEntries(
        $measuringPointId: Int!
        $startTime: BigInteger!
        $endTime: BigInteger!
    ) {
        highestVtopEntries(
            measuringPointId: $measuringPointId
            startTime: $startTime
            endTime: $endTime
        ) {
            entries {
                timestamp
                vtop {
                    x
                    y
                    z
                    vector
                }
                fdom {
                    x
                    y
                    z
                    vector
                }
                percentage {
                    x
                    y
                    z
                    vector
                }
            }
            vectorEnabled
        }
    }
`;

export const HighestVtopPerDayQuery = gql`
    query highestVtopPerDayEntries(
        $measuringPointId: Int!
        $startTime: BigInteger!
        $endTime: BigInteger!
    ) {
        highestVtopPerDay(
            measuringPointId: $measuringPointId
            startTime: $startTime
            endTime: $endTime
        ) {
            entries {
                timestamp
                vtop
                fdom
                percentage
                axis
            }
        }
    }
`;

export const dataSetQueries = [
    ...uniq(Object.values(sampleQueries)),
    eventsQuery,
    vperPeriodsQuery,
    tracesQuery,
    configQuery,
    dustQuery,
    soundQuery,
    dailyValuesQuery,
    ldenValuesQuery,
    leqAlarmTableQuery,
    HighestVtopQuery,
    HighestVtopPerDayQuery,
];
