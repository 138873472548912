import { Observable } from 'rxjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHand, faArrowPointer } from '@fortawesome/free-solid-svg-icons';
import useEventListener from '@use-it/event-listener';
import { useState } from 'react';
import { useObservableState } from 'observable-hooks';
import PropTypes from 'prop-types';

export const CursorFunction = Object.freeze({
    ZOOM: 'zoom',
    SELECT: 'select',
});

const buttonClassName =
    'h-[35px] w-[35px] data-[active=true]:border-primary data-[active=true]:border rounded hover:border hover:border-gray-400';

const iconMap = {
    [CursorFunction.SELECT]: faArrowPointer,
    [CursorFunction.ZOOM]: faHand,
};

const ariaTextMap = {
    [CursorFunction.SELECT]: gettext('CURSOR_FUNCTIONS_SELECT'),
    [CursorFunction.ZOOM]: gettext('CURSOR_FUNCTIONS_ZOOM'),
};

const SHIFT = 'Shift';

export function CursorFunctionSelector({ cursorFunction$ }) {
    const cursorFunction = useObservableState(cursorFunction$);
    const [shiftPressed, setShiftPressed] = useState(false);

    useEventListener('keydown', (event) => {
        if (event.key !== SHIFT) {
            return;
        }

        if (cursorFunction$.getValue() === CursorFunction.SELECT) {
            return;
        }

        if (event.key === SHIFT) {
            setShiftPressed(true);
            cursorFunction$.next(CursorFunction.SELECT);
        }
    });

    useEventListener('keyup', (event) => {
        if (event.key !== SHIFT) {
            return;
        }

        if (shiftPressed && cursorFunction$.getValue() === CursorFunction.SELECT) {
            cursorFunction$.next(CursorFunction.ZOOM);
        }

        setShiftPressed(false);
    });

    return (
        <span className="inline-block">
            {Object.values(CursorFunction).map((name) => (
                <button
                    key={name}
                    className={buttonClassName}
                    onClick={() => cursorFunction$.next(name)}
                    data-active={cursorFunction === name}
                    aria-label={ariaTextMap[name]}
                >
                    <FontAwesomeIcon icon={iconMap[name]} />
                </button>
            ))}
        </span>
    );
}
CursorFunctionSelector.propTypes = {
    cursorFunction$: PropTypes.instanceOf(Observable).isRequired,
};
