import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import ElementRefPropType from '../../utils';
import { defaultPropTypes } from '../utils';
import InputField from './InputField';
import { attachAutocompleteToElement } from '../../../utils/google-autocomplete';

function InjectableGoogleMapsSearchBoxElement({ inputRef, fieldOptions }) {
    const { name } = fieldOptions;
    const { setValue } = useFormContext();

    useEffect(() => {
        // Wait until the input exists.
        if (!inputRef.current) {
            return;
        }

        attachAutocompleteToElement(
            inputRef.current,
            // Somehow the Google Autocomplete does not trigger an onChange event on the input,
            // and thus the new text wont be stored in the React models, therefore we commit
            // it here manually after a location was selected.
            (location) => setValue(name, location)
        );

        // Currently there is no way to clean up Google Maps instances (as
        // far as I've found in my research), this isn't a problem for now,
        // but could be a possible memory leak when the Honeycomb becomes a
        // SPA.
        // Apart from the bug issue, I found an `unbindAll` method on the
        // instance itself. Unfortunately calling `unbindAll` doesn't seem
        // to clean up anything as the searchbox still works after that.
        // Bug issue: https://issuetracker.google.com/issues/35821412#comment32
    }, [inputRef, setValue, name]);

    return null;
}
InjectableGoogleMapsSearchBoxElement.propTypes = {
    inputRef: ElementRefPropType,
    ...defaultPropTypes,
};

function LocationField({ fieldOptions }) {
    return (
        <InputField
            fieldOptions={{
                ...fieldOptions,
                settings: {
                    ...fieldOptions.settings,
                    type: 'char',
                },
            }}
            InjectableElement={InjectableGoogleMapsSearchBoxElement}
            extraFormFieldContainerProps={{
                widthClass: 'w-full max-w-[790px]',
            }}
        />
    );
}
LocationField.propTypes = defaultPropTypes;

export default LocationField;
