import { gql } from '@apollo/client';
import { getClient } from './utils/graphql';

import { confirm } from './components/confirm/Confirm';
import copyModal, {
    MS_BEFORE_RELOAD_ON_SUCCESS,
} from './components/pages/measuringPoint/copyModal';

import {
    addNotificationHack,
    NOTIFICATION_VARIANT,
} from './components/notifications/Notifications';
import warning from './utils/logger';

const popupList = [
    // Delete Measuring Point.
    {
        className: 'delete-mp',
        data: ['mp'],
        url: '/measuring_point/delete/',
        urlKey: 'mp',
    },
    // Delete Project.
    {
        className: 'delete-project',
        data: ['project'],
        url: '/project/delete/',
        urlKey: 'project',
    },
    // Archive Project.
    {
        className: 'archive-project',
        data: ['project'],
        url: '/project/archive/',
        urlKey: 'project',
    },
    // Archive Measuring Point.
    {
        className: 'archive-mp',
        data: ['mp'],
        url: '/measuring_point/archive/',
        urlKey: 'mp',
    },
    // Detach Measuring Point.
    {
        className: 'detach-mp',
        data: ['mp'],
        url: '/measuring_point/unmount/',
        urlKey: 'mp',
    },
    // Undo Sharing Measuring Point.
    {
        className: 'unshare-mp',
        data: ['mp'],
        url: '/sharing/share-measuring-point-delete/',
        urlKey: 'mp',
    },
    // Undo Sharing Project.
    {
        className: 'unshare-project',
        data: ['project'],
        url: '/sharing/share-project-delete/',
        urlKey: 'project',
    },
    // Restart Measuring Point.
    {
        className: 'restart-mp',
        data: ['mp'],
        url: '/measuring_point/restart/',
        urlKey: 'mp',
    },
];

const DETACH_SENSOR_FROM_MEASURING_POINT = gql`
    mutation DetachSensorFromMeasuringPoint($id: ID!) {
        detachSensorFromMeasuringPoint(id: $id) {
            __typename
        }
    }
`;

const detachSensorFromMeasuringPoint = async (id) => {
    const mutationParameters = {
        variables: { id },
        mutation: DETACH_SENSOR_FROM_MEASURING_POINT,
    };

    await getClient()
        .mutate(mutationParameters)
        .then((_result) => {
            addNotificationHack({
                message: gettext('SENSOR_DETACHED_SUCCESSFULLY'),
                variant: NOTIFICATION_VARIANT.SUCCESS,
                scrollToTop: true,
            });
            setTimeout(() => window.location.reload(), MS_BEFORE_RELOAD_ON_SUCCESS);
        })
        .catch((error) => {
            addNotificationHack({
                message: gettext('SENSOR_DETACH_FAILED'),
                variant: NOTIFICATION_VARIANT.ERROR,
                scrollToTop: true,
            });
            warning({ message: error });
        });
};

const measuringPointOverviewPage = () => {
    Array.from(document.getElementsByClassName('copy-setting')).forEach((element) => {
        const { id, swarmType, source, destination } = element.dataset;

        element.addEventListener('click', (e) => {
            e.preventDefault();

            copyModal({
                id: parseInt(id, 10),
                swarmType,
                source,
                destination,
                onSuccess: () => {
                    // As the overview page is not yet converted to React, we currently
                    // just reload the page to get the newly created measuring point or
                    // project visible.
                    // Issue about converting the overview page:
                    // https://github.com/omnidots/website/issues/5579
                    setTimeout(() => window.location.reload(), MS_BEFORE_RELOAD_ON_SUCCESS);
                },
            });
        });
    });

    // Attaches the confirmation popup to the specified button.
    const popupGenerator = ({ className, url, urlKey }) => {
        Array.from(document.getElementsByClassName(className)).forEach((element) => {
            const { message, ...rest } = element.dataset;
            element.addEventListener('click', (e) => {
                e.preventDefault();
                confirm({
                    message,
                    convertMarkdown: true,
                    onConfirm: () => {
                        if (className === 'detach-mp') {
                            detachSensorFromMeasuringPoint(rest.mp);
                        } else {
                            window.location.href = url + rest[urlKey];
                        }
                    },
                });
            });
        });
    };

    popupList.forEach(({ className, data, url, urlKey }) =>
        popupGenerator({
            className,
            data,
            url,
            urlKey,
        })
    );
};

export default measuringPointOverviewPage;
