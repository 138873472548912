import { head, isEmpty } from 'lodash-es';
import {
    BehaviorSubject,
    Observable,
    distinctUntilChanged,
    shareReplay,
    pipe,
    switchMap,
    defer,
    tap,
    map,
    timer,
    take,
    startWith,
    identity,
    catchError,
    of,
    Subject,
    from,
    finalize,
} from 'rxjs';

// Operator that switches to the first Observable in the incoming array of Observables.
// Very useful to use in conjunction with the `useObservable` hook when the dependency
// array consists of only one observable.
export function switchHead() {
    return pipe(switchMap(head));
}

export function distinctUntilNotEmptyAnymore() {
    return pipe(distinctUntilChanged((previous, current) => isEmpty(previous) && isEmpty(current)));
}

const defaultFromObservableQueryOptions = {
    isLoading$: null,
    error$: null,
    useInitialLoading: false,
};

// Source: https://github.com/kamilkisiela/apollo-angular/issues/835#issuecomment-456332352
export function fromObservableQuery(observableQuery, optionsWithoutDefaults) {
    const options = {
        ...defaultFromObservableQueryOptions,
        ...optionsWithoutDefaults,
    };

    const observable = new Observable((subscriber) => {
        const subscription = observableQuery.subscribe(
            (value) => {
                subscriber.next(value);
            },
            (error) => {
                subscriber.error(error);
            },
            () => subscriber.complete()
        );

        return () => {
            subscription.unsubscribe();
        };
    });

    return observable.pipe(
        // Note: There is a bug in `watchQuery` that prevents emitting the initial
        // loading state (e.g., `loading: true`). Instead, due to this bug, the
        // only state sent is `loading: false` when the request completes. This
        // issue is also observed in Apollo Angular packages and is addressed
        // here:
        // https://github.com/kamilkisiela/apollo-angular/blob/f3dc3656a29e20172a8c25fa39ffc6da52b278d3/packages/apollo-angular/src/query-ref.ts#L30-L39
        options.useInitialLoading
            ? startWith({
                  ...observableQuery.getCurrentResult(false),
                  error: undefined,
                  partial: undefined,
                  stale: true,
              })
            : identity,
        tap({
            next: (response) => {
                // Whenever the observableQuery emits a new value, we also update
                // the loading state using isLoading$. This allows consumers of
                // isLoading$ to react to loading state changes in real-time.
                if (options.isLoading$ instanceof Subject) {
                    options.isLoading$.next(response.loading);
                }
            },
            subscribe: () => {
                // Clear any previous errors in error$.
                if (options.error$ instanceof Subject) {
                    options.error$.next();
                }
            },
            finalize: () => {
                // Ensure to set loading to false when the operation finalizes.
                if (options.isLoading$ instanceof Subject) {
                    options.isLoading$.next(false);
                }
            },
        }),
        // eslint-disable-next-line rxjs/no-implicit-any-catch
        catchError((err) => {
            if (options.error$ instanceof Subject) {
                // If an error subject is supplied, the error will be passed to it,
                // and `null` will be returned from this observable.
                options.error$.next(err);
                return of(null);
            }

            throw err;
        })
    );
}

/**
 * Creates an observable for executing a GraphQL mutation via Apollo client.
 * Optionally manages a loading and error state through provided BehaviorSubjects.
 *
 * @param {ApolloClient} client - The Apollo client instance to use for the mutation.
 * @param {Object} mutateArgs - Arguments to pass to the client's mutate method.
 * @param {BehaviorSubject<boolean>} isLoading$ - Optional. BehaviorSubject to manage
 *        the loading state. It emits true when the mutation starts and false when it
 *        either completes or an error occurs.
 * @param {BehaviorSubject<any>} error$ - Optional. BehaviorSubject to manage
 *        the error state. It emits an error object when an error occurs during the mutation.
 * @return {Observable} - An observable that triggers the mutation and handles errors.
 *        If an error occurs and error$ is provided, the observable will return `false`,
 *        otherwise it rethrows the error to be handled by further subscriptions.
 */
export function createMutationObservable(client, mutateArgs, isLoading$ = null, error$ = null) {
    return from(client.mutate(mutateArgs)).pipe(
        tap({
            subscribe: () => {
                if (isLoading$) {
                    isLoading$.next(true);
                }
                if (error$) {
                    error$.next();
                }
            },
            finalize: () => {
                if (isLoading$) {
                    isLoading$.next(false);
                }
            },
        }),
        // eslint-disable-next-line rxjs/no-implicit-any-catch
        catchError((err) => {
            if (error$) {
                // If an error subject is supplied, the error will be passed to it,
                // and `null` will be returned from this observable.
                error$.next(err);
                return of(null);
            }

            throw err;
        })
    );
}

export function shareReplayRefCount() {
    return pipe(shareReplay({ bufferSize: 1, refCount: true }));
}

export class Collection {
    constructor() {
        this._entries$ = new BehaviorSubject([]);
    }

    // Making entries$ accessible through a getter method enhances the flexibility
    // of our class. It sets the ground for extensions via inheritance. For instance,
    // you could add additional functionality that is triggered when entries$ is
    // subscribed to, as well as cleanup logic for when it is unsubscribed from.
    get entries$() {
        return this._entries$;
    }

    add(entry) {
        this._entries$.next(this._entries$.getValue().concat([entry]));
        return entry;
    }

    delete(entry) {
        this._entries$.next(this._entries$.getValue().filter((value) => value !== entry));
    }

    clear() {
        this._entries$.next([]);
    }
}

export function timeFrames(frameApproximateLength, numberOfFrames) {
    return defer(() => {
        const clock = timer(0, frameApproximateLength);
        if (numberOfFrames) {
            clock.pipe(take(numberOfFrames));
        }
        let t0 = Date.now();
        let t1;
        return clock.pipe(
            tap(() => {
                t1 = Date.now();
            }),
            map(() => t1 - t0),
            tap(() => {
                t0 = t1;
            })
        );
    });
}

/**
 * Creates an observable that starts the provided `sideEffect$` observable
 * when the source observable is subscribed to, and automatically unsubscribes
 * from the `sideEffect$` when the source observable is unsubscribed from,
 * completes, or errors.
 *
 * The side effect observable is treated independently in terms of error handling
 * and completion. Errors or completions from `sideEffect$` do not affect the
 * source observable's stream.
 *
 * Usage of `defer` ensures that the side effect starts exactly at the time of
 * subscription to the returned observable, preventing any premature side effects.
 *
 * @param {Observable} sideEffect$ - The observable to start as a side effect.
 * @returns {Observable} A function that takes the source observable and returns
 *     it with the side effect applied.
 *
 * @example
 * const source$ = of(1, 2, 3);
 * const logging$ = of('Logging started').pipe(tap(console.log));
 * const enhancedObservable = source$.pipe(withSideEffect(logging$));
 * enhancedObservable.subscribe(value => console.log(`Received: ${value}`));
 */
export function withSideEffect(sideEffect$) {
    return (source) =>
        defer(() => {
            const sideEffectSubscription = sideEffect$.subscribe();

            return source.pipe(
                finalize(() => {
                    // Clean up the sideEffect$ subscription when the source completes or errors,
                    // or when the subscription to the resultant observable is cancelled.
                    sideEffectSubscription.unsubscribe();
                })
            );
        });
}
