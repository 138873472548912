export function toFloat(value) {
    return parseFloat(value.replace(',', '.'));
}

export function tryToBoolean(value) {
    if (value === 'false' || value === 'true') {
        return value === 'true';
    }

    return value;
}
