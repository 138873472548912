import { useMemo, createContext, useContext, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
    faCaretDown,
    faCaretRight,
    faQuestionCircle,
    faBars,
    faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, merge } from 'lodash-es';

import schema from '../../schema.json';
import Button from '../form/Button';
import logo from './omnidots-logo.svg';
import BetaBadge from '../betaBadge/BetaBadge';
import NotificationBadge from '../notificationBadge/NotificationBadge';
import {
    languageMap,
    websiteUrl,
    languagePlaceholder,
    accountMenuId,
    downloadsMenuId,
} from './utils';
import { Profile } from '../../profile';

const MenuContext = createContext();
// The min-h-20 class does not work for some reason.
const menuWrapperClasses = 'relative min-h-[80px] shadow-menu bg-white';
const menuClasses =
    'absolute z-[98] m-auto flex h-20 w-full items-center bg-white px-4 pr-0 xl:pr-1.5 xl:space-x-5 xl:relative xl:max-w-[1600px]';
const menuListClasses =
    '-ml-4 mt-40 h-full grow items-center justify-end bg-white text-lg xl:mt-0 xl:flex xl:text-xs';
const authButtonClasses = 'whitespace-nowrap py-2.5 font-bold hover:bg-[#A6A6A6]';
const hamburgerMenuClasses = 'absolute right-8 top-6 h-8 w-8 pb-4 cursor-pointer xl:hidden';
const logoWrapperClasses = 'absolute w-52';
const buttonWrapperClasses = 'bg-white px-3 py-4 xl:py-0';
const listIconClasses = 'ml-1.5 h-3 w-3 fill-current';
const warningIconClasses = 'h-3 w-3 pb-0.5 pr-1 text-warning xl:pb-0';
const menuItemNameClasses = 'flex items-center whitespace-nowrap text-menu-color no-underline';
const subMenuListClasses = 'border border-b-0';
const menuItemListClasses =
    'group border-b border-gray-outline bg-white hover:bg-menu-hover xl:border-0';

const flipState = (state) => (state ? 'xl:hidden' : 'hide');

const useTranslatedMenu = () => {
    const { t } = useTranslation();

    return useMemo(() => {
        const translations = t('MENU', { returnObjects: true });
        return merge([], schema.menu, translations);
    }, [t]);
};

function shouldBeVisible({ checks = [] }, profile) {
    const failedCheck = checks.find((check) => !profile[check]);

    if (failedCheck) {
        return false;
    }

    return true;
}

function filterMenuForProfile(items, profile) {
    const { isProfileIncomplete, readyReportsCount } = profile;
    const showIncompleteIcon = (id) =>
        (isProfileIncomplete || readyReportsCount > 0) && id === accountMenuId;
    const showNotificationBadge = (id) => readyReportsCount > 0 && id === downloadsMenuId;

    return items
        .filter((item) => shouldBeVisible(item, profile))
        .map((item) => ({
            ...item,
            showIncompleteIcon: showIncompleteIcon(item.id),
            notificationCount: (showNotificationBadge(item.id) && readyReportsCount) || 0,
            // Replaces any URL containing the languagePlaceholder string
            // with the active language code.
            url: item.url && item.url.replace(languagePlaceholder, profile.languageCode),
            children: filterMenuForProfile(item.children, profile),
        }));
}

function useMenuForProfile(profile) {
    const menu = useTranslatedMenu();
    return useMemo(() => filterMenuForProfile(menu, profile), [menu, profile]);
}

const LanguageMenuItem = ({ item }) => {
    const { csrfToken } = useContext(MenuContext);
    return (
        <form action="/i18n/" method="post" className={item}>
            <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken}></input>
            <input name="next" type="hidden" value={window.location.pathname}></input>
            <input name="language" type="hidden" value={item}></input>
            <input
                type="submit"
                value={languageMap[item]}
                className={`${menuItemNameClasses} h-20 w-full py-7 pl-12 pr-8 xl:w-64 xl:px-8`}
            ></input>
        </form>
    );
};
LanguageMenuItem.propTypes = {
    item: PropTypes.string.isRequired,
};

function LanguageMenuList({ code }) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <li
            className={`${menuItemListClasses} language-menu`}
            onClick={() => setIsOpen((openState) => !openState)}
        >
            <a className={`${menuItemNameClasses} h-20 px-8`}>
                {languageMap[code]}
                <FontAwesomeIcon icon={faCaretDown} className={listIconClasses} />
            </a>
            <ul
                className={`${flipState(
                    isOpen
                )} ${subMenuListClasses} xl:absolute xl:w-64 xl:group-hover:block`}
            >
                {Object.keys(languageMap).map((item, i) => (
                    <li key={i} className="h-20 border-b bg-white hover:bg-menu-hover">
                        <LanguageMenuItem item={item} />
                    </li>
                ))}
            </ul>
        </li>
    );
}
LanguageMenuList.propTypes = {
    code: PropTypes.string,
    language: PropTypes.string,
};

const menuItemShape = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
    beta: PropTypes.bool,
    notificationCount: PropTypes.number.isRequired,
    showHelpIcon: PropTypes.bool,
    showIncompleteIcon: PropTypes.bool.isRequired,
    children(...args) {
        return PropTypes.arrayOf(PropTypes.shape(this)).apply(this, args);
    },
};

function SubMenuItem({ id, name, children, url, beta, notificationCount, level = 1, swarmIcon }) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <li
            className={`${
                level === 1 && 'group/sub'
            } border-b bg-white hover:bg-menu-hover xl:h-20`}
            onClick={() => setIsOpen((openState) => !openState)}
        >
            <a
                className={`${menuItemNameClasses} ${
                    level === 1 ? 'pl-12' : 'pl-20'
                } h-20 w-full justify-between py-7 pr-8 xl:px-8`}
                href={url}
                id={id}
            >
                <span className="flex items-center">
                    {!!swarmIcon && <div className={`${swarmIcon} mr-1.5 inline-block h-6 w-8`} />}
                    {name}
                    {beta && <BetaBadge className="ml-1" />}
                    {!!notificationCount && <NotificationBadge number={notificationCount} />}
                </span>
                {!isEmpty(children) && (
                    <>
                        <FontAwesomeIcon
                            icon={faCaretRight}
                            className={`${listIconClasses} hide xl:block`}
                        />
                        <FontAwesomeIcon
                            icon={faCaretDown}
                            className={`${listIconClasses} block xl:hidden`}
                        />
                    </>
                )}
            </a>
            {!isEmpty(children) && (
                <ul
                    className={`${flipState(
                        isOpen
                    )} ${subMenuListClasses} -top-[81px] left-[254px] xl:relative xl:w-full ${
                        level === 1 && 'xl:group-hover/sub:block'
                    }`}
                >
                    {children.map((item, i) => (
                        <SubMenuItem {...item} key={i} level={level + 1} />
                    ))}
                </ul>
            )}
        </li>
    );
}
SubMenuItem.propTypes = {
    ...menuItemShape,
    level: PropTypes.number,
};

function MenuItem({ id, name, children, url, showHelpIcon, showIncompleteIcon }) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <a
                className={`${menuItemNameClasses} h-20 justify-between px-8`}
                href={url}
                id={id}
                onClick={() => setIsOpen((openState) => !openState)}
            >
                {!showHelpIcon ? (
                    <div>
                        {showIncompleteIcon && (
                            <button type="button" aria-label="incomplete">
                                <FontAwesomeIcon
                                    icon={faCircleExclamation}
                                    className={warningIconClasses}
                                />
                            </button>
                        )}
                        {name}
                    </div>
                ) : (
                    <FontAwesomeIcon icon={faQuestionCircle} className="h-4 w-4 fill-current" />
                )}
                {!isEmpty(children) && (
                    <FontAwesomeIcon icon={faCaretDown} className={listIconClasses} />
                )}
            </a>
            <ul
                className={`${flipState(
                    isOpen
                )} ${subMenuListClasses} xl:absolute xl:w-64 xl:group-hover:block`}
            >
                {children.map((item, i) => (
                    <SubMenuItem {...item} key={i} />
                ))}
            </ul>
        </>
    );
}
MenuItem.propTypes = menuItemShape;

export function Menu({ profile }) {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const { logoutUrlPath, loginUrlPath } = schema;
    const {
        isAuthenticated,
        languageCode,
        resellerData: { isActive, topLogoUrl, companyName },
    } = profile;
    const menu = useMenuForProfile(profile);

    const redirectPath =
        window.location.pathname === logoutUrlPath || loginUrlPath ? '/' : window.location.pathname;

    const handleAuthButton = useCallback(
        () =>
            window.location.replace(
                `${isAuthenticated ? logoutUrlPath : loginUrlPath}?next=${redirectPath}`
            ),
        [isAuthenticated, loginUrlPath, logoutUrlPath, redirectPath]
    );

    return (
        <MenuContext.Provider value={profile}>
            <div className={menuWrapperClasses}>
                <div className={menuClasses}>
                    <a className={logoWrapperClasses} href={websiteUrl}>
                        <img
                            src={isActive && topLogoUrl ? topLogoUrl : logo}
                            alt={companyName ?? 'Omnidots'}
                        />
                    </a>
                    <ul className={`${menuListClasses} ${isOpen ? 'block' : 'hide xl:block'}`}>
                        {menu.map((item, i) => (
                            <li className={menuItemListClasses} key={i}>
                                <MenuItem {...item} />
                            </li>
                        ))}
                        <LanguageMenuList code={languageCode} />
                        <div className={buttonWrapperClasses}>
                            <Button
                                variant={isAuthenticated ? 'secondary' : 'primary'}
                                className={authButtonClasses}
                                onClick={handleAuthButton}
                                id="logout"
                            >
                                {isAuthenticated ? t('Sign out') : t('Log in')}
                            </Button>
                        </div>
                    </ul>
                    <FontAwesomeIcon
                        icon={faBars}
                        className={hamburgerMenuClasses}
                        onClick={() => setIsOpen(!isOpen)}
                    />
                </div>
            </div>
        </MenuContext.Provider>
    );
}
Menu.propTypes = {
    profile: PropTypes.instanceOf(Profile).isRequired,
};
