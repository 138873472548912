/* eslint no-console: ["error", { allow: ["warn", "error"] }] */

import { isEmpty } from 'lodash-es';
import rollbar from './rollbar';

const warning = (message, extraData = {}) => {
    const args = [message];

    if (!isEmpty(extraData)) {
        args.push(extraData);
    }

    if (rollbar) {
        // When extraData is `{postId: 1}` it will appear
        // as `message.extra.postId` in the Occurrences tab.
        rollbar.warning(...args);
        return;
    }

    console.warn(...args);
};

export default warning;
