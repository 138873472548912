import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { computePosition, shift } from '@floating-ui/dom';
import { Observable, switchMap } from 'rxjs';
import { useObservable, useSubscription } from 'observable-hooks';
import { containerClasses, overlayClassName } from '../../../components/confirm/Confirm';
import { NotificationsProvider } from '../../../components/notifications/Notifications';
import { Comment } from './models';

import CommentCreateView from './components/CommentCreateView';
import CommentDetailView from './components/CommentDetailView';
import profile, { ProfileProvider } from '../../../profile';

// Borrowed from https://stackblitz.com/edit/rxjs-mutation-observer?file=index.ts.
function fromMutationObserver(target, config) {
    return new Observable((subscriber) => {
        const mutation = new MutationObserver((mutations, _instance) => {
            subscriber.next(mutations);
        });
        mutation.observe(target, config);
        const unsubscribe = () => {
            mutation.disconnect();
        };
        return unsubscribe;
    });
}

function AlignModalWithActionButton({ actionButtonElementId, children }) {
    // Get the action button element by ID.
    const actionButtonElement = useMemo(
        () => document.getElementById(actionButtonElementId),
        [actionButtonElementId]
    );

    // useRef to keep track of the modal element.
    const modalRef = useRef();

    // Align modal position using @floating-ui/dom.
    const align = useCallback(() => {
        computePosition(actionButtonElement, modalRef.current, {
            middleware: [
                shift(), // Ensure modal stays within viewport.
            ],
        }).then(({ x, y }) => {
            // Check if modal exists when promise resolves.
            if (!modalRef.current) {
                return;
            }

            // Apply calculated positioning to the modal.
            Object.assign(modalRef.current.style, { left: `${x}px`, top: `${y}px` });
        });
    }, [actionButtonElement]);

    const actionButtonChanges$ = useObservable(
        (inputs$) =>
            inputs$.pipe(
                switchMap(([element]) =>
                    fromMutationObserver(element, {
                        attributes: true,
                        attributeFilter: ['style'],
                    })
                )
            ),
        [actionButtonElement]
    );

    useSubscription(actionButtonChanges$, align);
    useEffect(align, [align]);

    return (
        <div className={`absolute text-xs ${containerClasses}`} ref={modalRef}>
            {children}
        </div>
    );
}
AlignModalWithActionButton.propTypes = {
    actionButtonElementId: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

function showModalUI(actionButtonElementId, ModalBody, props) {
    confirmAlert({
        customUI: ({ onClose }) => (
            <ProfileProvider profile={profile}>
                <NotificationsProvider>
                    <AlignModalWithActionButton actionButtonElementId={actionButtonElementId}>
                        <ModalBody onClose={onClose} {...props} />
                    </AlignModalWithActionButton>
                </NotificationsProvider>
            </ProfileProvider>
        ),
        closeOnClickOutside: false,
        closeOnEscape: false,
        overlayClassName,
    });
}
showModalUI.propTypes = {
    actionButtonElementId: PropTypes.string.isRequired,
    ModalBody: PropTypes.elementType.isRequired,
    props: PropTypes.object,
};

export function commentCreateModal(partialComment, onSuccess) {
    showModalUI('action-buttons-container', CommentCreateView, { partialComment, onSuccess });
}
commentCreateModal.propTypes = {
    comment: PropTypes.shape({
        measuringPointId: PropTypes.number.isRequired,
        dataType: PropTypes.string.isRequired,
        startTime: PropTypes.number.isRequired,
        endTime: PropTypes.number.isRequired,
    }),
    onSuccess: PropTypes.func.isRequired,
};

export function commentDetailModal(comment) {
    showModalUI(`comment_${comment.id}`, CommentDetailView, { comment });
}
commentDetailModal.propTypes = {
    comment: PropTypes.instanceOf(Comment).isRequired,
};
