import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faXmark, faPlusMinus } from '@fortawesome/free-solid-svg-icons';
import { isNull } from 'lodash-es';
import ProgressBar from '../progressBar/ProgressBar';
import Tooltip from '../../../utils/tooltip';
import { DataTypes, fixFloat } from '../../../graph/data-types';
import { getLdenIcon } from '../utils';

function formatCorrection(correction, unit) {
    return `${correction > 0 ? '+' : ''}${correction}${unit ? ` ${unit}` : ''}`;
}

function LevelIcon({ level, isAlarm }) {
    return (
        <div className="flex content-center gap-px">
            <FontAwesomeIcon
                icon={isAlarm ? faXmark : faBell}
                className={`h-2 w-2 rounded-full p-0.5 text-white ${
                    isAlarm ? 'bg-error' : 'bg-warning'
                }`}
                size="sm"
            />
            <p className="text-2xs">{level}</p>
        </div>
    );
}
LevelIcon.propTypes = {
    level: PropTypes.number.isRequired,
    isAlarm: PropTypes.bool.isRequired,
    unit: PropTypes.string,
};

function SelectionBlock({
    name,
    startTime,
    endTime,
    value,
    correction,
    alarmLevel,
    warningLevel,
    isMinimal,
    unit,
}) {
    const measuredValue = fixFloat(DataTypes.LDEN, value);
    const correctionFormatted = formatCorrection(correction);
    const correctionFormattedWithUnit = formatCorrection(correction, unit);
    const { icon, iconColor } = getLdenIcon(measuredValue, warningLevel, alarmLevel);

    return (
        <div
            className={`flex h-20 flex-col justify-between rounded border border-gray-outline bg-white p-1 hover:border-graph-blue`}
        >
            <div className="flex flex-wrap justify-between">
                <div>
                    <div className="text-sm font-bold">{name}</div>
                    <div className="text-2xs text-menu-color">{`${startTime} - ${endTime}`}</div>
                </div>
                {!isNull(measuredValue) && (
                    <div className="flex">
                        <div className="flex">
                            <div className="text-nowrap text-sm font-bold">
                                {!isMinimal && `${measuredValue} ${unit}`}
                            </div>
                            <div className="h-1 pl-1">
                                <FontAwesomeIcon icon={icon} className={iconColor} size="lg" />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Tooltip
                content={
                    <>
                        {measuredValue && (
                            <>
                                <p>
                                    {gettext('MEASURED_LEVEL')}: {measuredValue} {unit}
                                    <FontAwesomeIcon icon={icon} className={iconColor} size="lg" />
                                </p>
                                <hr className="my-1" />
                            </>
                        )}
                        {correction !== 0 && (
                            <p>
                                <FontAwesomeIcon
                                    icon={faPlusMinus}
                                    className="mr-1 h-2 w-2 rounded-full bg-graph-blue p-0.5 text-white"
                                    size="sm"
                                />
                                {gettext('CORRECTION')}: {correctionFormattedWithUnit}
                            </p>
                        )}
                        <p>
                            <FontAwesomeIcon
                                icon={faBell}
                                className="mr-1 h-2 w-2 rounded-full bg-warning p-0.5 text-white"
                                size="sm"
                            />
                            {gettext('WARNING_LEVEL')}: {warningLevel} {unit}
                        </p>
                        <p>
                            <FontAwesomeIcon
                                icon={faXmark}
                                className="mr-1 h-2 w-2 rounded-full bg-error p-0.5 text-white"
                                size="sm"
                            />
                            {gettext('ALARM_LEVEL')}: {alarmLevel} {unit}
                        </p>
                    </>
                }
                arrow={false}
                placement="bottom-start"
            >
                <div>
                    <div className="flex gap-1 pb-0.5">
                        {correction !== 0 && (
                            <div className="flex content-center gap-px">
                                <FontAwesomeIcon
                                    icon={faPlusMinus}
                                    className="h-2 w-2 rounded-full bg-graph-blue p-0.5 text-white"
                                    size="sm"
                                />
                                <p className="text-2xs">{correctionFormatted}</p>
                            </div>
                        )}
                        {!isMinimal && warningLevel > 50 && (
                            <>
                                <LevelIcon level={warningLevel} unit={unit} isAlarm={false} />
                                <LevelIcon level={alarmLevel} unit={unit} isAlarm={true} />
                            </>
                        )}
                    </div>

                    <ProgressBar
                        alarmLevel={alarmLevel}
                        hasData={!!value}
                        warningLevel={warningLevel}
                        measuredValue={measuredValue}
                        isMinimal={isMinimal}
                        unit={unit}
                    />
                </div>
            </Tooltip>
        </div>
    );
}

SelectionBlock.propTypes = {
    name: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    value: PropTypes.number,
    correction: PropTypes.number.isRequired,
    alarmLevel: PropTypes.number.isRequired,
    warningLevel: PropTypes.number.isRequired,
    isMinimal: PropTypes.bool,
    unit: PropTypes.string,
};

export default SelectionBlock;
