import $ from 'jquery';

const notificationsModule = () => {
    $('.notifications-list .row.is-collapsable').on('click', function onClickRow() {
        $(this).toggleClass('collapsed');
    });

    $('.notifications-checkbox input').on('click', function onClickInput() {
        $(this).closest('a')[0].click();
    });
};

export default notificationsModule;
