import { forwardRef, useContext, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { isNil } from 'lodash-es';
import Input from '../../form/Input';
import {
    defaultPropTypes,
    FormContext,
    FormFieldContext,
    useIsOverridable,
    useIsOverridden,
} from '../utils';
import FormFieldContainer from './FormFieldContainer';

export function OverridableWrapper({
    render,
    disabled,
    overrideName,
    overridableByProjectSettings = true,
}) {
    const { setHover } = useContext(FormFieldContext);
    const { setOverriddenField, askAreYouSureToOverrideMessageOnce } = useContext(FormContext);

    // Unset hover when form field turns to disabled=false.
    useEffect(() => {
        if (!disabled) {
            setHover(false);
        }
    }, [disabled, setHover]);

    const extraInputProps = useMemo(() => {
        const props = {};

        if (disabled) {
            props.onMouseOver = () => {
                setHover(true);
            };
            props.onMouseLeave = () => {
                setHover(false);
            };
            props.onClick = () => {
                overridableByProjectSettings &&
                    askAreYouSureToOverrideMessageOnce() &&
                    setOverriddenField(overrideName, true);
            };
            props.readOnly = true;
        }

        return props;
    }, [
        disabled,
        setHover,
        overridableByProjectSettings,
        askAreYouSureToOverrideMessageOnce,
        setOverriddenField,
        overrideName,
    ]);

    return render(extraInputProps);
}
OverridableWrapper.propTypes = {
    disabled: PropTypes.bool.isRequired,
    overrideName: PropTypes.string.isRequired,
    overridableByProjectSettings: PropTypes.bool,
};
OverridableWrapper.defaultProps = {
    overridableByProjectSettings: true,
};

OverridableWrapper.displayName = 'OverridableWrapper';

/**
 * Wrapper around Input with some helpers for overridable fields.
 */
export const OverridableInput = forwardRef(
    ({ disabled, overrideName, overridableByProjectSettings = true, ...inputProps }, ref) => (
        <OverridableWrapper
            disabled={disabled}
            overrideName={overrideName}
            overridableByProjectSettings={overridableByProjectSettings}
            render={(props) => <Input ref={ref} {...inputProps} {...props} />}
        />
    )
);
OverridableInput.propTypes = {
    disabled: PropTypes.bool.isRequired,
    overrideName: PropTypes.string.isRequired,
    overridableByProjectSettings: PropTypes.bool,
};
OverridableInput.defaultProps = {
    overridableByProjectSettings: true,
};

OverridableInput.displayName = 'OverridableInput';

function InputField({ InjectableElement, fieldOptions, extraFormFieldContainerProps }) {
    const { name, settings } = fieldOptions;
    const { type, placeholder, step, required, maxLength, minValue, maxValue, disableWithZero } =
        settings;

    const overridable = useIsOverridable(name);
    const overridden = useIsOverridden(name);

    // Check if this field is explicitly disabled in views.py if so, it
    // cannot be enabled by project overrides.
    const disabled = settings.readOnly || (overridable && !overridden);
    const overridableByProjectSettings = !settings.readOnly && overridable && !overridden;
    const inputType = useMemo(
        () => (['integer', 'float'].includes(type) ? 'number' : null),
        [type]
    );
    const inputRef = useRef(null);

    return (
        <FormFieldContainer fieldOptions={fieldOptions} {...extraFormFieldContainerProps}>
            {InjectableElement && (
                <InjectableElement inputRef={inputRef} fieldOptions={fieldOptions} />
            )}
            <Controller
                name={name}
                defaultValue={settings.default}
                rules={{ required, maxLength, minValue, maxValue, type, disableWithZero }}
                render={({
                    field: { ref, value, onChange, ...formHookProps },
                    fieldState: { error },
                }) => (
                    <OverridableInput
                        id={`id_${name}`}
                        ref={(e) => {
                            ref(e);
                            inputRef.current = e;
                        }}
                        error={error}
                        disabled={disabled}
                        overrideName={name}
                        overridableByProjectSettings={overridableByProjectSettings}
                        placeholder={placeholder}
                        step={step}
                        type={inputType}
                        value={isNil(value) ? '' : value}
                        onChange={({ target: { value: newValue } }) => {
                            if (newValue === '') {
                                onChange(null);
                                return;
                            }

                            if (type === 'float') {
                                onChange(parseFloat(newValue.replace(',', '.'), 10));
                                return;
                            }

                            if (type === 'integer') {
                                onChange(parseInt(newValue, 10));
                                return;
                            }

                            onChange(newValue);
                        }}
                        {...formHookProps}
                    />
                )}
            />
        </FormFieldContainer>
    );
}
InputField.propTypes = defaultPropTypes;

export default InputField;
