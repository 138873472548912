/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable func-names */
/* eslint-disable no-unused-vars */
import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';

import { gql } from '@apollo/client';
import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { camelCase, isEmpty, isString, memoize, upperFirst } from 'lodash-es';
import { classList } from 'dynamic-class-list';
import $ from 'jquery';
import EventEmitter from 'eventemitter3';
import Papa from 'papaparse';
import { getClient } from '../utils/graphql';
import warning from '../utils/logger';
import markdown from '../utils/markdown';

const { schema, button: createButton, buttonCancel } = require('./schema');

const WALKTHROUGH_FRAGMENT = gql`
    fragment WalkthroughFields on Walkthrough {
        name
        status
        step
    }
`;

const GET_WALKTHROUGH_STATES = gql`
    query walkthroughs {
        walkthroughs {
            ...WalkthroughFields
        }
    }
    ${WALKTHROUGH_FRAGMENT}
`;

const UPDATE_WALKTHROUGH_STATE = gql`
    mutation updateWalkthroughState($name: String!, $status: WalkthroughStatus, $step: String) {
        updateWalkthroughState(name: $name, status: $status, step: $step) {
            ...WalkthroughFields
        }
    }
    ${WALKTHROUGH_FRAGMENT}
`;

const WalkthroughStatus = Object.freeze({
    PENDING: 'PENDING',
    OPEN: 'OPEN',
    CANCELED: 'CANCELED',
    AWAITING_BIGGER_SCREEN: 'AWAITING_BIGGER_SCREEN',
    FINISHED: 'FINISHED',
});

class Step extends Shepherd.Step {
    _setOptions(options) {
        this.id = options.id;
        this.translations = options.translations ?? {};
        this.translationKeys = options.translationKeys ?? {};

        options.modalOverlayOpeningPadding = 10;
        options.modalOverlayOpeningRadius = 25;
        options.cancelIcon = {
            enabled: true,
        };

        options.name = this.getTranslation('NAME');
        options.text = this.getTranslation('TEXT');
        options.title = this.getTranslation('TITLE');

        if (!isEmpty(options.buttons)) {
            options.buttons.forEach((button, index) => {
                button.text = this.getTranslation(`BUTTON_${index + 1}`);
            });
        }

        // Backup buttons for the Notion export.
        options.buttonsBackup = options.buttons;

        const $text = $('<div class="text">');
        $text.html(markdown(options.text));

        const $content = $('<div class="content">');
        $content.append($text);
        options.text = $content.get(0);

        if (options.buttonsVertical && !isEmpty(options.buttons)) {
            options.buttons.forEach((button) => {
                const $buttonContainer = $('<div>').addClass('vertical-button').appendTo($text);

                $('<button>')
                    .text(button.text)
                    .on('click', button.action.bind(this.tour))
                    .addClass(button.classes)
                    .appendTo($buttonContainer);
            });
            options.buttons = [];
        }

        options.popperOptions = {
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 20],
                    },
                },
            ],
        };

        super._setOptions(options);
    }

    _createTooltipContent() {
        const el = super._createTooltipContent();
        const $el = $(el);
        const $content = $el.find('.content');

        // Add status bar to tooltip.
        const $statusBar = $(this.tour.statusBarElement);
        $statusBar.insertBefore($content);

        // Create container for all the items on the left side of the header.
        const $leftItemHolder = $('<span>').insertBefore($el.find('.shepherd-cancel-icon'));

        // Create status bar toggle button.
        $('<i class="fa fa-bars toggle-status" aria-hidden="true"></i>')
            .on('click', (e) => {
                e.preventDefault();
                $el.toggleClass('status-bar-open');
                // Relocate tooltip after it got wider.
                this.tooltip.update();
            })
            .appendTo($leftItemHolder);

        if (!this.options.hideProgressIndicator) {
            // Create progress indicator.
            $('<span>')
                .text(
                    `${this.tour.steps.indexOf(this.tour.currentStep) + 1}/${
                        this.tour.steps.length
                    }`
                )
                .appendTo($leftItemHolder);
        }

        // Remove footer from the last step from the content container.
        $('.content .shepherd-footer').remove();

        // Move footer buttons inside content.
        $('.shepherd-footer').appendTo($content);

        return el;
    }

    getPrefixedTranslationKey(key) {
        return this.tour.getPrefixedTranslationKey(`${this.id.toUpperCase()}_${key}`);
    }

    getTranslation(partialkey) {
        // If this step has a specificly translation for this key, we use that.
        if (this.translations[partialkey]) {
            return this.translations[partialkey];
        }

        // Otherwise we get the translation from gettext using the key.
        const key = this.translationKeys[partialkey] ?? this.getPrefixedTranslationKey(partialkey);

        return gettext(key);
    }

    isApplicableToCurrentPath() {
        if (this.options.applicableToPath) {
            return this.options.applicableToPath === window.location.pathname;
        }

        return true;
    }

    goToPath() {
        if (this.options.applicableToPath) {
            window.location.href = window.location.origin + this.options.applicableToPath;
        }
    }

    getName() {
        return this.options.title;
    }

    isDone() {
        const ourIndex = this.tour.steps.indexOf(this);
        const activeStep = this.tour.getById(this.tour.getState().step);
        const activeStepIndex = activeStep ? this.tour.steps.indexOf(activeStep) : 0;
        return activeStepIndex > ourIndex;
    }

    showInStatusBar() {
        return !this.options.notShowInStatusBar;
    }

    /**
     * Checks if element exists when `this.options.attachTo.element` is specified.
     * Returns true when `this.options.attachTo.element` is not specified
     *
     * @returns {boolean}
     */
    attachToElementExists() {
        return !(this.options?.attachTo?.element && $(this.options.attachTo.element).length === 0);
    }

    show() {
        if (!isEmpty(this.options.toggleClasses)) {
            this.options.toggleClasses.forEach(([targetSelector, className]) => {
                $(targetSelector).addClass(className);
            });
        }

        // A step can use this to prevent a form from being submitted
        // when the user presses enter
        if (this.options?.advanceOn?.preventDefault) {
            const preventDefaultHandler = (e) => {
                e.preventDefault();
            };

            const $el = $(this.options.advanceOn.selector);
            const { event } = this.options.advanceOn;

            // Register prevent default handler.
            $el.on(event, preventDefaultHandler);

            const off = () => {
                $el.off(event, preventDefaultHandler);
            };

            // Unregister when step is hidden or destroyed.
            this.on('destroy', off);
            this.on('hide', off);
        }

        super.show();
    }
}

/**
 * Step used for indicating the wrong page. This step is
 * not added to the steps of the tour and thus is not visible as
 * part of the user's progress in the tour.
 */
class WrongPageStep extends Step {
    constructor(tour) {
        super(tour, {
            id: 'wrong-page',
            hideProgressIndicator: true,
            buttons: [
                createButton(function () {
                    this.getCurrentStepFromState().goToPath();
                }),
                buttonCancel(),
            ],
            buttonsVertical: true,
            translations: {
                TITLE: gettext('WALKTHROUGH_WRONG_PAGE_TITLE'),
                TEXT: gettext('WALKTHROUGH_WRONG_PAGE_TEXT'),
                BUTTON_1: gettext('WALKTHROUGH_CLICK_TO_GO_TO_CORRECT_PAGE'),
                BUTTON_2: gettext('CLOSE'),
            },
        });
    }
}

/**
 * Create a step used for indicating a too small screen. This step is
 * not added to the steps of the tour and thus is not visible as
 * part of the user's progress in the tour.
 */
class ScreenTooSmallStep extends Step {
    constructor(tour) {
        super(tour, {
            id: 'screen-too-small',
            hideProgressIndicator: true,
            buttons: [buttonCancel()],
            buttonsVertical: true,
            translations: {
                TITLE: gettext('WALKTHROUGH_SCREEN_TOO_SMALL_TITLE'),
                TEXT: gettext('WALKTHROUGH_SCREEN_TOO_SMALL_TEXT'),
                BUTTON_1: gettext('CLOSE'),
            },
        });
    }
}

class Tour extends Shepherd.Tour {
    constructor(manager, name, steps) {
        const options = {
            tourName: name,
            defaultStepOptions: {
                classes: 'shepherd-theme-omnidots',
                scrollTo: { behavior: 'smooth', block: 'center' },
            },
            useModalOverlay: true,
        };

        super(options);

        this.manager = manager;
        this.name = name;

        this.addSteps(steps);

        this.wrongPageStep = new WrongPageStep(this);
        this.screenTooSmallStep = new ScreenTooSmallStep(this);

        // Create `updateState` shortcuts from WalkthroughStatus values.
        Object.values(WalkthroughStatus).forEach((status) => {
            Object.defineProperty(this, `updateState${upperFirst(camelCase(status))}`, {
                value: (...args) => this.updateState(status, ...args),
            });
        });

        ['close', 'cancel'].forEach((event) =>
            this.on(event, ({ tour }) => {
                const step = tour.currentStep;

                // In case this event comes from a `ScreenTooSmall` step we store a
                // special status. With this status we can resume the walk-through
                // later when the user switches to a larger screen.
                if (step instanceof ScreenTooSmallStep) {
                    this.updateStateAwaitingBiggerScreen();
                    return;
                }

                this.updateStateCanceled();
            })
        );

        this.on('complete', () => {
            this.updateStateFinished();
        });

        let lastReceivedStatus = null;

        manager.on('updated', () => {
            if (!manager.isLoaded) {
                return;
            }

            const { status } = this.getState();

            // Only react to unique status changes.
            if (status === lastReceivedStatus) {
                return;
            }

            lastReceivedStatus = status;

            if (status === WalkthroughStatus.AWAITING_BIGGER_SCREEN) {
                this.checkScreenSize();
                if (!this.screenTooSmall) {
                    this.start();
                }
            }

            if (status === WalkthroughStatus.OPEN) {
                this.start();
            }
        });

        this.screenTooSmall = false;

        window.addEventListener('resize', this.checkScreenSize);
    }

    checkScreenSize() {
        // Value used is the collapsing point of the top menu, see menu.scss.
        const mobileMenuPoint = 1170;

        const width = document.body.clientWidth;
        const screenTooSmall = width < mobileMenuPoint;

        // Only react to changes.
        if (this.screenTooSmall === screenTooSmall) {
            return;
        }

        this.screenTooSmall = screenTooSmall;

        // Only show and hide modals if we are active.
        if (!this.isActive()) {
            return;
        }

        if (this.screenTooSmall) {
            this.showScreenTooSmallStep();
        } else {
            const { step } = this.getState();
            step && this.show(step);
        }
    }

    getPrefixedTranslationKey(key) {
        return `WALKTHROUGH_${this.name.toUpperCase()}_${key}`;
    }

    getNameTranslated() {
        return gettext(this.getPrefixedTranslationKey('NAME'));
    }

    addStep(options, index) {
        super.addStep(new Step(this, options), index);
    }

    /**
     * Used to show custom steps like the wrongPageStep and screenTooSmallStep.
     * This method ensures that the currently active step gets hidden properly
     * and the given custom step gets registered as active step.
     */
    _showCustomStep(customStep) {
        this._updateStateBeforeShow();
        this.currentStep = customStep;
        customStep.show();
    }

    showWrongPageStep() {
        this._showCustomStep(this.wrongPageStep);
    }

    showScreenTooSmallStep() {
        this._showCustomStep(this.screenTooSmallStep);
    }

    show(key = 0, forward = true) {
        const step = isString(key) ? this.getById(key) : this.steps[key];

        if (!step) {
            warning(`Walkthrough step '${key}' not found on '${this.name}'.`);
            return;
        }

        // Store at which step we are at in the database.
        this.updateState(null, step.id);

        if (!step.isApplicableToCurrentPath()) {
            this.showWrongPageStep();
            return;
        }

        super.show(key, forward);
    }

    getState() {
        return this.manager.getState(this.name);
    }

    isOpen() {
        return [WalkthroughStatus.OPEN, WalkthroughStatus.AWAITING_BIGGER_SCREEN].includes(
            this.getState().status
        );
    }

    updateState(status, step) {
        getClient().mutate({
            variables: {
                name: this.name,
                status,
                step,
            },
            mutation: UPDATE_WALKTHROUGH_STATE,
            update(cache, { data: { updateWalkthroughState } }) {
                // Update the cache after the update so that the observer
                // of the `GET_WALKTHROUGH_STATES` query receives the update.
                cache.modify({
                    fields: {
                        // eslint-disable-next-line default-param-last
                        walkthroughs(existingWalkthroughRefs = [], { readField }) {
                            // Quick safety check - if the walkthrough is already
                            // present in the cache, we don't need to add it again.
                            if (
                                existingWalkthroughRefs.some(
                                    (ref) => readField('name', ref) === updateWalkthroughState.name
                                )
                            ) {
                                return existingWalkthroughRefs;
                            }

                            const newWalkthroughRef = cache.writeFragment({
                                data: updateWalkthroughState,
                                fragment: WALKTHROUGH_FRAGMENT,
                            });

                            return [...existingWalkthroughRefs, newWalkthroughRef];
                        },
                    },
                });
            },
        });
    }

    start() {
        const { step } = this.getState();

        step ? this.show(step) : super.start();

        // Reset `screenTooSmall` so that the 'screen too small'
        // modal has a change to show.
        this.screenTooSmall = false;
        this.checkScreenSize();
    }

    /**
     * Return the current step according to the state in the backend.
     *
     * The `getCurrentStep` from ShepherdJS returns the last step that
     * was shown via `show`, this is not useful for us as that leads to
     * an undefined current step when the rendering of the statusbar happens
     * before the step was shown.
     *
     * @returns {Step}
     */
    getCurrentStepFromState() {
        const { step } = this.getState();
        return this.getById(step);
    }

    _done(event) {
        super._done(event);

        // Also manually destroy the `this.currentStep` as that may contain our 'wrong page'
        // step. Since the 'wrong page' step is not part of `this.steps` the base `_done`
        // of `Tour` will not clean that up.
        if (this.currentStep) {
            this.currentStep.destroy();
        }
    }
}

export const useManager = (manager) => {
    // Use `useState` as render trigger.
    const [_walkthroughStates, setWalkthroughStates] = useState([]);

    useEffect(() => {
        // Create event listener that calls handler function stored in ref.
        const handler = () => {
            setWalkthroughStates(manager.walkthroughStates);
        };

        // Add event listener.
        manager.on('updated', handler);

        // Remove event listener on cleanup.
        return () => {
            manager.on('updated', handler);
        };
    }, [manager]);

    return manager;
};

const StatusBar = ({ manager }) => {
    useManager(manager);

    if (!manager.isLoaded) {
        return null;
    }

    const walkthrough = manager.walkthroughs.find((w) => w.isOpen());

    if (!walkthrough) {
        return null;
    }

    return (
        <div key={walkthrough.name}>
            {walkthrough.steps
                .filter((step) => step.showInStatusBar())
                .map((step) => (
                    <div key={step.id}>
                        <div
                            className={classList('checkbox', {
                                checked: step.isDone(),
                            })}
                        ></div>
                        <a
                            onClick={(e) => {
                                e.preventDefault();
                                walkthrough.show(step.id);
                            }}
                        >
                            {step.getName()}
                        </a>
                    </div>
                ))}
        </div>
    );
};

class WalkthroughManager extends EventEmitter {
    constructor() {
        super();
        this.walkthroughs = [];
        this.walkthroughStates = [];

        this.isLoaded = false;

        const observable = getClient().watchQuery({
            query: GET_WALKTHROUGH_STATES,
        });

        this.querySubscription = observable.subscribe(({ data }) => {
            this.walkthroughStates = data.walkthroughs ?? [];

            this.isStatesLoaded = true;
            this.updated();
        });
    }

    loadSchema(options) {
        schema(options).forEach((walkthrough) => {
            this.addWalkthrough(walkthrough.name, walkthrough.steps);
        });

        this.statusBarElement = document.createElement('div');
        this.statusBarElement.classList.add('status-bar');
        ReactDOM.render(<StatusBar manager={this} />, this.statusBarElement);

        this.isSchemaLoaded = true;
        this.updated();
    }

    updated() {
        if (this.isSchemaLoaded && this.isStatesLoaded) {
            this.isLoaded = true;
            this.emit('updated');
        }
    }

    addWalkthrough(name, steps) {
        const tour = new Tour(this, name, steps);
        this.walkthroughs.push(tour);
        return tour;
    }

    getWalkthrough(name) {
        return this.walkthroughs.find((walkthrough) => walkthrough.name === name);
    }

    getState(name) {
        const foundState = this.walkthroughStates.find((state) => state.name === name);

        return foundState
            ? {
                  status: foundState.status,
                  step: foundState.step,
              }
            : {
                  status: WalkthroughStatus.PENDING,
                  step: null,
              };
    }
}

// Defined below `StatusBar` to avoid circular dependencies.
StatusBar.propTypes = {
    manager: PropTypes.instanceOf(WalkthroughManager).isRequired,
};

export const getWalkthroughManager = memoize(() => new WalkthroughManager(schema));

const downloadNotionCsv = (manager) => {
    const data = manager.walkthroughs.flatMap((walkthrough, i1) =>
        walkthrough.steps.map((step, i2) => ({
            'Step #': i1 + 1 + (i2 + 1) / 100,
            'Walkthrough ID': walkthrough.name,
            'Step ID': step.options.id,
            Title: step.options.title,
            Text: step.getTranslation('TEXT'),
            'Applicable to path': step.options.applicableToPath,
            'Button 1 text': step.options.buttonsBackup?.[0]?.text,
            'Button 2 text': step.options.buttonsBackup?.[1]?.text,
        }))
    );

    const csv = Papa.unparse(data);

    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const csvURL = window.URL.createObjectURL(csvData);

    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'download.csv');
    tempLink.click();
};

export const walkthroughModule = ({ options: { demoMeasuringPoint } }) => {
    const manager = getWalkthroughManager();
    manager.loadSchema({
        demoMeasuringPoint,
    });

    window.downloadNotionCsv = downloadNotionCsv.bind(this, manager);
};
