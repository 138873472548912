import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { useObservableState } from 'observable-hooks';
import { useProfile } from '../../../../profile';

import { formatNoSeconds } from '../utils';
import { Comment } from '../models';
import { commentContainerClasses } from './CommentInput';

export function CommentView({ comment, isParent }) {
    const profile = useProfile();

    const text = useObservableState(comment.text$);
    const createdAt = useObservableState(comment.createdAt$);
    const editedAt = useObservableState(comment.editedAt$);
    const timezone = useObservableState(comment.collection.dataSet.timezone$);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const isMutating = false;

    const isOwner = profile.userId === parseInt(comment.user.id, 10);

    const flexDirection = isOwner ? 'flex-row' : 'flex-row-reverse';
    const textDirection = isOwner ? 'text-left' : 'text-right';

    const commentMeta = `${comment.user.username} ${
        editedAt
            ? `${formatNoSeconds(editedAt, timezone)} (${gettext('EDITED')})`
            : formatNoSeconds(createdAt, timezone)
    }`;

    const buttons = useMemo(() => {
        if (showDeleteConfirmation) {
            return [
                {
                    icon: faCircleCheck,
                    color: 'green',
                    onClick: () => comment.delete(),
                    label: gettext('CONFIRM'),
                },
                {
                    icon: faCircleXmark,
                    color: 'red',
                    onClick: () => setShowDeleteConfirmation(false),
                    label: gettext('CANCEL'),
                },
            ];
        }

        if (isOwner) {
            const iconColor = isMutating ? 'gray' : 'black';
            const extraClass = isMutating ? 'opacity-50 pointer-events-none' : '';

            return [
                {
                    icon: faEdit,
                    color: iconColor,
                    extraClass,
                    onClick: () => comment.setEditing(true),
                    label: gettext('EDIT'),
                },
                {
                    icon: faTrash,
                    color: iconColor,
                    extraClass,
                    onClick: () => setShowDeleteConfirmation(true),
                    label: gettext('REMOVE'),
                },
            ];
        }

        return [];
    }, [comment, isMutating, isOwner, showDeleteConfirmation]);

    return (
        <div className={`${commentContainerClasses}`}>
            <div className={`flex ${flexDirection} justify-between`}>
                <p className={`text-oslo-gray ${textDirection}`}>{commentMeta}</p>

                {buttons && (
                    <div className={`flex ${flexDirection} gap-x-2`}>
                        {buttons.map(({ icon, color, extraClass, onClick, label }, index) => (
                            <button onClick={onClick} key={index} aria-label={label}>
                                <FontAwesomeIcon
                                    className={`cursor-pointer ${extraClass}`}
                                    icon={icon}
                                    color={color}
                                />
                            </button>
                        ))}
                    </div>
                )}
            </div>
            <p className={`${textDirection} whitespace-pre-line`}>
                {!showDeleteConfirmation && text}
                {showDeleteConfirmation &&
                    (isParent
                        ? `${gettext('REMOVE_COMMENT')}\n${gettext('REMOVE_FIRST_COMMENT')}`
                        : gettext('REMOVE_COMMENT'))}
            </p>
        </div>
    );
}
CommentView.propTypes = {
    comment: PropTypes.instanceOf(Comment).isRequired,
    isParent: PropTypes.bool.isRequired,
};
