import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { RoundedButton } from '../../form/Button';

const sharedPropTypes = {
    disabled: PropTypes.bool,
    manager: PropTypes.any,
};

export function AddRowButton({ disabled, manager }) {
    return (
        <RoundedButton
            type="button"
            variant="icon"
            disabled={disabled}
            onClick={() => {
                manager.addRow();
            }}
        >
            <FontAwesomeIcon icon={faPlus} size="sm" />
            <span>{gettext('ADD_ROW')}</span>
        </RoundedButton>
    );
}
AddRowButton.propTypes = {
    ...sharedPropTypes,
};

export function DeleteRowButton({ disabled, manager, index }) {
    return (
        <div className="mt-10">
            <button
                type="button"
                disabled={disabled}
                aria-label={gettext('REMOVE')}
                onClick={() => {
                    manager.removeRow(index);
                }}
                className="mb-5"
            >
                <FontAwesomeIcon icon={faTimesCircle} className="text-error" />
            </button>
        </div>
    );
}
DeleteRowButton.propTypes = {
    ...sharedPropTypes,
    index: PropTypes.number,
};
