import { Routes, Route } from 'react-router-dom';
import GenericSharingPage from './GenericSharingPage';
import { ENTITY_TYPE } from './utils';

export const ENTITY_TYPE_EDIT_PATHS = Object.freeze({
    [ENTITY_TYPE.USER]: 'share-user-edit',
    [ENTITY_TYPE.MEASURING_POINT]: 'share-measuring-point-edit/:id/',
    [ENTITY_TYPE.PROJECT]: 'share-project-edit/:id/',
});

export default function SharingRoutes() {
    return (
        <Routes>
            {Object.entries(ENTITY_TYPE_EDIT_PATHS).map(([entityType, path]) => (
                <Route
                    key={path}
                    path={path}
                    element={<GenericSharingPage entityType={entityType} />}
                ></Route>
            ))}
        </Routes>
    );
}
