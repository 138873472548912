import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonLoader = ({ wrapperClassName, className, count }) => (
    <div className={wrapperClassName}>
        <Skeleton count={count} className={className} />
    </div>
);

SkeletonLoader.propTypes = {
    wrapperClassName: PropTypes.string,
    className: PropTypes.string,
    count: PropTypes.number,
};

export default SkeletonLoader;
