/* eslint-disable */

import { bisector } from 'd3-array';
import { scaleLinear } from 'd3-scale';
import moment from 'moment';
import 'moment-timezone';
import { formatInTimeZone } from 'date-fns-tz';

import truncate from './moment-truncate';
import profile from '../profile';

export function timeFormat(date, locale) {
    let formatString;

    const hourFormat = profile.use24HourNotation ? 'HH' : 'hh';

    if (truncate(date, 'second') < date) {
        formatString = 'SSS'; // milliseconds.
    } else {
        if (truncate(date, 'minute') < date) {
            formatString = `${hourFormat}:mm:ss`; // hours, minutes, seconds.
        } else if (truncate(date, 'hour') < date) {
            formatString = `${hourFormat}:mm`; // hours, minutes.
        } else {
            formatString = `${hourFormat}:00`; // hours.
        }

        if (profile.use24HourNotation) {
            formatString += ' aa';
        }
    }

    return formatInTimeZone(date.toDate(), date.tz(), formatString, {locale});
};

function dateFormat(date, locale) {
    if (truncate(date, 'day') < date) {
        return timeFormat(date, locale);
    }

    let formatString;

    if (truncate(date, 'month') < date) {
        if (truncate(date, 'week') < date) {
            formatString = 'eee d'; // abbr day name, month day number.
        } else {
            formatString = 'MMM d'; // abbr month name, month day number.
        }
    } else if (truncate(date, 'year') < date) {
        formatString = 'MMMM'; // month name.
    } else {
        formatString = 'yyyy'; // year.
    }

    return formatInTimeZone(date.toDate(), date.tz(), formatString, {locale});
};

const _interval = (function () {
    // See [d3-time][] for API docs.
    //
    // [d3-time]: https://github.com/d3/d3-time#_interval
    function newInterval(floori, offseti, count, field) {
        function interval(date) {
            return floori((date = moment(date))), date;
        }

        interval.floor = interval;

        interval.ceil = function (date) {
            date = moment(date).subtract(1, 'milliseconds');
            return floori(date), offseti(date, 1), floori(date), date;
        };

        interval.range = function (start, stop, step) {
            const range = [];
            start = interval.ceil(start);
            step = step == null ? 1 : Math.floor(step);
            if (!start.isBefore(stop) || !(step > 0)) return range; // also handles Invalid Date
            do {
                range.push(moment(start));
            } while ((offseti(start, step), floori(start), start < stop));
            return range;
        };

        interval.filter = function (test) {
            return newInterval(
                (date) => {
                    if (date.isSameOrAfter(date)) {
                        while ((floori(date), !test(date))) {
                            date.subtract(1, 'milliseconds');
                        }
                    }
                },
                (date, step) => {
                    if (date.isSameOrAfter(date)) {
                        while (--step >= 0) {
                            while ((offseti(date, 1), !test(date))) {} // eslint-disable-line no-empty
                        }
                    }
                }
            );
        };

        interval.count = function (start, end) {
            const a = moment(start);
            const b = moment(end);
            return Math.floor(count(a, b));
        };

        interval.every = function (step) {
            step = Math.floor(step);

            if (!isFinite(step) || !(step > 0)) {
                return null;
            }

            if (!(step > 1)) {
                return interval;
            }

            const filter = field
                ? (d) => field(d) % step === 0
                : (d) => interval.count(0, d) % step === 0;

            return interval.filter(filter);
        };

        return interval;
    }

    const namespace = {};
    const units = [
        { name: 'second' },
        { name: 'minute' },
        { name: 'hour' },
        { name: 'day', count: 'date' },
        { name: 'month' },
        { name: 'year' },
    ];

    for (let i = units.length - 1; i >= 0; i--) {
        const unit = units[i];

        (function (unit) {
            namespace[unit.name] = newInterval(
                (date) => {
                    date.startOf(unit.name);
                },
                (date, step) => {
                    date.add(step, unit.name);
                },
                (start, end) => end.diff(start, `${unit.name}s`),
                (date) => date[unit.count || unit.name]()
            );
        })(unit);
    }

    function weekday(i) {
        return newInterval(
            (date) => {
                date.startOf('week');
            },
            (date, step) => {
                date.add(step, 'week');
            },
            (start, end) => end.diff(start, 'weeks')
        );
    }

    namespace.sunday = weekday(0);

    return namespace;
})();

const scaleTimezone = (() => {
    const { duration } = moment;

    const tickIntervals = [
        // Duration, times, d3 interval.
        [duration(1, 'seconds'), 1, _interval.second],
        [duration(5, 'seconds'), 5, _interval.second],
        [duration(15, 'seconds'), 15, _interval.second],
        [duration(30, 'seconds'), 30, _interval.second],
        [duration(1, 'minutes'), 1, _interval.minute],
        [duration(5, 'minutes'), 5, _interval.minute],
        [duration(15, 'minutes'), 15, _interval.minute],
        [duration(30, 'minutes'), 30, _interval.minute],
        [duration(1, 'hours'), 1, _interval.hour],
        [duration(3, 'hours'), 3, _interval.hour],
        [duration(6, 'hours'), 6, _interval.hour],
        [duration(12, 'hours'), 12, _interval.hour],
        [duration(1, 'days'), 1, _interval.day],
        [duration(2, 'days'), 2, _interval.day],
        [duration(1, 'weeks'), 1, _interval.sunday],
        [duration(1, 'months'), 1, _interval.month],
        [duration(3, 'months'), 3, _interval.month],
        [duration(1, 'years'), 1, _interval.year],
    ];

    function toMs(d) {
        return d.asMilliseconds();
    }

    function tickInterval(count, start, stop) {
        const target = Math.abs(stop - start) / count;
        let i = bisector((index) => toMs(index[0])).right(tickIntervals, target);

        // If the bisector has not been able to find an existing tickInterval
        // for the calculated tickInterval, then we fall back on the one that
        // is closest.
        if (i === 0) {
            i = 1;
        } else if (i === tickIntervals.length) {
            i = tickIntervals.length - 1;
        }

        // I don't know why this is done. It's in the d3 code.
        const a = target / toMs(tickIntervals[i - 1][0]);
        const b = toMs(tickIntervals[i][0]) / target;
        i = a < b ? i - 1 : i;
        return tickIntervals[i][2].every(tickIntervals[i][1]);
    }

    return (timezoneName, locale) => {
        const scale = scaleLinear();
        const { domain } = scale;
        const { invert } = scale;

        function date(t) {
            return new Date(t);
        }

        // Domain sets or gets the domain of the scale, i.e. the minimum and
        // maximum input value of a scale.
        //
        // When no arguments are given the current domain is translated
        // from timestamps to moment objects and returned.
        scale.domain = function (_) {
            if (arguments.length) {
                return domain(_);
            }
            return domain().map(date);
        };

        scale.ticks = function () {
            const d = domain();
            let start = d[0];
            let stop = d[1];

            const interval = tickInterval(10, start, stop);
            start = moment(start).tz(this.timezoneName);
            stop = moment(stop).tz(this.timezoneName);
            const ticks = interval.range(start, moment(stop).add(1, 'ms'));

            return ticks;
        };

        scale.invert = function (y) {
            return date(invert(y));
        };

        scale.tickFormat = function () {
            return (date) => {
                return dateFormat(date, scale.locale);
            };
        };

        scale.timezoneName = timezoneName;


        scale.setTimezoneName = function (newTimezoneName) {
            scale.timezoneName = newTimezoneName;
        };

        scale.locale = locale

        scale.setLocale = function (newLocale) {
            scale.locale = newLocale;
        };

        return scale;
    };
})();

export default scaleTimezone;
