import '../../../backend/sbr/static/graph.scss';

import { axisBottom } from 'd3-axis';
import { brushX } from 'd3-brush';
import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import EventEmitter from 'eventemitter3';
import $ from 'jquery';
import { defaultTo, get, some } from 'lodash-es';
import tippy from 'tippy.js';
import {
    BehaviorSubject,
    ReplaySubject,
    Subject,
    combineLatest,
    distinctUntilChanged,
    firstValueFrom,
    map,
    of,
    skip,
    startWith,
    switchMap,
} from 'rxjs';
import scaleTimezone from '../utils/d3-tz-scale';
import delay from '../utils/delay';
import { NotImplementedError } from '../utils/errors';
import { TimeXAxis, XAxis, YAxis } from './axis';
import { getMinuteDays, getYearDays, initLineWarningHelper, getOverrides } from './graph-helpers';
import { TooltipHelperFrequencyGraph, TooltipHelperTimeGraph } from './tooltip-helper';
import { ZoomHelper, ValueTimeGraphZoomHelper } from './zoom-helper';
import { applyNamingConvention, namingConventions$ } from '../utils/naming-conventions';
import { DateRange, sortRecords } from './dataset';
import NewLegendRenderer from './new-legend';
import { LegendRenderer } from './legend-helpers';
import FrequencySamplesGraphElementsProvider from './graph-elements/frequency-provider';
import { SamplesGraphElementsProvider } from './graph-elements/samples-provider';
import EventsGraphElementsProvider from './graph-elements/events-provider';
import { VperPeriodGraphElementsProvider } from './graph-elements/vper-periods-provider';
import { DustSamplesGraphElementsProvider } from './graph-elements/dust-provider';
import { DataTypes, convertDistance, getDataType } from './data-types';
import {
    ValueFrequencyGraphAlarmLinesProvider,
    ValueTimeGraphAlarmLinesProvider,
} from './graph-elements/alarm-lines-provider';
import { SoundSamplesGraphElementsProvider } from './graph-elements/sound-plugin/sound-plugin';
import SelectionsPlugin from './graph-elements/selections-plugin/selections-plugin';
import ActionsPlugin from './graph-elements/actions-plugin';
import { CursorFunction } from './cursor-functions';
import CommentsPlugin from './graph-elements/comments-plugin/comments-plugin';
import { timestampRangeOverlap } from '../utils/range';
import { locale$ } from '../utils/date-fns';
import { GraphType } from '../enums';

const isSampleBucket = (record) => record.__typename === 'SampleBucket';

// Object to register helpers to be used in Selenium tests. Tests that
// use this have to become pure JS test.
window.testHelpers = {};
window.testHelpers.getRecordsInView = () => {
    const { data } = window.timeGraph;

    return firstValueFrom(
        combineLatest([data.samples$, data.events$]).pipe(
            map(([samples, events]) => {
                const allRecords = samples.concat(events);
                const sortedRecords = sortRecords(allRecords);
                return data.filterRecordsWithinView(sortedRecords);
            })
        )
    );
};
window.testHelpers.fillBarGraphTooltip = async (index) => {
    // Finds the position for the data of the specified index.
    const positions = await firstValueFrom(window.timeGraph.tooltipHelper.positions$);
    const dataPosition = positions[index];

    // Calculates the x offset between the page start and the graph element.
    const node = window.timeGraph.tooltipHelper.plot.node();
    const rect = node.getBoundingClientRect();
    const offset = rect.left - node.clientLeft;

    // Manually provides a mouse position to the tooltip helper to display the tooltip.
    window.timeGraph.tooltipHelper.event$.next({ clientX: offset + dataPosition.x, clientY: 100 });
    window.timeGraph.tooltipHelper.isMouseInGraph$.next(true);
};
window.testHelpers.getBarGraphTooltipPositionTimestamp = async (index) => {
    const positions = await firstValueFrom(window.timeGraph.tooltipHelper.positions$);
    const { record } = positions[index].element;
    return get(record, isSampleBucket(record) ? ['vtop', 'timestamp'] : ['timestamp']);
};
window.testHelpers.barGraphtooltip = () => window.timeGraph.tooltipHelper.tooltip;
window.testHelpers.getFirstTraceUrl = async () => {
    const getRecordsInView = await window.testHelpers.getRecordsInView();
    const traces = await firstValueFrom(window.timeGraph.data.traces$);

    function timespanContainsTraces(timespan) {
        return some(traces, (trace) =>
            timestampRangeOverlap([timespan.from, timespan.to], [trace.start, trace.end])
        );
    }

    const sampleContainingTrace = getRecordsInView.find(
        (record) => isSampleBucket(record) && timespanContainsTraces(record.timespan)
    );

    if (!sampleContainingTrace) {
        return null;
    }

    const positions = await firstValueFrom(window.timeGraph.tooltipHelper.positions$);
    const indexOfTooltipPositionWithTrace = positions.findIndex(
        (position) => position.element.record === sampleContainingTrace
    );

    if (indexOfTooltipPositionWithTrace === -1) {
        return null;
    }

    await window.testHelpers.fillBarGraphTooltip(indexOfTooltipPositionWithTrace);

    return window.timeGraph.tooltipHelper.tooltip.select('a').attr('href');
};
window.testHelpers.waitForEvent = (event, doneCallback) => {
    window.timeGraph.data.on(event, doneCallback);
};
window.testHelpers.waitForPromise = (promise, doneCallback) => {
    promise.then(doneCallback);
};
window.testHelpers.getOverrides = getOverrides;

Object.defineProperty(window.testHelpers, 'eventsAndSamplesUpdatedPromise', {
    get: () =>
        Promise.all(
            ['samples-updated', 'events-updated'].map(
                (eventName) =>
                    new Promise((resolve) => {
                        window.timeGraph.data.on(eventName, resolve);
                    })
            )
        ),
});

Object.defineProperty(window.testHelpers, 'alarmLinesUpdatedPromise', {
    get: () => firstValueFrom(window.timeGraph.data.alarmLines$.pipe(skip(1))),
});

Object.defineProperty(window.testHelpers, 'categoryAndAlarmLinesPromise', {
    get: () => firstValueFrom(window.timeGraph.data.categoryAndAlarmLines$),
});
Object.defineProperty(window.testHelpers, 'barGraphTooltipPositionsPromise', {
    get: () => firstValueFrom(window.timeGraph.tooltipHelper.positions$),
});

/**
 * This function gets the actual represented value of Y axis, at the first
 * point on the X axis.
 *
 * It does this by taking the text and the place on the screen of the highest
 * y-axis value and the text and the place on the screen of the lowest y-axis.
 * Then it takes the place on the screen of the first point of the category line
 * on the x-axis and checks what value it should have relative to the previously
 * collected points.
 */
window.testHelpers.getFirstYOfDrawnCategoryLine = () => {
    const getTickY = ($tick) => {
        const style = window.getComputedStyle($tick[0]);
        const matrix = new WebKitCSSMatrix(style.webkitTransform);

        // Minus 0.5 for the line offset.
        return matrix.m42 - 0.5;
    };

    const getCatlineFirstY = () => {
        const catline = window.frequencyGraph.$container.find('.catLine')[0];
        return catline.getPointAtLength(5).y;
    };

    // Ticks on the y axis.
    const ticks = window.frequencyGraph.$container.find('.y-axis > .tick');

    const firstTick = ticks.first();
    const lastTick = ticks.last();

    const firstTickY = getTickY(firstTick);
    const firstTickValue = parseInt(firstTick.text(), 10);

    const lastTickY = getTickY(lastTick);
    const lastTickValue = parseInt(lastTick.text(), 10);

    const scale = scaleLinear()
        .domain([firstTickY, lastTickY])
        .range([firstTickValue, lastTickValue]);

    return scale(getCatlineFirstY());
};

export class Graph extends EventEmitter {
    constructor(config) {
        super();

        this.ready = new Promise((resolve) => {
            this.setAsReady = resolve;
        });

        this.unmountPromise = new Promise((resolve) => {
            this.unmount = resolve;
        });

        this.storeConfig(config);

        this.init().then(() => {
            this.setAsReady();
        });
    }

    storeConfig(config) {
        this.name = config.name;
        this.containerInput = config.container;
        this.height = config.height;
        this.width = config.width;
        this.margin = config.margin;
        this.buildContainer = config.buildContainer ?? false;
        this.scaleType$ = config.scaleType$ ?? null;

        this.containerId = config.containerId;
        this.containerClass = config.containerClass;
        this.containerHeight = config.containerHeight;
        this.containerWidth = config.containerWidth;
    }

    async init() {
        // Takes care of creating the `.graph-area` and allows for
        // inheriting classes to add their own elements.
        if (this.buildContainer) {
            await this.initContainer();
        }

        this.loadingOpacity = 0.85;

        this.container = select(this.containerInput);
        this.$container = $(this.containerInput);
        this.graphArea = this.container.select('.graph-area');

        // Empty wheel event that is needed to make Safari wheel zoom correctly.
        // https://stackoverflow.com/a/67925459
        this.graphArea.on('wheel', () => {});

        // The main SVG that will contain the graph.
        this.svg = this.graphArea
            .append('svg:svg')
            .attr('height', this.height)
            .attr('width', this.width);

        this.defs = this.svg.append('defs');

        this.graphHeight = this.height - this.margin.top - this.margin.bottom;
        this.graphWidth = this.width - this.margin.left - this.margin.right;

        // Add clipPath to restrict the region to which paint can be applied.
        // Store the clipPath reference for the brush graph.
        this.clipPath = this.defs.append('clipPath').attr('id', `${this.name}Clip`);

        this.clipPath
            .append('rect')
            .attr('height', this.graphHeight)
            .attr('width', this.graphWidth);

        // The actual element that shows the main view.
        this.graph = this.svg
            .append('g')
            .attr('class', `${this.name}Graph`)
            .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

        // Zooming element.
        this.zoomElement = this.graph
            .append('g')
            .attr('clip-path', `url(#${this.name}Clip)`)
            .attr('class', 'zoom');

        // Needed to get the g element size properly.
        this.zoomElement
            .append('rect')
            .attr('class', 'plot')
            .attr('width', this.graphWidth)
            .attr('height', this.graphHeight);

        this.shouldBeVisible$ = this.createShouldBeVisible$();

        this.shouldBeVisible$.subscribe((visible) => {
            this.toggleContainerVisibility(visible);
        });

        this.cursorFunction$ = new BehaviorSubject(CursorFunction.ZOOM);

        this.cursorFunction$.subscribe((current) => {
            this.zoomElement.classed('show-grab-cursor', current === CursorFunction.ZOOM);
        });

        this.createXAxis();
        this.createYAxis();

        this.initZoom();

        // Container for all the bars of the graph.
        this.drawArea = this.zoomElement.append('g').attr('class', `${this.name}Container`);

        this.initLoading();
    }

    async afterContainerCreation() {
        // Placeholder for the ValueFrequencyGraph to create it's warning div's.
    }

    async initContainer() {
        // Here we create all DOM elements associated with the graph container.

        // Create container ourselves if that was not supplied.
        const $container = this.containerInput ? $(this.containerInput) : $('<div>');

        $container.prop('id', this.containerId).addClass(this.containerClass);

        await this.afterContainerCreation($container);

        // Add the graph area div.
        this.$graphArea = $('<div>').addClass('graph-area relative').appendTo($container);

        this.height = this.containerHeight - $container.height();
        this.width = this.containerWidth;

        this.containerInput = $container.get(0);
    }

    initLoading() {
        // Add the loading box.
        this.loadingBox = this.graph.append('g').attr('class', 'loadingBox').attr('opacity', '0');

        const w = 125;
        const pad = 5;
        this.loadingBox
            .append('rect')
            .attr('width', w)
            .attr('height', 20)
            .attr('x', this.graphWidth / 2 - w / 2)
            .attr('y', pad)
            .attr('class', 'loading');

        this.loadingBox
            .append('text')
            .text(gettext('LOADING'))
            .attr('x', this.graphWidth / 2)
            .attr('y', pad + 14)
            .attr('class', 'loadingText');
    }

    initZoom() {
        // Placeholder to inject a zoom handler. Not implemented for the base graph.
    }

    zoomEnabledForX() {
        return true;
    }

    createXAxis() {
        this.xAxis = new XAxis(
            this,
            this.createXAxisDomain$(),
            [0, this.graphWidth],
            this.graphHeight,
            this.createXAxisText$(),
            this.zoomEnabledForX(),
            this.scaleType$
        );
    }

    zoomEnabledForY() {
        return true;
    }

    createYAxis() {
        this.yAxis = new YAxis(
            this,
            this.createYAxisDomain$(),
            [this.graphHeight, 0],
            this.graphWidth,
            this.createYAxisText$(),
            this.zoomEnabledForY(),
            this.scaleType$
        );
    }

    createXAxisDomain$() {
        throw new NotImplementedError();
    }

    createYAxisDomain$() {
        throw new NotImplementedError();
    }

    createXAxisText$() {
        throw new NotImplementedError();
    }

    createYAxisText$() {
        throw new NotImplementedError();
    }

    setLoading(isLoading) {
        this.loadingBox.attr('opacity', isLoading ? this.loadingOpacity : 0);
    }

    createShouldBeVisible$() {
        return of(true);
    }

    toggleContainerVisibility(toggle) {
        this.$container.toggle(toggle);
    }
}

function initPlugins(graph, pluginClasses) {
    // Initialise instances.
    const plugins = pluginClasses.map((Plugin) => new Plugin(graph));

    setTimeout(() => {
        // After all the classes are initiated, run the `init` function. Doing this in 2
        // stages allows for providers / plugins to interact with each other.
        plugins.forEach((plugin) => {
            plugin.init();
        });
    }, 0);

    return plugins;
}

export class SensorDataSetGraph extends Graph {
    storeConfig(config) {
        super.storeConfig(config);

        this.data = config.data;
        this.scales = config.scales;
        this.legendData = config.legendData;
        this.showTooltips = defaultTo(config.showTooltips, true);
        this.getShowTracesUrl = config.getShowTracesUrl;
        this.zoomTransitionDuration = config.zoomTransitionDuration;
        this.activePlugins$ = new ReplaySubject(1);
    }

    async init() {
        await super.init();

        this.graphTitle = this.container.select('.graph-title');

        this.updateGraphName();

        this.initLegend();

        this.initPlugins();

        if (this.showTooltips) {
            this.initTooltips();
        }

        this.data.on('loading-updated', this.onDataSetLoadingChanged.bind(this));

        this.data.on('updated', this.onDataSetUpdated.bind(this));

        // Trigger a render and graph title update every time the naming conventions change.
        namingConventions$.subscribe(() =>
            this.ready.then(() => {
                this.updateGraphName();
            })
        );
    }

    getPluginClasses() {
        throw new NotImplementedError();
    }

    initPlugins() {
        this.activePlugins$.next(initPlugins(this, this.getPluginClasses()));
    }

    async getPlugin(name) {
        return firstValueFrom(
            this.activePlugins$.pipe(
                map((providers) => {
                    const found = providers.find((provider) => provider.name === name);

                    if (!found) {
                        throw new Error(`Plugin with name ${name} no found.`);
                    }

                    return found;
                })
            )
        );
    }

    async afterContainerCreation($container) {
        await super.afterContainerCreation($container);

        // Add graph buttons div.
        $('<div>')
            .addClass('graph-buttons')
            .appendTo($container)
            .append($('<div>').addClass('graph-title'));
    }

    initLegend() {
        LegendRenderer.create$(this, this.getLegendExcludeTypes$(), this.legendData).subscribe();
    }

    initTooltips() {
        this.tooltipHelper = new this.TooltipHelperClass(
            this,
            this.zoomElement,
            this.graphWidth,
            this.graphHeight,
            this.getShowTracesUrl
        );
    }

    initZoom() {
        this.zoom = new ZoomHelper(this, 100, true, true, this.zoomTransitionDuration);
    }

    // Events:
    onDataSetLoadingChanged(loading) {
        this.setLoading(loading);
    }

    onDataSetUpdated() {
        this.updateGraphName();
    }

    getLegendExcludeTypes$() {
        return of([]);
    }

    getXAxisDataType() {
        throw new NotImplementedError();
    }

    getXAxisDataType$() {
        return of(this.getXAxisDataType());
    }

    getYAxisDataType() {
        throw new NotImplementedError();
    }

    getYAxisDataType$() {
        return of(this.getYAxisDataType());
    }

    createDomainForDataType$(dataType$) {
        return dataType$.pipe(
            switchMap((dataType) => this.scales.createMinMaxObservableForType(dataType))
        );
    }

    createXAxisDomain$() {
        return this.createDomainForDataType$(this.getXAxisDataType$());
    }

    createYAxisDomain$() {
        return this.createDomainForDataType$(this.getYAxisDataType$());
    }

    createAxisTextForDataType$(dataType$) {
        return dataType$.pipe(
            switchMap((dataType) => getDataType(dataType).getAxisTitle$(this.data))
        );
    }

    createXAxisText$() {
        return this.createAxisTextForDataType$(this.getXAxisDataType$());
    }

    createYAxisText$() {
        return this.createAxisTextForDataType$(this.getYAxisDataType$());
    }

    getDisplayedDataType() {
        return this.data.dataTypeSettings();
    }

    getGraphName() {
        return applyNamingConvention(this.getDisplayedDataType().graphName);
    }

    updateGraphName() {
        this.graphTitle.text(this.getGraphName());
    }

    get dataType() {
        return this.data.dataType;
    }
}

class Brush {
    constructor(main) {
        this.main = main;

        // Min width of the brush selection.
        this.minWidthBrush = 20;

        this.mainData = this.main.data;
        this.data = this.main.data.brushDataSet;
        this.legendData = this.main.legendData;
        this.firstLoad = true;

        this.actionButtonsWidth = 16;
        this.actionButtonsIncMargin = this.actionButtonsWidth + 5;

        this.margin = {
            top: main.height - main.margin.bottom + 10 + main.valuesHeight,
            right: main.margin.right,
            bottom: 0,
            left: main.margin.left + this.actionButtonsIncMargin,
        };

        this.height = main.height - main.valuesHeight - this.margin.top - this.margin.bottom;

        this.width = this.main.graphWidth - this.actionButtonsIncMargin * 2;

        this.graphHeight = this.height;
        this.graphWidth = this.width;

        this.graphType = this.main.graphType;

        const brushClip = this.main.clipPath.clone(true).attr('id', 'brushClip');
        brushClip.select('rect').attr('width', this.width);

        this.drawArea = this.main.svg
            .append('g')
            .attr('clip-path', `url(#${brushClip.attr('id')})`)
            .attr('class', 'brushGraph')
            .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

        this.createXAxis();
        this.createYAxis();

        // Xbrush for brush graph.
        this.brush = brushX().extent([
            [0, 0],
            [this.width, this.height],
        ]);

        // Append the brush.
        this.brushElement = this.drawArea.append('g').attr('class', 'brush').call(this.brush);

        this.updateBrushAccordingToDataSet();

        this.brush.on('brush', this.onBrush.bind(this));

        this.mainData.on('visible-updated', (emitter) => {
            if (emitter === this) {
                return;
            }

            this.updateBrushAccordingToDataSet();
        });

        this.createForwardBackwardButtons();

        this.graphType$ = this.main.graphType$;

        this.activePlugins$ = new BehaviorSubject(initPlugins(this, this.getPluginClasses()));
    }

    getPluginClasses() {
        return (
            this.main
                .getPluginClasses()
                // Don't show events and alarm lines in the brush graph.
                .filter(
                    (plugin) =>
                        ![
                            EventsGraphElementsProvider,
                            ValueTimeGraphAlarmLinesProvider,
                            VperPeriodGraphElementsProvider,
                            ActionsPlugin,
                            CommentsPlugin,
                            SelectionsPlugin,
                        ].includes(plugin)
                )
        );
    }

    updateBrushAccordingToDataSet() {
        // Center the brushed part limited to the minimum width.
        // This is so it remains grab-able and can act as a scrollbar.
        const limitedData = this.limitBrushRange(
            this.mainData.getVisibleMinMax().map(this.xAxis.scale, this.xAxis.scale),
            true
        );

        // Move the brush to the location we are looking at.
        this.brushElement.call(this.brush.move, limitedData.range);
    }

    onBrush(event) {
        if (!event.sourceEvent) {
            // If the sourceEvent is undefined, it means that the brush was moved manually
            // (probably caused by an update coming from the dataset), so we want to return
            // early to avoid resetting the visible min max on the dataset and prevent starting
            // a loop.
            return;
        }

        // Take the selected range or the whole range.
        const s = event.selection || this.xAxis.scale.range();
        if (!s) {
            return;
        }

        const limitedData = this.limitBrushRange(s, false);
        if (limitedData.changed) {
            // If we changed to the limits, recall ourself and return.
            this.brushElement.call(this.brush.move, limitedData.range, event);
            return;
        }

        this.mainData.setVisibleMinMax(...s.map(this.xAxis.scale.invert, this.xAxis.scale), this);

        // As the range of what we see now changed, we need to
        // check if we need to load new data.
        this.mainData.checkDataUpdateNeeded();
    }

    get dataType() {
        return this.main.dataType;
    }

    createXAxis() {
        // X scale for the brush view.
        const scale = scaleTimezone(this.main.data.timezone)
            .domain(this.main.xAxis.originalScale.domain())
            .range(this.main.xAxis.originalScale.range());

        const triggerAxisRender$ = new Subject();

        this.main.data.timezone$.subscribe((timezone) => {
            scale.setTimezoneName(timezone);
            triggerAxisRender$.next();
        });

        locale$.subscribe((locale) => {
            scale.setLocale(locale);
            triggerAxisRender$.next();
        });

        // xAxis for the brush view.
        const axis = axisBottom(scale);

        // Element showing the xAxis on the brush graph.
        const element = this.drawArea
            .append('g')
            .attr('class', 'axis x-axis')
            .attr('transform', `translate(0, ${this.height})`);

        triggerAxisRender$.pipe(startWith(null)).subscribe(() => {
            element.call(axis);
        });

        this.xAxis = {
            scale,
            axis,
            element,
            scaleUpdated$: this.main.xAxis.scaleUpdated$,
        };
    }

    createYAxis() {
        // Y scale for the brush view.
        const scale = scaleLinear().domain(this.main.yAxis.scale.domain()).range([this.height, 0]);

        this.yAxis = {
            scale,
            scaleUpdated$: this.main.yAxis.scaleUpdated$,
        };

        this.main.yAxis.domainUpdated$.subscribe(() => {
            scale.domain(this.main.yAxis.scale.domain());
        });
    }

    createForwardBackwardButtons() {
        const createContainer = (marginLeft, modifier, mirrors) => {
            // 18 because that just looks good.
            const marginTop = 18;
            // 16 = icon height, 2 = small margin.
            const iconHeight = 16 + 2;

            // CSS transform that optionally mirrors the forward buttons
            // into backward buttons.
            const groupTransform = mirrors
                ? `translate(${marginLeft + this.actionButtonsWidth}, ${
                      this.margin.top + marginTop
                  })scale(-1,1)`
                : `translate(${marginLeft}, ${this.margin.top + marginTop})`;

            const container = this.main.svg.append('g').attr('transform', groupTransform);

            const { DAY, WEEK, MONTH } = DateRange.PERIOD;
            [
                [DAY, '\uf051', gettext('DAY')],
                [WEEK, '\uf04e', gettext('WEEK')],
                [MONTH, '\uf050', gettext('MONTH')],
            ].forEach(([period, icon, text], index) => {
                const button = container
                    .append('text')
                    .attr('y', index * iconHeight)
                    .attr('font-family', 'FontAwesome')
                    .attr('class', 'forward-backward-button')
                    .text(icon)
                    .on('click', () => {
                        this.main.data.dateRange.applyModifier(
                            modifier,
                            period,
                            this.main.data.timezone
                        );
                    });

                tippy(button.node(), {
                    content: text,
                    theme: 'omnidots-white-shadow',
                });
            });
        };

        const { SUBTRACT, ADD } = DateRange.MODIFIER;

        // Backward buttons.
        createContainer(this.main.margin.left, SUBTRACT, true);
        // Forward buttons.
        createContainer(this.main.width - this.actionButtonsWidth, ADD, false);
    }

    limitBrushRange([left, right], middle) {
        /* eslint-disable no-param-reassign */
        let changed = false;
        if (right - left < this.minWidthBrush) {
            let max = false;
            if (middle) {
                const diff = (right - left) / 2;
                if (right + diff > this.graphWidth) {
                    max = true;
                } else {
                    left = left + diff - this.minWidthBrush / 2;
                    right = left + this.minWidthBrush;
                    changed = true;
                }
            } else if (right + this.minWidthBrush > this.graphWidth) {
                max = true;
            } else {
                right = left + this.minWidthBrush;
                changed = true;
            }
            if (max) {
                right = this.graphWidth;
                left = this.graphWidth - this.minWidthBrush;
                changed = true;
            }
        }
        return { range: [left, right], changed };
    }

    scaleX(element) {
        return this.xAxis.scale(element.timestamp);
    }

    scaleY(element) {
        const value = this.mainData.convertDistance(element.value);
        return this.yAxis.scale(value);
    }
}

export class ValueTimeGraph extends SensorDataSetGraph {
    getPluginClasses() {
        return [
            SamplesGraphElementsProvider,
            EventsGraphElementsProvider,
            VperPeriodGraphElementsProvider,
            DustSamplesGraphElementsProvider,
            ValueTimeGraphAlarmLinesProvider,
            ActionsPlugin,
            CommentsPlugin,
            SelectionsPlugin,
            SoundSamplesGraphElementsProvider,
        ];
    }

    initPlugins() {
        this.graphType$ = new BehaviorSubject(this.graphType);

        super.initPlugins();
    }

    getGraphTypeForCurrentDataType() {
        return getDataType(this.dataType).defaultGraphType;
    }

    storeConfig(config) {
        super.storeConfig(config);
        this.showBrushGraph = config.showBrushGraph;

        this.TooltipHelperClass = TooltipHelperTimeGraph;

        // Height reserved for the timezone and left and right date texts.
        this.valuesHeight = 25;

        this.graphTypeManuallyOverridden = false;

        this.graphType = config.graphType || this.getGraphTypeForCurrentDataType();

        // Attach event handler immediately to not miss any data type change,
        // but only act on it when the graph is ready.
        this.data.on('data-type-updated', async () => {
            await this.ready;

            if (this.lastType !== this.dataType) {
                this.lastType = this.dataType;
                this.setGraphType(this.getGraphTypeForCurrentDataType(), true);
            }
        });

        this.animate = config.animate || false;
    }

    async init() {
        await super.init();

        this.data.on('visible-updated', this.onDataSetVisibleUpdated.bind(this));

        const numberOfRecords = 365;
        this.rectWidth = this.graphWidth / numberOfRecords;

        this.initBrush();

        this.initStripePatterns();
    }

    initLegend() {
        // Make this graph available in the legend data context. This
        // allows the PM10 legend items to change the zoom leven when
        // clicked on.
        this.legendData.context.valueTimeGraph = this;

        const useNewLegend$ = this.data.dataTypeSettings$.pipe(
            map((x) => x.useNewLegend ?? false),
            distinctUntilChanged()
        );

        useNewLegend$
            .pipe(
                switchMap((useNewLegend) =>
                    useNewLegend
                        ? // TODO: This is the place where the new legend including
                          // the new tooltip mechanics has to be initialized.
                          // Github issue: https://github.com/omnidots/website/issues/6619
                          NewLegendRenderer.create$(this)
                        : LegendRenderer.create$(
                              this,
                              this.getLegendExcludeTypes$(),
                              this.legendData
                          )
                )
            )
            .subscribe();
    }

    onDataSetVisibleUpdated(emitter) {
        // Only react to events when they were not emitted by us.
        if (this === emitter) {
            return;
        }

        this.xAxis.scale.domain(this.data.getVisibleMinMax(), this.data);
    }

    getLegendExcludeTypes$() {
        // Dynamic exclude types.
        return this.data.combinedSettings$.pipe(map((x) => x.legendExcludeTypes));
    }

    setGraphType(type, automatic = false) {
        if (!automatic) {
            this.graphTypeManuallyOverridden = true;
        } else if (this.graphTypeManuallyOverridden) {
            return;
        }

        this.graphType$.next(type);
    }

    getYAxisDataType() {
        return this.data.dataType;
    }

    getYAxisDataType$() {
        return this.data.dataType$;
    }

    createXAxisDomain$() {
        return of(this.data.getVisibleMinMax());
    }

    createXAxis() {
        this.xAxis = new TimeXAxis(
            this,
            this.createXAxisDomain$(),
            this.graphWidth,
            this.graphHeight
        );

        this.xAxis.domainUpdated$.subscribe((emitter) => {
            if (emitter === this.data) {
                // If we are coming from the dataset, then we need to update the zoom too.
                this.zoom.setZoomToXAxis();
            } else {
                // If we are coming from a zoom, then we need to update the dataset too.
                this.data.setVisibleMinMax(
                    this.xAxis.getMin(),
                    this.xAxis.getMax(),
                    emitter ?? this
                );
            }
        });
    }

    zoomEnabledForY() {
        return false;
    }

    initZoom() {
        // Limit zoom to 1 minute view.
        const MAX_ZOOM = getYearDays() / getMinuteDays();

        this.zoom = new ValueTimeGraphZoomHelper(
            this,
            MAX_ZOOM,
            this.zoomEnabledForY(),
            false,
            this.zoomTransitionDuration
        );
        this.zoom.on('zoomed', ({ inTransition }) => {
            if (inTransition) {
                // If we are in a transition we don't want the `checkDataUpdateNeeded` function
                // to get called. Calling `checkDataUpdateNeeded` mid transition causes the graph
                // to request data that we are not interested in. This also causes a missing data
                // request at the end of our transition, because the end position and the mid
                // transition position are not different enough to trigger the last data request.
                return;
            }

            // As the range of what we see has now changed, we need to check if
            // we need to load new data.
            this.data.checkDataUpdateNeeded();
        });

        this.zoom.on('transition-end', () => {
            // Force a data update at the end of a transition, this is because we only
            // transition if we are sure what we want to see.
            this.data.checkDataUpdateNeeded(true);
        });
    }

    initBrush() {
        if (this.showBrushGraph) {
            this.brush = new Brush(this);
        }
    }

    initStripePatterns() {
        // Stripes used to display end_of_measurement and unknown_data events.
        const patterns = ['grey-stripes', 'other_datatype-stripes', 'yellow-stripes'];

        patterns.forEach((name) => {
            const pattern = this.defs
                .append('pattern')
                .attr('id', name)
                .attr('width', '16px')
                .attr('height', '16px')
                .attr('patternUnits', 'userSpaceOnUse');

            pattern
                .append('rect')
                .attr('class', 'dont-throw-away stripes')
                .attr('width', '16px')
                .attr('height', '16px');

            pattern
                .append('line')
                .attr('x1', '16px')
                .attr('y1', '0px')
                .attr('x2', '0px')
                .attr('y2', '16px')
                .attr('class', `${name} striping`);

            pattern
                .append('line')
                .attr('x1', '0px')
                .attr('y1', '32px')
                .attr('x2', '32px')
                .attr('y2', '0px')
                .attr('class', `${name} striping`);

            pattern
                .append('line')
                .attr('x1', '-16px')
                .attr('y1', '16px')
                .attr('x2', '16px')
                .attr('y2', '-16px')
                .attr('class', `${name} striping`);
        });
    }

    scaleX(element) {
        return this.xAxis.scale(element.timestamp);
    }

    scaleY(element) {
        const value = this.data.convertDistance(element.value);
        return this.yAxis.scale(value);
    }
}

export class ValueFrequencyGraph extends SensorDataSetGraph {
    storeConfig(config) {
        super.storeConfig(config);

        this.timeGraph = config.timeGraph;

        this.dotSize = config.dotSize; // px

        this.graphType = GraphType.DOT;

        this.TooltipHelperClass = TooltipHelperFrequencyGraph;

        this.containerExactSizeIsImportant = config.containerExactSizeIsImportant;
    }

    async afterContainerCreation($container) {
        await super.afterContainerCreation($container);

        // Create warning div's.
        const createWarningDiv = (id, text) => {
            $('<div>')
                .prop('id', id)
                .addClass('messagelist')
                .appendTo($container)
                .append($('<li>').addClass('warning').text(text));
        };

        createWarningDiv(
            'category_warning',
            gettext("The data doesn't match the current settings!")
        );

        createWarningDiv(
            'config_warning',
            gettext('Automatic detection has failed, please adjust the settings.')
        );

        // Show/hide line warnings.
        initLineWarningHelper($container, this.data);

        // If the exact size is important we need to wait until the dataset is ready and then
        // render the graph. This way the config warnings can be rendered after the dataset has
        // loaded, and with the config warnings rendered we can calculate the size of the
        // container, when we know the container size we can substract that from the graph size.
        // This way we know for sure our container wont exceed the configured graph size.
        if (this.containerExactSizeIsImportant) {
            await this.data.ready;
            await delay(30);
        }
    }

    getPluginClasses() {
        return [FrequencySamplesGraphElementsProvider, ValueFrequencyGraphAlarmLinesProvider];
    }

    createShouldBeVisible$() {
        return this.data.dataTypeSettings$.pipe(map((x) => x.showFrequencyGraph));
    }

    getLegendExcludeTypes$() {
        return of(getDataType(DataTypes.FDOM).legendExcludeTypes.concat(['m']));
    }

    getXAxisDataType() {
        return DataTypes.FDOM;
    }

    getYAxisDataType() {
        return DataTypes.VTOP;
    }

    getGraphName() {
        return applyNamingConvention(gettext('~~vtop_ext~~/frequency'));
    }

    scaleX(element) {
        const fdom = element.getValueForDataType(DataTypes.FDOM);
        return this.xAxis.scale(fdom);
    }

    scaleY(element) {
        const value = convertDistance(
            this.getYAxisDataType(),
            element.getValueForDataType(this.getYAxisDataType())
        );
        return this.yAxis.scale(value);
    }
}
