import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import {
    Observable,
    combineLatest,
    distinctUntilChanged,
    map,
    of,
    startWith,
    switchMap,
    from,
    combineLatestWith,
    catchError,
    tap,
} from 'rxjs';
import { useObservableState } from 'observable-hooks';
import { useCallback, useState } from 'react';
import { filter, flatten } from 'lodash-es';
import { NotImplementedError } from '../utils/errors';
import { formatDatetimeTz } from '../utils/formatting';
import { soundAnalysisCollection } from './graph-elements/selections-plugin/selections-plugin';
import { MultiSensorDataSet } from './dataset';
import validateReactRenderableContent from '../utils/validate-react-renderable-content';
import warning from '../utils/logger';

const positions = {
    'top-left': 'absolute top-0 left-0',
    'top-right': 'absolute top-0 right-0',
    'bottom-right': 'absolute bottom-0 right-0',
    'bottom-left': 'absolute bottom-0 left-0',
};

const getNextItemUtil = (items, current) => {
    const index = items.indexOf(current);

    const newIndex = index + 1;

    return items[newIndex >= items.length ? 0 : newIndex];
};

const tdClassName = 'p-2 border-r border-slate-100';

function Legend({ table$ }) {
    const table = useObservableState(table$);

    const [position, setPosition] = useState(Object.keys(positions)[1]);

    const goToNextPosition = useCallback(() => {
        setPosition((currentPosition) => getNextItemUtil(Object.keys(positions), currentPosition));
    }, [setPosition]);

    if (!table) {
        return null;
    }

    return (
        <div className={`my-4 bg-black/75 text-xs text-white ${positions[position]}`}>
            <button
                onClick={goToNextPosition}
                className="pointer-events-auto float-left m-2 mr-2 hidden"
            >
                <FontAwesomeIcon icon={faArrowsRotate} className="h-4 w-4 fill-current" />
            </button>
            <table>
                <thead>
                    <tr className="text-left">
                        {table.header.map((td, index) => (
                            <th key={index} className={tdClassName}>
                                {td}
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {table.rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((td, index) => (
                                <td key={index} className={tdClassName}>
                                    {td}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
Legend.propTypes = {
    table$: PropTypes.object.isRequired,
};

class BaseColumn {
    isActive$() {
        return of(true);
    }

    header$() {
        throw NotImplementedError();
    }

    content$() {
        throw NotImplementedError();
    }

    static createColumns$() {
        return of([new this()]);
    }
}

class SensorNameColumn extends BaseColumn {
    header$(graph) {
        return graph.data.dataTypeSettings$.pipe(map((x) => x.title));
    }

    content$(graph, dataSet) {
        return dataSet.name$;
    }
}

class TooltipColumn extends BaseColumn {
    isActive$(graph) {
        // Only start when the graph is ready, otherwise `graph.tooltipHelper`
        // might not be available yet.
        return from(graph.ready).pipe(
            switchMap(() => {
                // In case this graph has tooltips enabled it will have a `tooltipHelper` property.
                if (graph.tooltipHelper) {
                    // Turn data into a boolean.
                    return graph.tooltipHelper.data$.pipe(map((data) => !!data));
                }

                // In case this graph has tooltips disabled, for example in case of reporting or
                // the alarm line preview graph, we disable this column.
                return of(false);
            }),
            // In the meantime while we wait on the graph to become ready, we already start with
            // emitting `false` as starting value, marking us inactive by default.
            startWith(false),
            // Only emit on real changes.
            distinctUntilChanged()
        );
    }

    header$(graph) {
        return graph.tooltipHelper.data$.pipe(
            combineLatestWith(graph.data.timezone$),
            map(([data, timezone]) => formatDatetimeTz(timezone, data.element.record.timestamp))
        );
    }

    content$(graph, dataSet) {
        return dataSet.dataTypeSettings$.pipe(
            switchMap((dataTypeSettings) =>
                dataTypeSettings.getLegendTooltipContent$(graph, dataSet)
            )
        );
    }
}

class SelectionColumn extends BaseColumn {
    constructor(selection) {
        super();

        this.selection = selection;
    }

    isActive$(_graph) {
        return of(true);
    }

    header$(graph) {
        return combineLatest([this.selection.start$, this.selection.end$]).pipe(
            combineLatestWith(graph.data.timezone$),
            map(([dates, timezone]) => {
                if (dates[0]?.valueOf() === dates[1]?.valueOf()) {
                    return formatDatetimeTz(timezone, dates[0]);
                }

                return dates
                    .map((date) => (date ? formatDatetimeTz(timezone, date) : '...'))
                    .join(' - ');
            })
        );
    }

    content$(_graph, dataSet) {
        return soundAnalysisCollection.get(dataSet, this.selection).getLegendContent$();
    }

    static createColumns$(graph) {
        return from(graph.getPlugin('selections')).pipe(
            switchMap((selectionsPlugin) => selectionsPlugin.selections.entries$),
            map((selections) => selections.map((selection) => new this(selection)))
        );
    }
}

function getDataSetsForLegend$(dataSet) {
    if (dataSet instanceof MultiSensorDataSet) {
        return dataSet.currentSensor$.pipe(
            map((currentSensor) => [dataSet.getDataSet(currentSensor)])
        );
    }

    return of([dataSet]);
}

export default class NewLegendRenderer {
    columns = [SensorNameColumn, TooltipColumn, SelectionColumn];

    constructor(graph) {
        this.graph = graph;

        this.foreignObject = graph.graph
            .append('foreignObject')
            .attr('height', this.graph.graphHeight)
            .attr('width', this.graph.graphWidth)
            .attr('style', 'pointer-events: none;');

        this.container = this.foreignObject
            .append('xhtml:div')
            .attr('class', 'fixed h-full w-full')
            .attr('xmlns', 'http://www.w3.org/1999/xhtml');

        // Map our current selected dataSet into an array of dataSets. This
        // is to prepare for multiple active dataSets in the future.
        this.dataSets$ = getDataSetsForLegend$(this.graph.data);

        this.columns$ = combineLatest(
            this.columns.map((columnProvider) => columnProvider.createColumns$(this.graph))
        ).pipe(
            map((columnProviderResponses) => flatten(columnProviderResponses)),
            switchMap((columns) =>
                combineLatest(
                    columns.map((column) =>
                        column
                            .isActive$(this.graph)
                            .pipe(map((isActive) => (isActive ? column : null)))
                    )
                )
            ),
            // Filter null columns.
            map((possibleInActiveColumns) => filter(possibleInActiveColumns))
        );

        ReactDOM.render(<Legend table$={this.getTable$()} />, this.container.node());
    }

    getColumnHeaders$() {
        return this.columns$.pipe(
            switchMap((columns) =>
                combineLatest(columns.map((column) => column.header$(this.graph)))
            )
        );
    }

    getColumnContent$(dataSet) {
        return this.columns$.pipe(
            switchMap((columns) =>
                combineLatest(
                    columns.map((column) =>
                        column.content$(this.graph, dataSet).pipe(
                            tap((content) => validateReactRenderableContent(content)),
                            catchError((error) => {
                                warning(
                                    `Error in ${column.constructor.name}.content$ while fetching legend tooltip content:\n${error}`
                                );
                                // Return an empty string observable to continue the stream.
                                return of('');
                            })
                        )
                    )
                )
            )
        );
    }

    getRows$() {
        return this.dataSets$.pipe(
            switchMap((dataSets) =>
                combineLatest(dataSets.map((dataSet) => this.getColumnContent$(dataSet)))
            )
        );
    }

    getTable$() {
        return combineLatest({
            header: this.getColumnHeaders$(),
            rows: this.getRows$(),
        });
    }

    destroy() {
        // Unmount the React component.
        unmountComponentAtNode(this.container.node());

        // Remove all the created DOM elements.
        this.foreignObject.remove();
    }

    static create$(...args) {
        return new Observable((subscriber) => {
            const legendRenderer = new this(...args);

            subscriber.next(legendRenderer);

            // On unsubscribe.
            return () => {
                legendRenderer.destroy();
            };
        });
    }
}
