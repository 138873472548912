function initializeMap(sensors, icon) {
    let map;
    let done = false;
    function initialize() {
        const infos = [];
        const bounds = new google.maps.LatLngBounds();

        function closeInfo() {
            for (let i = 0; i < infos.length; i++) {
                infos[i].close();
            }
        }

        map = new google.maps.Map(document.getElementById('map-canvas'), {
            center: new google.maps.LatLng(0, 0),
            zoom: 2,
        });

        sensors.forEach((val) => {
            const markerOptions = {
                map,
                position: new google.maps.LatLng(val.location.lat, val.location.long),
                icon,
                title: val.title,
            };

            const newMarker = new google.maps.Marker(markerOptions);
            bounds.extend(newMarker.getPosition());

            google.maps.event.addListener(newMarker, 'dblclick', val.click_function);

            const newInfo = new google.maps.InfoWindow({
                content: val.content,
            });

            infos.push(newInfo);
            newMarker.info = newInfo;

            google.maps.event.addListener(newMarker, 'click', function onClickMarker() {
                closeInfo();
                this.info.open(map, this);
            });
        });

        google.maps.event.addListener(map, 'click', closeInfo);

        if (sensors.length > 0) map.fitBounds(bounds);

        function doneLoading() {
            const limitToZoom = 16;
            if (map.getZoom() > limitToZoom) {
                map.setZoom(limitToZoom);
            }
        }

        map.addListener('idle', () => {
            if (!done) {
                done = true;
                doneLoading();
            }
        });
    }
    google.maps.event.addDomListener(window, 'load', initialize);
}

const mapModule = ({ options: { icon, sensors } }) => {
    initializeMap(sensors, icon);
};

export default mapModule;
