import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleXmark, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import BaseRow from './BaseRow';
import Input, { INPUT_SIZE } from '../../../form/Input';
import ElementRefPropType from '../../../utils';
import Select from '../../../form/Select';
import { PERMISSION, PERMISSION_TRANSLATIONS } from '../utils';

const CreateRow = ({ availablePermissions, handleCancelClick, inputRef, permissionRef, error }) => (
    <BaseRow>
        <div>
            <Input
                placeholder={gettext('Email address or username')}
                size={INPUT_SIZE.MEDIUM}
                required={true}
                ref={inputRef}
            />
            {error && <p className={'text-red-600'}>{error}</p>}
        </div>
        <div className={'w-32'}>
            {availablePermissions.length === 1 ? (
                PERMISSION_TRANSLATIONS[availablePermissions[0]]
            ) : (
                <Select
                    required={true}
                    small={true}
                    data={Object.entries(PERMISSION_TRANSLATIONS).filter(([key, _value]) =>
                        availablePermissions.includes(key)
                    )}
                    ref={permissionRef}
                />
            )}
        </div>
        <div className="flex space-x-2.5">
            <button type="submit" aria-label={gettext('SAVE')}>
                <FontAwesomeIcon icon={faFloppyDisk} className="text-green-600" size="lg" />
            </button>
            <button type="button" aria-label={gettext('CANCEL')} onClick={handleCancelClick}>
                <FontAwesomeIcon icon={faRectangleXmark} className={'text-primary'} size="lg" />
            </button>
        </div>
    </BaseRow>
);

CreateRow.propTypes = {
    availablePermissions: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(PERMISSION))),
    handleCancelClick: PropTypes.func,
    inputRef: ElementRefPropType,
    permissionRef: ElementRefPropType,
    error: PropTypes.string,
};

export default CreateRow;
