import { map, of } from 'rxjs';
import { DayXAxis } from './axis';
import { SensorDataSetGraph } from './graph';
import { TooltipHelperVeffDayGraph } from './tooltip-helper';
import { applyNamingConvention } from '../utils/naming-conventions';
import { VperPeriodDayGraphElementsProvider } from './graph-elements/vper-periods-provider';
import { DataTypes, getDataType } from './data-types';

class VeffDayGraph extends SensorDataSetGraph {
    getPluginClasses() {
        return [VperPeriodDayGraphElementsProvider];
    }

    storeConfig(config) {
        super.storeConfig(config);
        this.TooltipHelperClass = TooltipHelperVeffDayGraph;
    }

    createShouldBeVisible$() {
        return this.data.combinedSettings$.pipe(map((x) => x.showVeffDayGraph));
    }

    getLegendExcludeTypes$() {
        return of(getDataType(DataTypes.VEFF).legendExcludeTypes.concat(['m']));
    }

    getYAxisDataType() {
        return DataTypes.VEFF;
    }

    createXAxis() {
        this.xAxis = new DayXAxis(this, this.graphWidth, this.graphHeight);
    }

    getGraphName() {
        return applyNamingConvention(gettext('VPER_DAY_GRAPH'));
    }

    scaleX(element) {
        return this.xAxis.scale(element.timestamp);
    }

    scaleY(element) {
        const value = this.data.convertDistance(element.value);
        return this.yAxis.scale(value);
    }
}

export default VeffDayGraph;
