import onElementReady from './on-element-ready';

export async function attachAutocompleteToElement(element, onSelect) {
    const { SearchBox } = await google.maps.importLibrary('places');

    element.addEventListener('keydown', (event) => {
        if (
            event.keyCode === 13 &&
            // When `element.style.display === ''` is `true`, it indicates that a
            // Google Autocomplete dropdown is open. If that dropdown is open and
            // the enter key is pressed, we stop the events from bubbling up to
            // prevent a form submission.
            Array.from(document.querySelectorAll('.pac-container')).find(
                (found) => found.style.display === ''
            )
        ) {
            event.preventDefault();
        }
    });

    // eslint-disable-next-line no-new
    const searchBox = new SearchBox(element);

    window.searchBox = searchBox;

    // Let the caller know a location was found.
    if (onSelect) {
        searchBox.addListener('places_changed', () => {
            onSelect(element.value);
        });
    }
}

export async function attachAutocompleteToQuerySelector(querySelector) {
    await onElementReady(querySelector);

    const element = document.querySelector(querySelector);

    attachAutocompleteToElement(element);
}
