import { curveStepBefore, line } from 'd3-shape';
import { flow, groupBy, isNull, map, negate, partialRight, property } from 'lodash-es';
import { finalize, tap } from 'rxjs';

const isNotNull = negate(isNull);

const buildSelector = (cssClasses) => cssClasses.map((className) => `.${className}`).join('');

export class GraphType {
    constructor(d3IdentifierCssClass) {
        this.d3IdentifierCssClass = d3IdentifierCssClass;
    }

    getX(data) {
        return data.x;
    }

    getY(data) {
        return data.y;
    }

    static createRenderObservable(graphElementPositions$, graph, d3IdentifierCssClass, ...args) {
        const renderer = new this(d3IdentifierCssClass, ...args);

        return graphElementPositions$.pipe(
            tap((elements) => {
                renderer.render(graph, elements);
            }),
            finalize(() => {
                // On unsubscribe remove the elements.
                renderer.render(graph, []);
            })
        );
    }
}

export class LineGraphType extends GraphType {
    line() {
        return line()
            .x((element) => element.x)
            .y((element) => element.y);
    }

    render(graph, data) {
        const axes = data;
        const groupedByAxis = flow(
            partialRight(groupBy, 'axis'),
            partialRight(map, (value, key) => ({ axis: key, data: value }))
        )(axes);

        const identifyingClasses = ['line', this.d3IdentifierCssClass].filter(isNotNull);

        // Render lines.
        graph.drawArea
            .selectAll(buildSelector(identifyingClasses))
            .data(groupedByAxis, property('axis'))
            .join(
                (enter) => {
                    enter
                        .append('svg:path')
                        .attr('class', (d) => `linegraph ${d.axis} ${identifyingClasses.join(' ')}`)
                        .attr('d', (d) => this.line()(d.data));
                },
                (update) => {
                    update.attr('d', (d) => this.line()(d.data));
                }
            );
    }
}

export class StepGraphType extends LineGraphType {
    line(graph, dataSet) {
        const lineGenerator = super.line(graph, dataSet);
        return lineGenerator.curve(curveStepBefore);
    }
}

export class BarGraphType extends GraphType {
    constructor(d3IdentifierCssClass, rectWidth) {
        super(d3IdentifierCssClass);
        this.rectWidth = rectWidth;
    }

    getX(data) {
        // Center rect on value.
        return super.getX(data) - this.rectWidth / 2;
    }

    getWidth() {
        return this.rectWidth;
    }

    getHeight(graph, data) {
        return graph.graphHeight - this.getY(data);
    }

    render(graph, data) {
        graph.drawArea
            .selectAll('.bar')
            .data(data, (d) => `${d.timestamp}-${d.axis}`)
            .join((enter) =>
                enter
                    .append('rect')
                    .attr('class', (d) => `bargraph ${d.axis} bar`)
                    .attr('width', this.getWidth.bind(this))
            )
            .attr('height', (d) => this.getHeight(graph, d))
            .attr('x', this.getX.bind(this))
            .attr('y', this.getY.bind(this));
    }
}

export class DotGraphType extends BarGraphType {
    getY(data) {
        // Center rect on value.
        return super.getY(data) - this.rectWidth / 2;
    }

    getHeight() {
        return this.rectWidth;
    }
}
