import {
    BehaviorSubject,
    Observable,
    ReplaySubject,
    combineLatest,
    from,
    map,
    of,
    skipWhile,
    switchMap,
    take,
    tap,
    withLatestFrom,
} from 'rxjs';
import { drag as d3Drag } from 'd3-drag';
import { pointer } from 'd3-selection';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { find, isArray } from 'lodash-es';
import { useObservable, useObservableState } from 'observable-hooks';
import { Collection, fromObservableQuery, shareReplayRefCount } from '../../../utils/rxjs';
import { CursorFunction } from '../../cursor-functions';
import { BaseGraphPlugin } from '../base-provider';
import { ReactComponentAction } from '../actions-plugin';
import filterWithinView from '../utils/filter-within-date-range';
import combineEntriesWithPositions from '../utils/combine-entries-with-positions';
import renderBlocksInGraph from '../utils/render-blocks-in-graph';
import { getClient } from '../../../utils/graphql';
import { soundAnalysisQuery } from './queries';
import { PERMISSION } from '../../../components/pages/sharing/utils';
import { DataTypes, fixFloat } from '../../data-types';
import { LnLegendTable } from '../sound-plugin/LnLegendTable';

export const SelectionState = Object.freeze({
    SELECTING: 'selecting',
    DONE: 'done',
    LOADING: 'loading',
});

export const SelectionType = Object.freeze({
    POINT: 'point',
    RANGE: 'range',
});

class Selection {
    constructor(collection, key) {
        this.collection = collection;
        this.key = key;

        this.start$ = new BehaviorSubject();
        this.end$ = new BehaviorSubject();
        this.state$ = new BehaviorSubject(SelectionState.SELECTING);
        this.type = SelectionType.POINT;
    }

    setStart(start) {
        this.start$.next(start);
    }

    setEnd(end) {
        this.end$.next(end);
    }

    setState(state) {
        this.state$.next(state);
    }

    setType(type) {
        this.type = type;
    }

    delete() {
        this.collection.delete(this);
    }
}

export const fetchSoundAnalysis$ = (measuringPointId, startTime, endTime) => {
    const queryParameters = {
        variables: { measuringPointId, startTime, endTime },
        query: soundAnalysisQuery,
    };

    return fromObservableQuery(getClient().watchQuery(queryParameters)).pipe(
        map((result) => result.data.soundAnalysis)
    );
};

const SoundAnalysisStatus = Object.freeze({
    DONE: 'done',
    LOADING: 'loading',
});

export class SoundAnalysis {
    constructor(dataSet, selection, collection) {
        this.dataSet = dataSet;
        this.selection = selection;
        this.collection = collection;

        this.subscription = this.createDataFetcher$().subscribe();
        this.status$ = new BehaviorSubject(SoundAnalysisStatus.LOADING);
        this.data$ = new ReplaySubject(1);
    }

    createDataFetcher$() {
        return this.selection.state$.pipe(
            skipWhile((state) => state !== SelectionState.DONE),
            withLatestFrom(this.selection.start$, this.selection.end$),
            switchMap(([_state, start, end]) =>
                combineLatest([
                    of([start, end]),
                    fetchSoundAnalysis$(this.dataSet.sensor, start, end),
                ])
            ),
            tap(([[start, end], response]) => {
                this.status$.next(SoundAnalysisStatus.DONE);

                // In case the backend hits a `NoMeasurementsError` it will just return null.
                if (!response) {
                    this.data$.next(null);
                    return;
                }

                const { startTime, endTime, ...data } = response;

                this.data$.next(data);

                if (startTime !== start) {
                    this.selection.start$.next(startTime);
                }

                if (endTime !== end) {
                    this.selection.end$.next(endTime);
                }
            })
        );
    }

    getLegendContent$() {
        const value$ = combineLatest([this.dataSet.dataType$, this.data$]).pipe(
            map(([dataType, data]) => {
                if (!data) {
                    return null;
                }

                const value = data[dataType];

                // If the value is an array, we are safe to assume this is Ln data.
                if (isArray(value)) {
                    return (
                        <LnLegendTable
                            lnValues={value}
                            fixFloat={(v) => fixFloat(DataTypes.LXN, v)}
                        />
                    );
                }

                return fixFloat(dataType, value);
            })
        );

        return this.status$.pipe(
            switchMap((status) =>
                status === SoundAnalysisStatus.DONE ? value$ : of(gettext('LOADING'))
            )
        );
    }

    destroy() {
        this.requestSubscription.unsubscribe();
    }

    static create$(...args) {
        const soundAnalysis = new this(...args);

        return new Observable((subscriber) => {
            subscriber.next(soundAnalysis);

            // On unsubscribe.
            return () => {
                soundAnalysis.destroy();
            };
        });
    }
}

class SoundAnalysisCollection {
    constructor() {
        this.analyses = [];
    }

    find(dataSet, selection) {
        const found = find(this.analyses, { dataSet, selection });
        return found && found.analysis;
    }

    /**
     * @param DataSet dataSet
     * @param Selection selection
     * @returns SoundAnalysis
     */
    get(dataSet, selection) {
        let analysis = this.find(dataSet, selection);
        if (!analysis) {
            analysis = new SoundAnalysis(dataSet, selection, this.analyses);
            this.analyses.push(analysis);
        }

        return analysis;
    }
}

export const soundAnalysisCollection = new SoundAnalysisCollection();

class SelectionCollection extends Collection {
    constructor() {
        super();

        this.createdCounter = 0;
    }

    add() {
        const selection = new Selection(this, (this.createdCounter += 1));
        return super.add(selection);
    }

    // We only want one selection per type. This method gets called after a
    // drag operation and cleans up the 'outdated' selections.
    removeOthersOfTheSameType(selection) {
        const toBeDeleted = this.entries$
            .getValue()
            .filter((entry) => entry.type === selection.type && entry !== selection);

        toBeDeleted.forEach((entry) => {
            this.delete(entry);
        });
    }

    addManually(startTime, endTime, markAsDone = true) {
        const selection = this.add();

        selection.setStart(startTime);
        selection.setEnd(endTime);
        selection.setType(SelectionType.RANGE);

        if (markAsDone) {
            selection.setState(SelectionState.DONE);
        }

        this.removeOthersOfTheSameType(selection);

        return selection;
    }
}

function SelectionActionComponent({ graph, selection }) {
    const commentsPluginAndHasFullAccess$ = useObservable(
        (inputs$) =>
            inputs$.pipe(
                switchMap(([incomingGraph]) =>
                    combineLatest([
                        from(incomingGraph.getPlugin('comments')),
                        incomingGraph.data.matchesPermission$(PERMISSION.FULL_ACCESS),
                    ])
                )
            ),
        [graph]
    );

    const [commentsPlugin, hasFullAccess] = useObservableState(commentsPluginAndHasFullAccess$, [
        null,
        false,
    ]);

    const buttons = useMemo(() => {
        const all = [
            { icon: faTimes, text: gettext('CLOSE_SELECTION'), onClick: () => selection.delete() },
        ];

        if (commentsPlugin && hasFullAccess) {
            all.push({
                icon: faPlus,
                text: gettext('ADD_COMMENT'),
                onClick: async () => {
                    // Create the comment and delete the current selection after confirmation.
                    commentsPlugin.newCommentFromSelection(selection, () => selection.delete());
                },
            });
        }

        return all;
    }, [selection, commentsPlugin, hasFullAccess]);

    return (
        <div className="flex divide-x-2 divide-solid divide-white" id="action-buttons-container">
            {buttons.map(({ text, icon, onClick }, index) => (
                <button
                    key={index}
                    // We have to hack a bit with paddings here as we cannot use
                    // `space-x-1` on the parent here as that does not work in
                    // combination with `divide-x`. Tailwind issue:
                    // https://github.com/tailwindlabs/tailwindcss/discussions/2466
                    // Also flip the button content for `CLOSE_SELECTION` button.
                    className={`flex items-center px-1 first:pl-0 last:pr-0 ${
                        icon === faTimes ? 'flex-row-reverse' : 'flex-row'
                    }`}
                    onClick={onClick}
                >
                    <FontAwesomeIcon icon={icon} />
                    <span className={`${icon === faTimes ? 'mr-1' : 'ml-1'}`}>{text}</span>
                </button>
            ))}
        </div>
    );
}
SelectionActionComponent.propTypes = {
    graph: PropTypes.object.isRequired,
    selection: PropTypes.instanceOf(Selection).isRequired,
};

class SelectionAction extends ReactComponentAction {
    component = SelectionActionComponent;
}

export default class SelectionsPlugin extends BaseGraphPlugin {
    name = 'selections';

    constructor(...args) {
        super(...args);

        this.selections = new SelectionCollection();

        this.selectionsInView$ = this.selections.entries$.pipe(
            filterWithinView(this.graph.data.dateRange),
            // Both the actions and renderer are going to use `selectionsInView$`, to
            // make things more efficient we use `shareReplay` to share the outcome of
            // above calculations.
            shareReplayRefCount()
        );
    }

    initDrag() {
        const plot = this.graph.zoomElement;

        const drag = d3Drag();
        // Only allow dragging when the cursor function is set to 'select'.
        drag.filter((event) => {
            // Ignore right mouse clicks.
            if (event.button === 2) {
                return false;
            }

            return this.graph.cursorFunction$.getValue() === CursorFunction.SELECT;
        });
        drag.on('start', (startEvent) => {
            this.graph.xAxis.scale$
                .pipe(
                    // Explicitly only take one value. Otherwise when the scale updates,
                    // we could end up adding 2 or more selections for a single drag start event.
                    take(1)
                )
                .subscribe((scale) => {
                    const selection = this.selections.add();

                    // Update the start date of the selection.
                    const [startX] = pointer(startEvent, plot.node());
                    selection.setStart(scale.invert(startX).valueOf());
                    selection.setEnd(scale.invert(startX).valueOf());

                    // Update the end date of the selection.
                    const dragged = (draggedEvent) => {
                        const [draggedX] = pointer(draggedEvent, plot.node());

                        // Prevent updates when someone only drags the y axis.
                        if (startX !== draggedX) {
                            // Depending on the direction of drag, we either update
                            // the start of the end of the selection.
                            const set = startX > draggedX ? selection.setStart : selection.setEnd;

                            set.call(selection, scale.invert(draggedX).valueOf());
                            selection.setType(SelectionType.RANGE);
                        }
                    };

                    // Update the state of the selection.
                    const ended = () => {
                        selection.setState(SelectionState.DONE);

                        // Make sure there is only one selection of this type.
                        this.selections.removeOthersOfTheSameType(selection);
                    };

                    startEvent.on('drag', dragged).on('end', ended);
                });
        });

        plot.call(drag);
    }

    initRenderer() {
        const renderer$ = this.selectionsInView$.pipe(
            combineEntriesWithPositions(this.graph.xAxis.scaleUpdated$),
            renderBlocksInGraph(
                this.graph,
                'selection',
                'fill-primary/10',
                'stroke-primary/90 stroke-2'
            )
        );

        this.subscription.add(renderer$.subscribe());
    }

    initActions() {
        const actionsProvider$ = this.selectionsInView$.pipe(
            map((selections) =>
                selections.map((selection) => {
                    const action = new SelectionAction(selection.key, {
                        selection,
                        graph: this.graph,
                    });

                    selection.start$.subscribe(action.start$);
                    selection.end$.subscribe(action.end$);

                    return action;
                })
            )
        );

        this.subscription.add(
            from(this.graph.getPlugin('actions'))
                .pipe(switchMap((actionsPlugin) => actionsPlugin.attachProvider$(actionsProvider$)))
                .subscribe()
        );
    }

    init() {
        this.initDrag();
        this.initRenderer();
        this.initActions();
    }
}
