import { ApolloProvider } from '@apollo/client';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { getClient } from './utils/graphql';

import './index.css';
import { branch$, siteTitle$ } from './components/pages/base-page';
import PageContainer from './components/pages/PageContainer';

const pageModule = ({ options: { legacyCssContainer, branch, siteTitle } }) => {
    // These values are needed to generate the page titles later on.
    branch$.next(branch);
    siteTitle$.next(siteTitle);

    const element = document.getElementById('js-page');

    render(
        <StrictMode>
            <ApolloProvider client={getClient()}>
                <PageContainer legacyCssContainer={legacyCssContainer} />
            </ApolloProvider>
        </StrictMode>,
        element
    );
};

export default pageModule;
