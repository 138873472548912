import { map, of } from 'rxjs';
import { isString, replace } from 'lodash-es';
import { configConverter } from '../../../components/pages/measuringPoint/configConverter';

const frequencyWeightingPrefix = 'frequencyWeighting';

const defaultUnit = 'dB';

export function getUnitWithFrequencyWeighting(config) {
    const frequencyWeighting = configConverter.internalToPublic(config)?.frequencyWeighting;

    if (isString(frequencyWeighting) && frequencyWeighting.includes(frequencyWeightingPrefix)) {
        const weighting = replace(frequencyWeighting, frequencyWeightingPrefix, '');
        return `${defaultUnit}(${weighting})`;
    }

    return defaultUnit;
}

export function getSoundUnit$(dataSet) {
    if (!dataSet) {
        return of(defaultUnit);
    }

    return dataSet.config$.pipe(map((config) => getUnitWithFrequencyWeighting(config)));
}
