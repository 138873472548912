import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { useObservableState, useSubscription } from 'observable-hooks';
import { combineLatest, firstValueFrom, of } from 'rxjs';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner';
import {
    NOTIFICATION_VARIANT,
    NotificationsContainer,
    useNotifications,
} from '../../../../components/notifications/Notifications';

import { useCreateComment } from '../hooks';
import { CommentInput } from './CommentInput';
import { CommentView } from './CommentView';
import { Comment } from '../models';

function ThreadItem({ comment, isParent }) {
    const isEditing = useObservableState(comment.isEditing$);
    const editText = useObservableState(comment.editText$);
    const editIsLoading = useObservableState(comment.editIsLoading$);
    const editError = useObservableState(comment.editError$);

    if (isEditing) {
        return (
            <CommentInput
                value={editText}
                onValueChange={(text) => comment.setEditText(text)}
                onAction={() => comment.saveEdit()}
                actionButtonLabel={gettext('EDIT')}
                onCancel={() => comment.setEditing(false)}
                isAdding={false}
                error={editError && gettext('ERROR')}
                isLoading={editIsLoading}
            />
        );
    }

    return <CommentView comment={comment} isParent={isParent} />;
}
ThreadItem.propTypes = {
    comment: PropTypes.instanceOf(Comment).isRequired,
    isParent: PropTypes.bool.isRequired,
};

function scrollCommentInView(commentId) {
    const addedComment = document.querySelector(`[data-testid='thread_comment_id_${commentId}']`);

    if (!addedComment) {
        // We are doing our best here. If the element cannot be found due to
        // some unusual race condition on the client's computer, so be it. We
        // have tests that prove that this works under normal conditions.
        return;
    }

    const container = addedComment.parentElement;
    const additionalScroll =
        addedComment.getBoundingClientRect().bottom - container.getBoundingClientRect().bottom;

    container.scrollTop += additionalScroll;
}

function AddComment({ parentComment }) {
    const [isAdding, setIsAdding] = useState(false);

    const onSuccess = useCallback(
        ({ id: addedCommentId }) => {
            setIsAdding(false);

            scrollCommentInView(addedCommentId);
        },
        [setIsAdding]
    );

    const { isLoading, error, newCommentText, setNewCommentText, createComment } =
        useCreateComment(onSuccess);

    const save = useCallback(async () => {
        const partialComment = await firstValueFrom(
            combineLatest({
                measuringPointId: of(parentComment.measuringPointId),
                dataType: of(parentComment.dataType),
                parentId: of(parentComment.id),
                startTime: parentComment.start$,
                endTime: parentComment.end$,
            })
        );
        createComment(partialComment);
    }, [createComment, parentComment]);

    return (
        <div className="mt-2">
            {!isAdding ? (
                <button onClick={() => setIsAdding(true)} aria-label={gettext('ADD')}>
                    <FontAwesomeIcon
                        className="cursor-pointer text-primary"
                        icon={faCirclePlus}
                        size="xl"
                    />
                </button>
            ) : (
                <CommentInput
                    value={newCommentText}
                    onValueChange={setNewCommentText}
                    onAction={save}
                    actionButtonLabel={gettext('ADD')}
                    onCancel={() => setIsAdding(false)}
                    error={error}
                    isLoading={isLoading}
                />
            )}
        </div>
    );
}
AddComment.propTypes = {
    parentComment: PropTypes.instanceOf(Comment).isRequired,
};

const CommentDetailView = ({ comment, onClose }) => {
    const thread = useObservableState(comment.thread$, []);
    const error = useObservableState(comment.replyCollection.error$);
    const isLoading = useObservableState(comment.replyCollection.isLoading$, false);

    // Close this view when the comment gets deleted.
    useSubscription(comment.deleted$, onClose);

    const { addNotification, clearNotifications } = useNotifications();

    useEffect(() => {
        clearNotifications();
        if (error) {
            addNotification({
                message: gettext('GENERIC_SERVER_ERROR'),
                variant: NOTIFICATION_VARIANT.ERROR,
            });
        }
    }, [error, addNotification, clearNotifications]);

    return (
        <>
            <div className="flex justify-between">
                <p>{gettext('COMMENTS')}</p>
                <button onClick={onClose}>
                    <FontAwesomeIcon className="cursor-pointer" icon={faRemove} />
                </button>
            </div>
            {isLoading ? (
                <div className="mb-1 text-center">
                    <LoadingSpinner className="text-primary" size="xl" />
                </div>
            ) : (
                <div className="flex max-h-40 flex-col gap-y-1 overflow-y-scroll">
                    {thread.map((childComment) => (
                        <div
                            key={childComment.id}
                            data-testid={`thread_comment_id_${childComment.id}`}
                        >
                            <ThreadItem
                                comment={childComment}
                                isParent={comment.id === childComment.id}
                            />
                        </div>
                    ))}
                </div>
            )}
            {error && <NotificationsContainer notificationProps={{ textSize: 'text-xs' }} />}
            <AddComment parentComment={comment} />
        </>
    );
};
CommentDetailView.propTypes = {
    comment: PropTypes.instanceOf(Comment).isRequired,
    onClose: PropTypes.func.isRequired,
};

export default CommentDetailView;
