import PropTypes from 'prop-types';

const Table = ({ header, rows }) => (
    <table className="table-responsive-label table">
        <thead>
            <tr>
                {header.map((title) => (
                    <th key={title}>{title}</th>
                ))}
            </tr>
        </thead>
        <tbody>
            {rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                    {row.map(({ value, className }, dataIndex) => (
                        <td key={dataIndex} data-label={header[dataIndex]} className={className}>
                            {value}
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    </table>
);

Table.propTypes = {
    header: PropTypes.array.isRequired,
    rows: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.exact({
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
                className: PropTypes.string,
            })
        )
    ),
};

export default Table;
