import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner';
import { RoundedButton } from '../../../../components/form/Button';
import TextArea from '../../../../components/form/TextArea';
import {
    NotificationsContainer,
    NOTIFICATION_VARIANT,
    useNotifications,
} from '../../../../components/notifications/Notifications';

import { MAX_COMMENT_LENGTH } from './CommentInput';
import { useCreateComment } from '../hooks';

const CommentCreateView = ({ partialComment, onSuccess, onClose }) => {
    const onCreateComplete = useCallback(() => {
        // Calling `onSuccess` will remove the selection.
        onSuccess();
        onClose();
    }, [onSuccess, onClose]);

    const { loading, error, newCommentText, setNewCommentText, createComment } =
        useCreateComment(onCreateComplete);
    const { addNotification, clearNotifications } = useNotifications();

    useEffect(() => {
        clearNotifications();
        if (error) {
            addNotification({
                message: gettext('GENERIC_SERVER_ERROR'),
                variant: NOTIFICATION_VARIANT.ERROR,
            });
        }
    }, [error, addNotification, clearNotifications]);

    return (
        <>
            <p className="text-center">{gettext('WRITE_COMMENT')}</p>
            <TextArea
                maxLength={MAX_COMMENT_LENGTH}
                value={newCommentText}
                onValueChange={setNewCommentText}
                disabled={loading}
            />
            {error && <NotificationsContainer notificationProps={{ textSize: 'text-xs' }} />}
            <div className="mt-2 space-x-3">
                <RoundedButton
                    type="button"
                    onClick={() => createComment(partialComment)}
                    variant="big"
                    color="primary"
                    disabled={loading}
                    flex
                >
                    {loading ? <LoadingSpinner /> : gettext('ADD')}
                </RoundedButton>
                <RoundedButton type="button" onClick={onClose} variant="big" color="secondary" flex>
                    {gettext('CANCEL')}
                </RoundedButton>
            </div>
        </>
    );
};
CommentCreateView.propTypes = {
    partialComment: PropTypes.shape({
        measuringPointId: PropTypes.number.isRequired,
        dataType: PropTypes.string.isRequired,
        startTime: PropTypes.number.isRequired,
        endTime: PropTypes.number.isRequired,
    }),
    onSuccess: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default CommentCreateView;
