import { useEffect, useLayoutEffect, useRef } from 'react';

// Unfortunately, the `observable-hooks` package does not support a `skip`
// argument for their useSubscription hook. That's why we implemented (perhaps
// for the time being) our own helper for subscribing and unsubscribing to
// observables.
const useSubscription = (input$, callback, skip) => {
    const callbackRef = useRef(callback);

    // Update the latest callback synchronously after render being committed.
    // https://github.com/crimx/observable-hooks/blob/master/packages/observable-hooks/src/internal/use-subscription-internal.ts#L26-L30
    useLayoutEffect(() => {
        callbackRef.current = callback;
    });

    useEffect(() => {
        if (skip) {
            return null;
        }

        const subscription = input$.subscribe(callbackRef.current);

        return subscription.unsubscribe.bind(subscription);
    }, [input$, callback, skip]);
};

export default useSubscription;
