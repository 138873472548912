/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import { useQuery, gql, useMutation } from '@apollo/client';
import { Route, Routes, Link, useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect, useMemo, useCallback } from 'react';
import Select from 'react-select';
import { cloneDeep, findLastIndex, isNil, round } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useObservableState } from 'observable-hooks';
import AlarmLinesGraph, { createEmptyAlarmPoints } from './graph/alarm-lines-graph';
import getDeleteMessage from './utils/messages';
import { distanceConverter, revertDistanceConverter } from './utils/formatting';
import { applyNamingConventionsByGuideline, defaultGuideline } from './utils/naming-conventions';
import Tooltip from './utils/tooltip';
import BasePage from './components/pages/base-page';
import Button from './components/form/Button';
import { useConfirm } from './components/confirm/Confirm';

import schema from './schema.json';
import { DataTypes, getDataType } from './graph/data-types';
import { useTranslatedFormSchema } from './components/pages/measuringPoint/schema-utils';
import { SwarmType } from './enums';

const CUSTOM_ALARM_FRAGMENT = gql`
    fragment CustomAlarmTypeFields on CustomAlarmType {
        id
        name
        points {
            freq
            vkar
        }
    }
`;

const GET_CUSTOM_ALARMS = gql`
    {
        customAlarms {
            edges {
                node {
                    ...CustomAlarmTypeFields
                }
            }
        }
    }
    ${CUSTOM_ALARM_FRAGMENT}
`;

const GET_CUSTOM_ALARM = gql`
    query GetCustomAlarm($id: ID!) {
        customAlarm(id: $id) {
            ...CustomAlarmTypeFields
        }
    }
    ${CUSTOM_ALARM_FRAGMENT}
`;

const CREATE_CUSTOM_ALARM = gql`
    mutation ($input: CustomAlarmCreateMutationInput!) {
        customAlarmCreate(input: $input) {
            customAlarm {
                ...CustomAlarmTypeFields
            }
            errors {
                field
                message
            }
        }
    }
    ${CUSTOM_ALARM_FRAGMENT}
`;

const UPDATE_CUSTOM_ALARM = gql`
    mutation ($input: CustomAlarmUpdateMutationInput!) {
        customAlarmUpdate(input: $input) {
            customAlarm {
                ...CustomAlarmTypeFields
            }
            errors {
                field
                message
            }
        }
    }
    ${CUSTOM_ALARM_FRAGMENT}
`;

const DELETE_CUSTOM_ALARM = gql`
    mutation ($input: CustomAlarmDeleteMutationInput!) {
        customAlarmDelete(input: $input) {
            customAlarm {
                id
            }
            errors {
                field
                message
            }
        }
    }
`;

function ListView() {
    const confirm = useConfirm();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { loading, error, data } = useQuery(GET_CUSTOM_ALARMS, {
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
    });

    const [deleteAlarm] = useMutation(DELETE_CUSTOM_ALARM, {
        update(cache, { data: { customAlarmDelete } }) {
            cache.modify({
                fields: {
                    // eslint-disable-next-line default-param-last
                    customAlarms(existingCustomAlarms = [], { readField }) {
                        return {
                            ...existingCustomAlarms,
                            edges: existingCustomAlarms.edges.filter(
                                (customAlarm) =>
                                    customAlarmDelete.customAlarm.id !==
                                    readField('id', customAlarm.node)
                            ),
                        };
                    },
                },
            });
        },
    });

    const handleRemoveAlarm = (id) => {
        deleteAlarm({
            variables: { input: { id } },
        });
    };

    const nodes = useMemo(() => data?.customAlarms.edges.map((edge) => edge.node), [data]);

    return (
        <BasePage
            title={t('CUSTOM_ALARM_LEVEL')}
            besideTitle={
                <Button onClick={() => navigate('/alarm_level/create')}>
                    {gettext('CUSTOM_ALARM_LEVEL_CREATE')}
                </Button>
            }
            legacyCssContainer={true}
        >
            <div>
                {loading && <p>{gettext('LOADING')}</p>}
                {error && <p>{gettext('ERROR')}</p>}
                {!data?.customAlarms.edges.length &&
                    interpolate(gettext('CUSTOM_ALARM_LEVEL_NONE_CREATED'), [
                        gettext('CUSTOM_ALARM_LEVEL_CREATE'),
                    ])}
            </div>
            {!loading && !error && nodes.length ? (
                <div className="custom-alarms">
                    <table className="table-responsive-label table">
                        <thead>
                            <tr>
                                <th>{gettext('NAME')}</th>
                                <th>{gettext('ACTIONS')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {nodes.map((node) => (
                                <tr key={node.id}>
                                    <td data-label={gettext('NAME')} className="alarm_level-name">
                                        {node.name}
                                    </td>
                                    <td
                                        data-label={gettext('ACTIONS')}
                                        className="alarm-level-options"
                                    >
                                        <Link to={`/alarm_level/${node.id}/`}>
                                            <i className="fa fa-edit"></i>
                                        </Link>
                                        <a
                                            href="#"
                                            title={gettext('DELETE')}
                                            onClick={() =>
                                                confirm({
                                                    message: getDeleteMessage(node.name),
                                                    convertMarkdown: true,
                                                    onConfirm: () => {
                                                        handleRemoveAlarm(node.id);
                                                    },
                                                })
                                            }
                                        >
                                            <i className="fa fa-trash"></i>
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : null}
        </BasePage>
    );
}

const cleanupPoints = (points) => {
    // Find the index of the last filled point.
    const index = findLastIndex(points, (point) => point.freq || point.vkar);

    // Remove all points after the last filled point, but
    // leave one to be filled in.
    const result = points.slice(0, index + 2);

    // Validation.
    let lastPoint = null;
    result.forEach((point, i) => {
        point.valid =
            // Valid if first:
            i === 0 ||
            // Valid if this is the new to be filled in row and not the last row:
            (i === result.length - 1 && i !== points.length - 1) ||
            // Valid if freq is equal or bigger than the last freq:
            lastPoint?.freq <= point.freq;

        lastPoint = point;
    });

    return result;
};

export const useApplyNamingConventions = () => {
    const [guideLine, setGuideLineState] = useState(defaultGuideline);

    const setGuideLine = useCallback(
        (value) => {
            // Run this here so that it happens before the re-render.
            applyNamingConventionsByGuideline(value);

            // Trigger re-render.
            setGuideLineState(value);
        },
        [setGuideLineState]
    );

    // Only run once to set the inital guide line.
    useEffect(() => {
        applyNamingConventionsByGuideline(defaultGuideline);
    }, [setGuideLine]);

    return [guideLine, setGuideLine];
};

function AddAlarmUpdateView() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const isAddMode = !id;

    const [vtopTitle] = useObservableState(() => getDataType(DataTypes.VTOP).getAxisTitle$());
    const [fdomTitle] = useObservableState(() => getDataType(DataTypes.FDOM).getAxisTitle$());

    const {
        loading: getCustomAlarmLoading,
        error: getCustomAlarmError,
        data: getCustomAlarmData,
    } = useQuery(GET_CUSTOM_ALARM, {
        variables: { id },
        skip: isAddMode,
    });

    const [updateAlarm] = useMutation(UPDATE_CUSTOM_ALARM);

    const [addAlarm, { loading: mutationLoading, error: mutationError }] = useMutation(
        CREATE_CUSTOM_ALARM,
        {
            update: (
                cache,
                {
                    data: {
                        customAlarmCreate: { customAlarm },
                    },
                }
            ) => {
                if (!customAlarm) {
                    return;
                }

                cache.modify({
                    broadcast: false,
                    fields: {
                        customAlarms(existingCustomAlarms) {
                            return {
                                ...existingCustomAlarms,
                                edges: [
                                    ...existingCustomAlarms.edges,
                                    {
                                        node: customAlarm,
                                    },
                                ],
                            };
                        },
                    },
                });
            },
        }
    );

    const [alarm, setState] = useState({
        name: '',
        points: createEmptyAlarmPoints(),
    });

    const formSchema = useTranslatedFormSchema(SwarmType.VIBRATION, false);
    const translatedGuideLineOptions = formSchema.fields.getByName('guideLine').options;

    const guideLineOptions = useMemo(
        () =>
            Array.from(schema.customAlarmLevelPreviewGuidelines).map((key) => ({
                value: key,
                label: translatedGuideLineOptions.get(key),
            })),
        [translatedGuideLineOptions]
    );

    const [guideLine, setGuideLine] = useApplyNamingConventions();

    const [notExising, setNotExising] = useState(false);

    const handleNameChange = (event) => {
        setState((old) => ({ ...old, name: event.target.value }));
    };

    const handleGuideLineChange = ({ value }) => {
        setGuideLine(value);
    };

    const handlefreqVkarChange = (type, event) => {
        const dataKey = event.target.getAttribute('data-key');

        setState((previousState) => {
            const state = { ...previousState };
            const { value } = event.target;

            state.points[dataKey][type] = value === '' ? null : parseFloat(value);

            return state;
        });
    };

    const handleFreqChange = (event) => handlefreqVkarChange('freq', event);
    const handleVkarChange = (event) => handlefreqVkarChange('vkar', event);

    const onSubmit = async (event) => {
        event.preventDefault();

        const mutationOptions = {
            variables: {
                input: {
                    name: alarm.name,
                    points: alarm.points.map(({ freq, vkar }) => ({
                        freq,
                        vkar: vkar && revertDistanceConverter(vkar),
                    })),
                },
            },
        };

        if (!isAddMode) {
            // Add ID for the update.
            mutationOptions.variables.input.id = id;

            updateAlarm(mutationOptions);
        } else {
            addAlarm(mutationOptions);
        }

        navigate('/alarm_level/');
    };

    useEffect(() => {
        if (getCustomAlarmData) {
            if (!getCustomAlarmData.customAlarm) {
                setNotExising(true);
                return;
            }

            const customAlarm = cloneDeep(getCustomAlarmData.customAlarm);

            // Do distance converting.
            customAlarm.points = customAlarm.points.map(({ freq, vkar }) => ({
                freq,
                vkar: vkar && round(distanceConverter(vkar), 2),
            }));

            setState(customAlarm);
        }
    }, [getCustomAlarmData]);

    if (getCustomAlarmLoading) return <p>{gettext('LOADING')}</p>;
    if (getCustomAlarmError || notExising) return <p>{gettext('ERROR')}</p>;

    const points = cleanupPoints(alarm.points);

    return (
        <BasePage title={t('CUSTOM_ALARM_LEVEL')} legacyCssContainer={true}>
            <div className="flex">
                <div className="w-1/2">
                    <form className="form custom-alarm-level-form" onSubmit={onSubmit}>
                        <li>
                            <label htmlFor="name">{gettext('NAME')}:</label>
                            <input
                                type="text"
                                id="name"
                                value={alarm.name}
                                onChange={handleNameChange}
                                required
                            />
                        </li>
                        <li>
                            <table id="alarm_settings">
                                <thead>
                                    <tr>
                                        <th>{fdomTitle}</th>
                                        <th>{vtopTitle}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {points.map((point, i) => {
                                        // Fields are required if they are on the first
                                        // row or not on the last row.
                                        const required = i === 0 || i !== points.length - 1;

                                        return (
                                            <tr key={i} className="field_{{ field }}">
                                                <td>
                                                    <input
                                                        type="number"
                                                        data-key={i}
                                                        value={point.freq ?? ''}
                                                        onChange={handleFreqChange}
                                                        required={required}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        data-key={i}
                                                        value={isNil(point.vkar) ? '' : point.vkar}
                                                        onChange={handleVkarChange}
                                                        required={required}
                                                    />
                                                </td>
                                                <td>
                                                    {!point.valid && (
                                                        <Tooltip
                                                            content={gettext(
                                                                'CUSTOM_ALARM_LEVEL_POINT_INVALID'
                                                            )}
                                                        >
                                                            <i className="fa fa-exclamation-circle"></i>
                                                        </Tooltip>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </li>
                        <div className="form-actions">
                            <button
                                className="pure-button pure-button-primary"
                                type="submit"
                                disabled={points.some((p) => !p.valid)}
                            >
                                {gettext('SAVE')}
                            </button>

                            <Link to={`/alarm_level/`} className="pure-button">
                                {gettext('CANCEL')}
                            </Link>
                        </div>
                        {mutationLoading && <p>{gettext('LOADING')}</p>}
                        {mutationError && <p>{gettext('ERROR')}</p>}
                    </form>
                </div>

                <div className="w-1/2">
                    <div className="guide-line-select">
                        <label>{gettext('Guideline')}: </label>
                        <Select
                            defaultValue={guideLineOptions.find(
                                (option) => option.value === guideLine
                            )}
                            onChange={handleGuideLineChange}
                            options={guideLineOptions}
                        />
                    </div>
                    <AlarmLinesGraph alarms={[alarm]} />
                </div>
            </div>
        </BasePage>
    );
}

export const CustomAlarmPageRoutes = () => (
    <Routes>
        <Route index element={<ListView />} />
        <Route path=":id" element={<AddAlarmUpdateView />} />
        <Route path="create" element={<AddAlarmUpdateView />} />
    </Routes>
);
