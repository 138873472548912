export const SURVEY_URL =
    'https://docs.google.com/forms/d/1mC5xPpcGDnOWq6mGVIXdx6-NHqqrNQe2fUHw312EsM0/viewform';

export const SURVEY_HEADER_TEXT = gettext('SURVEY_HEADER_TEXT');

export const SURVEY_BODY_TEXT = gettext('SURVEY_BODY_TEXT');

export const POPUP_DELAY_MS = 1000;

export const SURVEY_VIEWS = 2;

export const VIEWS_UNTIL_HIDE_COOKIE = 'viewsUntilHideSLM';

export const convertCookieToInteger = (cookie) => parseInt(cookie, 10);
