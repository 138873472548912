/* eslint-disable eqeqeq */
import $ from 'jquery';
import moment from 'moment';
import Remarkable from 'remarkable';
import fetch from 'cross-fetch';
import { formatDatetime } from './utils/formatting';

const maintenanceModule = () => {
    function writeCookie(name, value, days) {
        let date;
        let expires;
        if (days) {
            date = new Date();
            date.setDate(date.getDate() + days);
            expires = `; expires=${date.toGMTString()}`;
        } else {
            expires = '';
        }
        document.cookie = `${name}=${value}${expires}; path=/`;
    }

    function readCookie(name) {
        let i;
        let c;
        const nameEQ = `${name}=`;
        const ca = document.cookie.split(';');
        for (i = 0; i < ca.length; i++) {
            c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) == 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return '';
    }

    if (document.URL.includes('localhost')) {
        // No need in doing this on development.
        return;
    }

    fetch('https://status.omnidots.com/api/v1/incidents?per_page=1&order=asc&sort=id', {
        mode: 'no-cors',
    })
        .then((response) => response.json())
        .then((lastIncident) => {
            const messageHtml = new Remarkable().render(lastIncident.data[0].message);

            // User has read it already.
            if (readCookie(`status_hidden_${lastIncident.data[0].id}`) != '') return;

            const incidentTime = moment(lastIncident.data[0].updated_at);
            const now = moment();

            // Check if it's about something that happened today or something in the future
            // Otherwise there is no reason to show it.
            if (!incidentTime.isSame(now, 'day') && incidentTime.isBefore(now)) return;

            $('.status-check .status-title').text(lastIncident.data[0].name);
            $('.status-check .status-message').html(messageHtml);
            $('.status-check .status-timestamp').text(
                formatDatetime(lastIncident.data[0].updated_at)
            );
            $('.status-check').removeClass('hidden');

            // Show/hide the details.
            $('.status-check').click(function click() {
                $(this).toggleClass('collapsed');
            });

            // Allow the user to hide notifications about this number.
            $('.status-check .delete').click(() => {
                $('.status-check').hide();
                writeCookie(`status_hidden_${lastIncident.data[0].id}`, '1');
            });
        });
};

export default maintenanceModule;
