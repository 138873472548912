import ReactDOM from 'react-dom';
import slide1 from '../images/login-page-slide-1.png';
import slide2 from '../images/login-page-slide-2.png';
import slide3 from '../images/login-page-slide-3.png';
import Slider from './components/slider/Slider';

export const slides = [
    {
        src: slide1,
        title: gettext('LOGIN_PAGE_SLIDE_1_TITLE'),
        description: gettext('LOGIN_PAGE_SLIDE_1_DESCRIPTION'),
        button: {
            text: gettext('LOGIN_PAGE_SLIDE_1_BUTTON_TEXT'),
            link: 'https://www.omnidots.com/en/swarm-sound-level-meter',
        },
    },
    {
        src: slide2,
        title: gettext('LOGIN_PAGE_SLIDE_2_TITLE'),
        description: gettext('LOGIN_PAGE_SLIDE_2_DESCRIPTION'),
    },
    {
        src: slide3,
        title: gettext('LOGIN_PAGE_SLIDE_3_TITLE'),
        description: gettext('LOGIN_PAGE_SLIDE_3_DESCRIPTION'),
    },
];

const loginPageSliderModule = ({ options: { elementId } }) => {
    ReactDOM.render(<Slider slides={slides} />, document.getElementById(elementId));
};

export default loginPageSliderModule;
