/* eslint-disable */
// This page still needs to be made eslint proof.

import 'select2';
import 'select2/dist/css/select2.css';

import $ from 'jquery';
import ReactDOM from 'react-dom';
import { gql } from '@apollo/client';

import {
    Form,
    FormField,
    FormFieldGroup,
    FormFieldSubGroup,
    FormInformation,
    MeasuringPointProjectOverrides,
    AlarmReceptionField,
    ScheduleField,
    useConfigHelper,
} from './forms';
import { getDistanceUnit } from './utils/formatting';
import { applyNamingConvention, applyNamingConventionsByGuidelineNumber } from './utils/naming-conventions';
import AlarmPreviewGraph from './graph/alarm-preview-graph';
import { attachAutocompleteToQuerySelector } from './utils/google-autocomplete';
import { toFloat } from './utils/cast';
import { getClient } from './utils/graphql';
import { confirm, ConfirmType } from './components/confirm/Confirm';
import { ProjectConfigHelper, ConfigHelper } from './components/pages/measuringPoint/configHelper';
import { SwarmType } from './enums';
import { asyncScheduler, debounce, scheduled } from 'rxjs';
import profile from './profile';
import { upperFirst } from 'lodash-es';

const configChanged = () => {
    applyNamingConventionsByGuidelineNumber($(`[id$=guide_line]`).val());
};

const ACTIVATE_SUBCRIPTION_QUERY = gql`
    query($deviceName: String!) {
        canActivateSubscription(
            deviceName: $deviceName
        )
    }
`

class AlarmLevelHelperField {
    /**
     * @param {Subject} configHelperDone$
     * @param {FormField} alarmLevelField
     * @param {FormField} flatLevelField
     * @param {string} helpText
     * @param {string} unit
     */
    constructor(configHelperDone$, alarmLevelField, flatLevelField, helpText, unit) {
        this.configHelperDone$ = configHelperDone$;
        this.alarmLevelField = alarmLevelField;
        this.flatLevelField = flatLevelField;
        this.helpText = helpText;
        this.unit = unit;
        this.$input = $(`<input type='number' class='multi-input' step='any'/>`);
        this.$helpText = $(`<span class='helptext'></span>`);
        // Append to alarm level input and helptext to container.
        this.alarmLevelField.container.append(this.$input, this.$helpText);

        this.getInputAsFloat = () => toFloat(this.$input.val());
        this.getFlatLevelAsFloat = () => toFloat(this.flatLevelField.val());
        this.getAlarmLevelAsFloat = () => toFloat(this.alarmLevelField.val());

        const storeCalculation = (field, calculation) => () => {
            const value = calculation();

            // Store it if the outcome is a number.
            if (!Number.isNaN(value)) field.val(value);
        };

        // This function calculates and fills in the alarm level percentage. The alarm
        // level percentage is the difference between the original flat level field and
        // the flat level helper field. This function is called when changing the value
        // in the flat level helper field.
        this.inputToAlarmLevelField = storeCalculation(
            this.alarmLevelField,
            () => ((this.getInputAsFloat() / this.getFlatLevelAsFloat()) * 100).toFixed(2)
        );

        // This function calculates the value for the flat level helper field. The value
        // for the flat level helper field is the value of the flat level field times the
        // alarm level percentage. This function is called when initializing the helper
        // to set the initital values, and when changes are made to the flat level and alarm
        // level percentage fields.
        this.alarmLevelToInput = storeCalculation(this.$input, () => {
            this.updateHelpText();
            return this.getFlatLevelAsFloat() * (this.getAlarmLevelAsFloat() / 100);
        });

        this.initEvents();

        // Fill the helper input.
        this.alarmLevelToInput();

        // Update visibility.
        this.updateVisbility();
    }

    initEvents() {
        // React to changes in the helper input.
        this.$input.on('input', () => {
            this.inputToAlarmLevelField();

            // Trigger input so changes to the alarmLevelField get picked up
            // by the other helper.
            this.alarmLevelField.input.trigger('input', this);
        });

        // React to changes in the alarm level field.
        this.alarmLevelField.input.on('input', (_e, initializer) => {
            // Prevent reacting to own changes.
            if (initializer === this) return;

            this.alarmLevelToInput();
        });

        // React to changes in the flat level field.
        this.flatLevelField.input.on(
            'input',
            this.alarmLevelToInput.bind(this)
        );

        // Update helper visibility when configHelper was run.
        this.configHelperDone$.pipe(
            // Implement an asynchronous scheduler to ensure that the `updateVisibility`
            // check is only executed after all other events in the JavaScript call stack
            // have been executed. This is necessary to address a race condition that occurs
            // between the time jQuery hides or displays the `flatLevelField` container and
            // the execution of the `this.flatLevelField.container.is(':visible')` check.'
            debounce(() => scheduled([undefined], asyncScheduler)),
        ).subscribe(this.updateVisbility.bind(this));
    }

    updateHelpText() {
        // 1% of the flat level is the min val.
        const minVal = this.getFlatLevelAsFloat() / 100;

        // Let it be, someone entered text or something.
        if (Number.isNaN(minVal)) return;

        this.$helpText.text(
            interpolate(applyNamingConvention(this.helpText), [
                `${getDistanceUnit()}/${this.unit}`,
                minVal,
            ])
        );
    }

    updateVisbility() {
        // Link the helper's visibility to the flat level fields visibility.
        const visible = this.flatLevelField.container.is(':visible');
        this.$input.toggle(visible);
        this.$helpText.toggle(visible);
    }
}

/**
 * @param {Form} form
 */
const initAlarmLevelHelpers = (form, configHelperDone$) => {
    const vtopFlatLevelField = form.getField('flat_level');
    const atopFlatLevelField = form.getField('atop_flat_level');

    // Create AlarmLevelHelperField instances for all alarm levels.
    [1, 2, 3].forEach((index) => {
        const alarmLevelField = form.getField(`alarm_level_${index}`);
        new AlarmLevelHelperField(
            configHelperDone$,
            alarmLevelField,
            vtopFlatLevelField,
            gettext('ALARM_LEVEL_VTOP'),
            's'
        );
        new AlarmLevelHelperField(
            configHelperDone$,
            alarmLevelField,
            atopFlatLevelField,
            gettext('ALARM_LEVEL_ATOP'),
            's^2'
        );
    });
};

export class GenericEditPage {
    // This has to be made DRY someday: (https://github.com/omnidots/website/issues/2872)

    init_events() {
        $('#button-save-top').on('click', function () {
            $('#save-form').click();
        });

        $('#button-unmount-top').on('click', function () {
            $('#id_sensor_name').val('');

            // Use a timeout so that the field gets cleared before the alert appears.
            setTimeout(function () {
                confirm({
                    message: interpolate(gettext('SWARM_DECOUPLED_AFTER_SAVE'), [
                        upperFirst(SwarmType.VIBRATION),
                    ]),
                    type: ConfirmType.INFO,
                });
            }, 1);
        });

        function confirm_or_revert(obj, confirmed, unconfirmed, message) {
            if ($(obj).val() === confirmed) {
                if (!confirm({
                    message: applyNamingConvention(message),
                })) {
                    $(obj).val(unconfirmed);
                }
            }
        }

        $('#id_noise_saving_enabled').change(
            function (event) {
                confirm_or_revert(this, "True", "False", translations.NOISE_SAVE_WARING);
            }
        );

        function is_sbr_a_2017_foundation() {
            return ($('#id_guide_line').val() === "2" && $('#id_building_level').val() === "0");
        }

        function is_sn640312a() {
            return $('#id_guide_line').val() === "9";
        }

        $('#id_guide_line, #id_building_level').change(
            function () {
                // On SBR-A 2017 foundation we want to have PPA enabled by default.
                if (is_sbr_a_2017_foundation()) {
                    $('#id_atop_enabled').val("True");
                }
            }
        );

        $('#id_guide_line').change(
            function () {
                // On SN640312a we want to have PVS enabled by default.
                if (is_sn640312a()) {
                    $('#id_vector_enabled').val("True");
                }
            }
        );

        $('#id_vtop_enabled').change(
            function () {
                // Warn the user if they disable PPV measurements.
                confirm_or_revert(this, "False", "True", gettext('VTOP_DISABLE_WARNING'));
            }
        );

        $('#id_atop_enabled').change(
            function () {
                // Warn the user if they disable PPA measurements while the guideline requires to measure using it.
                if (is_sbr_a_2017_foundation()) {
                    confirm_or_revert(this, "False", "True", gettext('ATOP_DISABLE_WARNING'));
                }
                else {
                    confirm_or_revert(this, "True", "False", gettext('ATOP_ENABLE_WARNING'));
                }
            }
        );

        $('#id_vector_enabled').change(
            function () {
                // Warn the user if they disable PVS measurements while the guideline requires to measure using it.
                if (is_sn640312a()) {
                    confirm_or_revert(this, "False", "True", gettext('VECTOR_DISABLE_WARNING'));
                }
            }
        );

        $('select[id$=guide_line]').on(
            'change propertychange',
            configChanged
        );

        // Call me on start too, but after a delay as the selects are being wrapped.
        setTimeout(configChanged, 10);

        [this.check_activate_subscription].forEach((functionToCall) => { $('#id_sensor_name').focusout(functionToCall); functionToCall();});
    }

    alarm_level_fields() {
        var alarmLevels = [1, 2, 3];
        var breaker =
            "<li style='display: block;min-height: 0;margin: 0;'></li>";

        var alarmReceptionField = new AlarmReceptionField('#id_alarm_recipients');
        alarmReceptionField.create();

        for (var i = alarmLevels.length - 1; i >= 0; i--) {
            var v = alarmLevels[i];
            $('#id_alarm_level_' + v + '_email_addresses')
                .parent()
                .before(breaker);
            $('#id_alarm_level_' + v + '_sms_template')
                .parent()
                .after(breaker);
            $('#id_alarm_level_' + v + '_sms_template').on(
                'input',
                function () {
                    var self = $(this);
                    // eslint-disable-next-line no-control-regex
                    self.val(self.val().replace(/[^\x00-\x7F]/g, ''));
                }
            );
        }
        return alarmReceptionField;
    }

    async check_activate_subscription() {
        // Add the sensor name into the URL if it is available.
        const sensor_name = $('#id_sensor_name').val();
        if (sensor_name === undefined) return;

        const { data } = await getClient().query({
            query: ACTIVATE_SUBCRIPTION_QUERY,
            variables: {
                deviceName: sensor_name,
            },
        });

        if (data?.canActivateSubscription) {
            confirm({
                message: gettext('SUBSCRIPTION_AUTO_ACTIVATE_WARNING'),
                onConfirm: () => {
                    $('#id_auto_activate_subscription').val('True');
                },
                oncancel: () => {
                    $('#id_sensor_name').val('');
                    confirm({
                        message: gettext('SUBSCRIPTION_AUTO_ACTIVATE_DECLINE_MESSAGE'),
                        type: ConfirmType.INFO,
                    });
                }
            })
        }
    }

    set_preferred_timezone() {
        // Dont change timezone of existing entities.
        if (this.editing) {
            return;
        }

        $('#id_timezone').val(profile.preferredTimezone);
    }

    constructor(
        editing,
        formInformation,
        translations,
        getLineUrl,
        configHelperDone$,
    ) {
        this.editing = editing;
        this.formInformation = formInformation;
        this.translations = translations;
        this.getLineUrl = getLineUrl;

        this.init_events();
        this.scheduleField = new ScheduleField();
        this.alarmReceptionFields = this.alarm_level_fields();
        this.set_preferred_timezone();

        (function () {
            $('#id_timezone').select2({
                width: '100%',
                dropdownAutoWidth: true,
                selectionAdapter: $.fn.select2.amd.require(
                    'FontAwesomeSingleSelection'
                ),
            });
        })();

        (function () {
            var form = new Form($('#sensor_detail form'), [
                FormField.createFactory(
                    ':input',
                    '#alarm_settings :input, input[type=hidden], button, input[id^="id_alarm_level_"][id$="_mm"]'
                ),
                FormFieldGroup.createFactory(),
                FormFieldSubGroup.createFactory(),
            ]);
            window.form = form;

            initAlarmLevelHelpers(form, configHelperDone$);

            var formInfo = new FormInformation(form, formInformation);
            formInfo.applyInfoIcons();
        })();

        renderAlarmLevelsGraph(getLineUrl);
    }
}

export const projectEditPage = (module) => {
    const {
        projectPk,
        translations,
        formInformation,
        getLineUrl,
    } = module.options;

    var editing = !!projectPk;

    const configHelperDone$ = useConfigHelper(new ProjectConfigHelper(SwarmType.VIBRATION, projectPk));

    new GenericEditPage(
        editing,
        formInformation,
        translations,
        getLineUrl,
        configHelperDone$
    );

    var scheduleField = new ScheduleField();
    scheduleField.change_schedules();
};

const renderAlarmLevelsGraph = (getLineUrl) => {
    // These are used to add hr tag under the alarm level name.
    $('#id_alarm_level_1_name').after("<hr class='underline_alarm_level_1'>");
    $('#id_alarm_level_2_name').after("<hr class='underline_alarm_level_2'>");
    $('#id_alarm_level_3_name').after("<hr class='underline_alarm_level_3'>");

    ReactDOM.render(
        <AlarmPreviewGraph getLineUrl={getLineUrl} />,
        document.getElementById('alarm-graph')
    );

    $('.graph_open_btn').click(function () {
        // Rendering the graph again on graph open so that the graph is updated with the latest data.
        ReactDOM.render(
            <AlarmPreviewGraph getLineUrl={getLineUrl} />,
            document.getElementById('alarm-graph')
        );
        $('.graph_view').show();
        $('.graph_open_btn').hide();
    });
};

export const measuringPointEditPage = (module) => {
    const {
        mpPk,
        translations,
        appendWifiPasswordField,
        overriddenFields,
        formInformation,
        getLineUrl,
    } = module.options;

    var editing = !!mpPk;

    const configHelperDone$ = useConfigHelper(new ConfigHelper(SwarmType.VIBRATION));

    var editPage = new GenericEditPage(
        editing,
        formInformation,
        translations,
        getLineUrl,
        configHelperDone$
    );
    window.project_name = '';

    var form = window.form;
    var projectOverrides = new MeasuringPointProjectOverrides(
        form,
        overriddenFields,
        editPage
    );

    var prevData = {}
    form.getElements(FormField).forEach((field) => {
        prevData[field.name] = field.input.val();
    });

    $('#id_project').change(function () {
        if (is_linked_to_project()) {
            $.getJSON("/project/get/" + $('#id_project').val(), function (data) {
                projectOverrides.override(data);
            });
        } else {
            projectOverrides.undo(prevData);
        }
    });

    if (is_linked_to_project()) {
        $('#id_project').trigger('change');
    }

    function is_linked_to_project() {
        return $('#id_project').val() != '';
    }

    function wifi_password() {
        var wifi_password_field = $($('#wifi_password_template').html());

        // Move the field into the desired location.
        form.getFormElement('information').container.append(
            wifi_password_field
        );

        const wifi_input = $('#id_wifi_password')[0];
        const wifi_password_mask = '•'.repeat(wifi_input.value.length);

        // Hide the password on initialization.
        wifi_input.value = wifi_password_mask;

        // Create a toggle for showing the WiFi password.
        wifi_password_field.click(function (elem) {
            wifi_input.value = wifi_input.value == wifi_password_mask ? wifi_input.placeholder :  wifi_password_mask;
            return false;
        });
    }

    if (appendWifiPasswordField) {
        wifi_password();
    }

    attachAutocompleteToQuerySelector('#id_location');
};
