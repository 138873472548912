import PropTypes from 'prop-types';
import { inputFieldClasses } from './Input';

const TextArea = ({
    className = inputFieldClasses,
    rows = 5,
    maxLength,
    value,
    onValueChange,
    disabled = false,
    error = false,
}) => (
    <textarea
        className={`${className} ${error && 'border-red-500'}`}
        rows={rows}
        maxLength={maxLength}
        defaultValue={value}
        onChange={(e) => onValueChange(e.target.value)}
        disabled={disabled}
    />
);
TextArea.propTypes = {
    className: PropTypes.string,
    rows: PropTypes.number,
    maxLength: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
    onValueChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
};

export default TextArea;
