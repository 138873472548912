/* eslint-disable no-nested-ternary */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import BaseRow from './BaseRow';
import { useConfirm } from '../../../confirm/Confirm';
import { PERMISSION_TRANSLATIONS, PERMISSION, INVITE_STATUS } from '../utils';
import Select from '../../../form/Select';

import { getClient } from '../../../../utils/graphql';

const identify = (share) => getClient().cache.identify(share);

const EditRow = ({
    availablePermissions,
    share,
    deleteShare,
    pendingUpdates,
    setPendingUpdates,
}) => {
    const confirm = useConfirm();
    const isPending = useMemo(() => {
        const ref = identify(share);
        return pendingUpdates.some((pendingUpdate) => ref === identify(pendingUpdate));
    }, [share, pendingUpdates]);

    const handleDeleteClick = useCallback(() => {
        confirm({
            message: interpolate(gettext('DELETE_SHARE_CONFIRMATION'), [share.forUser]),
            onConfirm: () => {
                deleteShare(share);
            },
        });
    }, [share, deleteShare, confirm]);

    const handleChange = useCallback(
        (e) => {
            setPendingUpdates((current) => {
                if (e.target.value === share.permission) {
                    return current.filter(
                        (pendingUpdate) => identify(pendingUpdate) !== identify(share)
                    );
                }

                return [...current, { ...share, permission: e.target.value }];
            });
        },
        [setPendingUpdates, share]
    );

    return (
        <BaseRow>
            <>
                <FontAwesomeIcon
                    icon={share.invite === INVITE_STATUS.PENDING ? faSpinner : faUser}
                    size="sm"
                    className="mr-1.5 align-sub"
                />
                {share.forUser}
            </>
            <div className="flex w-32 items-center">
                {share.sharedBy ? (
                    availablePermissions.length === 1 ? (
                        PERMISSION_TRANSLATIONS[share.permission]
                    ) : (
                        <Select
                            small={true}
                            data={Object.entries(PERMISSION_TRANSLATIONS).filter(([key, _value]) =>
                                availablePermissions.includes(key)
                            )}
                            onChange={handleChange}
                            highlight={isPending}
                            defaultValue={PERMISSION[share.permission]}
                        />
                    )
                ) : (
                    gettext('ORIGINAL_CREATOR')
                )}
            </div>
            {share.sharedBy && (
                <button type="button" onClick={handleDeleteClick} aria-label={gettext('DELETE')}>
                    <FontAwesomeIcon icon={faTrash} className="text-red-600" size="lg" />
                </button>
            )}
        </BaseRow>
    );
};

EditRow.propTypes = {
    availablePermissions: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(PERMISSION))),
    share: PropTypes.object.isRequired,
    deleteShare: PropTypes.func,
    pendingUpdates: PropTypes.array,
    setPendingUpdates: PropTypes.func,
};

export default EditRow;
