import { toNestErrors } from '@hookform/resolvers';
import { object, string, number, boolean, mixed } from 'yup';
import { isString } from 'lodash-es';

// Borrowed from:
// https://github.com/react-hook-form/resolvers/blob/master/yup/src/yup.ts.
// * We could not use it because their resolver wants to be fed with a
// prebuilt schema while we want to dynamically generate it based on the
// properties for the field that boil down from schema.json.
// * We want to use our own custom error messages that have to be provided
// by our i18n framework.
// * We have some custom validation methods that are not supported via that
// way.

function parseErrorSchema(error) {
    return (error.inner || []).reduce((previous, err) => {
        if (!previous[err.path]) {
            const simplifiedError = { message: err.message, type: err.type };

            /**
             * Sometimes a logged field error is not just an error message string but an
             * object or an array that contains more detailed errors for nested or
             * complex form fields. In that case, `error` is not just an array with one
             * message but an array with more entries. If that is the case, we also attach
             * the more detailed error object/array.
             */
            if (!isString(err.errors[0])) {
                simplifiedError.errors = err.errors;
            }

            previous[err.path] = simplifiedError;
        }

        return previous;
    }, {});
}

function buildYupSchema(fieldsSchema) {
    const yupSchema = {};

    Object.keys(fieldsSchema).forEach((field) => {
        const config = fieldsSchema[field];
        if (!config.mount) return;

        let validator;
        let makeRequired = false;

        switch (config.type) {
            case 'char':
                validator = string().trim();
                if (config.maxLength !== undefined) {
                    validator = validator.max(
                        config.maxLength,
                        interpolate(
                            gettext('MAX_LENGTH_VALIDATION'),
                            { maxLength: config.maxLength },
                            true
                        )
                    );
                }
                break;

            case 'integer':
            case 'float':
                validator = number();
                if (config.disableWithZero === true && config.minValue !== undefined) {
                    // Use a custom validator to handle the disableWithZero condition.
                    validator = validator.test(
                        'minValueOrZero',
                        interpolate(
                            gettext('ALARM_VALUE_VALIDATION'),
                            { minValue: config.minValue },
                            true
                        ),
                        (value) => {
                            // Allow 0:
                            if (value === 0) {
                                return true;
                            }
                            // Apply minValue constraint.
                            return value >= config.minValue;
                        }
                    );
                } else if (config.minValue !== undefined) {
                    makeRequired = true;
                    validator = validator.min(
                        config.minValue,
                        interpolate(
                            gettext('MIN_VALUE_VALIDATION'),
                            { minValue: config.minValue },
                            true
                        )
                    );
                }
                if (config.maxValue !== undefined) {
                    makeRequired = true;
                    validator = validator.max(
                        config.maxValue,
                        interpolate(
                            gettext('MAX_VALUE_VALIDATION'),
                            { maxValue: config.maxValue },
                            true
                        )
                    );
                }
                break;

            case 'boolean':
                validator = boolean();
                break;

            default:
                validator = mixed();
                break;
        }

        validator = validator.nullable();

        if (config.required || makeRequired) {
            validator = validator.required(gettext('FIELD_REQUIRED_VALIDATION'));
        }

        if (config.validate) {
            validator = validator.test(
                'custom-validator',
                // Text below does not need a translation as it is never shown to the user.
                'Custom validation failed',
                function test(value, testContext) {
                    const { path, createError } = this;
                    const validOrError = config.validate(value, testContext);

                    if (validOrError !== true) {
                        return createError({
                            path,
                            message: validOrError,
                        });
                    }

                    // Validation passed.
                    return true;
                }
            );
        }

        yupSchema[field] = validator;
    });

    return object().shape(yupSchema);
}

// `resolver` in React Hook Form is a function that does the validation and
// value cleanup/sanitation. We use it for validation. So, you are better
// off reading this function name as `validator`.
export async function resolver(values, context, resolverOptions) {
    const schema = buildYupSchema(resolverOptions.fields);

    try {
        const result = await schema.validate(values, { abortEarly: false, context });

        return {
            values: result,
            errors: {},
        };
    } catch (e) {
        if (!e.inner) {
            throw e;
        }

        return {
            values: {},
            errors: toNestErrors(parseErrorSchema(e), resolverOptions),
        };
    }
}
