import { isNull } from 'lodash-es';
import { utcToZonedTime, toDate, format } from 'date-fns-tz';
import { isWithinInterval, subMilliseconds, areIntervalsOverlapping } from 'date-fns';
import { faCheck, faXmark, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const isoFormatWithoutZ = "yyyy-MM-dd'T'HH:mm:ss.SSS";
export const calendarTimezone = 'Etc/UTC';

// Function that converts a date to a calendar date in the UTC timezone.
// This ensures that dates are standardized by removing any timezone offset.
// For example:
// 2024-03-31 00:00:00+01:00 becomes 2024-03-31 00:00:00+00:00
// 2024-03-31 23:59:59+02:00 becomes 2024-03-31 23:59:59+00:00
// This helps in eliminating issues with variable-length days (e.g., 23 or 25 hours)
// caused by daylight saving time changes, ensuring consistent formatting.
export function toCalendarDate(date) {
    return toDate(format(date, isoFormatWithoutZ), { timeZone: calendarTimezone });
}

export function createTimeSpanObject(from, to) {
    return [{ timeSpan: { from, to } }];
}

export function getProgressBarColorClass(measuredValue, warningLevel, alarmLevel) {
    const offset = warningLevel - measuredValue;
    if (isNull(offset)) return null;

    if (offset > 0) return 'bg-success';
    if (measuredValue >= alarmLevel) return 'bg-error';

    return 'bg-warning';
}

export function getLdenIcon(measuredValue, warningLevel, alarmLevel) {
    if (measuredValue < warningLevel) return { icon: faCheck, iconColor: 'text-success' };
    if (measuredValue > alarmLevel) return { icon: faXmark, iconColor: 'text-error' };
    return { icon: faTriangleExclamation, iconColor: 'text-warning' };
}

export function getEventInfo(events, eventName) {
    const eventItem = events?.find((event) => event.__typename === eventName);
    return {
        date: eventItem?.timestamp ? new Date(eventItem.timestamp) : null,
        uploads: eventItem?.uploading ?? null,
    };
}

export function getDayPeriod(locale, period) {
    return locale.localize.dayPeriod(period);
}

export function generateBlocksPerDay({ scalesPerDay, timezone, blocksToRender }) {
    const days = Array.from(scalesPerDay.keys());
    const perDay = new Map(days.map((day) => [day, []]));

    blocksToRender.forEach(({ Element, data, elementProps }) => {
        // Separate the periods that could span multiple days into to be rendered blocks per day.
        data.forEach((period) => {
            const fromInCalendar = toCalendarDate(utcToZonedTime(period.timeSpan.from, timezone));
            const toInCalendar = toCalendarDate(utcToZonedTime(period.timeSpan.to, timezone));

            days.forEach((day) => {
                if (areIntervalsOverlapping({ start: fromInCalendar, end: toInCalendar }, day)) {
                    const startsOnThisDay = isWithinInterval(fromInCalendar, day);
                    const endsOnThisDay = isWithinInterval(subMilliseconds(toInCalendar, 1), day);

                    const start = startsOnThisDay ? fromInCalendar : day.start;
                    const end = endsOnThisDay ? toInCalendar : day.end;

                    const left = scalesPerDay.get(day)(start);
                    const right = scalesPerDay.get(day)(end);

                    perDay.get(day).push({
                        Element,
                        elementProps,
                        props: { period, left, width: right - left },
                    });
                }
            });
        });
    });

    return perDay;
}
