import { over } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { ExtendableError } from '../utils/errors';
import schema from '../schema.json';

export const loggedIn$ = new BehaviorSubject(true);

class ParsingJsonError extends ExtendableError {
    constructor(response, bodyAsText) {
        super(`Error while parsing JSON response of ${response.url}`);

        // Add the received body as metadata so that it can be inspected in Rollbar.
        // https://rollbar.com/guides/javascript/how-to-throw-exceptions-in-javascript/
        this.metadata = { bodyAsText };
    }
}

export const parseIncomingJson = async (response) => {
    const bodyAsText = await response.text();

    try {
        return JSON.parse(bodyAsText);
    } catch (e) {
        throw new ParsingJsonError(response, bodyAsText);
    }
};

class HttpError extends ExtendableError {}

export class IgnorableHttpError extends HttpError {}

const loginUrl = new URL(schema.loginUrlPath, window.location.origin);

class UnauthenticatedHttpError extends IgnorableHttpError {}

const checkIfUnauthenticated = (response) => {
    // At this moment when using cross-fetch, the `response.redirected` property
    // is not available. When using native fetch it is available. This means that
    // for now we only check the url. Perhaps this difference will be resolved in a
    // future version and we can add the check again.

    if (response.url.startsWith(loginUrl)) {
        // We've got redirected to the login page, this means we are not logged
        // in. Trigger an event so that the sbr-page can reload the graph page.
        loggedIn$.next(false);

        throw new UnauthenticatedHttpError();
    }
};

class StatusCode404HttpError extends IgnorableHttpError {}

const checkIfStatusCode404 = (response) => {
    if (response.status === 404) {
        // We do not want to throw errors for not found responses (for
        // example when using the graph while the user session changed).
        // We also return false to stop the further processing of
        // this request.
        throw new StatusCode404HttpError();
    }
};

class ResponseNotOkHttpError extends HttpError {}

const checkIfResponseNotOk = (response) => {
    if (!response.ok) {
        throw new ResponseNotOkHttpError(response.statusText);
    }
};

export const checkResponseForHttpErrors = (response) => {
    // The functions in this list will throw an error when it is not ok.
    over([checkIfUnauthenticated, checkIfStatusCode404, checkIfResponseNotOk])(response);
    return response;
};

export const handleFetchErrors = (response) => {
    try {
        checkResponseForHttpErrors(response);
    } catch (e) {
        if (e instanceof IgnorableHttpError) {
            // We do not want to throw errors for not found responses (for
            // example when using the graph while the user session changed).
            // We also return false to stop the further processing of
            // this request.
            return false;
        }

        throw e;
    }

    return true;
};

export const postData = async (url = '', data = {}) => {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (!handleFetchErrors(response)) {
        return null;
    }

    return parseIncomingJson(response);
};
