import PropTypes from 'prop-types';
import { trimStart } from 'lodash-es';

const ElementRefPropType = PropTypes.shape({ current: PropTypes.instanceOf(Element) });
export const funcRefPropType = PropTypes.shape({ current: PropTypes.func });

export default ElementRefPropType;

export const updateReadyReportsCount = (count) => {
    const bullet = document.getElementById('download-notification-bullet');

    if (bullet) bullet.dataset.number = count;
};

// Helper function for ensuring that a reference from the mock store matches
// a provided query. Checks if all fields in the query match the corresponding
// fields in the reference from the store.
function refMatchesQuery(store, ref, query) {
    return Object.entries(query).every(([field, value]) => store.get(ref, field) === value);
}

export class MockStoreHelper {
    constructor(store, cache, typename) {
        this.store = store; // Instance from `@graphql-tools/mock`.
        this.cache = cache; // Instance of Apollo client's cache.
        this.typename = typename; // GraphQL typename for the helper.

        // Array to retain references added to the mock store. Useful for
        // executing get, filter, or delete operations on the mock store.
        this.entries = [];
    }

    // Method to generate Apollo cache identifier for an object. The
    // `__typename` field is set as the typename given to the helper.
    identify(object) {
        object.__typename = this.typename;
        return this.cache.identify(object);
    }

    identifyAndGetRef(object) {
        // Below extracts 5 out of 'MeasuringPointCommentType:5'.
        const id = trimStart(this.identify(object), `${this.typename}:`);

        return this.getById(id);
    }

    getById(id) {
        return this.store.get(this.typename, id);
    }

    // Method to locate a single reference from the mock store entries that
    // matches the provided query. Returns the matching reference.
    findReference(query) {
        return this.entries.find((ref) =>
            // For each reference(ref) in the mock store entries, checks if all
            // fields in the provided query match the corresponding fields in the
            // reference.
            refMatchesQuery(this.store, ref, query)
        );
    }

    // Method for finding all matching references from the mock store entries
    // based upon a provided query. Returns the matching entries.
    filterReference(query) {
        return this.entries.filter((ref) =>
            // For each reference(ref) in the mock store entries, checks if all
            // fields in the provided query match the corresponding fields in the
            // reference.
            refMatchesQuery(this.store, ref, query)
        );
    }

    // Method for adding a new object to the mock store. Generates a unique id
    // for it and pushes the resultant object to the entries.
    add(object) {
        const ref = this.identifyAndGetRef(object);

        // Store the object under the just created reference.
        this.store.set(ref, object);

        // Store the reference in our internal list.
        this.entries.push(ref);

        return ref;
    }

    // Method for deleting a reference from the mock store that matches the
    // provided query.
    delete(query) {
        const foundRef = this.findReference(query);
        const index = this.entries.findIndex((ref) => ref === foundRef);
        this.entries.splice(index, 1);
        return foundRef;
    }

    // Method for updating a reference in the mock store based on identifyArgs,
    // setting new values from the update object.
    //
    // @param {Object} identifyArgs - An object that holds the query parameters
    // used to identify the object to update.
    // @param {Object} update - An object with the properties and their new
    // values that will be used for the update.
    // @returns {Reference} The Apollo JS reference to the updated object in
    // the store.
    update(identifyArgs, update) {
        const ref = this.identifyAndGetRef(identifyArgs);

        Object.entries(update).forEach(([key, value]) => {
            this.store.set(ref, key, value);
        });

        return ref;
    }
}

export function PageBreak() {
    return <div className="pagebreak" />;
}

export function NewPageTable({ title, header, tableData }) {
    return (
        <>
            <PageBreak />
            <h1>{title}</h1>
            <table className="w-full divide-solid border border-gray-500 text-center align-middle text-xs">
                <thead>
                    <tr>
                        {header.map((name, columnIndex) => (
                            <th key={columnIndex} className="border py-2">
                                {name}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((tableRow, tableRowIndex) => (
                        <tr key={tableRowIndex}>
                            {tableRow.map((content, columnIndex) => (
                                <td key={columnIndex} className="border py-1">
                                    {content}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}
NewPageTable.propTypes = {
    title: PropTypes.string.isRequired,
    header: PropTypes.array.isRequired,
    tableData: PropTypes.array.isRequired,
};

export function valueToPercentage(value, max) {
    const percentage = (value / max) * 100;

    return percentage > 100 ? 100 : percentage;
}
