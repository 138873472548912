import { addMilliseconds, differenceInMilliseconds } from 'date-fns';
import { animationFrameScheduler, Observable } from 'rxjs';

const createCountdownObservable = (milliseconds) => {
    const endDate = addMilliseconds(new Date(), milliseconds);

    return new Observable((subscriber) => {
        animationFrameScheduler.schedule(function timer() {
            const remaining = differenceInMilliseconds(endDate, new Date());

            // Default negative remaining milliseconds into 0.
            subscriber.next(Math.max(remaining, 0));

            if (remaining > 0) {
                this.schedule();
                return;
            }

            subscriber.complete();
        });
    });
};

export default createCountdownObservable;
