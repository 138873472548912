import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { isEmpty, round, some } from 'lodash-es';

import { defaultPropTypes, useIsOverridable, useIsOverridden } from '../../utils';
import FormFieldContainer from '../FormFieldContainer';
import { useGenericFormContext } from '../../../pages/measuringPoint/GenericFormContext';
import { useTranslatedFormSchema } from '../../../pages/measuringPoint/schema-utils';
import { GenericRowField } from '../genericRowField/GenericRowField';
import Input from '../../../form/Input';
import { checkNumberWithinRange } from '../utils';

const borderColorClasses = ['border-input-green', 'border-input-orange', 'border-input-red'];

const emptyRow = { name: '', value: 0 };

function validateRow(row) {
    const errors = checkNumberWithinRange({ row, fieldName: 'value' });

    return isEmpty(errors) ? null : errors;
}

export function AlarmLevelSettingsField({ fieldOptions }) {
    const { name, settings } = fieldOptions;
    const { required } = settings;
    const overridden = useIsOverridden(name);
    const disabled = useIsOverridable(name) && !overridden;
    const { swarmType } = useGenericFormContext();
    const schema = useTranslatedFormSchema(swarmType, false);

    const fields = useMemo(
        () => [
            {
                name: 'name',
                label: schema.fields.getByName(`alarmLevel1Name`).label,
                render(props) {
                    return (
                        <>
                            <Input {...props} />
                            <div
                                className={`border ${borderColorClasses[props.index]}`}
                                data-testid={`colored-border-${name}-${props.index}`}
                            ></div>
                        </>
                    );
                },
            },
            {
                name: 'value',
                label: schema.fields.getByName(`alarmLevel1`).label,
                render(props) {
                    return (
                        <>
                            <Input
                                type="number"
                                {...props}
                                onChange={(e) => {
                                    props.onChange({
                                        target: { value: round(e.target.value, 1) || '' },
                                    });
                                }}
                            />
                            <div className="border"></div>
                        </>
                    );
                },
            },
        ],
        [schema, name]
    );

    return (
        <Controller
            name={name}
            defaultValue={[]}
            rules={{
                required,
                validate(rows, _formValues) {
                    const errorsPerRow = rows.map((row) => validateRow(row));

                    // If one or more rows has an error, we return all the rows, if
                    // not we return `true` to indicate a passed validation.
                    return some(errorsPerRow) ? errorsPerRow : true;
                },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
                <FormFieldContainer
                    fieldOptions={fieldOptions}
                    marginClass=""
                    showLabel={false}
                    showError={false}
                    widthClass={''}
                >
                    <GenericRowField
                        name={name}
                        fields={fields}
                        emptyRow={emptyRow}
                        maxRows={3}
                        value={value}
                        error={error ? error.errors : null}
                        onChange={onChange}
                        disabled={disabled}
                    />
                </FormFieldContainer>
            )}
        />
    );
}
AlarmLevelSettingsField.propTypes = defaultPropTypes;
