import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';
import { SwarmType } from '../../../enums';

const GenericFormContext = createContext();

export default GenericFormContext;

export function useGenericFormContext() {
    const context = useContext(GenericFormContext);

    if (!context) {
        throw new Error('No generic form context available.');
    }

    return context;
}

export function GenericFormContextProvider({ swarmType, children }) {
    return (
        <GenericFormContext.Provider value={{ swarmType }}>{children}</GenericFormContext.Provider>
    );
}
GenericFormContextProvider.propTypes = {
    swarmType: PropTypes.oneOf(Object.values(SwarmType)).isRequired,
    children: PropTypes.node.isRequired,
};
