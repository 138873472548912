import { pipe, tap } from 'rxjs';

/**
 * Renders blocks in a graph based on provided entries with start and end positions.
 *
 * @param {Graph} graph - The graph object.
 * @param {string} identifier - CSS class for entering and finding DOM objects.
 * @param {string} rectCssClass - CSS class used to style SVG rectangles.
 * @returns {Observable} - RxJS observable for managing block rendering.
 */
function renderBlocksInGraph(graph, identifier, rectCssClass, borderCssClass = null) {
    function addBorderLine(entries, classSuffix, x) {
        graph.drawArea
            .selectAll(`.${identifier}-${classSuffix}-border`)
            .data(entries, (d) => d.key)
            .join((enter) =>
                enter
                    .append('line')
                    .attr('class', `${identifier}-${classSuffix}-border ${borderCssClass}`)
                    .attr('y1', 0)
                    .attr('y2', graph.graphHeight)
            )
            .attr('x1', x)
            .attr('x2', x);
    }

    /**
     * Renders blocks based on provided entries.
     *
     * @param {Object[]} entries - Array of objects with 'start' and 'end' positions.
     */
    function renderBlocks(entries) {
        graph.drawArea
            .selectAll(`.${identifier}`)
            .data(entries, (d) => d.key)
            .join((enter) =>
                enter
                    .append('rect')
                    .attr('class', `${identifier} ${rectCssClass}`)
                    .attr('y', 0)
                    .attr('height', graph.graphHeight)
            )
            .attr('x', (d) => d.start)
            .attr('width', (d) => {
                // Default to 1px wide when:
                // * no end date yet
                // * start date and end date are the same.
                if (!d.end || d.start === d.end) {
                    return 1;
                }

                return d.end - d.start;
            });

        if (borderCssClass !== null) {
            addBorderLine(entries, 'left', (d) => d.start);
            addBorderLine(entries, 'right', (d) => d.end);
        }
    }

    return pipe(
        tap({
            next: (entries) => renderBlocks(entries),
            unsubscribe: () =>
                // On unsubscribe, ensure D3 cleans up DOM elements.
                renderBlocks([]),
        })
    );
}

export default renderBlocksInGraph;
