import { map, of, switchMap } from 'rxjs';
import { DataTypes, swarmTypeDataTypes } from '../../data-types';
import { BaseGraphElement, valueNotNull } from '../base-provider';
import { SamplesGraphElementsProvider } from '../samples-provider';
import { SwarmType } from '../../../enums';
import schema from '../../../schema.json';

export function isSoundDataType(dataType) {
    return swarmTypeDataTypes[SwarmType.SOUND].includes(dataType);
}

export class SoundGraphElement extends BaseGraphElement {
    constructor(record, dataType) {
        super(record);
        // `axis` is injected into the CSS class of the `line` or `rect` that is to
        // be rendered. As SWARM Sound does not have an axis, we fill it with the
        // data type.
        this.axis = dataType;
    }

    get value() {
        return this.record.value;
    }

    static createCollectionFromRecords(records, dataType) {
        return records.map((record) => new this(record, dataType)).filter(valueNotNull);
    }
}

export class SoundLxnGraphElement extends BaseGraphElement {
    static TIME_PERCENTS_TO_SHOW = schema.lnLevels;

    constructor(record, value, timePercent) {
        super(record);

        this.value = value;

        // `axis` is injected into the CSS class of the `line` or `rect` that is to
        // be rendered. As SWARM Sound does not have an axis, we fill it with the
        // timePercent.
        this.axis = `L${timePercent}`;
    }

    static createCollectionFromRecords(records) {
        return records
            .flatMap((record) =>
                record.value
                    .filter(({ timePercent }) => this.TIME_PERCENTS_TO_SHOW.includes(timePercent))
                    .map(({ value, timePercent }) => new this(record, value, timePercent))
            )
            .filter(valueNotNull);
    }
}

function isActive$(dataSet) {
    return dataSet.dataType$.pipe(map((dataType) => isSoundDataType(dataType)));
}

/**
 * Observables flow diagram: https://miro.com/app/board/uXjVPSSWJQ0=/
 */
export class SoundSamplesGraphElementsProvider extends SamplesGraphElementsProvider {
    static createGraphElementsObservable(dataSet, _legendData) {
        return dataSet.dataType$.pipe(
            switchMap((dataType) => {
                if (!isSoundDataType(dataType)) {
                    return of([]);
                }

                return dataSet.soundData$.pipe(
                    map((records) => {
                        const subset = records[dataType];

                        if (!subset) {
                            return [];
                        }

                        if (dataType === DataTypes.LXN) {
                            return SoundLxnGraphElement.createCollectionFromRecords(subset);
                        }

                        return SoundGraphElement.createCollectionFromRecords(subset, dataType);
                    })
                );
            })
        );
    }

    getD3IdentifierCssClass() {
        return 'sound';
    }

    getShouldCalculateElementPositionsObservable() {
        return isActive$(this.dataSet);
    }
}
