import { BehaviorSubject, Observable, combineLatest, map, switchMap, tap } from 'rxjs';
import { computePosition, arrow, shift } from '@floating-ui/core';
import { platform as platformDom } from '@floating-ui/dom';
import { flatten } from 'lodash-es';
import ReactDOM from 'react-dom';
import React from 'react';
import { BaseGraphPlugin } from './base-provider';
import { Collection } from '../../utils/rxjs';
import { NotImplementedError } from '../../utils/errors';

class BaseAction {
    constructor(key) {
        this.key = key;
        this.start$ = new BehaviorSubject();
        this.end$ = new BehaviorSubject();
    }

    buttonTestId = null;

    backgroundColorClass = 'bg-primary';

    render() {
        throw new NotImplementedError();
    }
}

export class ReactComponentAction extends BaseAction {
    constructor(key, props) {
        super(key);
        this.props = props;
    }

    /**
     * Component that needs to be rendered as action.
     * @type {React.ComponentType}
     */
    component;

    /**
     * props that are going to be passed to the component.
     * @type {object}
     */
    props;

    render(node) {
        ReactDOM.render(React.createElement(this.component, this.props), node);
    }
}

function roundByDPR(value) {
    const dpr = window.devicePixelRatio || 1;
    return Math.round(value * dpr) / dpr;
}

export default class ActionsPlugin extends BaseGraphPlugin {
    name = 'actions';

    constructor(...args) {
        super(...args);

        // Allow other providers / actions to add their actions to this collection.
        this.actions = new Collection();
    }

    attachProvider$(actionsProvider$) {
        return new Observable(() => {
            this.actions.add(actionsProvider$);

            return () => {
                this.actions.delete(actionsProvider$);
            };
        });
    }

    init() {
        const graphAreaPosition = this.graph.graphArea.node().getBoundingClientRect();
        const svgPosition = this.graph.svg.node().getBoundingClientRect();

        const svgLeft = svgPosition.left - graphAreaPosition.left;
        const left = this.graph.margin.left + svgLeft;
        const top = svgPosition.top - graphAreaPosition.top;

        this.container = this.graph.graphArea
            .append('div')
            .style('height', `${this.graph.margin.top}px`)
            .style('width', `${this.graph.graphWidth}px`)
            .style('top', `${top}px`)
            .style('left', `${left}px`)
            .attr('class', ' absolute');

        const actions$ = this.actions.entries$.pipe(
            switchMap((actionProviders) => combineLatest(actionProviders)),
            map((columnProviderResponses) => flatten(columnProviderResponses))
        );

        actions$
            .pipe(
                tap((actions) => {
                    this.renderActions(actions);
                })
            )
            .subscribe();
    }

    renderActions(actions) {
        this.container
            .selectAll('.action')
            .data(actions, (d) => d.key)
            .join(
                (enter) => {
                    const action = enter
                        .append('div')
                        .attr(
                            'class',
                            (d) =>
                                `action text-sm w-max absolute top-0 left-0 rounded px-1 h-full ${d.backgroundColorClass}`
                        );

                    action.attr('id', (d) => d.id);

                    action.each((d, i, nodes) => {
                        d.render(nodes[i]);
                    });

                    // Add the 'arrow'. In our case the arrow is a yellow square that sits
                    // behind the button.
                    action
                        .append('div')
                        .attr(
                            'class',
                            (d) => `absolute bottom-0 arrow w-2 h-2 -z-10 ${d.backgroundColorClass}`
                        );

                    action.each((d, i, nodes) => {
                        const arrowElement = nodes[i].querySelector('.arrow');

                        const subscription = combineLatest([
                            d.start$,
                            d.end$,
                            this.graph.xAxis.scaleUpdated$,
                        ])
                            .pipe(
                                tap(([start, end, axis]) => {
                                    // The code below is based on the canvas example in the
                                    // Floating UI documentation's platform section:
                                    // https://floating-ui.com/docs/platform.

                                    // The `canvas` is a rectangle that resembles our action bar.
                                    const canvas = {
                                        width: axis.range()[1],
                                        height: 0,
                                        x: 0,
                                        y: 0,
                                    };

                                    // The `reference` is a rectangle that resembles the shape
                                    // on the graph which is being followed.
                                    const left = axis(start);
                                    const right = axis(end);
                                    const reference = {
                                        width: right - left,
                                        height: 0,
                                        x: left,
                                        y: 0,
                                    };

                                    // `floating` is the rectangle of our action icon/button.
                                    const floating = {
                                        x: 0,
                                        y: 0,
                                        ...platformDom.getDimensions(nodes[i]),
                                    };

                                    const platform = {
                                        getElementRects: (data) => data,
                                        getDimensions: platformDom.getDimensions,
                                        // Clip rectangle to our 'canvas' (action bar)
                                        getClippingRect: () => canvas,
                                    };

                                    computePosition(reference, floating, {
                                        platform,
                                        middleware: [shift(), arrow({ element: arrowElement })],
                                    }).then(({ x, middlewareData }) => {
                                        // Update button position.
                                        Object.assign(nodes[i].style, {
                                            top: '0',
                                            left: '0',
                                            transform: `translate(${roundByDPR(x)}px,0px)`,
                                        });

                                        if (middlewareData.arrow) {
                                            const { x: arrowX } = middlewareData.arrow;
                                            // Position the arrow, if it exists.
                                            Object.assign(arrowElement.style, {
                                                left: arrowX != null ? `${arrowX}px` : '',
                                            });
                                        }
                                    });
                                })
                            )

                            .subscribe();

                        // Store the subscription in order to unsubscribe when
                        // this node gets removed.
                        nodes[i].subscription = subscription;
                    });

                    return action;
                },
                (update) => update,
                (remove) =>
                    remove
                        .each((d, i, nodes) => {
                            nodes[i].subscription.unsubscribe();
                        })
                        .remove()
            );
    }
}
