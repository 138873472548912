import { isNil, property } from 'lodash-es';
import { combineLatestWith, map, tap } from 'rxjs';
import warning from '../../utils/logger';
import { BaseGraphElement, BaseGraphElementsProvider, GraphElementPosition } from './base-provider';

const EventLegendType = 'm';

class EventGraphElementPosition extends GraphElementPosition {
    // Overridden so that the y position of the tooltip circle is
    // in the middle of of the graph.
    static findY(graph, _element) {
        return graph.graphHeight / 2;
    }

    getToolTipCircleType() {
        return EventLegendType;
    }
}

export class EventGraphElement extends BaseGraphElement {
    getPositionElementClass() {
        return EventGraphElementPosition;
    }
}

/**
 * Observables flow diagram: https://miro.com/app/board/uXjVPSSWJQ0=/
 */
export default class EventsGraphElementsProvider extends BaseGraphElementsProvider {
    name = 'events';

    static createGraphElementsObservable(dataSet, legendData) {
        return dataSet.events$.pipe(
            combineLatestWith(legendData.visibleTypes$),
            map(([records, visibleTypes]) =>
                visibleTypes.includes(EventLegendType)
                    ? EventGraphElement.createCollectionFromRecords(records)
                    : []
            )
        );
    }

    init() {
        const renderer = this.graphElementPositions$.pipe(
            tap((events) => this.renderStartStopAreas(events))
        );

        this.subscription.add(renderer.subscribe());
    }

    renderStartStopAreas(elementPositions) {
        const events = [];
        const { visibleMin, visibleMax } = this.dataSet;

        const addEvent = (start, stop, type = 'not_measured_bargraph', fill = '#eeeeee') => {
            // Events that start after our visible max and stop before our
            // visible min are invisible and do not need to get rendered.
            if (start > visibleMax || stop < visibleMin) {
                return;
            }

            if (start > stop) {
                warning('Event start date is higher then end date.', {
                    events: elementPositions.map(property('record')),
                });
                // Prevent rendering a rect with negative width.
                return;
            }

            events.push({
                timestamp: start,
                endtime: stop,
                type,
                fill,
                key: type + start,
            });
        };

        const matchesDataType = this.graph.getDisplayedDataType().eventConfigMatcher;

        // Without any start/stop we won't display anything.
        if (elementPositions.length) {
            const fakeStart = this.dataSet.loadedMin;
            let measurementStop = null;

            let serverContact = null;
            let lastRecord = null;
            let ignoredLastStart = null;

            elementPositions.forEach((position) => {
                switch (position.record.__typename) {
                    case 'EventStop':
                        if (!isNil(ignoredLastStart)) {
                            addEvent(
                                ignoredLastStart.timestamp,
                                position.timestamp,
                                'other_datatype',
                                'url(#other_datatype-stripes)'
                            );
                            ignoredLastStart = null;
                        }
                        // Keep the oldest until we used the stop
                        // unless we didn't have one yet!
                        if (!measurementStop || measurementStop > position.timestamp) {
                            measurementStop = position.timestamp;
                        }
                        break;
                    case 'EventStart':
                        if (!matchesDataType(position.record)) {
                            ignoredLastStart = position;
                        }
                        if (!measurementStop) {
                            // Repeated measurement start, don't know where we stopped tho...
                            // Lets take the start time minus 60 seconds so we can at least see it.
                            measurementStop = position.timestamp - 60000;
                        }
                        addEvent(measurementStop, position.timestamp);
                        measurementStop = null;
                        break;
                    case 'EventServerContact':
                        serverContact = position.timestamp;
                        break;
                    case 'EventLastRecord':
                        lastRecord = position.timestamp;
                        break;
                    default:
                    // Ignore EventConfig.
                }
            });

            if (serverContact) {
                if (lastRecord) {
                    addEvent(lastRecord, serverContact, 'unknown_data', 'url(#yellow-stripes)');
                }
                if (!isNil(ignoredLastStart)) {
                    addEvent(
                        ignoredLastStart.timestamp,
                        serverContact,
                        'other_datatype',
                        'url(#other_datatype-stripes)'
                    );
                }
                addEvent(serverContact, visibleMax, 'end_of_measurement', 'url(#grey-stripes)');
            }
            // If we have a stop record but no new start
            // assume it's still stopped in that case.
            if (measurementStop != null && measurementStop !== fakeStart) {
                // Measurement start is the begin of the measurements
                // so we can now plot the gray area.
                // Picked year 3000 because of reasons.
                addEvent(measurementStop, visibleMax);
            }
        }

        // Measurement start is the begin of the measurements
        // so we can now plot the gray area.
        this.graph.drawArea
            .selectAll('.event')
            .data(events, (d) => d.key)
            .join((enter) =>
                enter
                    .append('rect')
                    .attr('class', (d) => `${d.type} event`)
                    .attr('y', 0)
                    .attr('height', this.graph.graphHeight)
                    .attr('fill', (d) => d.fill)
            )
            .attr('x', (d) => this.graph.xAxis.scale(d.timestamp))
            .attr(
                'width',
                (d) => this.graph.xAxis.scale(d.endtime) - this.graph.xAxis.scale(d.timestamp)
            );
    }
}
