import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import ExternalTimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import './time-picker-styling.scss';
import { useMemo } from 'react';

const TimePicker = ({
    small,
    highlight,
    disabled,
    value,
    is24Hour,
    ariaLabel,
    readOnly,
    error,
    ...props
}) => {
    const ariaLabelProps = useMemo(() => {
        if (!ariaLabel) {
            return {};
        }

        return {
            nativeInputAriaLabel: ariaLabel,
            hourAriaLabel: `${ariaLabel}-hour`,
            minuteAriaLabel: `${ariaLabel}-minute`,
            amPmAriaLabel: `${ariaLabel}-amPm`,
        };
    }, [ariaLabel]);

    return (
        <div
            className={`relative inline-flex w-full rounded border border-neutral-300 bg-white text-form-text ${
                highlight ? 'border-warning' : ''
            }
            ${error ? 'border-red-500' : ''} ${readOnly ? 'cursor-not-allowed' : ''}`}
        >
            <ExternalTimePicker
                value={value}
                className={`omnidots ${small ? 'py-2' : 'py-4'}`}
                disabled={disabled}
                disableClock
                format={is24Hour ? 'HH:mm' : 'hh:mm a'}
                clearIcon={null}
                {...ariaLabelProps}
                {...props}
            />

            {is24Hour || (
                <div
                    className={`pointer-events-none absolute inset-y-0 right-0 flex items-center px-4`}
                >
                    <FontAwesomeIcon icon={faAngleDown} className="h-4 w-4 fill-current" />
                </div>
            )}
        </div>
    );
};
TimePicker.propTypes = {
    small: PropTypes.bool,
    highlight: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    is24Hour: PropTypes.bool,
    disabled: PropTypes.bool,
    ariaLabel: PropTypes.string,
    readOnly: PropTypes.bool,
    error: PropTypes.bool,
};

export default TimePicker;
