const onElementReady = (querySelector) =>
    new Promise((resolve) => {
        const waitForElement = () => {
            const element = document.querySelector(querySelector);
            if (element) {
                resolve();
            } else {
                window.requestAnimationFrame(waitForElement);
            }
        };
        waitForElement();
    });

export default onElementReady;
