import 'select2';
import 'select2/dist/css/select2.css';

import { zoomIdentity } from 'd3-zoom';
import { gql, ApolloProvider } from '@apollo/client';
import $ from 'jquery';
import { isNil, isNull, first } from 'lodash-es';
import ReactDOM from 'react-dom';
import {
    BehaviorSubject,
    combineLatest,
    firstValueFrom,
    fromEvent,
    map,
    skip,
    skipWhile,
    switchMap,
    takeUntil,
    tap,
    throttleTime,
    timer,
} from 'rxjs';
import { secondsToMilliseconds } from 'date-fns';
import DataSetDateRangePicker from './graph/dataset-date-range-picker';

import { MultiSensorDataSet } from './graph/dataset';
import { ValueFrequencyGraph, ValueTimeGraph } from './graph/graph';
import {
    LastSeenPoller,
    Scales,
    getDayDays,
    getHourDays,
    getMinuteDays,
    getMonthDays,
    getWeekDays,
    getYearDays,
    initLineWarningHelper,
    oneDay,
    setGraphLoadedFlag,
    setLogo,
    getOverrides,
    overrides$,
    dataSetGuideLine,
    castValue,
} from './graph/graph-helpers';
import { XAxisScaleSlider, YAxisScaleSlider } from './graph/scale-slider';
import VeffDayGraph from './graph/veff-day-graph';
import { getClient } from './utils/graphql';
import FontAwesomeSingleSelection from './utils/select2-custom';
import { applyNamingConventionsByGuideline, defaultGuideline } from './utils/naming-conventions';
import { loggedIn$ } from './graph/http-helper';
import { VperTable } from './graph/vper-table';
import { vibrationGraphDataTypes, SCALES_TYPE } from './graph/data-types';
import { LegendData } from './graph/legend-helpers';
import ExportButtons from './components/graph/components/ExportButtons';
import { ScaleTypeSelect } from './graph/axis';
import { SwarmType } from './enums';
import OverridesForm from './components/graph/OverridesForm';
import { NotificationsProvider } from './components/notifications/Notifications';
import { CursorFunctionSelector } from './graph/cursor-functions';
import { isSoundDataType } from './graph/graph-elements/sound-plugin/sound-plugin';
import profile, { ProfileProvider } from './profile';
import { PERMISSION } from './components/pages/sharing/utils';
import DataTabSelector from './components/graph/dataTab/DataTabSelector';
import DataTabContent from './components/graph/dataTab/DataTabContent';
import { createEditUrl } from './components/pages/measuringPoint/GenericForm';

// Helper method that can be executed by dev's to get the PDF view URL
// of the current graph page they are looking at.
window.createPdfViewUrl = async () => {
    const dataSet = window.timeGraph.data;

    const [dataType, mpId, dateRange] = await firstValueFrom(
        combineLatest([dataSet.dataType$, dataSet.sensor$, dataSet.dateRange.observable$])
    );

    return [`/sbr/pdf_view`, mpId, dateRange[0], dateRange[1], dataType].join('/');
};

const sbrPage = (module) => {
    const {
        dateRangePickerLanguage,
        logos,
        lastMeasuringPoint,
        lastStart,
        lastEnd,
        lastOnlineUrl,
        getLineUrl,
        getAlarmsUrl,
        getShowTracesUrl,
        initialGuidelines,
    } = module.options;

    const height = 550;
    const width = $('.graph-area')[0].clientWidth - 70;
    const barGraphMargin = {
        top: 20,
        right: 0,
        bottom: 120,
        left: 55,
    };

    const dotGraphMargin = {
        top: 20,
        right: 0,
        bottom: 40,
        left: 55,
    };

    const dataSet = new MultiSensorDataSet(getLineUrl, getAlarmsUrl);

    // Init MP selector.
    (() => {
        const mpSelector = $('#measuring-point-selector');

        const loadMp = (id) => {
            if (isNil(id)) {
                return;
            }
            window.location.hash = `#${id}`;

            const measuringPointOptionElement = $(`select#measuring-point-selector [value=${id}]`);
            const swarmType = measuringPointOptionElement.attr('swarm_type');
            const permission = measuringPointOptionElement.attr('permission');
            const name = measuringPointOptionElement.text();

            if (!Object.values(SwarmType).includes(swarmType)) {
                throw new Error(`Could not determine swarm type of measuring point #${id}`);
            }
            if (!Object.values(PERMISSION).includes(permission)) {
                throw new Error(`Could not determine permission of measuring point #${id}`);
            }

            dataSet.loadSensor(id, name, swarmType, permission);
            dataSet.checkDataUpdateNeeded(true);
        };

        function formatOption(option) {
            if (!option.id) {
                return option.text;
            }
            const swarmType = option.element.attributes.swarm_type.value;
            const detached = castValue(option.element.attributes.detached.value);
            let iconType = 'vibration';
            if (swarmType === SwarmType.SOUND) {
                iconType = 'sound';
            } else if (swarmType === SwarmType.AIR) {
                iconType = 'air';
            }

            return $(`
                <span class="flex items-center">
                    <div class="swarm-${iconType}-icon ${
                detached ? 'detached-device' : ''
            } mr-1.5 h-6 w-8"></div>
                    ${option.text}
                </span>
            `);
        }

        mpSelector.select2({
            width: '100%',
            selectionAdapter: FontAwesomeSingleSelection,
            theme: 'default select2-search',
            templateResult: formatOption,
            templateSelection: formatOption,
        });
        mpSelector.on('select2:select', (event) => {
            loadMp(event.currentTarget.value);
        });

        let mpId = null;

        if (window.location.hash) {
            mpId = parseInt(window.location.hash.replace('#', ''), 10);
        } else if (lastMeasuringPoint && lastMeasuringPoint.length) {
            // For now we can only select one, so always pick the first.
            mpId = first(lastMeasuringPoint);
        } else {
            mpId = $('#measuring-point-selector option:nth-child(1)').attr('value');
        }

        // Only select if we have something to select.
        if (mpId) {
            mpSelector.val(mpId);
            // Trigger the `change.select2` event to update the displayed label.
            mpSelector.trigger('change.select2');

            loadMp(mpId);
        }
    })();

    // Go to last save.
    if (lastStart && lastEnd) {
        dataSet.setVisibleMinMax(new Date(lastStart), new Date(lastEnd));
    }
    const scales = new Scales();
    const legendData = new LegendData();

    const baseConfig = {
        height,
        width,
        scales,
        legendData,
        getShowTracesUrl,
    };

    const timeGraph = new ValueTimeGraph({
        ...baseConfig,
        margin: barGraphMargin,
        name: 'bar',
        container: '#graph-velocity',
        data: dataSet,
        showBrushGraph: true,
        animate: true,
    });

    window.timeGraph = timeGraph;

    // Pulled this observable apart so that it can be controlled by the toggle button too.
    const frequencyGraphScaleType$ = new BehaviorSubject(SCALES_TYPE.AUTO);

    const frequencyGraph = new ValueFrequencyGraph({
        ...baseConfig,
        margin: dotGraphMargin,
        name: 'dot',
        container: '#graph-dot',
        data: dataSet,
        dotSize: 4,
        scaleType$: frequencyGraphScaleType$,
    });

    window.frequencyGraph = frequencyGraph;

    // Show/hide line warnings.
    initLineWarningHelper(frequencyGraph.$container, dataSet);

    const veffDayGraph = new VeffDayGraph({
        ...baseConfig,
        margin: dotGraphMargin,
        name: 'veffDay',
        container: '#graph-veff-day',
        data: dataSet,
        timeGraph,
        dotSize: 4,
    });

    const graphs = [timeGraph, frequencyGraph, veffDayGraph];

    // eslint-disable-next-line no-new
    new VperTable('#vss-table', dataSet, legendData);

    // eslint-disable-next-line no-new
    new LastSeenPoller(lastOnlineUrl, dataSet);

    ReactDOM.render(
        <DataTabSelector dataSet={dataSet} />,
        document.getElementById('data-type-selector')
    );

    ReactDOM.render(
        <DataTabContent
            dataSet={dataSet}
            graphElement={document.getElementById('graph-velocity')}
        />,
        document.getElementById('data-type-content')
    );

    const initGraphTypeButtons = (container, graph) => {
        container.find('.graph-type').on('click', (event) => {
            event.preventDefault();

            const button = event.currentTarget;

            graph.setGraphType(button.dataset.type);
        });
    };

    const initZoomButtons = (graph) => {
        const zoomFactor = 1;

        const scaleBy = (factor) => {
            graph.zoom.zoom.scaleBy(graph.zoom.zoomTransition(), factor);
        };

        graph.$container.find('.graph-zoom').on('click', (event) => {
            event.preventDefault();

            const button = event.currentTarget;
            const { type } = button.dataset;

            switch (type) {
                case 'in':
                    scaleBy(1 + zoomFactor);
                    break;
                case 'out':
                    scaleBy(1 / (1 + zoomFactor));
                    break;
                case 'all':
                    graph.zoom.resetZoom();
                    break;
                default:
                    throw new Error(`Zoom action '${type}' not recognized`);
            }
        });
    };

    const toggleDateRangePicker = (unmountOnly) => {
        const placeholder = document.getElementById('date-range-picker-placeholder');

        // UnmountComponentAtNode returns false if there was no
        // component to unmount.
        const unmountSuccessful = ReactDOM.unmountComponentAtNode(placeholder);

        if (unmountOnly || unmountSuccessful) {
            return false;
        }

        ReactDOM.render(
            <DataSetDateRangePicker dataSet={dataSet} language={dateRangePickerLanguage} />,
            placeholder
        );
        return true;
    };

    const initZoomLevelFilters = (container, graph) => {
        const typeButtons = container.find('.view-types .view_type');
        const goToNowPercentage = 95; // percent

        typeButtons.on('click', (event) => {
            event.preventDefault();

            const button = event.currentTarget;

            typeButtons.removeClass('active');
            button.classList.add('active');

            const is = (typeName) => button.className.includes(typeName);

            let days = 1;
            if (is('date-range-picker')) {
                if (!toggleDateRangePicker()) {
                    // Remove class if the datepicker got unmounted.
                    typeButtons.removeClass('active');
                }
                return;
            }
            if (is('year')) {
                days = getYearDays();
            }
            if (is('month')) {
                days = getMonthDays();
            }
            if (is('week')) {
                days = getWeekDays();
            }
            if (is('day')) {
                days = getDayDays();
            }
            if (is('hour')) {
                days = getHourDays();
            }
            if (is('five_minute')) {
                days = getMinuteDays() * 5;
            }
            toggleDateRangePicker(true);
            graph.zoom.zoomToScale(days);
        });
        const getCurrentScale = () =>
            getYearDays() /
            ((graph.xAxis.scale.domain()[1].valueOf() - graph.xAxis.scale.domain()[0].valueOf()) /
                oneDay);

        const getCurrentDaysInMs = () =>
            graph.xAxis.scale.domain()[1].valueOf() - graph.xAxis.scale.domain()[0].valueOf();

        container.find('#to_now').on('click', () => {
            toggleDateRangePicker(true);
            const scale = getCurrentScale();
            const nowTemp = new Date();
            const currentDaysInMillis = getCurrentDaysInMs();
            nowTemp.setMilliseconds(
                nowTemp.getMilliseconds() -
                    currentDaysInMillis +
                    (currentDaysInMillis / 100) * (100 - goToNowPercentage)
            );
            graph.zoom
                .zoomTransition()
                .call(
                    graph.zoom.zoom.transform,
                    zoomIdentity.scale(scale).translate(-graph.xAxis.originalScale(nowTemp), 0)
                );
        });
    };

    // Init long press button for the time panning toggle.
    (() => {
        const button = document.querySelector('#to_now');
        const mouseDown$ = fromEvent(button, 'mousedown');
        const mouseUp$ = fromEvent(button, 'mouseup');

        const longPress$ = mouseDown$.pipe(
            switchMap(() => timer(secondsToMilliseconds(2)).pipe(takeUntil(mouseUp$)))
        );

        longPress$.subscribe(() => dataSet.dateRange.toggleTimePanning());
    })();

    // Init BarGraph Buttons.
    (() => {
        const container = $('#graph-velocity');

        initGraphTypeButtons(container, timeGraph);
        initZoomLevelFilters(container, timeGraph);
    })();

    // Init zoom buttons.
    graphs.forEach((graph) => {
        initZoomButtons(graph);
    });

    // Set graph logo.
    dataSet.currentSensor$.subscribe((id) => {
        if (isNull(id)) {
            // ID can be `null` while initializing.
            return;
        }

        const logo = logos[id.toString()];

        graphs.forEach((graph) => {
            setLogo(graph.graph, logo);
        });
    });

    // Init naming conventions changer.
    (() => {
        let initialGuideLine = defaultGuideline;
        if (window.location.hash) {
            const mpId = parseInt(window.location.hash.replace('#', ''), 10);
            // Only not-DUST measuring points have guidelines.
            if (Object.keys(initialGuidelines).includes(mpId)) {
                initialGuideLine = initialGuidelines[mpId];
            }
        }

        dataSetGuideLine(dataSet, initialGuideLine).subscribe((guideLine) => {
            applyNamingConventionsByGuideline(guideLine);
        });
    })();

    // Init range sliders.
    (() => {
        // Create Y axis range sliders.
        graphs.forEach((graph) => {
            // eslint-disable-next-line no-new
            new YAxisScaleSlider(graph, scales);
        });

        // Create one X axis range slider for the Fdom/Vtop graph.
        // eslint-disable-next-line no-new
        new XAxisScaleSlider(frequencyGraph, scales);
    })();

    // Init exports.
    (() => {
        timeGraph.ready.then(() => {
            ReactDOM.render(
                <ApolloProvider client={getClient()}>
                    <ExportButtons
                        dataSet={dataSet}
                        graphType$={timeGraph.graphType$}
                        scales={scales}
                        legendData={legendData}
                        frequencyGraphScaleType$={frequencyGraphScaleType$}
                    />
                </ApolloProvider>,
                document.getElementById('export-choices')
            );
        });
    })();

    {
        // Set the URL for the recent traces button:
        const $recentTracesButton = $('#id_to_traces');

        const updateRecentTracesButton = () => {
            $recentTracesButton.toggle(vibrationGraphDataTypes.includes(dataSet.dataType));
            $recentTracesButton.prop(
                'href',
                getShowTracesUrl(
                    dataSet.sensor,
                    new Date().setDate(new Date().getDate() - 7),
                    new Date().getTime(),
                    dataSet.dataType,
                    getOverrides()
                )
            );
        };

        // Update overrides in the recent traces URL when configHelper has run.
        overrides$.subscribe(() => updateRecentTracesButton());

        // Update recent traces button when the selected data type or
        // current sensor has changed. `data-type-updated` also triggers
        // when the MultiSensorDataSet changes sensor.
        dataSet.on('data-type-updated', updateRecentTracesButton);

        updateRecentTracesButton();
    }

    {
        // Set the URL for the recent traces button:
        const $configureMeasuringPointButton = $('#id_to_edit');

        dataSet.permission$.subscribe((permission) => {
            $configureMeasuringPointButton.toggle(permission === PERMISSION.FULL_ACCESS);
        });

        const updateConfigureMeasuringPointButton = () => {
            $configureMeasuringPointButton.prop(
                'href',
                createEditUrl(dataSet.sensor, dataSet.swarmType, false)
            );
        };

        // Update configure measuring point button when the selected data type or
        // current sensor has changed. `data-type-updated` also triggers
        // when the MultiSensorDataSet changes sensor.
        dataSet.on('data-type-updated', updateConfigureMeasuringPointButton);

        updateConfigureMeasuringPointButton();
    }

    // Store last graph state.
    {
        const storeGraphStateMutation = gql`
            mutation storeGraphState(
                $measuringPointId: Int!
                $startTime: BigInteger!
                $endTime: BigInteger!
            ) {
                storeGraphState(
                    measuringPointId: $measuringPointId
                    startTime: $startTime
                    endTime: $endTime
                ) {
                    ok
                }
            }
        `;

        combineLatest([
            dataSet.dateRange.observable$.pipe(
                // Leading is `false` because we don't want to send a mutation
                // when someone starts dragging, only at the end of dragging
                // or after 300ms while dragging.
                throttleTime(300, null, { leading: false, trailing: true })
            ),
            dataSet.currentSensor$,
        ])
            .pipe(
                // Skip the first emission as that just the currently loaded
                // state, we only want emissions that are triggered by an update.
                skip(1),
                map(([[min, max], currentSensor]) => ({
                    measuringPointId: currentSensor,
                    startTime: min,
                    endTime: max,
                })),
                // Only continue when the measuringPointId has a value.
                skipWhile((state) => !state.measuringPointId),
                tap(async (state) => {
                    await getClient().mutate({
                        variables: state,
                        mutation: storeGraphStateMutation,
                    });
                })
            )
            .subscribe();
    }

    // Init frequency graph scale type toggle button.
    const initFrequencyGraphScaleTypeToggleButton = () => {
        const id = 'frequency-scale-type-select';

        frequencyGraph.ready.then(() => {
            const toggleContainer = document.getElementById('toggle-scale-type');

            ReactDOM.render(
                <span className="relative -top-[10px] inline-block">
                    <label htmlFor={id} className="mr-1">
                        {gettext('SCALE_TYPE_LABEL')}
                    </label>
                    <div className="inline-block">
                        <ScaleTypeSelect scaleTypeObservable$={frequencyGraphScaleType$} id={id} />
                    </div>
                </span>,
                toggleContainer
            );
        });
    };
    initFrequencyGraphScaleTypeToggleButton();

    (function initSoundSurveyTrigger() {
        // Trigger event for SWARM Sound related survey in Hotjar on the Graph Page.
        dataSet.dataType$.subscribe((dataType) => {
            if (isSoundDataType(dataType) && typeof window.hj === 'function') {
                window.hj('event', 'open_sound_survey');
            }
        });
    })();

    (function initCursorFunctionsSelector() {
        const container = document.getElementById('graph-cursor-functions');

        timeGraph.ready.then(() => {
            ReactDOM.render(
                <CursorFunctionSelector
                    dataSet={dataSet}
                    cursorFunction$={timeGraph.cursorFunction$}
                />,
                container
            );
        });
    })();

    const overridesContainer = document.getElementById('overrides-container');
    ReactDOM.render(
        <ProfileProvider profile={profile}>
            <NotificationsProvider>
                <OverridesForm
                    dataSet={dataSet}
                    setOverrides={(overrides) => {
                        overrides$.next(overrides);
                    }}
                />
            </NotificationsProvider>
        </ProfileProvider>,
        overridesContainer
    );

    // Refresh the page when it is detected that the user is logged out, this
    // will show the login page.
    loggedIn$.subscribe((loggedIn) => !loggedIn && window.location.reload());

    // Let Chromium know we are ready.
    setGraphLoadedFlag(dataSet.ready);
};

export default sbrPage;
