export const inRange = (number, min, max) => number >= min && number <= max;

/**
 * Takes two unix timestamp ranges as array ([from, to], [from, to]).
 *
 * @param {int[]} timespanA - [from, to]
 * @param {int[]} timespanB - [from, to]
 * @returns {boolean} Returns true if they overlap, false if they don't.
 */
export const timestampRangeOverlap = (timespanA, timespanB) =>
    Math.max(timespanA[0], timespanB[0]) <= Math.min(timespanA[1], timespanB[1]);
