import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { asyncScheduler, combineLatest, debounce, map, scheduled, switchMap } from 'rxjs';
import { useObservable, useObservableState, useSubscription } from 'observable-hooks';
import { useFormContext } from 'react-hook-form';
import { isEqual, omit, pick } from 'lodash-es';
import { useTranslatedFormSchema } from '../pages/measuringPoint/schema-utils';
import { FormFieldLookup, LayoutHelper } from '../reactForm/Layout';
import Form from '../reactForm/Form';
import { ConfigHelper } from '../pages/measuringPoint/configHelper';
import ChoiceField from '../reactForm/fields/ChoiceField';
import { useWatchObservable } from '../reactForm/utils';
import { switchHead } from '../../utils/rxjs';
import { configConverter } from '../pages/measuringPoint/configConverter';
import { SwarmType } from '../../enums';
import { distanceConverter, revertDistanceConverter } from '../../utils/formatting';
import { MultiSensorDataSet } from '../../graph/dataset';

const OVERRIDE_OPTION = Object.freeze({
    AUTO: 'auto',
    MANUAL: 'manual',
});

const OVERRIDE_OPTION_TRANSLATIONS = Object.freeze({
    [OVERRIDE_OPTION.AUTO]: gettext('AUTO'),
    [OVERRIDE_OPTION.MANUAL]: gettext('CUSTOM'),
});

const GUIDE_LINE_FIELD_NAME = 'guideLine';
const OVERRIDE_FIELD_NAME = 'override';

const distanceConverterFields = ['flatLevel', 'atopFlatLevel'];

// Flat level requires type conversion (user wants to see their own units).
const fieldDistanceConverter = (field, value, revert = false) => {
    const converterMethod = revert ? revertDistanceConverter : distanceConverter;

    if (distanceConverterFields.includes(field)) {
        return converterMethod(value);
    }

    return value;
};

const configDistanceConverter = (config, revert = false) => {
    if (!config) {
        return config;
    }

    return Object.fromEntries(
        Object.entries(config).map(([field, value]) => [
            field,
            fieldDistanceConverter(field, value, revert),
        ])
    );
};

class OverridesConfigHelper extends ConfigHelper {
    transformConfigFields(configFields) {
        // Hack the `override` field into the choice table.
        return new Map([
            [
                OVERRIDE_FIELD_NAME,
                new Map([
                    [OVERRIDE_OPTION.AUTO, new Map()],
                    [OVERRIDE_OPTION.MANUAL, super.transformConfigFields(configFields)],
                ]),
            ],
        ]);
    }
}

const DataLink = (dataLinkProps) => {
    const { setValues, config$, setOverrides, guideLineFields } = dataLinkProps;

    const { watch } = useFormContext();
    const watch$ = useWatchObservable(watch);

    const overriddenConfig$ = useObservable(
        (inputs$) =>
            inputs$.pipe(
                switchMap((inputs) => combineLatest(inputs)),
                map(([values, config]) => {
                    // There is no override when `auto` is selected.
                    if (values[OVERRIDE_FIELD_NAME] === OVERRIDE_OPTION.AUTO) {
                        return null;
                    }

                    const onlyGuideLineValues = pick(values, Object.keys(guideLineFields));

                    // The config with just the guide line fields.
                    const relevantConfig = pick(config, Object.keys(guideLineFields));

                    // There is also no override when the form values match the current config.
                    // NOTE: We are removing some fields from the new override config to make it
                    // compareable. We need to do this since the config coming from the dataset
                    // does not yet contain all the available fields.
                    if (
                        isEqual(
                            pick(onlyGuideLineValues, Object.keys(relevantConfig)),
                            relevantConfig
                        )
                    ) {
                        return null;
                    }

                    return onlyGuideLineValues;
                }),
                debounce(() => scheduled([undefined], asyncScheduler)),
                // Use the revert distance converter to get everything back to millimeters.
                map((config) => configDistanceConverter(config, true)),
                // Map the 'public' config into an 'internal' config.
                // This is needed until https://github.com/omnidots/website/issues/6689 is adressed.
                map((config) => configConverter.publicToInternal(config))
            ),
        [watch$, config$]
    );

    useSubscription(overriddenConfig$, (values) => {
        setOverrides(configConverter.publicToInternal(values));
    });

    useSubscription(config$, (config) => {
        setValues(config);
    });

    return null;
};

// This overrides form is hardcoded to only be compatible with SWARM Vibration.
const overridesFormSwarmType = SwarmType.VIBRATION;

const OverridesForm = ({ dataSet: { config$: internalConfig$ }, setOverrides }) => {
    const formSchema = useTranslatedFormSchema(overridesFormSwarmType, false);
    const config$ = useObservable(
        (inputs$) =>
            inputs$.pipe(
                switchHead(),
                // Map the incoming 'internal' config into a 'public' config.
                // This is needed until https://github.com/omnidots/website/issues/6689 is adressed.
                map((config) => configConverter.internalToPublic(config)),
                // Use the distance converter to convert the millimeter value
                // into the user's desired format.
                map((config) => configDistanceConverter(config))
            ),
        [internalConfig$]
    );
    const config = useObservableState(config$, null);

    const configHelper = useMemo(() => new OverridesConfigHelper(overridesFormSwarmType), []);

    const guideLineFields = useMemo(() => {
        const layoutHelper = new LayoutHelper(formSchema.layout, formSchema.fields);
        return layoutHelper.getFieldsStartingAt(GUIDE_LINE_FIELD_NAME);
    }, [formSchema.layout, formSchema.fields]);

    const fieldsWithoutGuideLine = useMemo(
        () => omit(guideLineFields, [GUIDE_LINE_FIELD_NAME]),
        [guideLineFields]
    );

    if (!config) {
        return null;
    }

    return (
        <div>
            <Form
                information={formSchema.information}
                defaultValues={config}
                DataLink={DataLink}
                dataLinkProps={{
                    config$,
                    configHelper,
                    setOverrides,
                    guideLineFields,
                    onSubmitSuccessful: () => {},
                }}
                configHelper={configHelper}
                showSaveButton={false}
            >
                <ChoiceField
                    fieldOptions={{
                        name: OVERRIDE_FIELD_NAME,
                        settings: {
                            label: gettext('SETTINGS'),
                            options: Object.entries(OVERRIDE_OPTION_TRANSLATIONS),
                            default: OVERRIDE_OPTION.AUTO,
                        },
                    }}
                />
                {Object.entries(fieldsWithoutGuideLine).map(([name, settings]) => (
                    <FormFieldLookup key={name} name={name} settings={settings} />
                ))}
            </Form>
        </div>
    );
};

OverridesForm.propTypes = {
    dataSet: PropTypes.instanceOf(MultiSensorDataSet).isRequired,
    setOverrides: PropTypes.func,
};

export default OverridesForm;
