/* eslint-disable func-names */
const primaryButton = 'pure-button pure-button-primary button-table';
const secondaryButton = primaryButton.replace('primary', 'secondary');

const button = (action, secondary) => ({
    action,
    classes: secondary ? secondaryButton : primaryButton,
});

const buttonNext = (secondary) =>
    button(function () {
        return this.next();
    }, secondary);

const buttonCancel = () =>
    button(function () {
        return this.cancel();
    }, true);

const attachTo = (element, options = {}) => {
    const config = {
        attachTo: {
            element,
        },
    };

    if (options.on) {
        config.attachTo.on = options.on;
    }

    if (options.advanceOnEvent) {
        config.advanceOn = {
            selector: options.advanceOnElement ?? element,
            event: options.advanceOnEvent,
            preventDefault: options.preventDefault ?? false,
        };
    }

    return config;
};

const applicableToSbrView = () => ({
    applicableToPath: '/sbr/sbrView/',
});

const switchWalkthroughButton = (name) =>
    button(function () {
        this.complete();
        this.manager.getWalkthrough(name).updateStateOpen('');
    });

const schema = ({ demoMeasuringPoint }) => {
    const demoMeasuringPointEl = `tr[data-measuring-point-id="${demoMeasuringPoint}"]`;

    return [
        {
            name: 'getting-started',
            steps: [
                {
                    id: 'ask-to-start',
                    buttons: [buttonCancel(), buttonNext()],
                },
                {
                    id: 'owns-swarm-question',
                    buttons: [
                        switchWalkthroughButton('measuring-point-page'),
                        switchWalkthroughButton('graph'),
                    ],
                },
            ],
        },

        {
            name: 'measuring-point-page',
            steps: [
                {
                    id: 'click-measuring-point',
                    ...attachTo('#id_measuring_point', {
                        advanceOnEvent: 'mouseenter',
                    }),
                },
                {
                    id: 'click-create-measuring-point',
                    toggleClasses: [['#id_measuring_point', 'hover']],
                    ...attachTo('#id_measuring_point_create_vibration', {
                        advanceOnEvent: 'click',
                    }),
                },
                {
                    id: 'enter-mp-name',
                    applicableToPath: '/measuring_point/create/vibration',
                    ...attachTo('#id_name', {
                        advanceOnElement: 'form.form',
                        advanceOnEvent: 'submit',
                        preventDefault: true,
                    }),
                    translationKeys: {
                        BUTTON_1: 'CONTINUE',
                    },
                    buttons: [buttonNext()],
                },
                {
                    id: 'enter-swarm-name',
                    applicableToPath: '/measuring_point/create/vibration',
                    ...attachTo('#id_sensor_name', {
                        on: 'left',
                        advanceOnElement: 'form.form',
                        advanceOnEvent: 'submit',
                        preventDefault: true,
                    }),
                    buttons: [buttonNext()],
                    translationKeys: {
                        BUTTON_1: 'CONTINUE',
                    },
                },
                {
                    id: 'swarm-settings',
                    ...attachTo('#swarm_settings', {
                        on: 'auto',
                    }),
                    buttons: [buttonNext()],
                    translationKeys: {
                        BUTTON_1: 'CONTINUE',
                    },
                },
                {
                    id: 'alarm-settings',
                    ...attachTo('#alarm_levels', {
                        on: 'auto',
                    }),
                    buttons: [buttonNext()],
                    translationKeys: {
                        BUTTON_1: 'CONTINUE',
                    },
                },
                {
                    id: 'alarm-recipient-settings',
                    ...attachTo('#alarm_recipient_settings', {
                        on: 'auto',
                    }),
                    buttons: [buttonNext()],
                    translationKeys: {
                        BUTTON_1: 'CONTINUE',
                    },
                },
                {
                    id: 'measurement-schedule',
                    ...attachTo('#measurement_schedule', {
                        on: 'auto',
                    }),
                    buttons: [buttonNext()],
                    translationKeys: {
                        BUTTON_1: 'CONTINUE',
                    },
                },
                {
                    id: 'click-save',
                    ...attachTo('#save-form', {
                        on: 'auto',
                        advanceOnElement: 'form.form',
                        advanceOnEvent: 'submit',
                    }),
                },
                {
                    id: 'congratulations',
                    buttons: [switchWalkthroughButton('graph')],
                    translationKeys: {
                        BUTTON_1: 'CONTINUE',
                    },
                },
            ],
        },
        {
            name: 'graph',
            steps: [
                {
                    id: 'start',
                    buttons: [buttonNext()],
                    translationKeys: {
                        BUTTON_1: 'CONTINUE',
                    },
                },
                {
                    id: 'view-data',
                    applicableToPath: '/measuring_point/',
                    ...attachTo(`${demoMeasuringPointEl} .show-data`, {
                        advanceOnEvent: 'click',
                    }),
                },
                {
                    id: 'graph-types',
                    ...applicableToSbrView(),
                    ...attachTo('#data-types', { on: 'bottom' }),
                    buttons: [buttonNext()],
                    translationKeys: {
                        BUTTON_1: 'CONTINUE',
                    },
                },
                {
                    id: 'graph',
                    ...applicableToSbrView(),
                    ...attachTo('#graph-velocity .graph-area', {
                        on: 'bottom',
                    }),
                    buttons: [buttonNext()],
                    translationKeys: {
                        BUTTON_1: 'CONTINUE',
                    },
                },
                {
                    id: 'pan-zoom',
                    ...applicableToSbrView(),
                    ...attachTo('#graph-velocity .plot', { on: 'auto' }),
                    buttons: [buttonNext()],
                    translationKeys: {
                        BUTTON_1: 'CONTINUE',
                    },
                },
                {
                    id: 'brush-graph',
                    ...applicableToSbrView(),
                    ...attachTo('#graph-velocity .brushGraph .overlay', {
                        on: 'auto',
                    }),
                    buttons: [buttonNext()],
                    translationKeys: {
                        BUTTON_1: 'CONTINUE',
                    },
                },
                {
                    id: 'view-filter-buttons',
                    ...applicableToSbrView(),
                    ...attachTo('#graph-velocity .graph-buttons .view-types', {
                        on: 'auto',
                    }),
                    buttons: [buttonNext()],
                    translationKeys: {
                        BUTTON_1: 'CONTINUE',
                    },
                },
                {
                    id: 'display-buttons',
                    ...applicableToSbrView(),
                    ...attachTo('#graph-velocity .graph-buttons .graph-display-types', {
                        on: 'auto',
                    }),
                    buttons: [buttonNext()],
                    translationKeys: {
                        BUTTON_1: 'CONTINUE',
                    },
                },
                {
                    id: 'zoom-buttons',
                    ...applicableToSbrView(),
                    ...attachTo('#graph-velocity .graph-buttons .graph-zoom-types', { on: 'auto' }),
                    buttons: [buttonNext()],
                    translationKeys: {
                        BUTTON_1: 'CONTINUE',
                    },
                },
                {
                    id: 'export',
                    ...applicableToSbrView(),
                    ...attachTo('#graph-velocity #exports', { on: 'auto' }),
                    buttons: [buttonNext()],
                    translationKeys: {
                        BUTTON_1: 'CONTINUE',
                    },
                },
                {
                    id: 'switch-mp',
                    ...applicableToSbrView(),
                    ...attachTo(
                        'span[aria-labelledby="select2-measuring-point-selector-container"]',
                        { on: 'auto' }
                    ),
                    buttons: [buttonNext()],
                    translationKeys: {
                        BUTTON_1: 'CONTINUE',
                    },
                },
                {
                    id: 'show-help-menu',
                    ...applicableToSbrView(),
                    ...attachTo('#id_help', { on: 'auto' }),
                    buttons: [buttonNext()],
                    translationKeys: {
                        BUTTON_1: 'CONTINUE',
                    },
                },
                {
                    id: 'end',
                    ...applicableToSbrView(),
                    ...attachTo('#hubspot-messages-iframe-container'),
                    buttons: [buttonNext(), switchWalkthroughButton('remove-demo-mp')],
                    translationKeys: {
                        BUTTON_1: 'CONTINUE',
                    },
                },
            ],
        },
        {
            name: 'remove-demo-mp',
            steps: [
                {
                    id: 'hover-cog',
                    applicableToPath: '/measuring_point/',
                    ...attachTo(`${demoMeasuringPointEl} .submenu`, {
                        advanceOnEvent: 'mouseenter',
                    }),
                },
                {
                    id: 'click-undo-sharing',
                    applicableToPath: '/measuring_point/',
                    toggleClasses: [[`${demoMeasuringPointEl} .submenu`, 'hover']],
                    ...attachTo(`${demoMeasuringPointEl} .unshare-mp`, {
                        advanceOnEvent: 'click',
                    }),
                },
                {
                    id: 'end',
                    buttons: [buttonNext()],
                    translationKeys: {
                        BUTTON_1: 'CLOSE',
                    },
                },
            ],
        },
    ];
};

exports.button = button;
exports.buttonCancel = buttonCancel;
exports.schema = schema;
