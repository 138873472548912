import { select } from 'd3-selection';
import $ from 'jquery';
import { identity, intersection, zip } from 'lodash-es';
import { EMPTY, combineLatest, map, switchMap, throttleTime } from 'rxjs';
import { formatDateTz, formatTimeTz } from '../utils/formatting';
import { timestampRangeOverlap } from '../utils/range';
import { applyNamingConvention, namingConventions$ } from '../utils/naming-conventions';
import { getDataType, fixFloat } from './data-types';

export const periodTranslations = {
    DAY: gettext('Day'),
    EVENING: gettext('Evening'),
    NIGHT: gettext('Night'),
};

export class VperTable {
    constructor(containerSelector, dataSet, legendData, responsive = true) {
        this.$container = $(containerSelector);
        this.dataSet = dataSet;
        this.legendData = legendData;
        this.pageBreak = $("<div class='pagebreak'></div>").insertBefore(this.$container);

        const headerContainer = select(containerSelector).append('div');
        headerContainer.attr('class', 'header');
        this.header = headerContainer.append('h3');

        this.infoMessage = select(containerSelector).append('p');

        this.table = select(containerSelector).append('table');
        this.table.attr('class', 'table');
        this.table.classed('table-responsive-label', responsive);

        this.tableHeader = this.table.append('thead').append('tr');

        this.tableBody = this.table.append('tbody');

        this.dataSet.on('loading-updated', () => {
            this.renderInfoMessage();
        });

        this.initTableNameUpdater();

        this.shouldBeVisible$ = this.createShouldBeVisible$();

        // Toggle the container's visibility upon updated to shouldBeVisible$.
        this.shouldBeVisible$.subscribe((visible) => {
            this.toggleContainerVisibility(visible);
        });

        this.shouldBeVisible$
            .pipe(
                switchMap((visible) =>
                    visible
                        ? // If we are allowed to be visible, we can start listening for
                          // updates to the visible date range and vper periods in the dataset.
                          // `throttleTime` on the date range update to improve performance while
                          // scrolling/zooming.
                          combineLatest([
                              this.dataSet.dateRange.observable$.pipe(
                                  throttleTime(300, null, { leading: true, trailing: true })
                              ),
                              this.dataSet.vperPeriods$,
                          ])
                        : // Stop further action if we are not allowed to be visible.
                          EMPTY
                )
            )
            .subscribe(() => {
                this.render();
            });
    }

    createShouldBeVisible$() {
        return this.dataSet.combinedSettings$.pipe(map((x) => x.showVssTable));
    }

    toggleContainerVisibility(toggle) {
        this.$container.toggle(toggle);
        this.pageBreak.toggle(toggle);
    }

    renderInfoMessage() {
        this.infoMessage.text(this.dataSet.loading ? gettext('LOADING') : gettext('No data found'));
    }

    showInfoMessage() {
        this.infoMessage.style('display', 'block');
        this.table.style('display', 'none');
    }

    showTable() {
        this.infoMessage.style('display', 'none');
        this.table.style('display', 'table');
    }

    initTableNameUpdater() {
        // Update the table name when the naming conventions change.
        namingConventions$.subscribe(() => {
            this.header.text(applyNamingConvention(getDataType('vper').graphName));
        });
    }

    render() {
        const { vperPeriods, timezone } = this.dataSet;

        const { visibleMin, visibleMax } = this.dataSet;

        // Filter periods that are visible in the visible time window.
        const periods = vperPeriods.filter(({ timespan: { from, to } }) =>
            timestampRangeOverlap([from, to], [visibleMin, visibleMax])
        );

        if (periods.length === 0) {
            this.showInfoMessage();
            return;
        }

        this.showTable();

        const visibleTypes = this.legendData.getVisibleTypes();
        const visibleAxes = intersection(visibleTypes, ['x', 'y', 'z']);

        const vmaxTitle = applyNamingConvention(getDataType('vmax').title);
        const vperTitle = applyNamingConvention(getDataType('vper').title);
        const vperValuenames = getDataType('vper').valueNames;

        const headers = [
            gettext('Date'),
            gettext('Period'),
            ...visibleAxes.map((axis) => `${vmaxTitle} ${axis}`),
            ...visibleAxes.map((axis) => `${vperTitle} ${axis}`),
            ...vperValuenames.map((valueName) => applyNamingConvention(valueName)),
            gettext('Remarks'),
        ];

        this.tableHeader
            .selectAll('th')
            .data(headers, identity)
            .join((enter) => enter.append('th').text((d) => d));

        const rows = this.tableBody
            .selectAll('tr')
            .data(periods, (d) => `${d.timespan.from}-${d.timespan.to}`)
            .join('tr');

        rows.selectAll('td')
            .data((d) => {
                // Sample to columns.
                const formattedDate = formatDateTz(timezone, d.timestamp);

                const formatTime = (timestamp) => formatTimeTz(timezone, timestamp);

                const from = formatTime(d.timespan.from);
                const to = formatTime(d.timespan.to);
                let periodContent = d.period
                    ? `${periodTranslations[d.period]} (${from}-${to})`
                    : `${from}-${to}`;

                const completenessTitle = gettext('Completeness of measurement period');

                periodContent +=
                    ` <span title="${completenessTitle}">` +
                    `(${d.periodCompleteness.completeness}%)` +
                    `</span>`;

                const printValuePerAxis = (type, getValueForAxis) =>
                    visibleAxes.map((axis) => {
                        const value = getValueForAxis(axis);
                        return fixFloat(type, value);
                    });

                const veffContent = printValuePerAxis('vmax', (axis) => d.vmax[axis]);

                const vperContent = printValuePerAxis('vper', (axis) => d.vper[axis]);

                const rowData = [
                    formattedDate, // date
                    periodContent, // period
                    ...veffContent, // veff,max
                    ...vperContent, // vper
                    fixFloat('vmax', d.limits.A1),
                    fixFloat('vmax', d.limits.A2),
                    fixFloat('vmax', d.limits.A3),
                    d.limits.complies ? gettext('Complies') : gettext('Noncomplying'),
                ];

                // Merge headers and data together:
                return zip(headers, rowData).map((row) => ({
                    label: row[0],
                    data: row[1],
                }));
            })
            .join('td')
            .attr('data-label', (d) => d.label)
            .html((d) => d.data);
    }
}
