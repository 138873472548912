import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isBefore, parse } from 'date-fns';
import { isFunction } from 'lodash-es';
import { defaultPropTypes, useIsOverridable, useIsOverridden } from '../../utils';
import FormFieldContainer, { BaseFormFieldContainer } from '../FormFieldContainer';
import Select from '../../../form/Select';
import TimePicker from '../../../form/TimePicker';
import { OverridableInput, OverridableWrapper } from '../InputField';
import { useProfile } from '../../../../profile';

import { getFormSchema } from '../../../pages/measuringPoint/schema-utils';
import { SwarmType } from '../../../../enums';
import RowManager from '../../../../utils/row-manager';
import { DeleteRowButton, AddRowButton } from '../sharedComponents';

const DEFAULT_VALUE = getFormSchema(SwarmType.SOUND, false).fields.getByName(
    'dailyValueSettings'
).default;
const TIME_APPLICATION = {
    FULL: 'full',
    CUSTOM: 'custom',
};

function FormField({ name, title, children }) {
    return (
        <div className="relative mr-5 inline-block w-[250px] list-none align-top text-form-text">
            <div>
                <label htmlFor={`id_${name}`} className={`text-[11px]`}>
                    {title}
                </label>
            </div>
            {children}
        </div>
    );
}
FormField.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

const propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.shape({
        timeSettings: PropTypes.shape({
            start: PropTypes.string,
            end: PropTypes.string,
        }).isRequired,
        triggers: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
};

function usePartialValue(value, onChange, key) {
    const partialValue = useMemo(() => value[key], [value, key]);
    const partialOnChange = useCallback(
        (newValueOrCallback) => {
            const newValue = isFunction(newValueOrCallback)
                ? newValueOrCallback(value[key])
                : newValueOrCallback;

            onChange({
                ...value,
                [key]: newValue,
            });
        },
        [value, onChange, key]
    );

    return [partialValue, partialOnChange];
}

const expectedErrors = {
    END_BEFORE_START_ERROR: 'END_BEFORE_START_ERROR',
};

function TimeSettings({ name, value: valueIncoming, onChange: onChangeIncoming, disabled, error }) {
    const { t } = useTranslation();
    const [value, onChange] = usePartialValue(valueIncoming, onChangeIncoming, 'timeSettings');
    const timeApplicationOptions = useMemo(
        () => [
            [TIME_APPLICATION.FULL, t('24_HOURS')],
            [TIME_APPLICATION.CUSTOM, gettext('CUSTOM')],
        ],
        [t]
    );
    const [timeSettings, setTimeApplication] = useState(TIME_APPLICATION.FULL);
    const { use24HourNotation } = useProfile();

    const hasEndBeforeStartError = useMemo(
        () => error && error.message === expectedErrors.END_BEFORE_START_ERROR,
        [error]
    );

    const errorMessage = useMemo(
        () => (hasEndBeforeStartError ? gettext('END_BEFORE_START_ERROR') : null),
        [hasEndBeforeStartError]
    );

    useEffect(() => {
        setTimeApplication(
            value.start === '00:00' && value.end === '23:59'
                ? TIME_APPLICATION.FULL
                : TIME_APPLICATION.CUSTOM
        );
    }, [value]);

    return (
        <>
            <BaseFormFieldContainer error={errorMessage} widthClass="">
                <div className="flex gap-x-5">
                    <BaseFormFieldContainer
                        name="time_application"
                        label={t('TIME_APPLICATION')}
                        marginClass=""
                    >
                        <OverridableWrapper
                            overrideName={name}
                            disabled={disabled}
                            render={(props) => (
                                <Select
                                    id="id_time_application"
                                    data={timeApplicationOptions}
                                    value={timeSettings}
                                    onChange={({ target: { value: newValue } }) => {
                                        if (newValue === TIME_APPLICATION.FULL) {
                                            // The default settings are a full day. So
                                            // we can set it back to default here.
                                            onChange((current) => ({
                                                ...current,
                                                ...DEFAULT_VALUE.timeSettings,
                                            }));
                                        }

                                        setTimeApplication(newValue);
                                    }}
                                    {...props}
                                />
                            )}
                        />
                    </BaseFormFieldContainer>
                    {timeSettings === TIME_APPLICATION.CUSTOM && (
                        <>
                            <BaseFormFieldContainer
                                name="start_time"
                                label={gettext('START_TIME')}
                                marginClass=""
                            >
                                <OverridableWrapper
                                    overrideName={name}
                                    disabled={disabled}
                                    render={(props) => (
                                        <TimePicker
                                            id="id_start_time"
                                            value={value.start}
                                            is24Hour={use24HourNotation}
                                            error={hasEndBeforeStartError}
                                            onChange={(newValue) => {
                                                onChange((current) => ({
                                                    ...current,
                                                    start: newValue,
                                                }));
                                            }}
                                            {...props}
                                        />
                                    )}
                                />
                            </BaseFormFieldContainer>
                            <BaseFormFieldContainer
                                name="end_time"
                                label={gettext('END_TIME')}
                                marginClass=""
                            >
                                <OverridableWrapper
                                    overrideName={name}
                                    disabled={disabled}
                                    render={(props) => (
                                        <TimePicker
                                            id="id_end_time"
                                            value={value.end}
                                            is24Hour={use24HourNotation}
                                            error={hasEndBeforeStartError}
                                            onChange={(newValue) => {
                                                onChange((current) => ({
                                                    ...current,
                                                    end: newValue,
                                                }));
                                            }}
                                            {...props}
                                        />
                                    )}
                                />
                            </BaseFormFieldContainer>
                        </>
                    )}
                </div>
            </BaseFormFieldContainer>
        </>
    );
}
TimeSettings.propTypes = propTypes;

function TriggerInput({ index, name, overrideName, title, manager, value, disabled }) {
    const error = useMemo(() => (value < 0 ? gettext('TRIGGER_OR_DAYS_NEGATIVE') : null), [value]);

    return (
        <BaseFormFieldContainer name={`${name}_${index}`} label={title} error={error}>
            <OverridableInput
                overrideName={overrideName}
                id={`id_${name}_${index}`}
                value={value}
                type="number"
                min="0"
                disabled={disabled}
                onChange={(e) => {
                    const inputVal = e.target.value ? parseInt(e.target.value, 10) : '';
                    manager.changeRow(index, name, inputVal);
                }}
            />
        </BaseFormFieldContainer>
    );
}
TriggerInput.propTypes = {
    index: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    overrideName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    manager: PropTypes.instanceOf(RowManager).isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    disabled: PropTypes.bool,
};

function TriggerSettings({ name, value: valueIncoming, onChange: onChangeIncoming, disabled }) {
    const [value, onChange] = usePartialValue(valueIncoming, onChangeIncoming, 'triggers');
    const { rows, manager, rowLimitReached, showDeleteButtons } = RowManager.useRowManager(
        value,
        onChange,
        5,
        { trigger: 0, days: 0 }
    );

    return (
        <div>
            <h3 className="mb-3.5 text-black">{gettext('TRIGGER_SETTINGS')}</h3>
            {rows.map((row, index) => (
                <div
                    key={index}
                    className="mb-3.5 flex"
                    data-testid={`daily-value-alarm-row-${index}`}
                >
                    <TriggerInput
                        index={index}
                        name="trigger"
                        overrideName={name}
                        value={row.trigger}
                        title={gettext('EXCEEDANCE_TRIGGER_IN_DB')}
                        manager={manager}
                        disabled={disabled}
                    />

                    <TriggerInput
                        index={index}
                        name="days"
                        overrideName={name}
                        value={row.days}
                        title={gettext('MAXIMUM_EXPOSURE_TIME_IN_DAYS')}
                        manager={manager}
                        disabled={disabled}
                    />
                    {showDeleteButtons && (
                        <DeleteRowButton manager={manager} index={index} disabled={disabled} />
                    )}
                </div>
            ))}

            {!rowLimitReached && <AddRowButton manager={manager} disabled={disabled} />}
        </div>
    );
}
TriggerSettings.propTypes = propTypes;

function SoundDailyValueSettings(props) {
    return (
        <div className="flex flex-col gap-y-3.5 ">
            <TimeSettings {...props} />
            <TriggerSettings {...props} />
        </div>
    );
}
SoundDailyValueSettings.propTypes = propTypes;

function timeToDate(time) {
    return parse(time, 'HH:mm', new Date());
}

function hasUnexpectedError(error) {
    return error && !Object.values(expectedErrors).includes(error.message);
}

function SoundDailyValueSettingsField({ fieldOptions }) {
    const { name, settings } = fieldOptions;
    const { required } = settings;
    const overridden = useIsOverridden(name);
    const disabled = useIsOverridable(name) && !overridden;

    return (
        <Controller
            name={name}
            defaultValue={DEFAULT_VALUE}
            rules={{
                required,
                validate({ timeSettings }) {
                    if (isBefore(timeToDate(timeSettings.end), timeToDate(timeSettings.start))) {
                        return expectedErrors.END_BEFORE_START_ERROR;
                    }

                    return true;
                },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
                <FormFieldContainer
                    fieldOptions={fieldOptions}
                    marginClass=""
                    showLabel={false}
                    showError={hasUnexpectedError(error)}
                    widthClass={hasUnexpectedError(error) ? 'rounded border border-red-500' : ''}
                >
                    <SoundDailyValueSettings
                        name={name}
                        value={value}
                        error={error}
                        onChange={onChange}
                        disabled={disabled}
                    />
                </FormFieldContainer>
            )}
        />
    );
}
SoundDailyValueSettingsField.propTypes = defaultPropTypes;

export default SoundDailyValueSettingsField;
