import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';
import FontAwesomeSingleSelection from './utils/select2-custom';

const createSubscriptionPage = () => {
    $('select[name=customer]').select2({
        width: '100%',
        selectionAdapter: FontAwesomeSingleSelection,
    });
};

export default createSubscriptionPage;
