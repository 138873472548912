import PropTypes from 'prop-types';

import { useMemo, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { MultiSensorDataSet } from '../../../../graph/dataset';
import DailyValuesTable from '../../dailyValuesTable/DailyValuesTable';
import { BudgetViewer } from '../../../budgetViewer/BudgetViewer';
import { RoundedButton } from '../../../form/Button';
import { DailyValuesCalendar } from '../../dailyValuesCalendar/DailyValuesCalendar';

export function DailyValuesTab({ dataSet }) {
    const [showCalendar, setShowCalendar] = useState(true);

    const highlighted$ = useMemo(() => new BehaviorSubject(null), []);

    return (
        <div>
            <div className="flex flex-col-reverse gap-x-8 gap-y-4 px-8 py-4 md:flex-row">
                <div className="flex flex-col gap-y-4 md:basis-4/6">
                    <div className="flex items-center gap-4">
                        <span>{gettext('VIEW')}:</span>
                        <RoundedButton
                            color={showCalendar ? 'primary' : 'secondary'}
                            onClick={() => setShowCalendar(true)}
                        >
                            {gettext('CALENDAR')}
                        </RoundedButton>
                        <RoundedButton
                            color={!showCalendar ? 'primary' : 'secondary'}
                            onClick={() => setShowCalendar(false)}
                        >
                            {gettext('TABLE')}
                        </RoundedButton>
                    </div>
                    {showCalendar ? (
                        <DailyValuesCalendar dataSet={dataSet} highlighted$={highlighted$} />
                    ) : (
                        <DailyValuesTable dataSet={dataSet} />
                    )}
                </div>
                <div className="md:basis-2/6">
                    <BudgetViewer dataSet={dataSet} highlighted$={highlighted$} />
                </div>
            </div>
        </div>
    );
}
DailyValuesTab.propTypes = {
    dataSet: PropTypes.instanceOf(MultiSensorDataSet).isRequired,
};
