import { map, skipWhile } from 'rxjs';
import { NotImplementedError } from '../utils/errors';
import { SCALES_TYPE, convertDistanceAndRoundToTwo } from './data-types';

class BaseScaleSlider {
    constructor(graph, scales) {
        this.graph = graph;
        this.scales = scales;

        this.sliderSize = 20; // px

        this.slider = this.createSliderElement();

        this.update();

        this.scales.updated$.subscribe(({ emitter }) => {
            // Ignore event if this slider triggered it.
            if (emitter === this) {
                return;
            }

            this.update();
        });

        // Make the slider update to the current data type settings.
        this.graph.data.on('data-type-updated', this.update.bind(this));

        // Visible is true when the scale type for this axis is linear. Is it false
        // when scale type is logarithmic as the logarithmic scale is fixed and does
        // not need a slider.
        const $visible = this.getAxis().scaleType$.pipe(
            map((scaleType) => scaleType === SCALES_TYPE.LINEAR)
        );

        $visible
            .pipe(
                // The slider element is already created as visible so we are only
                // interested in changes after is changed to invisible.
                skipWhile((visible) => visible === true)
            )
            .subscribe((visible) => {
                // Toggle the invisible class based on the incoming state.
                this.slider.classed('invisible', !visible);
            });
    }

    onChange() {
        const value = this.slider.node().valueAsNumber;
        const type = this.getDataType();
        this.scales.set(type, value, this);
    }

    createSliderElement() {
        return this.graph.graphArea
            .append('input')
            .attr('type', 'range')
            .on('change', this.onChange.bind(this))
            .on('input', this.onChange.bind(this));
    }

    // Sets the slider min, max, step and current value.
    update() {
        const type = this.getDataType();
        const step = convertDistanceAndRoundToTwo(type, 0.5);
        // The order in which these properties are set is important.
        // Value should only be set after the min and the max have been set.
        this.slider
            .property('max', this.scales.getMax(type))
            .property('min', this.scales.getMin(type) + step)
            .property('value', this.scales.get(type))
            .property('step', step);
    }

    getDataType() {
        throw new NotImplementedError();
    }

    getAxis() {
        throw new NotImplementedError();
    }
}

export class YAxisScaleSlider extends BaseScaleSlider {
    getDataType() {
        return this.graph.getYAxisDataType();
    }

    getAxis() {
        return this.graph.yAxis;
    }

    createSliderElement() {
        return super
            .createSliderElement()
            .attr('orient', 'vertical')
            .style('width', `${this.sliderSize}px`)
            .style('height', `${this.graph.graphHeight}px`)
            .style('float', 'left')
            .style('margin-top', `${this.graph.margin.top}px`);
    }
}

export class XAxisScaleSlider extends BaseScaleSlider {
    getDataType() {
        return this.graph.getXAxisDataType();
    }

    getAxis() {
        return this.graph.xAxis;
    }

    createSliderElement() {
        return super
            .createSliderElement()
            .style('padding', '0')
            .style('width', `${this.graph.graphWidth}px`)
            .style('height', `${this.sliderSize}px`)
            .style('margin-left', `${this.graph.margin.left + this.sliderSize}px`);
    }
}
