import PropTypes from 'prop-types';
import React from 'react';

function BaseRow({ children }) {
    return (
        <tr className="border-b align-baseline odd:bg-white even:bg-gray-50">
            {React.Children.map(children, (child) => (
                <td className="px-6 py-4">{child}</td>
            ))}
        </tr>
    );
}

export default BaseRow;

BaseRow.propTypes = {
    children: PropTypes.node.isRequired,
};
