import { isFunction } from 'lodash-es';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useCallback, useEffect, useRef } from 'react';
import { RoundedButton } from '../form/Button';
import Input, { INPUT_SIZE } from '../form/Input';
import { funcRefPropType } from '../utils';
import markdown from '../../utils/markdown';

export const ConfirmType = Object.freeze({
    PROMPT: 'prompt',
    INFO: 'info',
    CONFIRM: 'confirm',
});

export const containerClasses =
    'w-80 flex-col space-y-3 rounded-lg bg-white p-3 text-center break-words';
export const overlayClassName = '!bg-black/50';

const sharedPropTypes = {
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    hideIcon: PropTypes.bool,
};

export const Info = ({ message, onClose, onCloseRef, body, hideIcon = false }) => {
    // Pass the `onClose` method back to our `confirm` method.
    onCloseRef.current = onClose;

    return (
        <div className={containerClasses}>
            {!hideIcon && <FontAwesomeIcon icon={faCircleInfo} size="2xl" />}
            {body || <p>{message}</p>}
            <div className="space-x-3">
                <RoundedButton
                    id="info-button"
                    type="button"
                    onClick={() => onClose()}
                    variant="big"
                    flex
                >
                    {gettext('OK')}
                </RoundedButton>
            </div>
        </div>
    );
};

Info.propTypes = {
    ...sharedPropTypes,
    message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    body: PropTypes.element,
    onClose: PropTypes.func,
    onCloseRef: funcRefPropType,
};

export const Confirm = ({
    message,
    onConfirm,
    onCancel,
    onClose,
    onCloseRef,
    body,
    hideIcon = false,
    icon,
}) => {
    // Pass the `onClose` method back to our `confirm` method.
    onCloseRef.current = onClose;

    const buttons = [
        {
            id: 'alert-confirm-button',
            label: gettext('CONFIRM'),
            onClick: onConfirm,
            color: 'primary',
        },
        {
            id: 'alert-cancel-button',
            label: gettext('CANCEL'),
            onClick: onCancel,
            color: 'secondary',
        },
    ];

    return (
        <div className={containerClasses}>
            {!hideIcon && <FontAwesomeIcon icon={icon ?? faTriangleExclamation} size="2xl" />}
            {body || <p>{message}</p>}
            <div className="space-x-3">
                {buttons.map(({ id, label, color, onClick }, index) => (
                    <RoundedButton
                        id={id}
                        key={index}
                        type="button"
                        onClick={() => {
                            isFunction(onClick) && onClick();
                            onClose();
                        }}
                        variant="big"
                        color={color}
                        flex
                    >
                        {label}
                    </RoundedButton>
                ))}
            </div>
        </div>
    );
};
Confirm.propTypes = {
    ...sharedPropTypes,
    message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    body: PropTypes.element,
    onClose: PropTypes.func,
    onCloseRef: funcRefPropType,
    icon: PropTypes.object,
};

export const Prompt = ({ message, onConfirm, ...props }) => {
    const inputRef = useRef();

    const { onCloseRef } = props;

    const confirmWithInput = () => {
        // Pass the input value to the `onConfirm` method.
        onConfirm(inputRef.current.value);
    };

    const body = (
        <>
            <p id="prompt-label">{message}</p>
            <p>
                <Input size={INPUT_SIZE.SMALL} aria-labelledby="prompt-label" ref={inputRef} />
            </p>
        </>
    );

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                confirmWithInput();
                onCloseRef.current();
            }}
        >
            <Confirm
                body={body}
                onConfirm={confirmWithInput}
                hideIcon={true}
                icon={faCircleInfo}
                {...props}
            />
        </form>
    );
};
Prompt.propTypes = Confirm.propTypes;

const TYPE = Object.freeze({
    prompt: Prompt,
    info: Info,
    confirm: Confirm,
});

export const confirmMethodOptionTypes = {
    ...sharedPropTypes,
    message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    convertMarkdown: PropTypes.bool,
    type: PropTypes.oneOf(Object.keys(TYPE)),
};

// Method that is able to be used outside of React. Use `useConfirm` when using this inside React.
export const confirm = (options) => {
    const {
        message,
        convertMarkdown,
        onConfirm,
        onCancel,
        type = ConfirmType.CONFIRM,
        hideIcon,
    } = options;

    PropTypes.checkPropTypes(confirmMethodOptionTypes, options, 'option', 'confirm');

    // Cheap copy of the React ref idea. `onCloseRef.current` will be filled with the
    // `onClose` method of the `confirmAlert` library by our custom UI. We need the
    // `onClose` outside of the component as we want to close the confirm when change
    // pages, or change a story in Storybook, somehow that functionality was not thought
    // of by the makers of the library.
    const onCloseRef = {};

    const UI = TYPE[type];

    confirmAlert({
        customUI: (props) => (
            <UI
                hideIcon={hideIcon}
                onCloseRef={onCloseRef}
                onConfirm={onConfirm}
                onCancel={onCancel}
                {...props}
                // Order is important! Our message should override the message provided
                // by the library props.
                message={
                    convertMarkdown ? (
                        <span dangerouslySetInnerHTML={{ __html: markdown(message) }}></span>
                    ) : (
                        message
                    )
                }
            />
        ),
        overlayClassName,
    });

    // Return a function that will close the confirm when executed.
    return () => onCloseRef.current && onCloseRef.current();
};
confirm.propTypes = confirmMethodOptionTypes;

// Hook that returns a wrapped `confirm` method. The `confirm` wrapper will store the
// `onClose` method and will call that automatically when the component gets unmounted.
export const useConfirm = () => {
    const onCloseReferences = useRef([]);

    useEffect(
        () => () => {
            // Call all stored onClose methods on unmount.
            onCloseReferences.current.forEach((onClose) => onClose());
        },
        []
    );

    return useCallback((...args) => {
        // Store the onClose method so that we can call that on unmount.
        onCloseReferences.current.push(confirm(...args));
    }, []);
};
