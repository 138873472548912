/* eslint-disable no-new */
/* eslint-disable no-underscore-dangle */
import $ from 'jquery';
import ReactDOM from 'react-dom';
import {
    ConvertDjangoNotifications,
    NotificationsContainer,
    NotificationsProvider,
} from './components/notifications/Notifications';

function FileInput(element) {
    const self = this;
    this.el = element;

    if (this.el.getAttribute('multiple') !== null) {
        throw new Error('multi-file input fields are not supported');
    }

    this.el.addEventListener('click', () => {
        self.toggleError(false);
    });
}

FileInput.prototype.maxSize = function maxSize() {
    const result = parseInt(this.el.getAttribute('max_size'), 10);
    if (Number.isNaN(result)) {
        return 0;
    }

    return result;
};

FileInput.prototype.curSize = function curSize() {
    if (!this._getFile()) {
        return 0;
    }

    return this._getFile().size;
};

FileInput.prototype.isTooBig = function isTooBig() {
    const max = this.maxSize();
    return max > 0 && this.curSize() > max;
};

FileInput.prototype._getFile = function _getFile() {
    return this.el.files[0];
};

FileInput.prototype.toggleError = function toggleError(enable) {
    if (enable) {
        this.el.classList.add('-error');
    } else {
        this.el.classList.remove('-error');
    }
};

function FormWithFileInputs(element) {
    const self = this;
    this.el = element;
    this.inputs = [].slice
        .call(this.el.elements)
        .filter(
            (v) => v.getAttribute('type') === 'file' && v.getAttribute('max_size') !== undefined
        )
        .map((v) => new FileInput(v));

    this.el.addEventListener('submit', (event) => {
        self._onsubmit(event);
    });
}

FormWithFileInputs.prototype._onsubmit = function _onsubmit(event) {
    if (!this.inputs) {
        return;
    }

    const valid = this.inputs
        .map((v) => {
            const isTooBig = v.isTooBig();
            v.toggleError(isTooBig);
            return !isTooBig;
        })
        .every((v) => v);

    if (!valid) {
        event.preventDefault();
    }
};

const baseTemplateModule = ({ options: { isJsPage } }) => {
    (() => {
        // If this is not a JS page (thus Django template), we want to replace the
        // Django notifications with the React notifications component.
        if (!isJsPage) {
            const djangoNotificationsContainer = document.getElementById('notifications-container');

            // If `#notifications-container` does not exist, it means that a Django template
            // has overwritten the `main-container` block and is therefore not interested in fancy
            // React notifications.
            if (!djangoNotificationsContainer) {
                return;
            }

            const reactMessagelistContainer = document.createElement('div');
            djangoNotificationsContainer.appendChild(reactMessagelistContainer);

            ReactDOM.render(
                <NotificationsProvider>
                    <ConvertDjangoNotifications />
                    <NotificationsContainer />
                </NotificationsProvider>,
                reactMessagelistContainer
            );
        }
    })();

    // Code below originates from the old files.js:
    // https://github.com/omnidots/website/blob/0f927daf916ab8767cdac59d75bc09a4f8a29779/main/static/js/files.js
    document.addEventListener('DOMContentLoaded', () => {
        for (let i = 0; i < document.forms.length; i++) {
            new FormWithFileInputs(document.forms[i]);
        }
    });

    // Code below originates from the old forms.js:
    // https://github.com/omnidots/website/blob/0f927daf916ab8767cdac59d75bc09a4f8a29779/main/static/js/forms.js
    {
        // Finds error fields of form and marks those as with error
        const normalErrors = document.querySelectorAll('.form .errorlist:not(.nonfield)');
        normalErrors.forEach((normalError) => {
            // Grab the error text and append it to the help text
            const errorText = $(normalError).find('li').text();
            let helptextNode = $(normalError.parentNode).find('.helptext');

            if (helptextNode.length) {
                helptextNode.html(`<div class="-error">${errorText}</div>${helptextNode.text()}`);
            } else {
                // No helptext node, lets add one.
                helptextNode = document.createElement('span');
                helptextNode.classList.add('helptext');
                helptextNode.textContent = errorText;
                normalError.parentNode.append(helptextNode);
            }
            normalError.parentNode.classList.add('-error');
        });

        const nonfieldErrors = document.querySelectorAll('.form .errorlist.nonfield');
        nonfieldErrors.forEach((nonfieldError) => {
            nonfieldError.parentNode.classList.add('nonfield-error');
        });

        const selectFields = document.querySelectorAll('select:not(.selectpicker)');
        selectFields.forEach((selectField) => {
            // Create wrapper and angle down elements
            const selectWrapper = document.createElement('div');
            selectWrapper.classList.add('select_wrapper');

            const { id } = selectField;
            if (id) {
                selectWrapper.classList.add(`select_wrapper_${id}`);
            }

            const angleDown = document.createElement('i');
            angleDown.classList.add('fa');
            angleDown.classList.add('fa-angle-down');
            angleDown.classList.add('select_angle_down');

            // Put wrapper after the current select
            selectField.parentNode.insertBefore(selectWrapper, selectField.nextSibling);

            // Move select and angleDown into the wrapper
            selectWrapper.appendChild(selectField);
            selectWrapper.appendChild(angleDown);
        });

        $('form.form').submit(() => {
            const url = $('#id_website_url').val();
            if (url !== undefined && !url.includes('://')) {
                $('#id_website_url').val(`https://${url}`);
            }
        });
    }
};

export default baseTemplateModule;
