/* eslint-disable react/prop-types */
import { useEffect, useState, useMemo, useCallback } from 'react';
import { debounce, uniqueId } from 'lodash-es';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import getLocale from '../utils/date-fns';
import DateTimeRange from '../utils/DateTimeRange';

function convertUtcDateToTimezoneDate(date, dataSet) {
    return utcToZonedTime(date, dataSet.timezone);
}

export function convertToUtc(browserDate, dataSet) {
    return zonedTimeToUtc(browserDate, dataSet.timezone);
}

const useOnDataSetDateChanged = (dataSet, callback) => {
    useEffect(() => {
        dataSet.on('visible-updated', callback);
        dataSet.on('timezone-updated', callback);

        return () => {
            dataSet.removeListener('visible-updated', callback);
            dataSet.removeListener('timezone-updated', callback);
        };
    }, [dataSet, callback]);
};

/**
 * @param {BaseDataSet} dataSet
 * @param {string} UUID Optional emitter ID that can be used to ignore events triggered by itself.
 */
export const useDataSetRanges = (dataSet, UUID = null) => {
    const createRangesFromDataSet = useCallback(
        () => [
            {
                startDate: convertUtcDateToTimezoneDate(dataSet.visibleMin, dataSet),
                endDate: convertUtcDateToTimezoneDate(dataSet.visibleMax, dataSet),
                key: 'selection',
            },
        ],
        [dataSet]
    );

    const [ranges, setRanges] = useState(createRangesFromDataSet());

    const updateRangesFromDataSet = useMemo(
        () =>
            debounce((emitter) => {
                if (emitter === UUID) {
                    return;
                }

                setRanges(createRangesFromDataSet);
            }, 300),
        [UUID, createRangesFromDataSet]
    );

    useOnDataSetDateChanged(dataSet, updateRangesFromDataSet);

    return { ranges, setRanges };
};

const DataSetDateRangePicker = ({ dataSet, language }) => {
    const UUID = useMemo(() => uniqueId('datepicker'), []);
    const { ranges, setRanges } = useDataSetRanges(dataSet, UUID);

    const onChange = useCallback(
        (startDate, endDate) =>
            // Set the new min or max value.
            dataSet.dateRange.setMinMax(
                convertToUtc(startDate, dataSet),
                convertToUtc(endDate, dataSet),
                UUID
            ),
        [UUID, dataSet]
    );

    return (
        <div className="dataset-date-range-picker-container">
            <DateTimeRange
                onChange={({ selection }) => {
                    setRanges([selection]);
                    onChange(selection.startDate, selection.endDate);
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={ranges}
                rangeColors={['#F8DA28']}
                direction="horizontal"
                preventSnapRefocus={true}
                calendarFocus="backwards"
                locale={getLocale(language)}
                className="small"
            />
        </div>
    );
};

export default DataSetDateRangePicker;
