import { isEmpty, matchesProperty } from 'lodash-es';
import PropTypes from 'prop-types';
import { isApolloError } from '@apollo/client';
import { millisecondsToSeconds, secondsToMilliseconds } from 'date-fns';
import { useObservable, useObservableState } from 'observable-hooks';
import { distinctUntilChanged, map } from 'rxjs';
import { getClient } from '../../../utils/graphql';
import { confirm, ConfirmType } from '../../confirm/Confirm';
import { addNotificationHack, NOTIFICATION_VARIANT } from '../../notifications/Notifications';
import { ConfigType, createMutation, createObjectNames, createQueriesForSwarmType } from './utils';
import warning from '../../../utils/logger';
import createCountdownObservable from '../../../utils/countdown';
import { SwarmType } from '../../../enums';

export const MS_BEFORE_RELOAD_ON_SUCCESS = secondsToMilliseconds(3);

export const createCopyMutation = (swarmType, source, destination) => {
    const isProject = destination === ConfigType.PROJECT.type;

    const sourceObjectName = ConfigType[source].objectName;
    const { objectName, objectNameLowerFirst } = createObjectNames(swarmType, isProject);
    const { returnFieldsFragmentName, returnFieldsFragment } =
        createQueriesForSwarmType(swarmType)[isProject];

    // Creates 'AirMeasuringPointCreateFromExistingMeasuringPointInput'.
    const inputTypeName = `${objectName}CreateFromExisting${sourceObjectName}Input`;

    // Creates 'airMeasuringPointCreateFromExistingMeasuringPoint'
    const mutationName = `${objectNameLowerFirst}CreateFromExisting${sourceObjectName}`;

    // Below creates:
    // ```
    // mutation ($input: AirMeasuringPointCreateFromExistingMeasuringPointInput!) {
    //   airMeasuringPointCreateFromExistingMeasuringPoint(input: $input) {
    //     airMeasuringPoint {
    //       id,
    //       name,
    //       ...etc
    //     }
    //   }
    // }
    // ```
    return {
        mutationName,
        mutation: createMutation(
            mutationName,
            inputTypeName,
            objectNameLowerFirst,
            returnFieldsFragment,
            returnFieldsFragmentName
        ),
    };
};

export const createCopyModalQuestionText = (destination) =>
    interpolate(gettext('NAME_FOR_COPY_QUESTION'), [
        ConfigType[destination].translation.toLowerCase(),
    ]);

const SuccessNotificationMessage = () => {
    const refreshCountdown = useObservableState(
        useObservable(() =>
            createCountdownObservable(MS_BEFORE_RELOAD_ON_SUCCESS).pipe(
                map(millisecondsToSeconds),
                distinctUntilChanged(),
                map((seconds) => interpolate(gettext('PAGE_REFRESH_IN'), { seconds }, true))
            )
        )
    );
    return (
        <span>
            {gettext('COPIED_SUCCESSFULLY')}&nbsp;
            {refreshCountdown}
        </span>
    );
};

export const copyModalOptionTypes = {
    id: PropTypes.number.isRequired,
    swarmType: PropTypes.oneOf(Object.values(SwarmType)).isRequired,
    source: PropTypes.oneOf(Object.keys(ConfigType)).isRequired,
    destination: PropTypes.oneOf(Object.keys(ConfigType)).isRequired,
    onSuccess: PropTypes.func.isRequired,
};

const copyModal = (options) => {
    const { id, swarmType, source, destination, onSuccess } = options;
    PropTypes.checkPropTypes(copyModalOptionTypes, options, 'option', 'copyModal');

    const { mutation, mutationName } = createCopyMutation(swarmType, source, destination);

    return confirm({
        message: createCopyModalQuestionText(destination),
        convertMarkdown: true,
        type: ConfirmType.PROMPT,
        onConfirm: async (name) => {
            try {
                const result = await getClient().mutate({
                    mutation,
                    variables: {
                        input: {
                            existingId: id,
                            name,
                        },
                    },
                });

                const { errors } = result.data[mutationName];

                if (!isEmpty(errors)) {
                    // This should actually check on an error code but the
                    // backend does not throw that yet.
                    const nameAlreadyExistsError = errors.some(
                        matchesProperty('message', 'This name already exists')
                    );

                    if (nameAlreadyExistsError) {
                        addNotificationHack({
                            message: (
                                <span>
                                    {gettext('NAME_ALREADY_EXISTS_ERROR')}&nbsp;
                                    <a
                                        onClick={(e) => {
                                            e.preventDefault();
                                            copyModal(options);
                                        }}
                                    >
                                        {gettext('RETRY')}
                                    </a>
                                </span>
                            ),
                            variant: NOTIFICATION_VARIANT.ERROR,
                        });
                        return;
                    }

                    throw new Error(errors);
                }

                addNotificationHack({
                    message: <SuccessNotificationMessage />,
                    scrollToTop: true,
                });
                onSuccess();
            } catch (e) {
                // Apollo errors are already logged by the `onError` function.
                if (!isApolloError(e)) {
                    warning(e);
                }

                addNotificationHack({
                    message: gettext('ERROR'),
                    variant: NOTIFICATION_VARIANT.ERROR,
                });
            }
        },
    });
};
copyModal.propTypes = copyModalOptionTypes;

export default copyModal;
