import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import { useContext } from 'react';
import { EXPORT_TYPES, EXPORT_TYPES_DISPLAY_TEXT } from './utils';
import BetaBadge from '../../betaBadge/BetaBadge';
import ExportContext from './ExportContext';

const reportTemplatesQuery = gql`
    query reportTemplates {
        reportTemplates {
            id
            name
        }
    }
`;

const ListItem = ({ onClick, text, beta }) => (
    <li className="relative mb-2 box-border whitespace-nowrap bg-menu-background  hover:bg-menu-hover">
        <button onClick={onClick} className="w-full py-2 pl-2 text-left font-bold text-menu-color">
            {beta && <BetaBadge className="mr-0.5" />} {text}
        </button>
    </li>
);

ListItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    beta: PropTypes.bool,
};

const Message = ({ message }) => (
    <span className="pl-2 text-menu-color no-underline">{message}</span>
);
Message.propTypes = {
    message: PropTypes.string,
};

const ExportTypes = () => {
    const { createGraphReport, openDatePickerForExportType } = useContext(ExportContext);
    return Object.entries(EXPORT_TYPES_DISPLAY_TEXT).map(([exportType]) => (
        <ListItem
            onClick={
                exportType === EXPORT_TYPES.PDF
                    ? () => createGraphReport(EXPORT_TYPES.PDF)
                    : () => openDatePickerForExportType(exportType)
            }
            text={EXPORT_TYPES_DISPLAY_TEXT[exportType]}
            key={exportType}
        />
    ));
};

const ReportTemplates = () => {
    const { loading, error, data } = useQuery(reportTemplatesQuery);
    const { createGraphReport } = useContext(ExportContext);

    if (loading) return <Message message={gettext('REPORT_TEMPLATES_LOADING')} />;
    if (error) return <Message message={gettext('REPORT_TEMPLATES_LOADING_ERROR')} />;

    return data.reportTemplates.map((template) => (
        <ListItem
            key={template.id}
            text={template.name}
            onClick={() => createGraphReport(EXPORT_TYPES.PDF, { templateId: template.id })}
            beta={true}
        />
    ));
};

const ExportChoices = () => (
    <ul>
        <ExportTypes />
        <ReportTemplates />
    </ul>
);

export default ExportChoices;
