import { isEmpty } from 'lodash-es';
import { combineLatest, map, of, pipe, switchMap } from 'rxjs';

/**
 * Combines an array of objects, each containing `start$` and `end$` observables,
 * with a D3 scale observable to calculate their positions.
 *
 * @param {Observable} scale$ - Observable emitting D3 scale functions.
 * @returns {OperatorFunction<Array<{ start$: Observable, end$: Observable }>,
 *   Array<Object>>} Returns an operator function that takes an observable of entries
 *   and returns an observable emitting entries augmented with their start and end positions.
 */
function combineEntriesWithPositions(scale$) {
    /**
     * Combines start and end date observables into an observable emitting an entry
     * augmented with start and end positions.
     *
     * @param {Object} entry - Object containing `start$` and `end$` observables.
     * @param {Object} scale - D3 scale.
     * @returns {Observable} Observable emitting the entry with start and end positions.
     */
    const entryWithPosition$ = (entry, scale) =>
        combineLatest({
            start: entry.start$.pipe(map((date) => scale(date))),
            end: entry.end$.pipe(map((date) => scale(date))),
        }).pipe(map((startAndEndPositions) => ({ ...entry, ...startAndEndPositions })));

    /**
     * Operator function that combines an array of entries with their start and end positions.
     *
     * @param {Array<Object>} entries - Array of objects, each containing `start$` and
     *   `end$` observables.
     * @returns {Observable} Observable emitting an array of entries augmented with start
     *   and end positions.
     */
    return pipe(
        switchMap((entries) => {
            if (isEmpty(entries)) {
                return of([]);
            }

            return scale$.pipe(
                switchMap((scale) =>
                    combineLatest(entries.map((entry) => entryWithPosition$(entry, scale)))
                )
            );
        })
    );
}

export default combineEntriesWithPositions;
