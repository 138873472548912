import Cookies from 'js-cookie';
import { gql } from '@apollo/client';
import { addHours } from 'date-fns';
import { getClient } from './utils/graphql';
import warning from './utils/logger';

// Tokens expire after 12 hours. Using 11 to be on the safe side.
// https://developers.hubspot.com/docs/api/conversation/visitor-identification
const TOKEN_EXPIRES = 11; // hours
const CACHE_KEY_PREFIX = 'HVID'; // HubSpot Visitor Identification Token.

const createExpireDate = () => addHours(new Date(), TOKEN_EXPIRES);

const loadWidget = () => {
    const load = () => window.HubSpotConversations.widget.load();

    if (window.HubSpotConversations) {
        load();
    } else {
        // Snippet was not initialized yet. These callbacks will be
        // called once the external API has been initialized.
        window.hsConversationsOnReady = [load];
    }
};

const query = gql`
    query getHubSpotIdentificationToken {
        hubspotIdentificationToken
    }
`;

const requestToken = async () => {
    const result = await getClient().query({
        query,
    });

    const token = result.data.hubspotIdentificationToken;

    if (!token) {
        throw new Error('No token in query result.');
    }

    return token;
};

const getToken = async (userPk) => {
    const cacheKey = `${CACHE_KEY_PREFIX}_${userPk}`;

    const tokenFromCache = Cookies.get(cacheKey);

    if (tokenFromCache) {
        return tokenFromCache;
    }

    const token = await requestToken();

    Cookies.set(cacheKey, token, { expires: createExpireDate() });

    return token;
};

const tryToIdentify = async (userPk, email) => {
    try {
        const token = await getToken(userPk);

        if (!token) {
            return;
        }

        // Set identification details.
        window.hsConversationsSettings = {
            identificationEmail: email,
            identificationToken: token,
        };
    } catch (error) {
        warning(`Failed to do HubSpot identification due to: ${error}`);
    }
};

const hubspotIdentifyModule = async ({ options: { isAuthenticated, userPk, email } }) => {
    if (isAuthenticated) {
        await tryToIdentify(userPk, email);
    }

    loadWidget();
};

export default hubspotIdentifyModule;
