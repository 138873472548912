import { useContext, useRef } from 'react';
import { DateRange } from 'react-date-range';
import { useObservable, useObservableEagerState } from 'observable-hooks';
import { map, switchMap } from 'rxjs';
import { endOfDay, startOfDay } from 'date-fns';
import Button from '../../form/Button';
import ExportContext from './ExportContext';
import { convertToUtc, useDataSetRanges } from '../../../graph/dataset-date-range-picker';
import { startOfOmnidots, useLocale } from '../../../utils/date-fns';
import schema from '../../../schema.json';
import { namingConventions$ } from '../../../utils/naming-conventions';
import Select from '../../form/Select';
import { DataTypes } from '../../../graph/data-types';

export const ALL_DATA_TYPE = 'all';

export const EXPORT_BUTTONS_TRANSLATIONS = Object.freeze({
    EXPORT: gettext('EXPORT'),
    EXPORT_ALL: gettext('EXPORT_ALL'),
});

const EXPORT_OPTIONS = {
    [EXPORT_BUTTONS_TRANSLATIONS.EXPORT]: {},
    [EXPORT_BUTTONS_TRANSLATIONS.EXPORT_ALL]: { dataType: ALL_DATA_TYPE },
};

const dustExportOptionTranslations$ = namingConventions$.pipe(
    map((namingConventions) =>
        Object.fromEntries(
            Object.entries(schema.dustExportOptions).map(([key, translationKey]) => [
                key,
                namingConventions[translationKey],
            ])
        )
    )
);

const ExportDatePicker = () => {
    const { datePickerExportType, setIsDatePickerOpen, createGraphReport, dataSet } =
        useContext(ExportContext);
    const { ranges, setRanges } = useDataSetRanges(dataSet);
    const locale = useLocale();
    const dustExportOptionRef = useRef();
    const dustExportOptionTranslations = useObservableEagerState(dustExportOptionTranslations$);

    const showDustExportOptions = useObservableEagerState(
        useObservable(
            (inputs$) =>
                inputs$.pipe(
                    switchMap(([dataType$]) => dataType$),
                    // TODO: `DataTypes.PM2P5` should also show the dust export options but that
                    // will be fixed in this issue: https://github.com/omnidots/website/issues/5786
                    map((dataType) => DataTypes.PM10 === dataType)
                ),
            [dataSet.dataType$]
        )
    );

    return (
        <div className="flex w-80 flex-col">
            <Button onClick={() => setIsDatePickerOpen(false)} variant="exportButton">
                {gettext('BACK')}
            </Button>
            <DateRange
                onChange={({ selection }) => {
                    setRanges([selection]);
                }}
                ranges={ranges}
                rangeColors={['#F8DA28']}
                minDate={startOfOmnidots}
                maxDate={new Date()}
                locale={locale}
            />
            {showDustExportOptions && (
                <Select
                    ref={dustExportOptionRef}
                    data={Object.entries(dustExportOptionTranslations)}
                />
            )}
            {Object.entries(EXPORT_OPTIONS).map(([name, options]) => (
                <Button
                    onClick={() => {
                        createGraphReport(
                            datePickerExportType.current,
                            {
                                ...options,
                                ...(showDustExportOptions
                                    ? { dustType: dustExportOptionRef.current.value }
                                    : {}),
                            },
                            [
                                convertToUtc(startOfDay(ranges[0].startDate), dataSet).valueOf(),
                                convertToUtc(endOfDay(ranges[0].endDate), dataSet).valueOf(),
                            ]
                        );
                        setIsDatePickerOpen(false);
                    }}
                    variant="exportButton"
                    key={name}
                >
                    {name}
                </Button>
            ))}
        </div>
    );
};

export default ExportDatePicker;
