import { isEmpty } from 'lodash-es';
import { combineLatest, distinctUntilChanged, map, of, pipe, switchMap } from 'rxjs';
import { timestampRangeOverlap } from '../../../utils/range';
import compareArrays from '../../../utils/compareArrays';

/**
 * Filters an observable emitting arrays of objects, each containing `start$` and
 * `end$` observables, based on a specified date range.
 *
 * @param {DateRange} dateRange - The date range to filter entries. Only entries
 * falling within this range will be emitted.
 *
 * @returns {OperatorFunction<
 *    Array<{ start$: Observable, end$: Observable }>,
 *    Array<{ start$: Observable, end$: Observable }>
 * >}
 * Returns an OperatorFunction that can be used with the RxJS pipe to apply the date
 * range filtering.
 */
function filterWithinView(dateRange) {
    return pipe(
        switchMap((entries) => {
            if (isEmpty(entries)) {
                return of([]);
            }

            return combineLatest(
                entries.map((entry) =>
                    combineLatest({
                        entry: of(entry),
                        isInView: combineLatest([
                            dateRange.observable$,
                            entry.start$,
                            entry.end$,
                        ]).pipe(
                            map(([inView, start, end]) =>
                                timestampRangeOverlap(inView, [start, end])
                            )
                        ),
                    })
                )
            );
        }),
        map((entries) => entries.filter(({ isInView }) => isInView).map(({ entry }) => entry)),
        // Prevent unnecessary emits when the date range changes
        // but the actual selections in view stays the same.
        distinctUntilChanged(compareArrays)
    );
}

export default filterWithinView;
