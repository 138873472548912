import { get, isNil, isNull, some } from 'lodash-es';
import { combineLatestWith, map, merge, of, pipe, startWith, Subscription, switchMap } from 'rxjs';
import { NotImplementedError } from '../../utils/errors';
import { applyNamingConvention } from '../../utils/naming-conventions';
import {
    DataTypes,
    convertDistanceAndFloat,
    getDataType,
    swarmTypeDataTypes,
    vibrationGraphDataTypes,
} from '../data-types';
import { SwarmType } from '../../enums';
import { getOverrides, getTooltipHeader } from '../graph-helpers';
import { periodTranslations } from '../vper-table';
import { shareReplayRefCount } from '../../utils/rxjs';
import { timestampRangeOverlap } from '../../utils/range';

export const valueNotNull = (a) => !isNull(a.value);

export const sortAxis = (a, b) => {
    if (a.value && b.value) {
        return b.value - a.value;
    }

    return 0;
};

export const filterElementsOnAxesWithObservable = (observable$) =>
    pipe(
        combineLatestWith(observable$),
        map(([elements, visibleTypes]) =>
            elements.filter((element) => visibleTypes.includes(element.axis))
        )
    );

export const filterElementsOnAxesWithLegend = (legendData) =>
    filterElementsOnAxesWithObservable(legendData.visibleTypes$);

export class TooltipPoint {
    constructor(position) {
        this.position = position;
    }

    get x() {
        return this.position.x;
    }

    get y() {
        return this.position.y;
    }
}

export class GraphElementPosition {
    constructor(element, x, y) {
        this.element = element;
        this.x = x;
        this.y = y;
    }

    get record() {
        return this.element.record;
    }

    get timestamp() {
        return this.element.timestamp;
    }

    get axis() {
        return this.element.axis;
    }

    static findX(graph, element) {
        return graph.scaleX(element);
    }

    static findY(graph, element) {
        return graph.scaleY(element);
    }

    static createForElement(element, graph) {
        return new this(element, this.findX(graph, element), this.findY(graph, element));
    }

    getToolTipCircleType() {
        return this.axis;
    }

    getToolTipPoints() {
        return [new TooltipPoint(this)];
    }

    // This method allows, for example, when hovering with your mouse
    // on an x axis element, the other axes are also highlighted.
    findAssociatedPositions(positions) {
        return positions.filter((position) => position.record === this.record);
    }

    getToolTipCircles$(positions$) {
        return positions$.pipe(
            map((positions) =>
                this.findAssociatedPositions(positions).flatMap((position) =>
                    position.getToolTipPoints().map((point) => ({
                        type: point.position.getToolTipCircleType(),
                        transform: `translate(${point.x}, ${point.y})`,
                    }))
                )
            )
        );
    }
}

const vperPeriodBucketTypeNames = ['KBFTrBucket', 'VperBucket'];

export class BaseGraphElement {
    constructor(record) {
        // Store the source record.
        this.record = record;
    }

    get timestamp() {
        return this.record.timestamp;
    }

    static createCollectionFromRecords(records) {
        return records.map((record) => new this(record));
    }

    getTooltipVisibleAxes(legendData) {
        return legendData.getVisibleTypes();
    }

    getTooltipVisibleData(graph) {
        return graph.getDisplayedDataType().visibleData;
    }

    timespanContainsTraces(timespan, dataSet) {
        return some(dataSet.traces, (trace) =>
            timestampRangeOverlap([timespan.from, timespan.to], [trace.start, trace.end])
        );
    }

    getToolTipText(dataSet, graph) {
        const sample = this.record;
        const { sensor, timezone } = dataSet;

        let toolTipText = getTooltipHeader(this.timestamp, timezone, sensor);

        // Bucket balloon.
        if (vperPeriodBucketTypeNames.includes(sample.__typename)) {
            toolTipText += `${gettext('Period')}: ${periodTranslations[
                sample.period
            ].toLowerCase()}<br/>`;
        }

        // Bucket balloon.
        if (
            ['SampleBucket', 'Dust', 'DustMeta', ...vperPeriodBucketTypeNames].includes(
                sample.__typename
            )
        ) {
            const sampleToText = (s, axes, types) => {
                let text = '';

                // Determine if we are dealing with an air sample
                const isAir = swarmTypeDataTypes[SwarmType.AIR].includes(
                    graph.getDisplayedDataType().key
                );

                axes.forEach((axis) => {
                    types.forEach((type) => {
                        // Determine the value getter for air or others.
                        const valueGetter = isAir ? axis : [type, axis];

                        const value = get(s, valueGetter);
                        if (isAir && type.test && !type.test(axis)) {
                            return;
                        }

                        // Make sure it is actually a value.
                        if (isNil(value)) {
                            return;
                        }

                        // Determine which name to get.
                        const valueName = isAir ? axis : type;

                        // Get the actual unit, title and converted value.
                        const { unit, title } = getDataType(valueName);
                        const convertedValue = convertDistanceAndFloat(valueName, value);

                        // Construct the title for this value
                        const valueTitle = isAir
                            ? title
                            : [applyNamingConvention(title), axis].join(' ');

                        // Construct the actual entry for the tooltip.
                        text += `${valueTitle}: ${[convertedValue, unit].join(' ')}<br/>`;
                    });
                });

                return text;
            };

            const visibleAxes = this.getTooltipVisibleAxes(graph.legendData);
            const visibleData = this.getTooltipVisibleData(graph);

            toolTipText += sampleToText(sample, visibleAxes, visibleData);

            // Show Vper values inside a Veff SampleBucket tooltip.
            if (sample.__typename === 'SampleBucket' && dataSet.dataType === DataTypes.VEFF) {
                const vperPeriodSample = this.getVperPeriodForTimestamp(this.timestamp, dataSet);

                toolTipText += sampleToText(vperPeriodSample, ['x', 'y', 'z'], ['vper']);
            }

            // These DataTypes don't do traces.
            if (vibrationGraphDataTypes.includes(dataSet.dataType)) {
                const containsTraces = this.timespanContainsTraces(sample.timespan, dataSet);
                let traces;

                if (containsTraces) {
                    const overrides = getOverrides();

                    const url = graph.getShowTracesUrl(
                        sensor,
                        sample.timespan.from,
                        sample.timespan.to,
                        dataSet.dataType,
                        overrides
                    );
                    const text = gettext('Click for traces');
                    traces = `<a href='${url}'>${text}</a>`;
                } else {
                    traces = gettext('No traces');
                }

                toolTipText += `Traces: ${traces}<br/>`;
            }
        }

        // Event balloon.
        if (sample.__typename.startsWith('Event')) {
            const rowsToShow = ['name', 'stopCause', 'startMessage', 'uploading'];
            rowsToShow.forEach((type) => {
                if (!isNil(sample[type])) {
                    const { title } = getDataType(type);
                    if (title) {
                        toolTipText += `${title}: `;
                    }
                    toolTipText += `${sample[type]}<br/>`;
                }
            });
            if (
                sample.__typename === 'EventStart' &&
                !graph.getDisplayedDataType().eventConfigMatcher(sample)
            ) {
                toolTipText += gettext('MP_NOT_MEASURING_TYPE');
            }
        }

        return toolTipText;
    }

    getPositionElementClass() {
        return GraphElementPosition;
    }

    createPosition(graph) {
        return this.getPositionElementClass().createForElement(this, graph);
    }
}

export class BaseGraphPlugin {
    constructor(graph) {
        this.graph = graph;
        this.subscription = new Subscription();
    }

    get dataSet() {
        return this.graph.data;
    }

    init() {
        throw new NotImplementedError();
    }
}

export class BaseGraphElementsProvider extends BaseGraphPlugin {
    constructor(...args) {
        super(...args);

        const graphElementPositions$ = this.createGraphElementPositionsObservable();

        this.graphElementPositions$ = this.getShouldCalculateElementPositionsObservable().pipe(
            switchMap((shouldCalculateElementPositions) =>
                shouldCalculateElementPositions ? graphElementPositions$ : of([])
            )
        );
    }

    getShouldCalculateElementPositionsObservable() {
        return of(true);
    }

    createGraphElementPositionsObservable() {
        return this.constructor
            .getGraphElementsObservableForDataSet(this.graph.data, this.graph.legendData)
            .pipe(
                combineLatestWith(
                    merge(this.graph.xAxis.scaleUpdated$, this.graph.yAxis.scaleUpdated$).pipe(
                        startWith(null)
                    )
                ),
                map(([elements, _]) =>
                    elements.map((element) => element.createPosition(this.graph))
                ),
                shareReplayRefCount()
            );
    }

    static createGraphElementsObservable(_dataSet, _legendData) {
        throw new NotImplementedError();
    }

    static getGraphElementsObservableForDataSet(dataSet, legendData) {
        if (!dataSet.graphElementObservables.has(this)) {
            dataSet.graphElementObservables.set(
                this,
                this.createGraphElementsObservable(dataSet, legendData)
            );
        }

        return dataSet.graphElementObservables.get(this);
    }
}
