import PropTypes from 'prop-types';

import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config';

// https://tailwindcss.com/docs/configuration#referencing-in-java-script
const fullTailwindConfig = resolveConfig(tailwindConfig);

export const DAILY_VALUE_BG_COLORS = [
    'bg-daily-values-yellow',
    'bg-daily-values-orange',
    'bg-daily-values-magenta',
    'bg-daily-values-purple',
    'bg-daily-values-blue',
];

export const DAILY_VALUE_FILL_COLORS = [
    fullTailwindConfig.theme.colors['daily-values-yellow'],
    fullTailwindConfig.theme.colors['daily-values-orange'],
    fullTailwindConfig.theme.colors['daily-values-magenta'],
    fullTailwindConfig.theme.colors['daily-values-purple'],
    fullTailwindConfig.theme.colors['daily-values-blue'],
];

export const GRAY_OUTLINE_COLOR = fullTailwindConfig.theme.colors['gray-outline'];

export const dataShape = PropTypes.shape({
    date: PropTypes.string.isRequired,
    Date: PropTypes.instanceOf(Date),
    lXEq: PropTypes.number.isRequired,
    timeSpan: PropTypes.shape({
        from: PropTypes.number.isRequired,
        to: PropTypes.number.isRequired,
    }).isRequired,
});
