// Initial implementation for SLM survey was removed. Find details
// of removal in the PR: https://github.com/omnidots/website/pull/6381
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { useState, useCallback, useEffect } from 'react';
import Button from '../form/Button';
import {
    POPUP_DELAY_MS,
    convertCookieToInteger,
    SURVEY_VIEWS,
    VIEWS_UNTIL_HIDE_COOKIE,
} from './utils';

const getShowFromCookie = (sessionId) => {
    const lastSessionId = Cookies.get('lastSessionId');
    const viewsUntilHide = Cookies.get(VIEWS_UNTIL_HIDE_COOKIE);

    if (lastSessionId && lastSessionId === sessionId) return false;
    if (viewsUntilHide) {
        return convertCookieToInteger(viewsUntilHide) > 0;
    }
    return true;
};

const SurveyPopup = ({ headerText, bodyText, url, sessionId }) => {
    const [showPopup, setShowPopup] = useState(false);

    const handleYes = useCallback(() => {
        Cookies.set(VIEWS_UNTIL_HIDE_COOKIE, 0);
        setShowPopup(false);
        window.open(url);
    }, [url]);

    const handleLater = useCallback(() => {
        const viewsUntilHideCookie = Cookies.get(VIEWS_UNTIL_HIDE_COOKIE);
        Cookies.set('lastSessionId', sessionId);
        setShowPopup(false);
        if (viewsUntilHideCookie)
            return Cookies.set(
                VIEWS_UNTIL_HIDE_COOKIE,
                convertCookieToInteger(viewsUntilHideCookie) - 1
            );
        return Cookies.set(VIEWS_UNTIL_HIDE_COOKIE, SURVEY_VIEWS);
    }, [sessionId]);

    useEffect(() => {
        setTimeout(setShowPopup(getShowFromCookie(sessionId)), POPUP_DELAY_MS);
    }, [sessionId]);

    return (
        showPopup && (
            <div className="hide fixed bottom-0 right-20 z-50 w-571 min-w-80 animate-slide-up rounded-t-md border border-solid border-gray-outline bg-white px-6 py-3.5 font-display shadow-044xl md:block ">
                <div className="m-auto w-96 text-center font-display text-base font-bold">
                    {headerText}
                </div>
                <div className="pt-3 text-center text-sm font-normal">{bodyText}</div>
                <div className="flex items-center justify-center gap-6 pt-4">
                    <Button aria-label={gettext('YES_SURE')} onClick={handleYes}>
                        {gettext('YES_SURE')}
                    </Button>
                    <Button
                        aria-label={gettext('MAYBE_LATER')}
                        onClick={handleLater}
                        variant="secondary"
                    >
                        {gettext('MAYBE_LATER')}
                    </Button>
                </div>
            </div>
        )
    );
};

SurveyPopup.propTypes = {
    headerText: PropTypes.string,
    bodyText: PropTypes.string,
    url: PropTypes.string,
    sessionId: PropTypes.string,
};

export default SurveyPopup;
