import reactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { getWalkthroughManager, useManager } from './walkthrough';
import Table from '../utils/table';

const UpdateStatusLink = ({ walkthrough, status, text }) => (
    <a
        onClick={() => {
            walkthrough.updateState(status, '');
        }}
    >
        {text}
    </a>
);

UpdateStatusLink.propTypes = {
    walkthrough: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

const App = ({ manager }) => {
    useManager(manager);

    if (!manager.isLoaded) return <p>{gettext('LOADING')}</p>;

    return (
        <Table
            header={[gettext('NAME'), gettext('ACTIONS')]}
            rows={manager.walkthroughs.map((walkthrough) => [
                { value: walkthrough.getNameTranslated() },
                {
                    value: walkthrough.isOpen() ? (
                        <UpdateStatusLink
                            walkthrough={walkthrough}
                            status="CANCELED"
                            text={gettext('STOP')}
                        />
                    ) : (
                        <UpdateStatusLink
                            walkthrough={walkthrough}
                            status="OPEN"
                            text={gettext('START')}
                        />
                    ),
                },
            ])}
        />
    );
};
App.propTypes = {
    manager: PropTypes.object.isRequired,
};

const walkthroughPage = () => {
    const manager = getWalkthroughManager();

    // For more details see: https://facebook.github.io/react/docs/top-level-api.html#react.render
    const element = document.getElementById('my-view');
    reactDOM.render(<App manager={manager} />, element);
};

export default walkthroughPage;
