/* eslint-disable react/prop-types */
import { Component, useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DateRange } from 'react-date-range';
import { generateStyles } from 'react-date-range/dist/utils';
import coreStyles from 'react-date-range/dist/styles';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {
    format,
    setHours,
    setMinutes,
    setYear,
    setSeconds,
    getYear,
    setDayOfYear,
    getDayOfYear,
    setMonth,
    getMonth,
    setMilliseconds,
    addMinutes,
    isBefore,
    getDate,
} from 'date-fns';
import { debounce } from 'lodash-es';
import profile from '../profile';
import { startOfOmnidots } from './date-fns';

const DAY_START_TIME = '00:00';
const DAY_END_TIME = '23:59';

const applyTimeString = (date, timeString) => {
    const [hours, minutes] = timeString ? timeString.split(':') : [0, 0];
    return setMinutes(setHours(date, hours), minutes);
};

const dateToTimeString = (date) => format(date, 'HH:mm');

const transferDatePartToDate = (newDate, existingDate) =>
    setDayOfYear(
        setMonth(setYear(existingDate, getYear(newDate)), getMonth(newDate)),
        getDayOfYear(newDate)
    );

function AllDayCheckbox({ getCurrent, timeWhenChecked, checkboxLabel, changeTime }) {
    // Default value 12:00 just because it's nice in the middle of
    // both checked values.
    const [uncheckTime, setUncheckTime] = useState('12:00');

    return (
        <div className="all-day-checkbox-wrapper">
            <label>
                <input
                    type="checkbox"
                    onChange={(event) => {
                        if (event.target.checked) {
                            // Set the time to the 'timeWhenChecked'.
                            setUncheckTime(dateToTimeString(getCurrent()));
                            changeTime(timeWhenChecked);
                        } else {
                            // Unchecking with no dateBeforeChecked
                            // sets the date to 12:00. 12:00 just
                            // because it's nice in the middle of
                            // both checked values.
                            changeTime(uncheckTime);
                        }
                    }}
                    checked={dateToTimeString(getCurrent()) === timeWhenChecked}
                />
                {checkboxLabel}
            </label>
        </div>
    );
}

// This timepicker will only emit an onChange after a debounce of 300ms or when losing
// focus. This is in contrast to the normal timepicker behaviour that emits an onChange
// with every adjustment immediately.
const TimePickerDebounced = ({ value, onChange, ...props }) => {
    const changedValueRef = useRef();

    const emitChange = useCallback(() => {
        changedValueRef.current && onChange(changedValueRef.current);
    }, [onChange]);

    const emitChangeDebounced = useMemo(() => debounce(emitChange, 300), [emitChange]);

    return (
        <TimePicker
            onChange={(timeString) => {
                changedValueRef.current = timeString;
                emitChangeDebounced();
            }}
            value={value}
            // When losing focus (tab or click outside) we want to skip the debounce.
            onClockClose={emitChange}
            {...props}
        />
    );
};

class DateTimeRange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uncheckTime: {},
        };
        this.styles = generateStyles([coreStyles, props.classNames]);
    }

    setUncheckTime(slot, time) {
        this.setState((state) => ({
            uncheckTime: {
                ...state.uncheckTime,
                [slot]: time,
            },
        }));
    }

    modifyDate(modifier) {
        const newState = modifier(this.props.ranges[0]);

        // Truncate the start and end date to the first/last milliseconds resp..
        newState.startDate = setSeconds(newState.startDate, 0);
        newState.endDate = setMilliseconds(setSeconds(newState.endDate, 59), 999);

        // Prevent negative date ranges.
        if (isBefore(newState.endDate, newState.startDate)) {
            const newEndDate = addMinutes(newState.startDate, 1);

            // The negative date range correction should not modify the selected
            // day. In that case we just set the start time to 00:00 and the end
            // time to 23:59.
            // Note: getDate returns the day of the month of the given date.
            if (getDate(newState.endDate) !== getDate(newEndDate)) {
                newState.startDate = applyTimeString(newState.startDate, DAY_START_TIME);
                newState.endDate = applyTimeString(newState.endDate, DAY_END_TIME);
            } else {
                newState.endDate = newEndDate;
            }
        }

        this.props.onChange({
            selection: {
                key: 'selection',
                ...newState,
            },
        });
    }

    changeTime(dateKey, timeString) {
        this.modifyDate((oldRange) => ({
            ...oldRange,
            [dateKey]: applyTimeString(oldRange[dateKey], timeString),
        }));
    }

    getCurrent(dateKey) {
        return this.props.ranges[0][dateKey];
    }

    render() {
        return (
            <div
                className={classnames(
                    'rdrDateTimeRangeWrapper',
                    this.styles.dateRangePickerWrapper
                )}
            >
                <DateRange
                    {...this.props}
                    ref={(t) => {
                        this.dateRange = t;
                    }}
                    className={this.props.className}
                    minDate={startOfOmnidots}
                    maxDate={new Date()}
                    onChange={(newDate) => {
                        // Every onChange from the DateRange picker reset the time
                        // to 00:00:00. We want the time to remain unchanged when
                        // only a date is selected. Therefore, we copy the time from
                        // the originally selected date range to the new date range.
                        this.modifyDate((oldRange) =>
                            Object.fromEntries(
                                ['startDate', 'endDate'].map((dateKey) => [
                                    dateKey,
                                    transferDatePartToDate(
                                        newDate.selection[dateKey],
                                        oldRange[dateKey]
                                    ),
                                ])
                            )
                        );
                    }}
                />
                <div className="time-range-picker-wrapper">
                    {[
                        ['startDate', gettext('START_OF_DAY'), DAY_START_TIME],
                        ['endDate', gettext('END_OF_DAY'), DAY_END_TIME],
                    ].map(([dateKey, checkboxLabel, timeWhenChecked], index) => (
                        <div className="time-range-picker-item" key={index}>
                            <TimePickerDebounced
                                onChange={(timeString) => {
                                    this.changeTime(dateKey, timeString);
                                }}
                                value={this.props.ranges[0][dateKey]}
                                disableClock
                                format={profile.use24HourNotation ? 'HH:mm' : 'hh:mm a'}
                                clearIcon={null}
                            />

                            <AllDayCheckbox
                                getCurrent={() => this.getCurrent(dateKey)}
                                changeTime={(value) => {
                                    this.changeTime(dateKey, value);
                                }}
                                checkboxLabel={checkboxLabel}
                                timeWhenChecked={timeWhenChecked}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

DateTimeRange.defaultProps = {};

DateTimeRange.propTypes = {
    ...DateRange.propTypes,
    className: PropTypes.string,
};

export default DateTimeRange;
