import { isValidElement } from 'react';

/**
 * Validates whether the given content is renderable by React.
 * - If the content is a valid React element, a string, a number, or a boolean, returns the content.
 * - If the content is an array, validates each item.
 * - Throws an error if the content is not renderable by React.
 *
 * This function mimics the behavior of React's internal `throwOnInvalidObjectType`
 * function found in the `react-reconciler` package, which is not exposed publicly.
 *
 * @param {*} content - The content to validate.
 * @returns {*} The validated content.
 * @throws {Error} If the content is not renderable by React.
 */
function validateReactRenderableContent(content) {
    if (isValidElement(content)) {
        return content;
    }
    if (['string', 'number', 'boolean', 'undefined'].includes(typeof content) || content === null) {
        return content;
    }
    if (Array.isArray(content)) {
        content.forEach(validateReactRenderableContent);
        return content;
    }
    const contentType = Array.isArray(content) ? 'array' : typeof content;
    const contentString = JSON.stringify(content, null, 2);
    throw new Error(
        `Content is not a valid React renderable element or primitive.\n` +
            `Content type: ${contentType}\n` +
            `Content: ${contentString}`
    );
}

export default validateReactRenderableContent;
