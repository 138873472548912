import { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

const Select = forwardRef(({ small, data, highlight, ...props }, ref) => (
    <div className={`relative inline-block w-full text-form-text`}>
        <select
            ref={ref}
            className={`
                w-full
                appearance-none
                rounded
                border
                border-neutral-300
                bg-white
                px-2
                pr-10
                leading-tight
                focus:outline-none
                ${small ? 'py-2' : 'py-4'}
                ${highlight ? 'border-warning' : ''}
                ${props.readOnly ? 'cursor-not-allowed' : ''}
                `}
            {...props}
        >
            {data &&
                data.map(([key, displayName]) => {
                    const value = key ?? '';
                    return (
                        <option key={value} value={value}>
                            {displayName}
                        </option>
                    );
                })}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4">
            <FontAwesomeIcon icon={faAngleDown} className="h-4 w-4 fill-current" />
        </div>
    </div>
));
Select.propTypes = {
    small: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.array),
    highlight: PropTypes.bool,
    readOnly: PropTypes.bool,
};
Select.displayName = 'Select';

export default Select;
