import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { defaultPropTypes } from '../utils';
import ChoiceField from './ChoiceField';
import { createListQueryForVariant } from '../../pages/measuringPoint/utils';
import { useGenericFormContext } from '../../pages/measuringPoint/GenericFormContext';

function ProjectField({ fieldOptions }) {
    const { swarmType } = useGenericFormContext();

    const [query, returnKey] = createListQueryForVariant(swarmType, true);
    const { data } = useQuery(query);

    const projects = useMemo(() => data?.[returnKey] ?? [], [data, returnKey]);

    const extraChoiceOptions = useMemo(
        () => projects.map(({ id, name }) => [id, name]),
        [projects]
    );

    return <ChoiceField fieldOptions={fieldOptions} extraChoiceOptions={extraChoiceOptions} />;
}
ProjectField.propTypes = defaultPropTypes;

export default ProjectField;
