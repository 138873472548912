import { hoursToSeconds, minutesToSeconds } from 'date-fns';

function extractIntervalNumber(optionText) {
    const match = optionText.match(/interval([0-9]+|Disabled)/i);

    if (!match) {
        throw new Error(`Invalid option text: ${optionText}`);
    }

    return match[1] === 'Disabled' ? 0 : parseInt(match[1], 10);
}

export function extractSecondsFromLeqInterval(optionText) {
    if (!optionText) {
        return null;
    }

    const interval = extractIntervalNumber(optionText);

    if (interval === 0) {
        return 0;
    }
    if (optionText.includes('Second')) {
        return interval;
    }
    if (optionText.includes('Minute')) {
        return minutesToSeconds(interval);
    }
    if (optionText.includes('Hour')) {
        return hoursToSeconds(interval);
    }
    throw new Error(`Unknown unit in option text: ${optionText}`);
}
