export const SwarmType = Object.freeze({
    VIBRATION: 'vibration',
    AIR: 'air',
    SOUND: 'sound',
});

export const SwarmTypeTranslations = Object.freeze({
    [SwarmType.VIBRATION]: gettext('SWARM_VIBRATION'),
    [SwarmType.AIR]: gettext('SWARM_AIR'),
    [SwarmType.SOUND]: gettext('SWARM_SOUND'),
});

export const ContentTab = Object.freeze({
    GRAPH: 'graph',
    DAILY_VALUES: 'daily-values',
    LDEN: 'lden',
});

export const GraphType = Object.freeze({
    STEP: 'step',
    BAR: 'bar',
    LINE: 'line',
    DOT: 'dot',
});
