import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useObservable, useObservableState } from 'observable-hooks';
import { map } from 'rxjs';
import { useQuery } from '@apollo/client';
import { BaseDataSet } from '../../../graph/dataset';
import { DataTypes, fixFloat } from '../../../graph/data-types';
import { NewPageTable } from '../../utils';
import { switchHead } from '../../../utils/rxjs';
import { leqAlarmTableQuery } from '../../../graph/queries';
import { getClient } from '../../../utils/graphql';
import { getSoundUnit$ } from '../../../graph/graph-elements/sound-plugin/unit-formatter';
import { formatDatetimeTz, formatTimeTz } from '../../../utils/formatting';
import { useLocale, secondsToDurationText } from '../../../utils/date-fns';

export function LeqAlarmTable({ dataSet, setReady }) {
    const shouldQuery$ = useObservable(
        (inputs$) =>
            inputs$.pipe(
                switchHead(),
                map((dataType) => dataType === DataTypes.LXEQ)
            ),
        [dataSet.dataType$]
    );
    const shouldQuery = useObservableState(shouldQuery$, null);
    const unit = useObservableState(getSoundUnit$(dataSet));
    const locale = useLocale();
    const timezone = useObservableState(dataSet.timezone$);

    const { data, loading } = useQuery(leqAlarmTableQuery, {
        client: getClient(),
        variables: {
            measuringPointId: dataSet.sensor,
            startTime: dataSet.dateRange.min,
            endTime: dataSet.dateRange.max,
        },
        // Only query when LEQ is requested.
        skip: !shouldQuery,
    });

    useEffect(() => {
        // Only respond if shouldQuery has been set by the shouldQuery$.
        if (!loading && shouldQuery !== null) {
            setReady();
        }
    }, [loading, setReady, shouldQuery]);

    const alarmData = data?.leqAlarmTable?.data || [];

    if (alarmData.length === 0) return null;

    const tableData = alarmData.map((tableRow) => [
        `${formatDatetimeTz(timezone, tableRow.startTime)} - ${formatTimeTz(
            timezone,
            tableRow.endTime
        )}`,
        tableRow.name,
        secondsToDurationText(tableRow.alarmInterval, locale),
        fixFloat(DataTypes.LXN, tableRow.alarmLevel),
        fixFloat(DataTypes.LXN, tableRow.value),
    ]);

    const header = [
        gettext('Date/Time'),
        gettext('LEQ_ALARM_NAME'),
        gettext('LEQ_ALARM_INTERVAL'),
        `${gettext('LEQ_ALARM_LEVEL')} (${unit})`,
        `${gettext('LEQ_ALARM_MEASURED')} (${unit})`,
    ];
    const title = gettext('LEQ_ALARM_EXCEEDANCE_LIST_TITLE');

    return <NewPageTable {...{ title, header, tableData }} />;
}

LeqAlarmTable.propTypes = {
    dataSet: PropTypes.instanceOf(BaseDataSet).isRequired,
    setReady: PropTypes.func.isRequired,
};
