import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useObservableState } from 'observable-hooks';
import { axisBottom, axisLeft } from 'd3-axis';
import { scaleLinear, scaleLog, scaleUtc } from 'd3-scale';
import moment from 'moment';
import { ceil } from 'lodash-es';
import {
    BehaviorSubject,
    EMPTY,
    Observable,
    Subject,
    asyncScheduler,
    combineLatest,
    map,
    observeOn,
    of,
    shareReplay,
    skip,
    skipUntil,
    startWith,
    switchMap,
    take,
    tap,
    throwError,
    withLatestFrom,
} from 'rxjs';

import scaleTimezone, { timeFormat } from '../utils/d3-tz-scale';
import { NotImplementedError } from '../utils/errors';
import { DateText, now, yearBack } from './graph-helpers';
import Select from '../components/form/Select';
import { SCALES_TYPE } from './data-types';
import { getDistanceUnit, distanceConverter } from '../utils/formatting';
import { locale$ } from '../utils/date-fns';

export const EPOCH_DAY_START = Date.UTC(1970, 0, 1, 0, 0);
export const EPOCH_DAY_END = Date.UTC(1970, 0, 2, 0, 0);
const LOGARITHMIC_SCALE_DOMAIN_X_HZ = [1, 250];
const LOGARITHMIC_SCALE_DOMAIN_Y_MM = [0.1, 300];
const LOGARITHMIC_TICKS = 5;

// Custom floor that apart from flooring also bring the most right number down to 1.
const customFloor = (value) => {
    const precision = -Math.floor(Math.log10(value));
    return ceil(value / 10, precision);
};

// Custom ceil that creates 'nicer' values for use in a logarithmic scale.
const customCeil = (value) => {
    const precision = -Math.floor(Math.log10(value) - 1);
    return ceil(value, precision);
};

const convertLogarithmicScaleDomain = (unit) => {
    if (unit === 'mm') {
        return LOGARITHMIC_SCALE_DOMAIN_Y_MM;
    }

    const [start, end] = LOGARITHMIC_SCALE_DOMAIN_Y_MM;

    return [customFloor(distanceConverter(start)), customCeil(distanceConverter(end))];
};

export const logarithmicScaleDomain = Object.freeze({
    x: LOGARITHMIC_SCALE_DOMAIN_X_HZ,
    y: convertLogarithmicScaleDomain(getDistanceUnit()),
});

const injectDomainUpdatedInterceptor = (scale, domainUpdated$) => {
    const originalScaleDomain = scale.domain.bind(scale);

    // Override the `domain` function with our own so that we can inject
    // our own goodies like `updateValues` to update the date texts
    // and the `domainUpdated` observable so that we can later update the visible
    // time window on the dataset.
    scale.domain = (newDomain, emitter = null) => {
        // If `newDomain` is defined `originalScaleDomain` will act as a setter and sets
        // the new visible area. If `newDomain` is undefined `originalScaleDomain` will
        // act as a getter and returns a copy of the scale’s current domain
        // https://github.com/d3/d3-scale#continuous_domain
        const result = newDomain ? originalScaleDomain(newDomain) : originalScaleDomain();

        if (newDomain) {
            // Trigger an update on the subject.
            domainUpdated$.next(emitter);
        }

        return result;
    };
};

const scaleFunctions = Object.freeze({
    [SCALES_TYPE.LINEAR]: scaleLinear,
    [SCALES_TYPE.LOGARITHMIC]: scaleLog,
});

const scaleTranslations = Object.freeze({
    [SCALES_TYPE.AUTO]: gettext('SCALE_TYPE_AUTO'),
    [SCALES_TYPE.LINEAR]: gettext('SCALE_TYPE_LINEAR'),
    [SCALES_TYPE.LOGARITHMIC]: gettext('SCALE_TYPE_LOGARITHMIC'),
});

export const ScaleTypeSelect = ({ scaleTypeObservable$, ...props }) => {
    const data = useMemo(() => Object.entries(scaleTranslations), []);
    const value = useObservableState(scaleTypeObservable$);

    return (
        <Select
            small={true}
            data={data}
            onChange={(e) => {
                scaleTypeObservable$.next(e.target.value);
            }}
            value={value}
            {...props}
        />
    );
};

ScaleTypeSelect.propTypes = {
    scaleTypeObservable$: PropTypes.instanceOf(Observable).isRequired,
};

class BaseAxis {
    constructor(
        axisOrientation,
        graph,
        domain$,
        range,
        tickSize,
        axisText$,
        zoomable = false,
        scaleType$ = null
    ) {
        this.axisOrientation = axisOrientation;
        this.graph = graph;
        this.graphSvg = this.graph.svg;
        this.graphMain = this.graph.graph;
        this.domain$ = domain$;
        this.range = range;
        this.tickSize = tickSize;
        this.axisText$ = axisText$;
        this.zoomable = zoomable;

        // Subject that allows to trigger a re-render for the axis. Useful for classes that
        // extend this base class. For example when the TimeXAxis receives an update on the
        // timezone, the axis also needs an extra render to make it display the correct time
        // near the thicks.
        this.triggerAxisRender$ = new Subject();

        // `scaleTypeController$` is optional, if provided the scale type can be controlled
        // from the outside (e.g. in reports or via a toggle button), if not specified we
        // will make one specific to this axis. Also `scaleTypeController$` is allowed to
        // contain `AUTO`, while `scaleType$` is only allowed to contain `LINEAR` and
        // `LOGARITHMIC`. When `scaleTypeController$` is `AUTO`, it will automatically
        // decide on the best value for `scaleType$`
        this.scaleTypeController$ = scaleType$ ?? new BehaviorSubject(SCALES_TYPE.LINEAR);

        this.scaleType$ = this.scaleTypeController$.pipe(
            switchMap((scaleType) => {
                if (!Object.keys(SCALES_TYPE).includes(scaleType)) {
                    return throwError(() => new Error(`Invalid scale type: '${scaleType}'`));
                }

                // When `AUTO` is selected we use the `frequencyGraphScaleType` setting
                // from the current data type or guide line.
                if (scaleType === SCALES_TYPE.AUTO) {
                    return graph.data.combinedSettings$.pipe(
                        map((settings) => settings.frequencyGraphScaleType)
                    );
                }

                return of(scaleType);
            }),
            shareReplay({
                bufferSize: 1,
            })
        );
        // Element showing the axis on the main view.
        this.element = this.createElement();

        this.domainUpdated$ = new Subject();
        this.originalDomainUpdated$ = new Subject();
        this.initScale();
        this.initAxis();

        // Emits immediately when subscribed to, then after every scale update and domain update.
        this.scaleUpdated$ = combineLatest([
            this.scale$,
            this.domainUpdated$.pipe(startWith(null)),
        ]).pipe(
            // Ditch the `domainUpdated`.
            map(([scale, _domainUpdated]) => scale)
        );

        if (this.axisText$) {
            this.textElement = this.createAxisTextElement();

            this.graph.shouldBeVisible$
                .pipe(switchMap((visible) => (visible ? axisText$ : EMPTY)))
                .subscribe((text) => {
                    this.updateAxisText(text);
                });
        }

        // Every time the axis or domain gets updated or the triggerAxisRender subject
        // get triggered, update the element with the new axis.
        combineLatest([
            this.axis$,
            this.domainUpdated$.pipe(startWith(null)),
            this.triggerAxisRender$.pipe(startWith(null)),
        ]).subscribe(() => {
            this.element.call(this.axis);
        });

        this.originalDomainUpdated$.subscribe(() => {
            if (zoomable) {
                // The `resetZoom` call will update the domain of `this.scale`
                // and make it in sync with `this.originalScale`.
                this.graph.zoom.resetZoom();
            } else {
                // Without zooming enabled we can skip the `resetZoom` part and
                // update `this.scale` immediately.
                this.scale.domain(this.originalScale.domain());
            }
        });
    }

    createScale() {
        return this.scaleType$.pipe(
            withLatestFrom(this.domain$),
            map(([scaleType, domain]) => {
                const scaleFunction = scaleFunctions[scaleType];
                return scaleFunction()
                    .domain(
                        scaleType === SCALES_TYPE.LOGARITHMIC
                            ? logarithmicScaleDomain[this.axisOrientation]
                            : domain
                    )
                    .range(this.range);
            })
        );
    }

    initScale() {
        this.scale$ = this.createScale().pipe(
            tap((scale) => {
                // Store the non observable version for backwards compatibility.
                this.scale = scale;
                this.originalScale = scale.copy();

                injectDomainUpdatedInterceptor(scale, this.domainUpdated$);
                injectDomainUpdatedInterceptor(this.originalScale, this.originalDomainUpdated$);
            }),
            shareReplay({
                bufferSize: 1,
            })
        );

        // Subscribe to get the variables ready for not observable compatible stuff.
        this.scale$.subscribe();

        // After the first `scale` and the `originalScale` are initialized, we also
        // want to watch for updates on the `domain$`, the `domain$` can be updated
        // from the outside by changes to the selected data type or when the scale
        // sliders are changed. We apply these changes to the `originalScale`, the
        // `originalScale` will apply it back to the normal `scale`.
        this.scaleType$
            .pipe(
                // Wait until the first scale is initialized.
                skipUntil(this.scale$.pipe(take(1))),
                // If the scale type is logarithmic we stop here as it has a fixed
                // scale. If it is linear we start tapping into the `domain$`
                // observable. Skip the first emitted value as that one is already
                // used in the creation of the scale itself.
                switchMap((scaleType) =>
                    scaleType === SCALES_TYPE.LOGARITHMIC ? EMPTY : this.domain$.pipe(skip(1))
                )
            )
            .subscribe((domain) => {
                this.originalScale.domain(domain);
            });
    }

    getAxisGenerator() {
        throw new NotImplementedError();
    }

    createAxis(scale, scaleType) {
        const axis = this.getAxisGenerator()(scale).tickSize(-this.tickSize);

        if (scaleType === SCALES_TYPE.LOGARITHMIC) {
            axis.ticks(LOGARITHMIC_TICKS);
        }

        return axis;
    }

    initAxis() {
        this.axis$ = this.scale$.pipe(
            // As scale$ is the first subscriber to `scaleType$` and this is
            // executed immediately after that, the next `withLatestFrom` has
            // not received the latest update to `scaleType$` yet. Therefore we
            // wait until the next event loop here.
            observeOn(asyncScheduler),
            withLatestFrom(this.scaleType$),
            map(([scale, scaleType]) => this.createAxis(scale, scaleType)),
            shareReplay({
                bufferSize: 1,
            })
        );

        this.axis$.subscribe((axis) => {
            this.axis = axis;
        });
    }

    createAxisTextElement() {
        return this.graphSvg.append('text');
    }

    updateAxisText() {
        throw new NotImplementedError();
    }
}

export class XAxis extends BaseAxis {
    constructor(...args) {
        super('x', ...args);
    }

    createElement() {
        // Element showing the x-axis on the main view.
        return this.graphMain
            .append('g')
            .attr('class', 'axis x-axis')
            .attr('transform', `translate(0, ${this.tickSize})`);
    }

    getAxisGenerator() {
        return axisBottom;
    }

    createAxisTextElement() {
        return super.createAxisTextElement();
    }

    updateAxisText(text) {
        // Set text and re-center.
        this.textElement.text(text);
        this.textElement.attr('x', (_d, i, nodes) => {
            const middleOfGraph = this.graph.graphWidth / 2 + this.graph.margin.left;
            return middleOfGraph - nodes[i].getBBox().width / 2;
        });
        this.textElement.attr(
            'y',
            (_d, i, nodes) => this.graph.height - nodes[i].getBBox().height / 2
        );
    }
}

export class YAxis extends BaseAxis {
    constructor(...args) {
        super('y', ...args);
    }

    createElement() {
        // Element showing the y-axis on the main view.
        return this.graphMain.append('g').attr('class', 'axis y-axis');
    }

    getAxisGenerator() {
        return axisLeft;
    }

    createAxisTextElement() {
        return super.createAxisTextElement().attr('x', (this.graph.height / 2) * -1);
    }

    updateAxisText(text) {
        // Set text and re-center.
        this.textElement.text(text);
        this.textElement.attr(
            'transform',
            (_d, i, nodes) => `rotate(-90, 0, ${nodes[i].getBBox().height * -1})`
        );
    }
}

export class TimeXAxis extends XAxis {
    constructor(graph, domain$, graphWidth, tickSize) {
        const axisText$ = graph.data.timezone$.pipe(
            map((timezone) => `${gettext('Date/time')} (${timezone})`)
        );

        // First create a scale with a full year. This sets the max zoom-out level.
        super(graph, of(TimeXAxis.initialTimeAxisMinMax()), [0, graphWidth], tickSize, axisText$);

        domain$.pipe(take(1)).subscribe((domain) => {
            // Then set the real initial scale.
            this.scale.domain(domain);
        });

        this.initValues();

        this.domainUpdated$.subscribe(() => {
            // Update text dates on the x axis.
            this.updateValues();
        });

        this.graph.data.timezone$.subscribe((timezone) => {
            this.updateTimezone(timezone);
        });

        locale$.subscribe((locale) => {
            this.updateLocale(locale);
        });
    }

    static initialTimeAxisMinMax() {
        return [moment.utc(yearBack), moment.utc(now)];
    }

    initValues() {
        // Add a left value.
        this.leftValue = new DateText(
            this.graphSvg,
            this.getMin(),
            this.graph.data.timezone,
            () => this.graph.margin.left,
            () => this.graph.height - this.graph.margin.bottom + this.graph.valuesHeight + 5
        );

        // Add an indicator for the left value.
        this.graphSvg
            .append('rect')
            .attr('width', 1)
            .attr('height', 5)
            .attr('x', this.graph.margin.left)
            .attr('y', this.graph.height - this.graph.margin.bottom + 15);

        // Add a right value.
        this.rightValue = new DateText(
            this.graphSvg,
            this.getMax(),
            this.graph.data.timezone,
            (dateText) => {
                const textWidth = dateText.selection.node().getComputedTextLength();

                // If the text width is 0px, it indicates that the element is hidden.
                // In that case, we cease the calculation. This is because recalculating
                // element positions relative to the widths, heights, or positions of
                // hidden elements can lead to unexpected behavior.
                if (textWidth === 0) {
                    return null;
                }

                return (
                    this.graph.graphWidth +
                    this.graph.margin.left -
                    dateText.selection.node().getComputedTextLength()
                );
            },
            () => this.graph.height - this.graph.margin.bottom + this.graph.valuesHeight + 5
        );

        // Add an indicator for the right value.
        this.graphSvg
            .append('rect')
            .attr('width', 1)
            .attr('height', 5)
            .attr('x', this.graph.graphWidth + this.graph.margin.left - 1)
            .attr('y', this.graph.height - this.graph.margin.bottom + 15);
    }

    /**
     * Updates the left and right date texts on the x axis.
     */
    updateValues() {
        this.leftValue.setDate(this.getMin());
        this.rightValue.setDate(this.getMax());
    }

    getMin() {
        return this.scale.domain()[0];
    }

    getMax() {
        return this.scale.domain()[1];
    }

    /**
     * Updates the scale timezone and the texts.
     */
    updateTimezone(timezone) {
        // Update the left and right texts.
        this.leftValue.setTimezone(timezone);
        this.rightValue.setTimezone(timezone);

        // Update scale timezone:
        this.scale.setTimezoneName(timezone);
        // The timezone on the scale got updated, now the axis needs to get re-rendered.
        this.triggerAxisRender$.next();
    }

    /**
     * Updates locale.
     */
    updateLocale(locale) {
        // Update locale:
        this.scale.setLocale(locale);
        // The locale used by the scale got updated, now the axis needs to get re-rendered.
        this.triggerAxisRender$.next();
    }

    updateAxisText(text) {
        // Updated version of updateAxisText for the `time` axis because the
        // graph using it can sometimes have a `brush graph` which makes the
        // height different.
        super.updateAxisText(text);
        this.textElement.attr(
            'y',
            this.graph.height - this.graph.margin.bottom + this.graph.valuesHeight + 5
        );
    }

    createScale() {
        return this.domain$.pipe(
            take(1),
            map((domain) =>
                scaleTimezone(this.graph.data.timezone).domain(domain).range(this.range)
            )
        );
    }
}

export class DayXAxis extends XAxis {
    constructor(graph, graphWidth, tickSize) {
        super(graph, of([EPOCH_DAY_START, EPOCH_DAY_END]), [0, graphWidth], tickSize);
    }

    createScale() {
        return this.domain$.pipe(
            take(1),
            map((domain) => scaleUtc().domain(domain).rangeRound(this.range))
        );
    }

    createAxis(...args) {
        return super
            .createAxis(...args)
            .tickFormat((date) => timeFormat(moment.utc(date)))
            .tickPadding(10);
    }
}
