import 'select2';
import 'select2/dist/css/select2.css';

import './select2-custom.css';

import $ from 'jquery';

// SingleSelection adapter that uses a Font Awesome icon as dropdown arrow.
$.fn.select2.amd.define(
    'FontAwesomeSingleSelection',
    [
        'select2/utils',
        'select2/selection/base',
        'select2/selection/placeholder',
        'select2/selection/eventRelay',
        'select2/selection/single',
    ],
    (Utils, BaseSelection, Placeholder, EventRelay, SingleSelection) => {
        // Decorates SingleSelection with Placeholder.
        let adapter = Utils.Decorate(SingleSelection, Placeholder);
        // Decorates adapter with EventRelay - ensures events will continue to fire
        // e.g. selected, changed.
        adapter = Utils.Decorate(adapter, EventRelay);

        adapter.prototype.render = function render() {
            const $selection = BaseSelection.prototype.render.call(this);

            $selection.addClass('select2-selection--single');

            $selection.html(
                '<span class="select2-selection__rendered"></span>' +
                    '<span class="select2-selection__arrow" role="presentation">' +
                    '<i class="fa fa-angle-down"></i>' +
                    '</span>'
            );

            return $selection;
        };

        adapter.prototype.update = function update(data) {
            SingleSelection.prototype.update.call(this, data);
        };

        return adapter;
    }
);

const FontAwesomeSingleSelection = $.fn.select2.amd.require('FontAwesomeSingleSelection');

export default FontAwesomeSingleSelection;
