// Currently used for the DataTabs but should be extended to other components later.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

function ErrorContainer() {
    return (
        <div className="flex h-32 flex-col items-center justify-center">
            <FontAwesomeIcon icon={faTriangleExclamation} className="text-warning" size="2xl" />
            {gettext('ERROR_PAGE_TITLE')}
        </div>
    );
}

export default ErrorContainer;
