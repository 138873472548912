import { memoize } from 'lodash-es';

/**
 * memoize by default caches based on the first argument. This
 * makes it actually cache based on all arguments.
 */
function memoizeMultiArg(func) {
    return memoize(func, (...args) => args.join('_'));
}

export default memoizeMultiArg;
