import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import SkeletonLoader from '../skeletonLoader/SkeletonLoader';

const blockClasses = 'w-full drop-shadow-sm';
const tableHeaderRowClasses = 'mt-2.5 ml-2.5 h-6 px-1';
const tableHeaderCellClasses = 'px-2 text-xs leading-6 text-gray-400 bg-gray-100 font-semibold';
const tableRowClasses = 'py-4';
const tableRowCellClasses = 'h-6 pt-4 px-2 leading-6';
const blockHeaderContainerClasses = `flex h-11 flex-row justify-between justify-items-center gap-2.5 rounded-tr py-2 px-2.5 font-black -tracking-widened ${blockClasses}`;
const blockHeaderContentClasses = 'w-32 flex-initial text-neutral-800';
const blockHeaderLeftClasses = 'h-6 w-16 text-base font-semibold leading-6';
const blockHeaderRightClasses = 'h-5 w-28 text-sm font-bold leading-5';

const SortButtons = ({ columnName, orderBy, setOrderBy }) => (
    <span className="mb-0.5 inline-flex flex-col pl-1 align-middle">
        {[
            {
                orderPrefix: '',
                buttonClassName: 'h-1.5',
                icon: faSortUp,
                iconClassName: `block h-3`,
            },
            {
                orderPrefix: '-',
                buttonClassName: 'relative h-1.5 overflow-hidden',
                icon: faSortDown,
                iconClassName: `absolute bottom-0 block h-3`,
            },
        ].map(({ orderPrefix, buttonClassName, icon, iconClassName }, index) => {
            const whenSelected = `${orderPrefix}${columnName}`;
            return (
                <button
                    key={`${columnName}-${index}`}
                    onClick={() => {
                        setOrderBy((current) =>
                            // Unset orderBy when clicking while already selected.
                            current === whenSelected ? null : whenSelected
                        );
                    }}
                    className={buttonClassName}
                >
                    <FontAwesomeIcon
                        icon={icon}
                        className={`${iconClassName} ${
                            orderBy === `${whenSelected}` ? 'text-black' : ''
                        }`}
                    />
                </button>
            );
        })}
    </span>
);
SortButtons.propTypes = {
    columnName: PropTypes.string.isRequired,
    orderBy: PropTypes.string,
    setOrderBy: PropTypes.func.isRequired,
};

const TableHeader = ({ columns, orderAndPaginationControl = null }) => (
    <thead>
        <tr className={tableHeaderRowClasses}>
            {columns.map((column, columnIndex) => (
                <th
                    key={columnIndex}
                    className={
                        columnIndex + 1 === columns.length
                            ? `${tableHeaderCellClasses} text-right`
                            : `${tableHeaderCellClasses} text-left`
                    }
                >
                    {column.title}
                    {column?.showSortIcon && orderAndPaginationControl && (
                        <SortButtons
                            columnName={column.key}
                            orderBy={orderAndPaginationControl.orderBy}
                            setOrderBy={orderAndPaginationControl.setOrderBy}
                        />
                    )}
                </th>
            ))}
        </tr>
    </thead>
);

TableHeader.propTypes = {
    columns: PropTypes.array,
    orderAndPaginationControl: PropTypes.object,
};

const isLastColumn = (index, columns) => index + 1 === columns.length;

const TableRow = ({ columns, data }) =>
    data.map((rowData, rowIndex) => (
        <tr key={`table-body-${rowIndex}`} className={tableRowClasses}>
            {columns.map((column, columnIndex) => (
                <td
                    key={`${columnIndex}-${rowIndex}`}
                    className={`${tableRowCellClasses} text-xs ${
                        isLastColumn(columnIndex, columns) && 'text-right'
                    }`}
                >
                    {column.render ? column.render(column, rowData) : get(rowData, column.key, '')}
                </td>
            ))}
        </tr>
    ));

const BlockHeader = ({ header }) => (
    <div className={blockHeaderContainerClasses}>
        <div className={blockHeaderContentClasses}>
            <label className={blockHeaderLeftClasses}>{header}</label>
        </div>
        <div className={`${blockHeaderContentClasses} text-right`}>
            <label className={blockHeaderRightClasses}>Download CSV</label>
            <FontAwesomeIcon icon={faDownload} className="h-3 w-3 text-gray-400" />
        </div>
    </div>
);

BlockHeader.propTypes = {
    header: PropTypes.string,
};

const Table = ({
    data,
    columns,
    header,
    showHeader = false,
    orderAndPaginationControl = null,
    loading,
    showBorder = true,
}) => (
    <div className={`rounded${showBorder ? ' border' : ''}`}>
        {showHeader && <BlockHeader header={header} />}
        <hr className="border-gray-200 pb-2" />

        <div className="px-1.5">
            {loading ? (
                <div className="pb-1.5 text-center">
                    <SkeletonLoader count={3} wrapperClassName="p-2" />
                </div>
            ) : (
                <table className={`${blockClasses} mb-2.5 rounded-bl`}>
                    <TableHeader
                        columns={columns}
                        orderAndPaginationControl={orderAndPaginationControl}
                    ></TableHeader>
                    <tbody>
                        <TableRow columns={columns} data={data}></TableRow>
                    </tbody>
                </table>
            )}
        </div>
    </div>
);

Table.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    header: PropTypes.string,
    showHeader: PropTypes.bool,
    orderAndPaginationControl: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    showBorder: PropTypes.bool,
};

export default Table;
