import { useEffect, useMemo, useRef } from 'react';
import { cloneDeep, isEmpty, isEqual, set } from 'lodash-es';
import { BehaviorSubject, distinctUntilChanged, filter, map } from 'rxjs';
import { useObservableState, useSubscription } from 'observable-hooks';

class RowManager {
    maxRows;

    emptyRow;

    constructor(maxRows, emptyRow) {
        this.maxRows = maxRows;
        this.emptyRow = emptyRow;

        // Rows that are coming from the API.
        this.input$ = new BehaviorSubject([]);

        // Rows that are going back to the API.
        this.output$ = new BehaviorSubject([]);

        // The rows that will be shown in the UI.
        this.rows$ = new BehaviorSubject([]);

        this.rowLimitReached$ = this.rows$.pipe(
            map((rows) => rows.length >= this.maxRows),
            distinctUntilChanged()
        );

        this.showDeleteButtons$ = this.rows$.pipe(
            map((rows) => rows.length > 1),
            distinctUntilChanged()
        );

        // Handle incoming rows.
        this.handleInputRows();

        // Handle outgoing rows.
        this.handleOutputRows();
    }

    // Method to handle incoming rows.
    handleInputRows() {
        this.input$
            .pipe(map((input) => this.addFirstEmptyRow(input)))
            // Push the modified input into `rows$`.
            .subscribe(this.rows$);
    }

    // Method to handle outgoing rows.
    handleOutputRows() {
        this.rows$
            .pipe(
                // Optionally strip the empty row that was added in `setInput`.
                map((output) => this.stripFirstEmptyRow(output)),
                // There is no need to emit output when the output is the same a the input.
                filter((output) => !this.outputIsEqualToInput(output))
            )
            .subscribe(this.output$);
    }

    // If input rows are empty, add a default row.
    addFirstEmptyRow(rows) {
        return isEmpty(rows) ? [this.emptyRow] : rows;
    }

    // Strip the first empty row if it's the only row in the array.
    stripFirstEmptyRow(rows) {
        // Check if the rows array has a single item and that item is an 'emptyRow'.
        // If so, return an empty array which signifies a 'disabled' state to the listener.
        if (rows.length === 1 && isEqual(rows[0], this.emptyRow)) {
            return [];
        }

        // Otherwise, return the current array as is.
        return rows;
    }

    // Check if the processed output is same as input.
    outputIsEqualToInput(output) {
        return isEqual(output, this.input$.getValue());
    }

    // Add an empty row to the list.
    addRow() {
        const current = this.rows$.value;
        this.rows$.next([...current, this.emptyRow]);
    }

    // Remove a row from the list.
    removeRow = (index) => {
        const current = this.rows$.value;
        this.rows$.next(current.filter((row, i) => i !== index));
    };

    // Change a specific row in the list.
    changeRow = (index, key, newValue) => {
        const current = this.rows$.value;
        const copy = [...current];

        copy[index] = cloneDeep(current[index]);

        // Set the new value at the specified path on the row.
        set(copy[index], key, newValue);

        this.rows$.next(copy);
    };

    // Method to integrate this class with React components.
    static useRowManager(value, onChange, maxRows, emptyRow) {
        // ESlint will complain about that hooks cannot be called in
        // a class component, but we are not doing that.
        /* eslint-disable react-hooks/rules-of-hooks */
        const emptyRowRef = useRef(emptyRow);
        const manager = useMemo(
            () => new this(maxRows, emptyRowRef.current),
            [maxRows, emptyRowRef]
        );

        // Push the incoming values to the RowManager.
        useEffect(() => {
            manager.input$.next(value);
        }, [manager, value]);

        // Push the outgoing values from RowManager to the onChange callback.
        useSubscription(manager.output$, (rows) => onChange(rows));

        const rows = useObservableState(manager.rows$);
        const rowLimitReached = useObservableState(manager.rowLimitReached$);
        const showDeleteButtons = useObservableState(manager.showDeleteButtons$);

        return { rows, manager, rowLimitReached, showDeleteButtons };
        /* eslint-enable */
    }
}

export default RowManager;
