/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/**
 * This page is still WIP.
 * TODO:
 *  * Transafer all the cog actions from the original overview page.
 *  * Change <i className="fa fa-cog" /> into <FontAwesomeIcon />
 *  * Create Storybook story where this page functions completely independently.
 */

import { useQuery, gql } from '@apollo/client';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { groupBy, keyBy } from 'lodash-es';
import PropTypes from 'prop-types';
import { SURVEY_BODY_TEXT, SURVEY_URL, SURVEY_HEADER_TEXT } from '../../surveyPopup/utils';
import BasePage from '../base-page';
import { getSwarmTypeByGraphqlObject } from './utils';
import SurveyPopup from '../../surveyPopup/surveyPopup';
import { SwarmType } from '../../../enums';

// TODO: Use SwarmType enum.
const GET_MEASURING_POINTS = gql`
    query getMeasuringPoints {
        airMeasuringPoints {
            id
            name
            project
            deviceName
        }
        vibrationMeasuringPoints {
            id
            name
            project
            deviceName
        }
        airProjects {
            id
            name
        }
        vibrationProjects {
            id
            name
        }
    }
`;

const swarmIconClasses = {
    [SwarmType.AIR]: 'swarm-air-icon',
    [SwarmType.VIBRATION]: 'swarm-vibration-icon',
    [SwarmType.SOUND]: 'swarm-sound-icon',
};

const getSwarmIconClass = (object) => {
    if (object) {
        return swarmIconClasses[getSwarmTypeByGraphqlObject(object)];
    }

    return null;
};

const MeasuringPointGroup = ({ group }) => (
    <div className="project main-container-new px-5">
        <div className="project-header">
            <h3>
                <div className="project-device">
                    <div className={getSwarmIconClass(group.project)}></div>
                </div>
                <div>{group.name}</div>
            </h3>

            {group.project && (
                <div className="submenu">
                    <i className="fa fa-cog"></i>
                    <div className="submenu-items">
                        <Link to={`/project/react/edit/${group.project.id}/`}>Configuration</Link>

                        {/* <a
                            className="to-reports"
                            href="/project/edit_report/3628"
                        >
                            Reports
                        </a>
                        <a
                            href="/project/archive/3628"
                            // onClick="return confirm('Are you sure you want to archive blabla test?')"
                        >
                            Archive
                        </a>
                        <a
                            href="/project/delete/3628"
                            // onClick="return confirm('Are you sure you want to delete blabla test?')"
                        >
                            Delete
                        </a>
                        <a
                            href="/sharing/share-project-edit/3628"
                            className="share-project"
                        >
                            Share project
                        </a> */}
                    </div>
                </div>
            )}
        </div>
        <table className="table-responsive-label td-7 table">
            <thead>
                <tr>
                    <th className="name">
                        <a
                            href="/measuring_point/?sort_path=name&amp;sort_reverse=None"
                            className="sorter"
                        >
                            Name
                        </a>
                    </th>

                    <th className="city">
                        <a
                            href="/measuring_point/?sort_path=city&amp;sort_reverse=None"
                            className="sorter"
                        >
                            City
                        </a>
                    </th>

                    <th className="sensor_online">
                        <a
                            href="/measuring_point/?sort_path=sensor.online&amp;sort_reverse=None"
                            className="sorter"
                        >
                            Status
                        </a>
                    </th>

                    <th className="sensor_simple_name">
                        <a
                            href="/measuring_point/?sort_path=sensor.simple_name&amp;sort_reverse=None"
                            className="sorter"
                        >
                            Device
                        </a>
                    </th>

                    <th className="sensor_lastseen">
                        <a
                            href="/measuring_point/?sort_path=sensor.lastseen&amp;sort_reverse=None"
                            className="sorter"
                        >
                            Last contact
                        </a>
                    </th>

                    <th className="sensor_battery_charge">
                        <a
                            href="/measuring_point/?sort_path=sensor.battery_charge&amp;sort_reverse=None"
                            className="sorter"
                        >
                            Battery charge
                        </a>
                    </th>

                    <th className="nopath">Options</th>
                </tr>
            </thead>

            <tbody>
                {group.measuringPoints.map((mp) => {
                    const { id, name, deviceName } = mp;
                    return (
                        <tr key={id} data-measuring-point-id={id}>
                            <td data-label="Name" className="mp-name">
                                {name}
                            </td>
                            <td data-label="City">Unknown</td>

                            <td data-label="Status" className="status">
                                <div className="circle">inactive</div>
                            </td>
                            <td data-label="Device">
                                <div className="name">
                                    <div
                                        className={`${
                                            !deviceName && 'detached-device'
                                        } ${getSwarmIconClass(mp)}`}
                                    ></div>
                                </div>
                            </td>
                            <td data-label="Last contact" className="server_contact">
                                <span
                                    className="datetime"
                                    data-datetime=""
                                    data-datetime-target="title"
                                ></span>
                            </td>
                            <td data-label="Battery charge">
                                <span title="There is no battery state known or the last state was too long ago.">
                                    Unknown
                                </span>
                            </td>

                            <td data-label="Options" className="has-submenu">
                                <a
                                    href="/sbr/sbrView/#12330"
                                    className="pure-button pure-button-primary button-table show-data"
                                >
                                    View data
                                </a>

                                <div className="submenu">
                                    <i className="fa fa-cog"></i>

                                    <div className="submenu-items">
                                        <Link to={`/measuring_point/react/edit/${id}/`}>
                                            Configuration
                                        </Link>
                                        {/* <a
                                            className="to-reports"
                                            href="/reports/edit/12330"
                                        >
                                            Reports
                                        </a>
                                        <a
                                            href="/sharing/share-measuring-point-edit/12330"
                                            className="share-measuring-point"
                                        >
                                            Share measuring point
                                        </a>

                                        <a
                                            href="/measuring_point/archive/12330"
                                            // onClick="return confirm('Are you sure you want to archive test d mp?')"
                                        >
                                            Archive
                                        </a>
                                        <a
                                            href="/measuring_point/delete/12330"
                                            // onClick="return confirm('Are you sure you want to delete test d mp?')"
                                        >
                                            Delete
                                        </a> */}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    </div>
);

const OverviewPage = ({ isProject }) => {
    const title = useMemo(
        () => `Measuring point${isProject ? ' project ' : ''}overview`,
        [isProject]
    );

    // TODO: Do something with loading and error states.
    const { _loading, _error, data } = useQuery(GET_MEASURING_POINTS, {
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
    });

    const measuringPointGroups = useMemo(() => {
        // TODO: Use SwarmType enum. As this page is not live yet this can be done in:
        // https://github.com/omnidots/website/issues/6588
        const measuringPoints = [
            ...(data?.airMeasuringPoints ?? []),
            ...(data?.vibrationMeasuringPoints ?? []),
        ];
        const projects = keyBy(
            [...(data?.airProjects ?? []), ...(data?.vibrationProjects ?? [])],
            'id'
        );

        return Object.entries(groupBy(measuringPoints, 'project')).map(([projectId, mps]) => ({
            key: projectId,
            name:
                projectId === 'null'
                    ? 'Without project'
                    : projects[projectId]?.name ?? 'Unknown project',
            project: projects[projectId],
            measuringPoints: mps,
        }));
    }, [data]);

    return (
        <BasePage title={title}>
            {measuringPointGroups.map((group) => (
                <MeasuringPointGroup key={group.key} group={group} />
            ))}
            <SurveyPopup
                headerText={SURVEY_HEADER_TEXT}
                bodyText={SURVEY_BODY_TEXT}
                url={SURVEY_URL}
            />
        </BasePage>
    );
};
OverviewPage.propTypes = {
    isProject: PropTypes.bool.isRequired,
};

export default OverviewPage;
