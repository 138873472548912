import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { computePosition, shift } from '@floating-ui/core';
import { platform as platformDom } from '@floating-ui/dom';
import SelectionBlock from '../selectionBlock/SelectionBlock';

// The value '160' is gotten arbitrarily after manual testing,
// to determine which point is best to show the minimal progress bar
// and enlarged selection block on hover.
const minimalSelectionWidth = 160;

function SelectionWrapper({ block, formatTime, unit, id }) {
    const isMinimal = block.width < minimalSelectionWidth;
    const elementRef = useRef();
    const [isHovered, setIsHovered] = useState(false);
    const [overriddenLeft, setOverriddenLeft] = useState();

    useEffect(() => {
        if (!(isHovered && isMinimal)) {
            setOverriddenLeft(null);
            return;
        }

        const element = elementRef.current;
        const parent = element.parentElement;

        const canvas = {
            x: 0,
            y: 0,
            ...platformDom.getDimensions(parent),
        };

        // The `reference` contains initial width and left
        // position of the block.
        const reference = {
            width: block.width,
            height: 0,
            x: block.left,
            y: 0,
        };

        // `floating` is the Selection Block.
        const floating = {
            x: 0,
            y: 0,
            ...platformDom.getDimensions(element),
        };

        const platform = {
            getElementRects: (data) => data,
            getDimensions: platformDom.getDimensions,
            getClippingRect: () => canvas,
        };

        computePosition(reference, floating, {
            platform,
            middleware: [shift()],
        }).then(({ x }) => {
            setOverriddenLeft(x);
        });
    }, [isHovered, block, isMinimal]);

    return (
        <div
            className="absolute hover:z-10"
            style={{
                left: overriddenLeft || block.left,
                width: isMinimal && isHovered ? 270 : block.width,
            }}
            onMouseOver={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            data-testid={id}
            ref={elementRef}
        >
            <SelectionBlock
                {...{
                    name: block.period.timeWindow.name,
                    startTime: formatTime(block.period.timeSpan.from),
                    endTime: formatTime(block.period.timeSpan.to),
                    warningLevel: block.period.timeWindow.warningLevel,
                    value: block.period.value,
                    alarmLevel: block.period.timeWindow.alarmLevel,
                    correction: block.period.timeWindow.correction,
                    isMinimal: isMinimal && !isHovered,
                    unit,
                }}
            />
        </div>
    );
}

SelectionWrapper.propTypes = {
    block: PropTypes.object.isRequired,
    formatTime: PropTypes.func.isRequired,
    unit: PropTypes.string,
    id: PropTypes.string.isRequired,
};

export default SelectionWrapper;
