import { unified } from 'unified';
import remarkParse from 'remark-parse';
import remarkBreaks from 'remark-breaks';
import remarkRehype from 'remark-rehype';
import rehypeStringify from 'rehype-stringify';

const markdown = (input) =>
    String(
        unified()
            .use(remarkParse)
            // Turns newlines into <br>'s:
            .use(remarkBreaks)
            // Turns markdown into AST:
            .use(remarkRehype)
            // Turns AST into s string:
            .use(rehypeStringify)
            .processSync(input)
    );

export default markdown;
