import { forwardRef } from 'react';
import PropTypes from 'prop-types';

export const inputFieldClasses =
    'focus:shadow-outline w-full appearance-none rounded border border-neutral-300 bg-white px-2 read-only:cursor-not-allowed disabled:cursor-not-allowed disabled:pointer-events-none leading-tight text-form-text focus:outline-none';

export const INPUT_SIZE = Object.freeze({
    SMALL: 'SMALL',
    MEDIUM: 'MEDIUM',
    LARGE: 'LARGE',
});

const SIZE_CLASSES = Object.freeze({
    [INPUT_SIZE.SMALL]: 'py-1',
    [INPUT_SIZE.MEDIUM]: 'py-2',
    [INPUT_SIZE.LARGE]: 'py-4',
});

const Input = forwardRef(({ size, error, ...props }, ref) => {
    // `disabled:pointer-events-none` is to allow onClick event on disabled
    // input's in Chrome. This is needed to show the `areYouSureToOverride` confirm.
    const classes = `
        ${
            props?.type === 'checkbox'
                ? ''
                : `
                    ${inputFieldClasses}
                    ${SIZE_CLASSES[size]}
                    ${error && 'border-red-500'}
                    ${props.readOnly ? 'cursor-not-allowed' : ''}
            `
        }
    `;

    return <input ref={ref} className={classes} {...props} />;
});
Input.propTypes = {
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object, PropTypes.string]),
    type: PropTypes.oneOf(['text', 'number', 'checkbox', 'password']),
    step: PropTypes.number,
    size: PropTypes.oneOf(Object.keys(INPUT_SIZE)),
    readOnly: PropTypes.bool,
};
Input.defaultProps = {
    type: 'text',
    size: INPUT_SIZE.LARGE,
};

Input.displayName = 'Input';

export default Input;
