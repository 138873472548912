import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useObservable, useObservableState } from 'observable-hooks';
import { map } from 'rxjs';
import { useQuery } from '@apollo/client';
import { BaseDataSet } from '../../../graph/dataset';
import {
    DataTypes,
    convertDistanceAndFloat,
    fixFloat,
    getDataType,
} from '../../../graph/data-types';
import { namingConventions$, applyNamingConvention } from '../../../utils/naming-conventions';
import { NewPageTable } from '../../utils';
import { switchHead } from '../../../utils/rxjs';
import { HighestVtopQuery, HighestVtopPerDayQuery } from '../../../graph/queries';
import { getClient } from '../../../utils/graphql';
import { formatDatetimeTz } from '../../../utils/formatting';

function getTitleForType(datatype) {
    const { unit, title } = getDataType(datatype);
    return `${applyNamingConvention(title)} (${unit})`;
}

function ReadingsTable({ title, tableData }) {
    const namingConventions = useObservableState(namingConventions$);

    const header = useMemo(
        () => [
            gettext('Date/Time'),
            gettext('AXIS'),
            getTitleForType(DataTypes.FDOM),
            getTitleForType(DataTypes.VTOP),
            gettext('READING_PERCENTAGE'),
        ],
        /*
         Disabling `exhaustive-deps` because in this case we know better than the
         linter. We want the header to update when the namingConventions change, but
         we are not really interested in the naming conventions themselves. So we
         use it in the dependency array but do not use it in the memo itself. The
         linter thinks we forgot to clean up something, but this is by design.
         */
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [namingConventions]
    );

    return <NewPageTable {...{ title, header, tableData }} />;
}

ReadingsTable.propTypes = {
    title: PropTypes.string.isRequired,
    tableData: PropTypes.array.isRequired,
};

function useDataQuery(dataSet, setReady, query, queryReturnName) {
    const shouldQuery$ = useObservable(
        (inputs$) =>
            inputs$.pipe(
                switchHead(),
                map((dataType) => dataType === DataTypes.VTOP)
            ),
        [dataSet.dataType$]
    );
    const shouldQuery = useObservableState(shouldQuery$, null);

    const { data, loading } = useQuery(query, {
        client: getClient(),
        variables: {
            measuringPointId: dataSet.sensor,
            startTime: dataSet.dateRange.min,
            endTime: dataSet.dateRange.max,
        },
        skip: !shouldQuery,
    });

    useEffect(() => {
        // Only respond if shouldQuery has been set by the shouldQuery$.
        if (!loading && shouldQuery !== null) {
            setReady();
        }
    }, [loading, setReady, shouldQuery]);

    return data?.[queryReturnName] || {};
}

function getRowData(timezone, timestamp, axis, fdom, vtop, percentage) {
    return [
        formatDatetimeTz(timezone, timestamp),
        axis,
        convertDistanceAndFloat(DataTypes.FDOM, fdom),
        // VTOP data type somehow makes 0 out of non null, so prevent that.
        vtop !== null ? convertDistanceAndFloat(DataTypes.VTOP, vtop) : '',
        // Use FDOM's datatype to round to one decimal place.
        fixFloat(DataTypes.FDOM, percentage),
    ];
}

export function HighestReadingTable({ dataSet, setReady }) {
    const data = useDataQuery(dataSet, setReady, HighestVtopQuery, 'highestVtopEntries');
    const timezone = useObservableState(dataSet.timezone$);
    const entries = data?.entries || [];
    if (entries.length === 0) return null;

    const axes = ['x', 'y', 'z'];
    if (data?.vectorEnabled) {
        axes.push('vector');
    }

    const tableData = entries.flatMap((tableRow) => [
        ...axes.map((axis) =>
            getRowData(
                timezone,
                tableRow.timestamp,
                axis,
                tableRow.fdom[axis],
                tableRow.vtop[axis],
                tableRow.percentage[axis]
            )
        ),
    ]);

    const title = gettext('HIGHEST_READINGS_TABLE_TITLE');

    return <ReadingsTable {...{ title, tableData }} />;
}

HighestReadingTable.propTypes = {
    dataSet: PropTypes.instanceOf(BaseDataSet).isRequired,
    setReady: PropTypes.func.isRequired,
};

export function HighestAxisReadingTable({ dataSet, setReady }) {
    const entries =
        useDataQuery(dataSet, setReady, HighestVtopPerDayQuery, 'highestVtopPerDay')?.entries || [];
    const timezone = useObservableState(dataSet.timezone$);
    if (entries.length === 0) return null;

    const tableData = entries.map((tableRow) =>
        getRowData(
            timezone,
            tableRow.timestamp,
            tableRow.axis,
            tableRow.fdom,
            tableRow.vtop,
            tableRow.percentage
        )
    );

    const title = gettext('HIGHEST_AXIS_READINGS_TABLE_TITLE');

    return <ReadingsTable {...{ title, tableData }} />;
}

HighestAxisReadingTable.propTypes = {
    dataSet: PropTypes.instanceOf(BaseDataSet).isRequired,
    setReady: PropTypes.func.isRequired,
};
