import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useObservable, useObservableState } from 'observable-hooks';
import { map, switchMap } from 'rxjs';
import { ErrorBoundary } from 'react-error-boundary';

import { MultiSensorDataSet } from '../../../graph/dataset';
import { ContentTab } from '../../../enums';
import { GraphTab } from './tabs/GraphTab';
import { DailyValuesTab } from './tabs/DailyValuesTab';
import { LdenTab } from './tabs/LdenTab';
import ErrorContainer from '../../errorContainer/ErrorContainer';

const contentTabs = [
    {
        key: ContentTab.GRAPH,
        Component: GraphTab,
        unmountable: false,
    },
    {
        key: ContentTab.DAILY_VALUES,
        Component: DailyValuesTab,
        unmountable: true,
    },
    {
        key: ContentTab.LDEN,
        Component: LdenTab,
        unmountable: true,
    },
];

function createContentTab$(dataSet) {
    return dataSet.dataTypeSettings$.pipe(map((x) => x.contentTab));
}

function DataTypeContent(props) {
    const contentTab$ = useObservable(
        (inputs$) => inputs$.pipe(switchMap(([dataSet]) => createContentTab$(dataSet))),
        [props.dataSet]
    );

    const contentTab = useObservableState(contentTab$, ContentTab.GRAPH);

    const filteredTabs = useMemo(
        () =>
            contentTabs
                // Mark the active tab.
                .map((tab) => ({ ...tab, active: contentTab === tab.key }))
                // Only keep the active tab and the ones that are not unmountable.
                .filter((tab) => tab.active || !tab.unmountable),
        [contentTab]
    );

    return filteredTabs.map(({ key, Component, active }) => (
        <ErrorBoundary key={key} fallback={<ErrorContainer />}>
            <Component active={active} {...props} />
        </ErrorBoundary>
    ));
}

DataTypeContent.propTypes = {
    dataSet: PropTypes.instanceOf(MultiSensorDataSet).isRequired,
    graphElement: PropTypes.instanceOf(Element).isRequired,
};

export default DataTypeContent;
