import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

export function GraphTab({ active, graphElement }) {
    const graphTabElementRef = useRef();

    useEffect(() => {
        if (!graphElement) {
            throw new Error('Graph to be wrapped not found.');
        }

        // Relocate the existing graph into our tab.
        const graphTabElement = graphTabElementRef.current;
        const { parent } = graphTabElementRef.current;
        graphTabElement.append(graphElement);

        // On unmount we undo the relocate.
        return () => {
            parent.append(graphTabElement);
        };
    }, [graphElement]);

    return <div ref={graphTabElementRef} className={!active ? 'hide' : null}></div>;
}
GraphTab.propTypes = {
    active: PropTypes.bool.isRequired,
    graphElement: PropTypes.instanceOf(Element).isRequired,
};
