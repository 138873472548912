import { cross } from 'd3-array';
import { line } from 'd3-shape';
import { filter, flatMap, isObject, maxBy, property, uniq } from 'lodash-es';
import moment from 'moment';
import { combineLatestWith, map, of, tap } from 'rxjs';
import truncate from '../../utils/moment-truncate';
import { EPOCH_DAY_END, EPOCH_DAY_START } from '../axis';
import {
    BaseGraphElement,
    BaseGraphElementsProvider,
    filterElementsOnAxesWithObservable,
    GraphElementPosition,
    TooltipPoint,
} from './base-provider';
import { shareReplayRefCount } from '../../utils/rxjs';

const vperAxes = ['x', 'y', 'z'];

export class LineFromTooltipPoint extends TooltipPoint {
    get x() {
        return this.position.x.from;
    }
}
export class LineToTooltipPoint extends TooltipPoint {
    get x() {
        return this.position.x.to;
    }
}

class VperPeriodGraphElementPosition extends GraphElementPosition {
    static findX(graph, element) {
        return {
            from: graph.scaleX({ timestamp: element.from }),
            to: graph.scaleX({ timestamp: element.to }),
        };
    }

    getToolTipPoints() {
        // Split the Vper period line into two points.
        return [new LineFromTooltipPoint(this), new LineToTooltipPoint(this)];
    }
}

class VperPeriodGraphElement extends BaseGraphElement {
    constructor(record, axis) {
        super(record);

        const { from, to } = this.record.timespan;
        this.from = from;
        this.to = to;
        this.key = `${from}-${to}-${axis}`;
        this.axis = axis;
    }

    get value() {
        return this.record.vper[this.axis];
    }

    get period() {
        return this.record.period;
    }

    get timezone() {
        return this.record.timezone;
    }

    static createCollectionFromRecords(records) {
        return flatMap(records, (record) => vperAxes.map((axis) => new this(record, axis)));
    }

    getPositionElementClass() {
        return VperPeriodGraphElementPosition;
    }

    getTooltipVisibleAxes() {
        return vperAxes;
    }

    // Used to efficiently copy graph elements for the day graph.
    static copyFromObjectWithoutConstructor(source) {
        return Object.assign(Object.create(this.prototype), source);
    }
}

export class VperPeriodGraphElementsProvider extends BaseGraphElementsProvider {
    static createGraphElementsObservable(dataSet, legendData) {
        const visibleAxes$ = of(['x', 'y', 'z']).pipe(
            combineLatestWith(legendData.visibleTypes$),
            map(([axes, visibleTypes]) =>
                axes.filter((axis) => visibleTypes.includes(`vper-${axis}`))
            )
        );

        return dataSet.vperPeriods$.pipe(
            map((records) => VperPeriodGraphElement.createCollectionFromRecords(records)),
            filterElementsOnAxesWithObservable(visibleAxes$),
            // Using shareReplay as this observable can serve the time graph and the veff
            // day graph simultaneously.
            shareReplayRefCount()
        );
    }

    init() {
        const renderer = this.graphElementPositions$.pipe(
            tap((graphElementPositions) => {
                this.graph.drawArea
                    .selectAll('.vperline')
                    .data(graphElementPositions, property(['element', 'key']))
                    .join((enter) =>
                        enter.append('svg:path').attr('class', (d) => `vperline ${d.axis}`)
                    )
                    .attr('d', (d) =>
                        line()([
                            // Line start x, y.
                            [d.x.from, d.y],
                            // Line end x, y.
                            [d.x.to, d.y],
                        ])
                    );
            })
        );
        this.subscription.add(renderer.subscribe());
    }

    getShouldCalculateElementPositionsObservable() {
        return this.dataSet.combinedSettings$.pipe(map((x) => x.showVperLines));
    }
}

const dateToEpoch = (timestamp, timezone) => {
    const date = moment.utc(timestamp).tz(timezone);
    return date.subtract(truncate(date, 'date').valueOf()).valueOf();
};

/**
 * Observables flow diagram: https://miro.com/app/board/uXjVPSSWJQ0=/
 */
export class VperPeriodDayGraphElementsProvider extends VperPeriodGraphElementsProvider {
    static createGraphElementsObservable(dataSet, legendData) {
        return super.createGraphElementsObservable(dataSet, legendData).pipe(
            map((periodGraphElements) => {
                const periodTypes = uniq(periodGraphElements.map(property('period')));
                const axes = uniq(periodGraphElements.map(property('axis')));

                // filterCriterias contains the Cartesian product of periodTypes and
                // axes as objects.
                const filterCriterias = cross(periodTypes, axes, (period, axis) => ({
                    period,
                    axis,
                }));

                const maxVperPeriodsPerAxes = filterCriterias
                    .map((filterCriteria) => {
                        const period = maxBy(filter(periodGraphElements, filterCriteria), 'value');

                        if (!period) {
                            return null;
                        }

                        return period;
                    })
                    .filter(isObject);

                return maxVperPeriodsPerAxes;
            }),
            map((periodGraphElements) =>
                periodGraphElements
                    .flatMap((periodGraphElement) => {
                        // Change the from and to dates into dates that are on January 1, 1970
                        // but have the same time.
                        const newProperties = {
                            ...periodGraphElement,
                            from: dateToEpoch(periodGraphElement.from, periodGraphElement.timezone),
                            to: dateToEpoch(periodGraphElement.to, periodGraphElement.timezone),
                        };

                        // Split the night period into two lines.
                        if (newProperties.from > newProperties.to) {
                            return [
                                {
                                    ...newProperties,
                                    from: EPOCH_DAY_START,
                                },
                                {
                                    ...newProperties,
                                    to: EPOCH_DAY_END,
                                },
                            ];
                        }

                        return newProperties;
                    })
                    .map((newProperties) =>
                        VperPeriodGraphElement.copyFromObjectWithoutConstructor(newProperties)
                    )
            )
        );
    }
}
