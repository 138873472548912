import { scaleLinear } from 'd3-scale';
import fetch from 'cross-fetch';
import $ from 'jquery';
import { nth, noop, upperFirst, isNil, isNull } from 'lodash-es';
import moment from 'moment';
import { hoursToMilliseconds, secondsToMilliseconds } from 'date-fns';
import {
    BehaviorSubject,
    distinctUntilChanged,
    filter,
    identity,
    map,
    Observable,
    startWith,
    Subject,
} from 'rxjs';

import delay from '../utils/delay';
import { ExtendableError } from '../utils/errors';

import { formatDatetimeTz, revertDistanceConverter } from '../utils/formatting';
import { inRange } from '../utils/range';
import { getClient } from '../utils/graphql';
import warning from '../utils/logger';

// Dirty hacking of the XMLHttpRequest prototype.
// Things using this should be converted to use fetch instead.
import '../utils/request';
import { checkResponseForHttpErrors, IgnorableHttpError, parseIncomingJson } from './http-helper';
import { toFloat } from '../utils/cast';
import { convertDistanceAndRoundToTwo, getDataType } from './data-types';
import { SwarmType } from '../enums';

const correctionDays = 2;

export const now = moment.utc().add(correctionDays, 'days');

export const yearBack = moment.utc(now).subtract(1, 'years');

export const oneDay = hoursToMilliseconds(24);

export const getYearDays = () =>
    Math.round(Math.abs((now.valueOf() - yearBack.valueOf()) / oneDay));

export const getMonthDays = () => now.daysInMonth();

export const getDayDays = () => 1;

export const getWeekDays = () => getDayDays() * 7;

export const getHourDays = () => getDayDays() / 24;

export const getMinuteDays = () => getHourDays() / 60;

// A difference of how much scrolling away from the loaded view should load new data.
export const scrollTrigger = 0.2; // Factor.

// How far zoomed in of the loaded view to load new data.
export const zoomTrigger = 0.55; // Factor.

export function setLogo(element, logoUrl) {
    element
        .selectAll('image')
        .data(logoUrl ? [logoUrl] : [])
        .join((enter) =>
            enter
                .append('image')
                .attr('class', 'graph_logo')
                .attr('x', '8px')
                .attr('y', '8px')
                .attr('width', '90px')
                .attr('height', '90px')
                .attr('opacity', '0.5')
                .attr('preserveAspectRatio', 'xMinYMin meet')
        )
        .attr('xlink:href', (d) => d);
}

export class DateText {
    constructor(node, date, timezone, x, y) {
        this.selection = node.append('text');
        this.date = date;
        this.timezone = timezone;
        this.calculateX = x;
        this.calculateY = y;

        this.update();
    }

    setDate(date) {
        this.date = date;
        this.update();
    }

    setTimezone(timezone) {
        this.timezone = timezone;
        this.update();
    }

    setCoordinate(axis, calculateFunction) {
        const value = calculateFunction(this);

        // When the coordinate calculation function returns `null`, it means
        // that it could not complete the calculation. This may occur when the
        // element is hidden. In such cases, we also do not want to reposition
        // elements.
        if (!isNull(value)) {
            this.selection.attr(axis, value);
        }
    }

    update() {
        this.selection.text(formatDatetimeTz(this.timezone, this.date));
        this.setCoordinate('x', this.calculateX);
        this.setCoordinate('y', this.calculateY);
    }
}

export class LastSeenPoller {
    dataUpdateInterval = secondsToMilliseconds(5); // ms.

    requestTimeout = secondsToMilliseconds(1); // ms.

    maxFails = 3;

    constructor(lastOnlineUrl, dataSet) {
        this.abortController = null;
        this.lastOnlineUrl = lastOnlineUrl;
        this.dataSet = dataSet;

        this.dataSet.on('updated', () => {
            this.changeSensor(this.dataSet.sensor);
        });

        this.changeSensor(this.dataSet.sensor);
    }

    changeSensor(sensor) {
        if (this.sensor === sensor) {
            return;
        }

        this.sensor = sensor;
        this.reLoading = false;
        this.lastSeen = '';
        this.fails = 0;
        if (this.dataUpdateTimer) {
            // Abort any still running connection.
            this.abortRunningRequest();
            this.stopPoller();
        }

        if (!this.sensor) {
            return;
        }

        // Start the timer for this sensor.
        this.dataUpdater();
    }

    stopPoller() {
        // Clear the current interval.
        clearInterval(this.dataUpdateTimer);
        this.dataUpdateTimer = null;
    }

    // Updates data when new data is available.
    dataUpdater() {
        if (!this.dataUpdateTimer) {
            this.dataUpdateTimer = setInterval(
                this.dataUpdater.bind(this),
                this.dataUpdateInterval
            );
        }

        // Only refresh when we are not still requesting the last seen of the
        // sensors and were not loading the data for the graph.
        if (!this.reLoading) {
            // Lock for one request.
            this.reLoading = true;

            // Set a timeout for this.
            this.intervalTimeout = setTimeout(() => {
                // Doesn't make sense to do anything if reloading is already done.
                if (!this.reLoading) return;

                // Abort the lookup.
                this.abortRunningRequest();

                // Unlock the reLoading system (will try again in x seconds).
                this.reLoading = false;
            }, this.requestTimeout);

            this.startRequest();
        }
    }

    abortRunningRequest() {
        this.abortController && this.abortController.abort();
    }

    startRequest() {
        this.abortController = new AbortController();
        const { signal } = this.abortController;

        fetch(`${this.lastOnlineUrl}?sensors=${this.sensor}`, { signal })
            .then(checkResponseForHttpErrors)
            .then(parseIncomingJson)
            .then(this.handleIncomingLastSeen.bind(this))
            .catch((e) => {
                this.clearLastSeenTimer();

                // Abort errors are expected.
                if (e.name === 'AbortError') {
                    return;
                }

                this.fails += 1;

                if (this.fails >= this.maxFails) {
                    this.stopPoller();
                    warning('Stopping last seen poller because of too many failed requests.');
                }

                // IgnorableHttpError's can be ignored, but should stop the poller.
                if (e instanceof IgnorableHttpError) {
                    return;
                }

                throw e;
            });
    }

    clearLastSeenTimer() {
        // Abort the timeout function
        clearInterval(this.intervalTimeout);

        // Mark request done (unlock)
        this.reLoading = false;
    }

    handleIncomingLastSeen(data) {
        if (this.lastSeen !== data) {
            this.lastSeen = data;
            if (this.lastSeen !== '') {
                this.lastSeenChanged();
            }
        }
        this.clearLastSeenTimer();
    }

    lastSeenChanged() {
        const dateIsLoaded = (date) =>
            inRange(date, this.dataSet.loadedMin, this.dataSet.loadedMax);

        // Only update if the old date is within vision (first time use new date).
        const date = moment.utc(this.lastSeen);

        if (dateIsLoaded(date)) {
            this.dataSet.checkDataUpdateNeeded(true);
        }
    }
}

export function castValue(value, name) {
    // Auto-cast values to their actual types.
    if (value === undefined || value === null) {
        return undefined;
    }
    if (value === 'False' || value === 'True') {
        return value === 'True';
    }

    /* eslint-disable no-param-reassign */
    if (value.includes('.') || value.includes(',')) {
        value = toFloat(value);
    } else {
        const intValue = parseInt(value, 10);

        if (!Number.isNaN(intValue)) {
            value = intValue;
        }
    }
    // Flat level requires type conversion (get_graph_line wants mm/s).
    if (['flat_level', 'atop_flat_level'].includes(name)) {
        value = revertDistanceConverter(value);
    }
    /* eslint-enable no-param-reassign */

    return value;
}

export const overrides$ = new BehaviorSubject({});

export function getOverrides() {
    return overrides$.getValue() || {};
}

// Show/hide line warnings.
export const initLineWarningHelper = ($graphContainer, data) => {
    const $categoryWarning = $graphContainer.find('#category_warning');
    const $configWarning = $graphContainer.find('#config_warning');

    // Make sure to hide this thing at first because we are still loading data.
    $configWarning.toggle(data.categoryLineOverrideError);

    data.on('updated', () => {
        $configWarning.toggle(data.categoryLineOverrideError);

        if (data.categoryLineOverrideInvalid) {
            // Make it all gray now.
            $categoryWarning.show();
            // $.addClass doesn't work on SVG elements.
            $('.dotContainer > .dot').attr('class', (index, classNames) => {
                // Don't mark it multiple times invalid.
                if (classNames.indexOf('invalid') !== -1) {
                    return classNames;
                }

                return `${classNames} invalid`;
            });
        } else {
            // Data is ok. Make sure they are not marked invalid.
            $categoryWarning.hide();
            // $.removeClass doesn't work on SVG elements.
            $('.dotContainer > .dot').attr('class', (_index, classNames) =>
                classNames.replace('invalid', '')
            );
        }
    });
};

export const getTooltipHeader = (timestamp, timezone, sensor) => {
    const formattedDate = formatDatetimeTz(timezone, timestamp);
    let tooltipText = '';
    const measuringPointName = $(`#measuring-point-selector option[value=${sensor}]`)
        .text()
        .replace(/(\r\n|\n|\r|\t)/gm, '');
    tooltipText += `${gettext('Date/Time')}: ${formattedDate}<br/>`;
    tooltipText += `${upperFirst(gettext('MEASURING_POINT'))}: ${measuringPointName}</br>`;
    return tooltipText;
};

export class QueryCanceledError extends ExtendableError {}

/**
 * Class that only allows 1 request per query at a time. When a query is
 * executed, the still in-flight request for previous query with the same
 * name will be canceled.
 *
 * Info about the watchQuery approach:
 * https://github.com/apollographql/apollo-client/issues/4150#issuecomment-487412557
 */
export class SlottedQueryManager {
    constructor() {
        this.slots = new Map();
    }

    storeSubscription(slot, subscription) {
        if (this.slots.has(slot)) {
            // Encountered a filled subscription this given slot, this
            // should not happen. Subscriptions must be canceled before
            // creating a new subscription. Theoretically, this can't
            // happen because browser JS is single threaded.
            warning(`Detected un-canceled subscription for slot ${slot}.`);
            this.cancelSubscription(slot);
        }

        this.slots.set(slot, subscription);
    }

    cancelSubscription(slot) {
        if (this.slots.has(slot)) {
            this.slots.get(slot).cancel();
            this.slots.delete(slot);
        }
    }

    execute(queryParameters) {
        let subscription;

        // Grab the operation name from the query and use that as slot key.
        const slot = queryParameters.query.definitions[0].name.value;

        // Cancel in-flight request for this slot.
        this.cancelSubscription(slot);

        const promise = new Promise((resolve, reject) => {
            const observable = getClient().watchQuery(queryParameters);
            subscription = observable.subscribe(resolve, reject);

            subscription.cancel = () => {
                if (!subscription.closed) {
                    // Let the invoker of `execute` know that we have been canceled.
                    reject(new QueryCanceledError(`Canceled ${slot} query.`));
                }
            };

            this.storeSubscription(slot, subscription);
        });

        // Clean up the subscription after this promise got resolved or rejected.
        // Unsubscribing will also cancel the request if it is still in-flight.
        promise
            // Ignore errors because we only want finally to be executed. It is
            // the task of other subscribers to this promise to act on errors.
            .catch(noop)
            .finally(() => {
                subscription.unsubscribe();
            });

        return promise;
    }
}

/**
 * Class that keeps track of whether a dataset has one or
 * multiple functions that are loading.
 */
export class LoadingStateTracker {
    constructor() {
        this.loadingCounter = new WeakMap();
    }

    getCount(dataSet) {
        return this.loadingCounter.has(dataSet) ? this.loadingCounter.get(dataSet) : 0;
    }

    setCount(dataSet, count) {
        this.loadingCounter.set(dataSet, count);
        dataSet.setLoading(count > 0);
    }

    wrapFunction(dataSet, fn) {
        return async (...args) => {
            this.setCount(dataSet, this.getCount(dataSet) + 1);

            try {
                return await fn.apply(dataSet, args);
            } catch (error) {
                if (error instanceof QueryCanceledError) {
                    return null;
                }

                throw error;
            } finally {
                this.setCount(dataSet, this.getCount(dataSet) - 1);
            }
        };
    }

    createLoadingSideEffect$(dataSet) {
        return new Observable(() => {
            this.setCount(dataSet, this.getCount(dataSet) + 1);

            return () => {
                this.setCount(dataSet, this.getCount(dataSet) - 1);
            };
        });
    }
}

export class Scales {
    constructor() {
        // This holds the custom scale per data type set by the user,
        // this is needed to restore the scale after changing the data type.
        this.customScales = new Map();
        this.updated$ = new Subject();
    }

    set(type, value, emitter = null) {
        // Store the user selected scale for this data type.
        this.customScales.set(type, value);
        this.updated$.next({ type, value, emitter });
    }

    get(type) {
        return (
            this.customScales.get(type) ??
            convertDistanceAndRoundToTwo(type, getDataType(type).valueScaleInitial)
        );
    }

    getMax(type) {
        return convertDistanceAndRoundToTwo(type, getDataType(type).valueScaleMax);
    }

    getMin(type) {
        return convertDistanceAndRoundToTwo(type, getDataType(type).valueScaleMin);
    }

    createObservableForType(dataType) {
        return this.updated$.pipe(
            startWith(null),
            map(() => this.get(dataType)),
            // The `updated` event also gets triggerd on vtop, atop, etc changes, therefore
            // we use a `distinctUntilChanged` to only react on the specific dataType changes.
            distinctUntilChanged()
        );
    }

    createMinMaxObservableForType(dataType) {
        return this.createObservableForType(dataType).pipe(
            map((currentMax) => [this.getMin(dataType), currentMax])
        );
    }
}

export const TIME_TO_RENDER = 10; // ms

export const setGraphLoadedFlag = async (promise) => {
    await promise;

    // Some time to render.
    await delay(TIME_TO_RENDER);

    // Let Chromium know we are ready.
    window.status = 'graph_loaded';
};

export const limitLine = (points, limit) =>
    points
        // Points into direction pairs.
        // point = [vkar, freq]
        // in:  [[1, 2], [12, 4], [3, 6], [4, 7]]
        // out: [[[1, 2], [12, 4]], [[12, 4], [3, 6]], [[3, 6], [4, 7]]]
        .reduce((accumulator, currentValue, index, array) => {
            const nextValue = nth(array, index + 1);

            if (nextValue) {
                accumulator.push([currentValue, nextValue]);
            }

            return accumulator;
        }, [])
        // Limit vkar's of direction pairs and return as seperate points.
        // limit: 6
        // in:  [[[1, 2], [12, 4]], [[12, 4], [3, 6]], [[3, 6], [4, 7]]]
        // out: [[1, 2], [6, 2.9], [6, 5.33], [3, 6], [3, 6], [4, 7]]
        .flatMap(([left, right]) => {
            const scale = scaleLinear().domain([left[0], right[0]]).range([left[1], right[1]]);

            const limitVkar = (point) => {
                const vkar = point[0];
                return vkar > limit ? [limit, scale(limit)] : point;
            };

            return [limitVkar(left), limitVkar(right)];
        })
        // Remove consecutive duplicates from points array.
        // in:  [[1, 2], [6, 2.9], [6, 5.33], [3, 6], [3, 6], [4, 7]]
        // out: [[1, 2], [6, 2.9], [6, 5.33], [3, 6], [4, 7]]
        .filter((element, i, array) => i === 0 || array[i - 1] !== element);

export function dataSetGuideLine(dataSet, initialGuideline) {
    return dataSet.config$.pipe(
        // Only continue when the config has a guide line and is a
        // vibration config.
        filter((config) => config && config.swarmType === SwarmType.VIBRATION),
        // Map the config to the guide line number.
        map((config) => config.guideLine),
        // This prevents things from flashing from a default setting to
        // once the configuration is known.
        isNil(initialGuideline) ? identity : startWith(initialGuideline),
        // Prevent the naming conventions from being called when scrolling
        // through the graph or switching between sensors while the guide
        // line number remains the same.
        distinctUntilChanged()
    );
}
