import { gql } from '@apollo/client';
import { map } from 'rxjs';
import { first } from 'lodash-es';
import { getClient } from '../../../utils/graphql';
import { fromObservableQuery } from '../../../utils/rxjs';

const DEVICE_FEATURES_FIELDS_FRAGMENT = gql`
    fragment SwarmFeaturesFields on SwarmFeatures {
        sensorName
        swarmType
        unavailableFeatures {
            field
            option
            showBanner
        }
    }
`;

const DEVICE_QUERY = gql`
    query featureCheckSwarm($sensorName: String!) {
        featureCheckSwarm(sensorName: $sensorName) {
            ...SwarmFeaturesFields
        }
    }
    ${DEVICE_FEATURES_FIELDS_FRAGMENT}
`;

export const featureCheckSwarm$ = (sensorName) => {
    const queryParameters = {
        variables: { sensorName },
        query: DEVICE_QUERY,
    };

    return fromObservableQuery(getClient().watchQuery(queryParameters)).pipe(
        map((result) => {
            const deviceResult = first(result.data.featureCheckSwarm);

            if (!deviceResult) {
                throw new Error(
                    `The 'featureCheckSwarm' query did not return anything for the sensor named '${sensorName}'.`
                );
            }

            return result.data.featureCheckSwarm;
        })
    );
};

const PROJECT_QUERY = gql`
    query featureCheckProject($projectId: ID!) {
        featureCheckProject(projectId: $projectId) {
            ...SwarmFeaturesFields
        }
    }
    ${DEVICE_FEATURES_FIELDS_FRAGMENT}
`;

export const featureCheckProject$ = (projectId) => {
    const queryParameters = {
        variables: { projectId },
        query: PROJECT_QUERY,
    };

    return fromObservableQuery(getClient().watchQuery(queryParameters)).pipe(
        map((result) => result.data.featureCheckProject)
    );
};
