export const minValue = 50;
export const maxValue = 140;

export function checkNumberWithinRange({ row, fieldName }) {
    const errors = {};
    const isWithinRange =
        row?.[fieldName] === 0 || (row?.[fieldName] >= minValue && row?.[fieldName] <= maxValue);

    if (!isWithinRange) {
        errors[fieldName] = interpolate(
            gettext('VALUE_SHOULD_BE_WITHIN_RANGE'),
            { minValue, maxValue },
            true
        );
    }

    return errors;
}
