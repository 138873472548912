import { useEffect, useMemo } from 'react';
import {
    createBrowserRouter,
    useLocation,
    RouterProvider,
    Outlet,
    useRouteError,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    ConvertDjangoNotifications,
    NotificationsContainer,
    NotificationsProvider,
} from '../notifications/Notifications';
import NotFoundPage from './NotFoundPage';
import { MeasuringPointRoutes, ProjectRoutes } from './measuringPoint/Routes';
import { CustomAlarmPageRoutes } from '../../custom-alarm-page';
import SharingRoutes from './sharing/SharingRoutes';
import DownloadPage from './downloadPage/DownloadPage';
import ErrorPage from './ErrorPage';
import warning from '../../utils/logger';
import profile, { Profile, ProfileProvider } from '../../profile';

// This component updates the URL in the language buttons as soon the
// React router changes URL.
const ChangeLanguageNextUrlUpdater = () => {
    const { pathname } = useLocation();

    const languageButtons = useMemo(
        () => document.querySelectorAll('.language-menu input[name=next]'),
        []
    );

    useEffect(() => {
        languageButtons.forEach((button) => {
            button.value = pathname;
        });
    }, [pathname, languageButtons]);

    return null;
};

function ErrorBoundary() {
    const error = useRouteError();
    warning(error);
    return <ErrorPage />;
}

const PageContainer = ({
    legacyCssContainer,
    createBrowserRouterMethod = createBrowserRouter,
    routerProps,
    extraRoutes = [],
    routerInjectables = [],
    profile: profileOverride,
}) => {
    const router = useMemo(
        () =>
            createBrowserRouterMethod(
                [
                    {
                        errorElement: <ErrorBoundary />,
                        element: (
                            <div>
                                <ChangeLanguageNextUrlUpdater />
                                {routerInjectables.map((injectable) => injectable)}
                                <Outlet />
                            </div>
                        ),
                        children: [
                            { path: '/measuring_point/*', element: <MeasuringPointRoutes /> },
                            { path: '/project/*', element: <ProjectRoutes /> },
                            { path: '/alarm_level/*', element: <CustomAlarmPageRoutes /> },
                            { path: '/sharing/*', element: <SharingRoutes /> },
                            { path: '/reports/downloads', element: <DownloadPage /> },
                            ...extraRoutes.map((route) => route),
                            { path: '*', element: <NotFoundPage /> },
                        ],
                    },
                ],
                routerProps
            ),
        [createBrowserRouterMethod, extraRoutes, routerProps, routerInjectables]
    );

    return (
        <ProfileProvider profile={profileOverride || profile}>
            <NotificationsProvider>
                <ConvertDjangoNotifications />
                <div className={legacyCssContainer ? '' : 'main-container-new'}>
                    <NotificationsContainer />
                </div>

                <RouterProvider router={router} />
            </NotificationsProvider>
        </ProfileProvider>
    );
};
PageContainer.propTypes = {
    legacyCssContainer: PropTypes.bool,
    createBrowserRouterMethod: PropTypes.func,
    routerProps: PropTypes.object,
    extraRoutes: PropTypes.arrayOf(PropTypes.object),
    routerInjectables: PropTypes.arrayOf(PropTypes.object),
    profile: PropTypes.instanceOf(Profile),
};

export default PageContainer;
