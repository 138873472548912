import PropTypes from 'prop-types';
import { Observable, combineLatest, firstValueFrom, from, map, of, switchMap } from 'rxjs';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useObservableState } from 'observable-hooks';
import combineEntriesWithPositions from '../utils/combine-entries-with-positions';
import renderBlocksInGraph from '../utils/render-blocks-in-graph';
import { BaseGraphPlugin } from '../base-provider';
import { ReactComponentAction } from '../actions-plugin';

import { commentCreateModal, commentDetailModal } from './modals';
import { getDataTypeKeyByValue } from '../../data-types';

function CommentActionComponent({ testId, index$, onClick }) {
    const index = useObservableState(index$);

    return (
        <button className="flex h-full items-center" data-testid={testId} onClick={onClick}>
            <FontAwesomeIcon icon={faCommentAlt} className="h-2.5 w-2.5 text-white" />
            <span className="absolute flex h-2.5 w-2.5 items-center justify-center pb-0.5 text-xxs">
                {index}
            </span>
        </button>
    );
}
CommentActionComponent.propTypes = {
    testId: PropTypes.string.isRequired,
    index$: PropTypes.instanceOf(Observable).isRequired,
    onClick: PropTypes.func.isRequired,
};

class CommentAction extends ReactComponentAction {
    constructor(comment, dataSet, index, onClick) {
        const { id } = comment;
        const key = `comment_${id}`;

        // When in report mode, keep watching the `commentsInView$` to keep track of our
        // current index. If not in reportMode leave index$ null to hide the number in the
        // action icon.
        const index$ = dataSet.reportMode
            ? dataSet.commentsInView$.pipe(map((comments) => comments.indexOf(comment) + 1))
            : of(null);

        super(key, { onClick, testId: key, index$ });

        this.id = key;
    }

    component = CommentActionComponent;

    backgroundColorClass = 'bg-black';
}

export default class CommentsPlugin extends BaseGraphPlugin {
    name = 'comments';

    async newCommentFromSelection(selection, onSuccess) {
        const partialComment = await firstValueFrom(
            combineLatest({
                measuringPointId: this.graph.data.sensor$,
                dataType: this.graph.data.dataType$.pipe(map(getDataTypeKeyByValue)),
                startTime: selection.start$,
                endTime: selection.end$,
            })
        );

        commentCreateModal(partialComment, onSuccess);
    }

    initRenderer() {
        const renderer$ = this.graph.data.commentsInView$.pipe(
            combineEntriesWithPositions(this.graph.xAxis.scaleUpdated$),
            renderBlocksInGraph(this.graph, 'comment', 'fill-gray-400/50')
        );

        this.subscription.add(renderer$.subscribe());
    }

    initActions() {
        const actionsProvider$ = this.graph.data.commentsInView$.pipe(
            map((comments) =>
                comments.map((comment, index) => {
                    const action = new CommentAction(comment, this.graph.data, index + 1, () => {
                        commentDetailModal(comment);
                    });

                    action.start$.next(comment.start$.getValue());
                    action.end$.next(comment.end$.getValue());

                    return action;
                })
            )
        );

        this.subscription.add(
            from(this.graph.getPlugin('actions'))
                .pipe(switchMap((actionsPlugin) => actionsPlugin.attachProvider$(actionsProvider$)))
                .subscribe()
        );
    }

    init() {
        this.initRenderer();
        this.initActions();
    }
}
