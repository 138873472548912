import { identity, isNil, zip, snakeCase } from 'lodash-es';
import { getFormSchema } from './schema-utils';
import { SwarmType } from '../../../enums';

function convertKeys(obj, convertFunc) {
    Object.keys(obj).forEach((key) => {
        // Skip special fields i.e. '__typename'.
        if (key.startsWith('__')) {
            return;
        }
        const newKey = convertFunc(key);
        if (newKey !== key) {
            obj[newKey] = obj[key];
            delete obj[key];
        }
    });
}

function reverseMapKeyValues(incoming) {
    return new Map(Array.from(incoming).map(([key, value]) => [value, key]));
}

// Config converter that is needed until https://github.com/omnidots/website/issues/6689 is adressed.
export class ConfigConverter {
    constructor() {
        const internalToPublicFieldOptions = new Map();
        this.internalToPublicMap = new Map([
            [
                'names',
                new Map(
                    Object.values(SwarmType)
                        // Collect all the fields per swarm type.
                        .map((swarmType) => getFormSchema(swarmType, false).fields)
                        // Merge all those fields together.
                        .flat()
                        // Create the dictionary.
                        .map((field) => {
                            if (!('internalKey' in field)) {
                                return null;
                            }

                            if ('internalOptions' in field) {
                                internalToPublicFieldOptions.set(
                                    field.internalKey,
                                    // Create a mapping between internal and public field options.
                                    new Map(zip(field.internalOptions, field.options))
                                );
                            }

                            return [field.internalKey, field.name];
                        })
                        // Filter null values.
                        .filter(identity)
                ),
            ],
            ['options', internalToPublicFieldOptions],
        ]);

        // Reverse above mapping.
        this.publicToInternalMap = new Map([
            ['names', reverseMapKeyValues(this.internalToPublicMap.get('names'))],
            [
                'options',
                new Map(
                    Array.from(this.internalToPublicMap.get('options')).map(([key, options]) => [
                        this.internalToPublicMap.get('names').get(key),
                        reverseMapKeyValues(options),
                    ])
                ),
            ],
        ]);
    }

    convert(config, mapping) {
        if (isNil(config)) {
            return config;
        }

        return Object.fromEntries(
            Object.entries(config).map(([key, value]) => [
                mapping.get('names').get(key) ?? key,
                mapping.get('options').get(key)?.get(value) ?? value,
            ])
        );
    }

    internalToPublic(config) {
        return this.convert(config, this.internalToPublicMap);
    }

    publicToInternal(config, extraFields = false) {
        const convertedConfig = this.convert(config, this.publicToInternalMap);
        // Certain fields like 'sensorPk' and 'swarmType' need to be converted as well.
        // These fields are not part of the form fields and therefore won't be
        // converted automatically. Hence, we need to handle the conversion of these
        // specific fields separately.
        if (extraFields) {
            convertKeys(convertedConfig, snakeCase);
        }
        return convertedConfig;
    }
}

export const configConverter = new ConfigConverter();
