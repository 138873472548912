import { map } from 'rxjs';
import { fromObservableQuery } from '../../../utils/rxjs';
import { getClient } from '../../../utils/graphql';
import { format } from '../../../utils/formatting';

import { MEASURING_POINT_COMMENTS_QUERY, MEASURING_POINT_COMMENT_QUERY } from './queries';

function fetch$(query, variables, isLoading$, error$) {
    const queryParameters = {
        variables,
        query,
        fetchPolicy: 'cache-and-network',
    };

    return fromObservableQuery(getClient().watchQuery(queryParameters), {
        isLoading$,
        error$,
        useInitialLoading: true,
    });
}

export function fetchComment$(id, isLoading$, error$) {
    return fetch$(MEASURING_POINT_COMMENT_QUERY, { id }, isLoading$, error$).pipe(
        map(({ data }) => data?.measuringPointComment)
    );
}

export function fetchComments$(measuringPointId, withReplies, isLoading$, error$) {
    return fetch$(
        MEASURING_POINT_COMMENTS_QUERY,
        { measuringPointId, withReplies },
        isLoading$,
        error$
    ).pipe(map(({ data }) => data?.measuringPointComments));
}

export function formatNoSeconds(date, timezone) {
    return format(date, { timezone, hideSeconds: true });
}
