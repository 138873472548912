import { useMemo } from 'react';
import $ from 'jquery';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { useObservableEagerState } from 'observable-hooks';
import { isString } from 'lodash-es';
import schema from '../schema.json';
import warning from './logger';
import i18n from '../translations/i18n';
import { language$ } from './date-fns';

const { guidelines } = schema.namingConventions;

export const { defaultGuideline } = schema.namingConventions;

const getNamingConventionsForGuideline = (guideline) => {
    const translationKey = `NAMING_CONVENTIONS.${guideline}`;

    if (!i18n.exists(translationKey)) {
        warning(`No naming conventions found for guideline: '${guideline}'`);
        return {};
    }

    // Grab the naming conventions from the currently loaded language.
    return i18n.t(translationKey, { returnObjects: true });
};

export const guideline$ = new BehaviorSubject(defaultGuideline);

export const namingConventions$ = new BehaviorSubject(
    getNamingConventionsForGuideline(defaultGuideline)
);

// Listen for updates on guideline$ and language$.
combineLatest([guideline$, language$])
    .pipe(
        // Map those changes to a naming conventions object.
        map(([guideline, _language]) => getNamingConventionsForGuideline(guideline))
    )
    // Push updates to `namingConventions$`.
    .subscribe(namingConventions$);

export const setNamingConventionsToGuideline = (guideLine) => {
    guideline$.next(guideLine);
};

const getNamingConventions = () => namingConventions$.getValue();

export const applyNamingConvention = (text) => {
    if (!getNamingConventions()) return text;

    Object.entries(getNamingConventions()).forEach(([key, replacement]) => {
        // eslint-disable-next-line no-param-reassign
        text = text.replaceAll(`~~${key}~~`, replacement);
    });

    return text;
};

/**
 * @deprecated This is a legacy method as it requires jQuery, migrate to NamingConventionSpan.
 */
export const applyNamingConventionToElement = (el) => {
    const $el = $(el);
    const contents = $el.contents();
    const text = applyNamingConvention($el.attr('naming-convention'));

    // If this element has no content, we can put the text in directly.
    if (contents.length === 0) {
        $el.text(text);
        return;
    }

    // If this element does have content, we have to replace the text per content element.
    contents.each((_i, element) => {
        if (element.nodeType === Node.TEXT_NODE) {
            element.nodeValue = text;
        }
    });
};

const guidelineNumberToName = (number) => guidelines[number];

const applyNamingConventionsToDOM = () =>
    $('[naming-convention]').each((_index, el) => applyNamingConventionToElement(el));

/**
 * @deprecated This is a legacy method as it requires jQuery, migrate to NamingConventionSpan.
 */
export const applyNamingConventionsByGuideline = (guideLine) => {
    setNamingConventionsToGuideline(guideLine);
    applyNamingConventionsToDOM();
};

/**
 * @deprecated This is a legacy method as it requires jQuery, migrate to NamingConventionSpan.
 */
export const applyNamingConventionsByGuidelineNumber = (guideLineNumber) => {
    setNamingConventionsToGuideline(guidelineNumberToName(guideLineNumber));
    applyNamingConventionsToDOM();
};

export const useNamingConventions = (inputText) => {
    const namingConventions = useObservableEagerState(namingConventions$);

    return useMemo(() => {
        if (!isString(inputText)) {
            return inputText;
        }

        return Object.entries(namingConventions).reduce(
            (currentText, [placeholder, guidelineText]) =>
                currentText.replaceAll(`~~${placeholder}~~`, guidelineText),
            inputText
        );
    }, [inputText, namingConventions]);
};
