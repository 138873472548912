export const accountMenuId = 'id_account_menu';
export const downloadsMenuId = 'id_reports_downloads';
export const languagePlaceholder = '{{LANGUAGE_CODE}}';
export const websiteUrl = '/measuring_point/';

export const languageMap = Object.freeze({
    en: 'English',
    nl: 'Nederlands',
    de: 'Deutsch',
    fr: 'Français',
});
