// Dirty hacking of the XMLHttpRequest prototype.
// Things using this should be converted to use fetch instead.
import './utils/request';

import './index.css';

import './utils/rollbar';

import $ from 'jquery';
import moment from 'moment';
import 'moment-timezone';

import { format, minutesToMilliseconds } from 'date-fns';
import { measuringPointEditPage, projectEditPage } from './measuring-point-page';
import reportEditPage from './report-edit-page';
import { reportExportPage, reportExportPageForApi, graphPreview } from './report-export-page';
import sbrPage from './sbr-page';
import tracePage from './trace-page';
import createSubscriptionPage from './create-subscription-page';

import urlConvert from './utils/urlConvert';
import warning from './utils/logger';

import {
    distanceConverter,
    getDistanceUnit,
    revertDistanceConverter,
    formatDatetime,
    formatTime,
} from './utils/formatting';
import { walkthroughModule } from './walkthrough/walkthrough';
import walkthroughPage from './walkthrough/walkthrough-page';
import { profileModule } from './profile';
import hubspotChatModule from './hubspot-chat';
import baseTemplateModule from './base-template';
import maintenanceModule from './maintenance';
import pageModule from './page-module';
import mapModule from './maps';
import notificationsModule from './notifications';
import reportTemplatesModule from './report-templates';
import accountingSubscriptionsModule from './accounting-subscriptions';
import measuringPointOverviewPage from './measuring-point-overview-page';
import createCountdownObservable from './utils/countdown';
import { confirm } from './components/confirm/Confirm';
import twoFactorModule from './two-factor';
import menuModule from './menu';
import topBannerModule from './top-banner';
import loginPageSliderModule from './login-page-slider';

const modules = {
    profile: profileModule,
    sbr_page: sbrPage,
    report_export_page: reportExportPage,
    report_export_page_for_api: reportExportPageForApi,
    graph_preview: graphPreview,
    report_edit_page: reportEditPage,
    project_edit_page: projectEditPage,
    measuring_point_edit_page: measuringPointEditPage,
    measuring_point_overview_page: measuringPointOverviewPage,
    trace_page: tracePage,
    walkthrough: walkthroughModule,
    walkthrough_page: walkthroughPage,
    map: mapModule,
    notifications: notificationsModule,
    create_subscription_page: createSubscriptionPage,
    hubspot_chat: hubspotChatModule,
    base_template: baseTemplateModule,
    report_templates: reportTemplatesModule,
    maintenance: maintenanceModule,
    accounting_subscriptions: accountingSubscriptionsModule,
    page: pageModule,
    two_factor: twoFactorModule,
    menu: menuModule,
    top_banner: topBannerModule,
    login_page_slider: loginPageSliderModule,
};

export default modules;

$(document).ready(() => {
    if (!window.modules) {
        return;
    }

    window.modules.forEach((module) => {
        if (!(module.name in modules)) {
            warning(`Module ${module.name} not found.`);
            return;
        }

        modules[module.name](module);
    });
});

$(document).ready(() => {
    // Switch language if GET parameter `language` is provided.
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const language = urlParams.get('language');
    if (document.documentElement.lang !== language) {
        $(`form.${language}`).submit();
    }

    $('.datetime').each((_index, el) => {
        // Converts the date inside the `datetime` data attribute of all `.datetime`
        // elements into the browser its timezone, then replaces the specified target
        // with the timezone corrected date.

        const $el = $(el);
        const datetime = $el.data('datetime');
        const target = $el.data('datetime-target');

        if (!datetime) {
            return;
        }

        const dateFormatted = formatDatetime(moment.utc(datetime));

        if (target === 'title') {
            $el.prop('title', dateFormatted);
            return;
        }

        if (target === 'text') {
            $el.text(dateFormatted);
        }
    });

    // This is for Resend Token Timer.
    const resendChallengeTimerEl = document.getElementById('resend_challenge_timer');
    if (resendChallengeTimerEl) {
        createCountdownObservable(minutesToMilliseconds(1)).subscribe({
            next: (remaining) => {
                resendChallengeTimerEl.innerHTML = format(remaining, 'mm:ss');
            },
            complete: () => {
                document.getElementById('helper_info').style.display = 'none';
                document.getElementById('resend_link').style.display = 'inline';
            },
        });
    }

    // Temporary fix to bring back the auto focus on the search field when opening
    // a select2 dropdown.
    // Bug issue: https://github.com/select2/select2/issues/5993
    // Source: https://github.com/KRRUg/KLMS/pull/72/commits/63c47fbb544a3382915511c360b1c71893aef245
    $(document).on('select2:open', ({ target: { id } }) => {
        const searchField = document.querySelector(
            `.select2-search__field[aria-controls="select2-${id}-results"]`
        );
        if (searchField) {
            searchField.focus();
        }
    });
});

// Make some functions available to the global scope to keep not converted pages working.
window.$ = $;
window.jQuery = $;
window.urlConvert = urlConvert;
window.moment = moment;
window.our_confirm = confirm;

window.format_time = formatTime;
window.format_datetime = formatDatetime;
window.distance_converter = distanceConverter;
window.revert_distance_converter = revertDistanceConverter;
window.get_distance_unit = getDistanceUnit;
