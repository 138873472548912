import { isRegExp } from 'lodash-es';

/**
 * Simpeler version on the `Array.includes` but allows for matching
 * on regex expressions.
 *
 * @param {Array} collection
 * @param {string} value
 */
export const collectionIncludes = (collection, value) =>
    !!collection.find((test) => {
        if (isRegExp(test)) {
            return test.test(value);
        }

        return test === value;
    });

/**
 * Reverse of `collectionIncludes`
 *
 * @param {Array} collection
 * @param {string} value
 */
export const collectionNotIncludes = (collection, value) => !collectionIncludes(collection, value);
