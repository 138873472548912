import { useMemo } from 'react';
import { isEmpty, range } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import { useObservableState } from 'observable-hooks';

import { nextMonday, nextSunday, add, addWeeks } from 'date-fns';
import { defaultPropTypes, useWatchObservable } from '../utils';
import schema from '../../../schema.json';
import LdenCalendar from '../../ldenCalendar/LdenCalendar';
import { getUnitWithFrequencyWeighting } from '../../../graph/graph-elements/sound-plugin/unit-formatter';

const epoch = new Date(0);
const utcTimezone = 'Etc/UTC';

const templateStart = nextSunday(epoch);

function getDuration(days, time) {
    const timeInt = parseInt(time, 10);
    const hours = timeInt / 100;
    const minutes = timeInt % 100;

    return { days, hours, minutes };
}

function extendTemplate(template, repeat) {
    return range(repeat).flatMap((week) =>
        template.map((timeWindow) => {
            const offset = week * 7;
            const newStartDay = timeWindow.startDay + offset;
            let newEndDay = timeWindow.endDay + offset;

            // Handle the case where the endDay should roll over to the next week.
            if (timeWindow.endDay < timeWindow.startDay) {
                newEndDay += 7;
            }

            return {
                ...timeWindow,
                startDay: newStartDay,
                endDay: newEndDay,
            };
        })
    );
}

function LdenGuidelinePreviewField() {
    const { watch } = useFormContext();

    const ldenGuideline$ = useWatchObservable(watch, 'ldenGuidelineChoiceType');
    const ldenGuideline = useObservableState(ldenGuideline$);

    const frequencyWeighting$ = useWatchObservable(watch, 'frequencyWeighting');
    const frequencyWeighting = useObservableState(frequencyWeighting$);

    const currentDate = useMemo(() => new Date(), []);

    const unit = useMemo(() => {
        const config = frequencyWeighting
            ? {
                  frequency_weighting: frequencyWeighting,
              }
            : {};

        return getUnitWithFrequencyWeighting(config);
    }, [frequencyWeighting]);

    const template = useMemo(
        () => (ldenGuideline ? extendTemplate(schema.ldenGuidelines[ldenGuideline], 3) : []),
        [ldenGuideline]
    );

    const data = useMemo(
        () =>
            template.map((timeWindow) => ({
                timeWindow,
                value: null,
                timeSpan: {
                    from: add(
                        templateStart,
                        getDuration(timeWindow.startDay, timeWindow.startTime)
                    ),
                    to: add(templateStart, getDuration(timeWindow.endDay, timeWindow.endTime)),
                },
            })),
        [template]
    );

    if (isEmpty(template)) {
        return null;
    }

    return (
        <LdenCalendar
            data={data}
            // By extending the template for 3 weeks and setting the view start date
            // to the second week, we ensure that any time windows starting before or
            // ending after the initial week are covered. This prevents empty spots at
            // the beginning and end of the displayed week.
            inViewStartDate={addWeeks(nextMonday(templateStart), 1)}
            dayStartOffset={7}
            timezone={utcTimezone}
            unit={unit}
            currentDate={currentDate}
        />
    );
}
LdenGuidelinePreviewField.propTypes = defaultPropTypes;

export default LdenGuidelinePreviewField;
