import { find, matches, isNull, matchesProperty } from 'lodash-es';

import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import unfrozenSchema from '../../../schema.json';
import { useReplaceDistanceUnit } from '../../../utils/formatting';
import { useProfile } from '../../../profile';
import deepFreeze from '../../../utils/deepFreeze';

const schema = deepFreeze(unfrozenSchema);

export const timezoneToGraphQLEnum = (value) =>
    value?.replaceAll('/', '_')?.replaceAll('-', 'Minus')?.replaceAll('+', 'Plus');

class FieldsArray extends Array {
    getByName(name) {
        const found = this.find(matchesProperty('name', name));

        if (!found) {
            throw new Error(`No field found named: '${name}'.`);
        }

        return found;
    }

    removeByName(name) {
        const index = this.indexOf(this.getByName(name));
        this.splice(index, 1);
    }

    replaceByName(name, field) {
        const index = this.indexOf(this.getByName(name));
        this.splice(index, 1, field);
    }

    insertAfter(name, field) {
        const index = this.indexOf(this.getByName(name));
        this.splice(index + 1, 0, field);
    }
}

export const getFormSchema = (swarmType, isProject) => {
    const isMatch = matches({ swarmType, isProject });

    const formSchema = find(schema.forms, (s) => isMatch(s.properties))?.schema;

    if (!formSchema) {
        throw new Error(`No form schema found for swarm:${swarmType} isProject:${isProject}.`);
    }

    return Object.freeze({
        ...formSchema,
        fields: Object.freeze(FieldsArray.from(formSchema.fields)),
    });
};

export const useTranslatedFormSchema = (swarmType, isProject) => {
    const { t, i18n } = useTranslation();
    const profile = useProfile();
    const replaceDistanceUnit = useReplaceDistanceUnit();

    return useMemo(() => {
        const formSchema = getFormSchema(swarmType, isProject);

        const fields = formSchema.fields.map((field) => {
            const fieldPrefix = `${formSchema.translationPrefix}__FIELD__${field.name}`;

            const translatedItems = {
                label: replaceDistanceUnit(t(`${fieldPrefix}__LABEL`)),
                help_text: replaceDistanceUnit(t(`${fieldPrefix}__HELP_TEXT`)),
            };

            const placeholderKey = `${fieldPrefix}__PLACEHOLDER`;
            if (i18n.exists(placeholderKey)) {
                translatedItems.placeholder = t(placeholderKey);
            }

            if ('options' in field) {
                translatedItems.options = new Map(
                    field.options.map((optionKey) => [
                        optionKey,
                        t(`${fieldPrefix}__OPTION__${isNull(optionKey) ? 'None' : optionKey}`),
                    ])
                );
            }

            if ('checkboxes' in field) {
                translatedItems.checkboxes = new Map(
                    field.checkboxes.map((checkboxKey) => [
                        checkboxKey,
                        t(`${fieldPrefix}__CHECKBOX__${checkboxKey}`),
                    ])
                );
            }

            return Object.freeze({
                ...field,
                ...translatedItems,
            });
        });

        fields.replaceByName(
            'timezone',
            Object.freeze({
                ...fields.getByName('timezone'),
                default: timezoneToGraphQLEnum(profile.preferredTimezone),
            })
        );

        const information = Object.fromEntries(
            formSchema.information.map((key) => [
                key,
                t(`${formSchema.translationPrefix}__INFO__${key}`),
            ])
        );

        const addInGroupTitle = ([name, childGroup]) => [
            name,
            t(`${formSchema.translationPrefix}__LAYOUT__${name}`),
            Array.isArray(childGroup) ? childGroup.map(addInGroupTitle) : childGroup,
        ];

        const layout = formSchema.layout.map(addInGroupTitle);

        return Object.freeze({
            ...formSchema,
            fields: Object.freeze(fields),
            layout: Object.freeze(layout),
            information: Object.freeze(information),
        });
    }, [t, swarmType, isProject, i18n, replaceDistanceUnit, profile.preferredTimezone]);
};
