import { useFormContext } from 'react-hook-form';
import { useObservableState } from 'observable-hooks';
import { useTranslation } from 'react-i18next';
import { defaultPropTypes, useWatchObservable } from '../utils';
import { BaseFormFieldContainer } from './FormFieldContainer';
import MaskedInput from '../../form/MaskedInput';

function WifiPasswordField({ fieldOptions }) {
    const { watch } = useFormContext();
    const { name } = fieldOptions;
    const { t } = useTranslation();
    const deviceWifiPassword$ = useWatchObservable(watch, 'sensor.wifiPassword');
    const wifiPassword = useObservableState(deviceWifiPassword$);

    if (!wifiPassword) {
        // Hide the WIFI password field if the value is null (no sensor attached)
        // or when it is '' (no password set for sensor).
        return null;
    }

    return (
        <BaseFormFieldContainer name={name} label={t('WiFi password of your SWARM')}>
            <MaskedInput id={`id_${name}`} value={wifiPassword} type="text" readOnly={true} />
        </BaseFormFieldContainer>
    );
}
WifiPasswordField.propTypes = defaultPropTypes;

export default WifiPasswordField;
