import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import Button from '../form/Button';

function hideBanner(cookieName, setShowBanner) {
    Cookies.set(cookieName, 1, { expires: 365 });
    setShowBanner(false);
}

function TopBanner({ text, buttonText, buttonUrl, cookieName }) {
    const [showBanner, setShowBanner] = useState(!(Cookies.get(cookieName) === '1'));

    const handleClick = useCallback(() => {
        hideBanner(cookieName, setShowBanner);
        window.open(buttonUrl);
    }, [buttonUrl, cookieName]);

    const handleClose = useCallback(() => {
        hideBanner(cookieName, setShowBanner);
    }, [cookieName]);

    if (!showBanner) {
        return null;
    }

    return (
        <div className="flex min-h-20 w-full items-center justify-evenly bg-banner-green px-6 text-sm font-black text-white md:px-[15%] md:text-lg">
            <div>{text}</div>
            <Button
                variant="outlineButton"
                onClick={() => handleClick(buttonUrl)}
                className="!py-2 px-4"
            >
                {buttonText}
            </Button>
            <button
                className="absolute right-6"
                onClick={handleClose}
                aria-label={gettext('CLOSE')}
            >
                <FontAwesomeIcon icon={faXmark} />
            </button>
        </div>
    );
}
TopBanner.propTypes = {
    text: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonUrl: PropTypes.string.isRequired,
    cookieName: PropTypes.string.isRequired,
};

export default TopBanner;
