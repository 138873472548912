import { line } from 'd3-shape';
import {
    asyncScheduler,
    combineLatest,
    combineLatestWith,
    distinctUntilChanged,
    identity,
    map,
    observeOn,
    of,
    pipe,
    switchMap,
} from 'rxjs';
import { distanceConverter } from '../../utils/formatting';
// Dirty hacking of the XMLHttpRequest prototype.
// Things using this should be converted to use fetch instead.
import '../../utils/request';
import { fitVkarToFreqLineIntoRange } from '../spectrum';
import { logarithmicScaleDomain } from '../axis';
import { BaseGraphPlugin } from './base-provider';
import { limitLine } from '../graph-helpers';
import { SCALES_TYPE } from '../data-types';

class BaseAlarmLinesProvider extends BaseGraphPlugin {
    extraShouldRenderCheck() {
        return identity;
    }

    extraLineModifications() {
        return identity;
    }

    drawFlatLine() {
        return false;
    }

    init() {
        const lines$ = this.graph.data.dataTypeSettings$.pipe(
            map(({ showGuideLineLines }) => showGuideLineLines),
            this.extraShouldRenderCheck(),
            distinctUntilChanged(),
            switchMap((shouldRender) =>
                shouldRender ? this.graph.data.categoryAndAlarmLines$ : of([])
            ),
            this.extraLineModifications()
        );

        const drawLine$ = combineLatest([
            this.graph.xAxis.scaleUpdated$,
            this.graph.yAxis.scaleUpdated$,
        ]).pipe(
            // Since the scale can be updated by changing scaleType$ and the outcome of
            // lines$ also depends on the last scaleType$ value, we now have to wait until
            // all subscribers on scaleType$ got updated.
            observeOn(asyncScheduler),
            map(() =>
                line()
                    .x((d) =>
                        this.drawFlatLine()
                            ? // For the time graph a flat line spanning the whole graph is
                              // sufficient.
                              this.graph.xAxis.scale(
                                  d[1] === 0 ? this.graph.xAxis.getMin() : this.graph.xAxis.getMax()
                              )
                            : this.graph.xAxis.scale(d[1])
                    )
                    .y((d) => this.graph.yAxis.scale(distanceConverter(d[0])))
            )
        );

        const renderer = combineLatest([lines$, drawLine$]).subscribe(([lines, drawLine]) => {
            // Render lines.
            this.graph.drawArea
                .selectAll('.catLine, .alarmLine')
                .data(lines)
                .join('svg:path')
                .attr('class', (d) =>
                    d.type === 'alarm' ? `alarmLine alarmLine${d.alarmIndex}` : 'catLine usedLine'
                )
                .attr('d', (d) => drawLine(d.line));
        });

        this.subscription.add(renderer);
    }
}

export class ValueTimeGraphAlarmLinesProvider extends BaseAlarmLinesProvider {
    drawFlatLine() {
        return true;
    }

    extraShouldRenderCheck() {
        return pipe(
            switchMap((shouldRender) =>
                // The time chart can only draw flat lines. So if the data type indicates
                // that the alarm lines could be drawn, we also need to know whether the
                // alarm line is even a flat line at all.
                shouldRender ? this.graph.data.categoryLineIsFlat$ : of(false)
            )
        );
    }
}

export class ValueFrequencyGraphAlarmLinesProvider extends BaseAlarmLinesProvider {
    extraLineModifications() {
        return pipe(
            combineLatestWith(this.graph.xAxis.scaleType$),
            combineLatestWith(this.graph.data.dataTypeSettings$),
            map(([[lines, scaleType], dataTypeSettings]) => {
                const makeLineFitScale =
                    scaleType === SCALES_TYPE.LOGARITHMIC
                        ? // For the logarithmic scale type we must modify
                          // the line to start from 1 and end at 250.
                          // If the line starts at 0 we get `Nan` errors when interpolating
                          // the screen locations. If the line does not end at 100, its just ugly.
                          (l) => fitVkarToFreqLineIntoRange(l, logarithmicScaleDomain.x)
                        : // Do nothing when scale type is linear.
                          identity;

                return lines.map((l) => {
                    const limit = dataTypeSettings.valueScaleMax;
                    return {
                        ...l,
                        line: limitLine(makeLineFitScale(l.line), limit * 1.2),
                    };
                });
            })
        );
    }
}
