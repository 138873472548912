import PropTypes from 'prop-types';
import $ from 'jquery';
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { round, isNull, isNil, isEqual } from 'lodash-es';

import useEventListener from '@use-it/event-listener';
import { distanceConverter } from '../utils/formatting';
import { castValue } from './graph-helpers';
import { postData } from './http-helper';
import AlarmLinesGraph, { createEmptyAlarmPoints } from './alarm-lines-graph';
import { toFloat } from '../utils/cast';

const handleExpandCollapseGraph = () => {
    $('.fa-arrows').toggleClass('fa-arrow_grey fa-arrow_black');
    $('.alarm_container').toggleClass('collapse_graph_container expand_graph_container');
};

const graphMinimize = () => {
    $('.graph_view').hide();
    $('.graph_open_btn').show();
};

const measurementSettingSelector =
    '#swarm_settings_measurement input, #swarm_settings_measurement select';

function getSettingsFromPage() {
    return Object.fromEntries(
        Array.from(document.querySelectorAll(measurementSettingSelector)).map((element) => [
            element.name,
            castValue(element.value, element.name),
        ])
    );
}

async function getLines(config, record, getLineUrl) {
    return postData(getLineUrl, { config, record });
}

const initializeAlarm = (alarmLevelIndex) => ({
    points: createEmptyAlarmPoints(),
    alarmLevelIndex,
});

const useCategoryWatcher = (getLineUrl) => {
    const [state, setState] = useState(initializeAlarm());

    const inputs = useMemo(() => document.querySelectorAll(measurementSettingSelector), []);

    const lastSettings = useRef(null);

    const collectSettings = useCallback(async () => {
        const settings = getSettingsFromPage();

        if (isEqual(settings, lastSettings.current)) {
            // No need to request a new line.
            return;
        }

        lastSettings.current = settings;

        const response = await getLines({}, settings, getLineUrl);

        if (isNull(response)) {
            return;
        }

        setState((currentState) => ({
            ...currentState,
            points: isNil(response.line)
                ? createEmptyAlarmPoints()
                : response.line.map(([vkar, freq]) => ({
                      freq,
                      vkar: vkar && round(distanceConverter(vkar), 2),
                  })),
        }));
    }, [getLineUrl]);

    useEffect(() => {
        inputs.forEach((input) => input.addEventListener('change', collectSettings));

        return () => {
            inputs.forEach((input) => input.removeEventListener('change', collectSettings));
        };
    }, [inputs, collectSettings]);

    // React to project updates.
    useEventListener('projectUpdated', collectSettings);

    // Trigger once manually.
    useEffect(collectSettings, [collectSettings]);

    return state;
};

const useAlarmLevelWatcher = (alarmLevelIndex, category) => {
    const input = useMemo(
        () => document.querySelector(`#id_alarm_level_${alarmLevelIndex}`),
        [alarmLevelIndex]
    );

    // Minus 1 as the alarm level color CSS classes start at 0.
    const [state, setState] = useState(initializeAlarm(alarmLevelIndex - 1));

    const updateState = useCallback(() => {
        const percentageLevel = 100 - toFloat(input.value);

        setState((currentState) => ({
            ...currentState,
            points: category.points.map(({ freq, vkar }) => ({
                freq,
                vkar: isNull(vkar) ? null : vkar - (percentageLevel / 100) * vkar,
            })),
        }));
    }, [category, input]);

    // Trigger once manually.
    useEffect(updateState, [updateState]);

    // React to alarm level updates.
    useEventListener('input', updateState, input);

    return state;
};

const AlarmPreviewGraph = ({ getLineUrl }) => {
    const alarm = useCategoryWatcher(getLineUrl);
    const alarmStateOne = useAlarmLevelWatcher(1, alarm);
    const alarmStateTwo = useAlarmLevelWatcher(2, alarm);
    const alarmStateThree = useAlarmLevelWatcher(3, alarm);

    return (
        <div className="graph_view">
            <div className="heading_alarm_level">
                <h1>{gettext('ALARM_LEVELS')}</h1>
                <div>
                    <button
                        title={gettext('EXPAND_COLLAPSE_GRAPH')}
                        className="expandGraph"
                        onClick={handleExpandCollapseGraph}
                    >
                        <i className="fa fa-arrows fa-arrow_black"></i>
                    </button>
                    <button
                        title={gettext('MINIMIZE_GRAPH')}
                        className="close-btn-graph"
                        onClick={graphMinimize}
                    >
                        <i className="fa fa-angle-down"></i>
                    </button>
                </div>
            </div>
            <AlarmLinesGraph alarms={[alarm, alarmStateOne, alarmStateTwo, alarmStateThree]} />
        </div>
    );
};
AlarmPreviewGraph.propTypes = {
    getLineUrl: PropTypes.string.isRequired,
};

export default AlarmPreviewGraph;
