import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useObservableState } from 'observable-hooks';
import { useQuery } from '@apollo/client';
import { MultiSensorDataSet } from '../../../../graph/dataset';
import { WeekPicker, useThisWeekForTimezone } from '../../../ldenCalendar/weekPicker/WeekPicker';
import { getClient } from '../../../../utils/graphql';
import LdenCalendar from '../../../ldenCalendar/LdenCalendar';
import { ldenValuesQuery } from '../../../../graph/queries';
import { getSoundUnit$ } from '../../../../graph/graph-elements/sound-plugin/unit-formatter';

export function LdenTab({ dataSet }) {
    const timezone = useObservableState(dataSet.timezone$);
    const measuringPointId = useObservableState(dataSet.sensor$);
    const measuringPointName = useObservableState(dataSet.name$);
    const unit = useObservableState(getSoundUnit$(dataSet));
    const events = useObservableState(dataSet.events$);
    const currentDate = useMemo(() => new Date(), []);

    const [range, setRange] = useThisWeekForTimezone(timezone);
    const queryVariables = useMemo(
        () => ({
            measuringPointId,
            startTime: range.startDate.valueOf(),
            endTime: range.endDate.valueOf(),
        }),
        [measuringPointId, range]
    );

    const { loading, error, data } = useQuery(ldenValuesQuery, {
        client: getClient(),
        variables: queryVariables,
        // Only query when we have a measuring point ID.
        skip: !measuringPointId,
    });

    return (
        <>
            <div className="flex justify-end p-2">
                <WeekPicker range={range} onChange={setRange} timezone={timezone} />
            </div>

            <div className="p-2">
                {error ? (
                    <p>{gettext('ERROR')}</p>
                ) : (
                    <LdenCalendar
                        data={data?.ldenValues?.values || []}
                        inViewStartDate={range.startDate}
                        dayStartOffset={7}
                        timezone={timezone}
                        loading={loading}
                        unit={unit}
                        events={events}
                        measuringPointName={measuringPointName}
                        currentDate={currentDate}
                    />
                )}
            </div>
        </>
    );
}

LdenTab.propTypes = {
    dataSet: PropTypes.instanceOf(MultiSensorDataSet).isRequired,
};
