import PropTypes from 'prop-types';
import { useObservableState } from 'observable-hooks';
import { useMemo } from 'react';
import { BaseDataSet } from '../../../graph/dataset';
import { Comment } from '../../../graph/graph-elements/comments-plugin/models';
import { formatNoSeconds } from '../../../graph/graph-elements/comments-plugin/utils';
import { PageBreak } from '../../utils';

export function ThreadItem({ comment }) {
    const createdAt = useObservableState(comment.createdAt$);
    const text = useObservableState(comment.text$);
    const timezone = useObservableState(comment.collection.dataSet.timezone$);

    const time = useMemo(() => formatNoSeconds(createdAt, timezone), [createdAt, timezone]);

    return (
        <tr>
            <td className="w-1/2 border border-black p-2">
                {time} - {comment.user.username}
            </td>
            <td className="w-1/2 border border-black p-2">{text}</td>
        </tr>
    );
}
ThreadItem.propTypes = {
    comment: PropTypes.instanceOf(Comment).isRequired,
};

export function ReportComment({ comment, index }) {
    const start = useObservableState(comment.start$);
    const end = useObservableState(comment.end$);
    const timezone = useObservableState(comment.collection.dataSet.timezone$);
    const thread = useObservableState(comment.thread$, []);

    const title = useMemo(() => {
        const f = (date) => formatNoSeconds(date, timezone);
        return interpolate(
            gettext('REPORT_COMMENT_TITLE'),
            { index, start: f(start), end: f(end) },
            true
        );
    }, [index, start, end, timezone]);

    return (
        <div>
            <div className="w-full border border-b-0 border-black p-2">{title}</div>

            <table className="w-full border-collapse border border-t-0 border-black">
                <thead>
                    <tr>
                        <th className="p-2">{gettext('REPORT_COMMENT_DATE_NAME')}</th>
                        <th className="p-2">{gettext('REPORT_COMMENT_MESSAGE')}</th>
                    </tr>
                </thead>
                <tbody className="">
                    {thread.map((childComment) => (
                        <ThreadItem key={childComment.id} comment={childComment} />
                    ))}
                </tbody>
            </table>
        </div>
    );
}
ReportComment.propTypes = {
    comment: PropTypes.instanceOf(Comment).isRequired,
    index: PropTypes.number.isRequired,
};

export function ReportComments({ dataSet }) {
    const comments = useObservableState(dataSet.commentsInView$);

    if (!comments) {
        return <div>{gettext('LOADING')}</div>;
    }

    if (comments.length === 0) {
        return null;
    }

    return (
        <>
            <PageBreak />
            <ol className="flex flex-col space-y-4">
                {comments.map((comment, index) => (
                    <li key={comment.id}>
                        <ReportComment comment={comment} index={index + 1} />
                    </li>
                ))}
            </ol>
        </>
    );
}
ReportComments.propTypes = {
    dataSet: PropTypes.instanceOf(BaseDataSet).isRequired,
};
