// This allows for extending the Error class and make it print the
// extended error name in the stack, and not the generic Error.
// https://stackoverflow.com/a/32749533/937477
export class ExtendableError extends Error {
    constructor(message) {
        super(message);
        this.name = this.constructor.name;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error(message).stack;
        }
    }
}

export class NotImplementedError extends ExtendableError {}
