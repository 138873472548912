import { confirm } from './components/confirm/Confirm';

const twoFactorModule = ({ options: { unregisterMessage } }) => {
    const confirmUnregisterForm = document.getElementById('confirm-unregister-form');
    const confirmUnregisterSubmit = document.getElementById('confirm-unregister-submit');

    if (confirmUnregisterSubmit) {
        confirmUnregisterSubmit.addEventListener('click', (e) => {
            e.preventDefault();
            confirm({
                message: unregisterMessage,
                onConfirm: () => {
                    confirmUnregisterForm.submit();
                },
            });
        });
    }
};

export default twoFactorModule;
