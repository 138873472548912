import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Observable } from 'rxjs';
import { useHandleFileTypeExports } from './utils';
import ExportContext from './ExportContext';
import ExportChoices from './ExportChoices';
import ExportDatePicker from './ExportDatePicker';
import { Scales } from '../../../graph/graph-helpers';
import { MultiSensorDataSet } from '../../../graph/dataset';
import StatusPopup from '../../statusPopup/StatusPopup';
import { LegendData } from '../../../graph/legend-helpers';

const ExportButtons = ({ dataSet, graphType$, scales, legendData, frequencyGraphScaleType$ }) => {
    const exportActions = useHandleFileTypeExports(
        dataSet,
        graphType$,
        scales,
        legendData,
        frequencyGraphScaleType$
    );
    const {
        isDatePickerOpen,
        showStatusPopup,
        setShowStatusPopup,
        downloadFiles,
        updateLastDownloadMutation,
    } = exportActions;

    const statusPopupNode = document.getElementById('status-popup');

    return (
        <ExportContext.Provider value={exportActions}>
            {isDatePickerOpen ? <ExportDatePicker /> : <ExportChoices />}
            {showStatusPopup &&
                ReactDOM.createPortal(
                    <StatusPopup
                        onClose={() => {
                            setShowStatusPopup(false);
                        }}
                        files={downloadFiles}
                        updateLastDownloadMutation={updateLastDownloadMutation}
                    />,
                    statusPopupNode
                )}
        </ExportContext.Provider>
    );
};

export default ExportButtons;

ExportButtons.propTypes = {
    dataSet: PropTypes.instanceOf(MultiSensorDataSet).isRequired,
    graphType$: PropTypes.instanceOf(Observable).isRequired,
    scales: PropTypes.instanceOf(Scales).isRequired,
    legendData: PropTypes.instanceOf(LegendData).isRequired,
    frequencyGraphScaleType$: PropTypes.instanceOf(Observable).isRequired,
};
