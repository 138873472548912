import ReactDOM from 'react-dom';

import { Menu } from './components/menu/Menu';
import profile from './profile';

const menuModule = ({ options: { elementId } }) => {
    ReactDOM.render(<Menu profile={profile} />, document.getElementById(elementId));
};

export default menuModule;
