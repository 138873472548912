function formatTime(hours, minutes, suffix) {
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}${
        suffix ? ` ${suffix}` : ''
    }`;
}

export function to12h(time) {
    const r = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/.exec(time);
    // Returns time if already in 12h format.
    if (!r) {
        return time;
    }

    const h = parseInt(r[1], 10);
    const m = parseInt(r[2], 10);

    return formatTime(h % 12 === 0 ? 12 : h % 12, m, h < 12 ? 'AM' : 'PM');
}

export function to24h(time) {
    const r = /^(1[0-2]|0?[1-9]):([0-5]?[0-9])[ ]*?([AP]M)$/i.exec(time);
    // Returns time if already in 24h format.
    if (!r) {
        return time;
    }

    const h = parseInt(r[1], 10);
    const m = parseInt(r[2], 10);

    return formatTime((h % 12) + 12 * (r[3].toLowerCase() === 'pm'), m);
}
