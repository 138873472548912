import { BehaviorSubject, EMPTY, Subject, firstValueFrom, map, of, switchMap, tap } from 'rxjs';
import { differenceBy, intersectionBy, isArray, matchesProperty } from 'lodash-es';
import {
    Collection,
    shareReplayRefCount,
    withSideEffect,
    createMutationObservable,
} from '../../../utils/rxjs';
import { fetchComments$, fetchComment$ } from './utils';
import { PERMISSION } from '../../../components/pages/sharing/utils';
import { getClient } from '../../../utils/graphql';
import { DELETE_COMMENT_MUTATION, UPDATE_MEASURING_POINT_COMMENT_MUTATION } from './queries';
import { NotImplementedError } from '../../../utils/errors';

export class Comment {
    constructor(collection, id, data) {
        this.collection = collection;
        this.id = id;
        this.dataType = data.dataType;
        this.measuringPointId = data.measuringPointId;

        this.user = {};
        this.text$ = new BehaviorSubject();
        this.start$ = new BehaviorSubject(data.startTime);
        this.end$ = new BehaviorSubject(data.endTime);
        this.createdAt$ = new BehaviorSubject();
        this.editedAt$ = new BehaviorSubject();

        this.editIsLoading$ = new BehaviorSubject(false);
        this.editError$ = new BehaviorSubject();
        this.isEditing$ = new BehaviorSubject();
        this.editText$ = new BehaviorSubject('');

        this.deleted$ = new Subject();

        // Sync updates to text onto the edit text.
        this.text$.subscribe(this.editText$);

        // eslint-disable-next-line no-use-before-define
        this.replyCollection = new ReplyCollection(this.collection.dataSet, this.id);

        // The thread observable is just all the replies plus the source comment prepended to it.
        this.thread$ = this.replyCollection.entries$.pipe(map((entries) => [this].concat(entries)));

        this.syncWithApi(data);
    }

    setEditing(editing) {
        this.isEditing$.next(editing);
    }

    setEditText(text) {
        this.editText$.next(text);
    }

    syncWithApi(data) {
        this.user = data.user;
        this.text$.next(data.text);

        this.start$.next(data.startTime);
        this.end$.next(data.endTime);
        this.createdAt$.next(data.createdAt);
        this.editedAt$.next(data.editedAt);

        if (isArray(data.replies)) {
            this.replyCollection.syncWithApi(data.replies);
        }
    }

    setStart(start) {
        this.start$.next(start);
    }

    setEnd(end) {
        this.end$.next(end);
    }

    setState(state) {
        this.state$.next(state);
    }

    async delete() {
        const success = await firstValueFrom(
            createMutationObservable(getClient(), {
                variables: { id: this.id },
                mutation: DELETE_COMMENT_MUTATION,

                update: (
                    cache,
                    {
                        data: {
                            deleteMeasuringPointComment: { measuringPointComment },
                        },
                    }
                ) => {
                    cache.evict({
                        id: cache.identify(measuringPointComment),
                    });
                },
            })
        );

        if (success) {
            this.deleted$.next();
        }
    }

    async saveEdit() {
        const editText = await firstValueFrom(this.editText$);

        const success = await firstValueFrom(
            createMutationObservable(
                getClient(),
                {
                    variables: {
                        id: this.id,
                        text: editText,
                    },
                    mutation: UPDATE_MEASURING_POINT_COMMENT_MUTATION,
                },
                this.editIsLoading$,
                this.editError$
            )
        );

        if (success) {
            this.setEditing(false);
        }
    }
}

class BaseCommentsCollection extends Collection {
    constructor(dataSet) {
        super();

        this.isLoading$ = new BehaviorSubject(false);
        this.error$ = new BehaviorSubject();

        this.dataSet = dataSet;
    }

    get prefetchReplies() {
        return this.dataSet.reportMode;
    }

    add(comment) {
        return super.add(new Comment(this, comment.id, comment));
    }

    syncWithApi(data) {
        // // Identify new comments from API.
        const newComments = differenceBy(data, this._entries$.getValue(), 'id');
        newComments.forEach((comment) => this.add(comment));

        // Identify comments to remove.
        const commentsToRemove = differenceBy(this._entries$.getValue(), data, 'id');
        commentsToRemove.forEach((comment) => this.delete(comment));

        // Identify comments to update.
        const commentsToUpdate = intersectionBy(data, this._entries$.getValue(), 'id');
        commentsToUpdate.forEach((comment) => {
            const existingComment = this._entries$
                .getValue()
                .find(matchesProperty('id', comment.id));
            existingComment.syncWithApi(comment);
        });
    }

    get entries$() {
        return this._entries$.pipe(withSideEffect(this.onSubscribe$()), shareReplayRefCount());
    }

    onSubscribe$() {
        throw NotImplementedError();
    }
}

class ReplyCollection extends BaseCommentsCollection {
    constructor(dataSet, parentId) {
        super(dataSet);

        this.id = parentId;
    }

    onSubscribe$() {
        if (this.prefetchReplies) {
            // We don't have to fetch the comments of measuring point comments
            // if the measuring point comments already prefetched those. Prefetching
            // happens in reports.
            return EMPTY;
        }

        return fetchComment$(this.id, this.isLoading$, this.error$).pipe(
            tap((data) => {
                // Sync incoming data with collection.
                this.syncWithApi(data?.replies);
            })
        );
    }
}

export class MeasuringPointCommentsCollection extends BaseCommentsCollection {
    constructor(...args) {
        super(...args);

        this.isLoading$
            .pipe(
                switchMap((loading) => (loading ? this.dataSet.createLoadingSideEffect$() : EMPTY))
            )
            .subscribe();
    }

    onSubscribe$() {
        return this.dataSet.matchesPermission$(PERMISSION.FULL_ACCESS).pipe(
            switchMap((hasFullAccess) => {
                // If the user does not have full access, leave this collection
                // empty and do not fetch the comments.
                if (!hasFullAccess) {
                    return of([]);
                }

                // Fetch the comments.
                // TODO: Add retry mechanism.
                return this.dataSet.sensor$.pipe(
                    switchMap((id) =>
                        fetchComments$(id, this.prefetchReplies, this.isLoading$, this.error$)
                    )
                );
            }),
            tap((data) => {
                // Sync incoming data with collection.
                this.syncWithApi(data);
            })
        );
    }
}
