import { createContext, useContext, useMemo, useState } from 'react';
import { compact, isEmpty, isArray, isNumber, isNil } from 'lodash-es';
import PropTypes from 'prop-types';
import { FormContext } from './utils';
import InputField from './fields/InputField';
import ChoiceField from './fields/ChoiceField';
import BooleanField from './fields/BooleanField';
import InformationTooltip from './InformationTooltip';
import { useConfigHelperUpdated } from '../pages/measuringPoint/configHelper';

const formFieldComponents = {
    char: InputField,
    integer: InputField,
    float: InputField,
    choice: ChoiceField,
    boolean: BooleanField,
};

const useGroupHiddenByConfigHelper = (fieldNames) => {
    const [hidden, setHidden] = useState(false);

    useConfigHelperUpdated(fieldNames, (availableFields) => {
        const shouldBeVisibile = fieldNames.some((fieldName) => fieldName in availableFields);
        setHidden(!shouldBeVisibile);
    });

    return hidden;
};

function RootGroupContainer({ children }) {
    return (
        <div className="odd:bg-main-background-odd">
            <div className="main-container-new mx-auto py-10">{children}</div>
        </div>
    );
}
RootGroupContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const FormFieldOverridesContext = createContext({});

// Form field component that will find the best suiting form field component
// for the given form field type in the settings.
// Also allows for custom form field component overrides by form field name.
export function FormFieldLookup({ name, settings, formFieldOverrides }) {
    const FormField = formFieldOverrides?.[name] ?? formFieldComponents[settings.type];

    if (!FormField) {
        throw new Error(`Could not find field component for ${name} of type ${settings.type}`);
    }

    return (
        <FormField
            fieldOptions={{
                name,
                settings,
            }}
        />
    );
}
FormFieldLookup.propTypes = {
    name: PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    formFieldOverrides: PropTypes.objectOf(PropTypes.func.isRequired),
};

function Group({ group: { name, title, fields, groups }, level = 2 }) {
    const { information } = useContext(FormContext);
    const formFieldOverrides = useContext(FormFieldOverridesContext);

    const hidden = useGroupHiddenByConfigHelper(useMemo(() => Object.keys(fields), [fields]));

    // We don't show empty groups.
    if (isEmpty(groups) && isEmpty(fields)) {
        return null;
    }

    const Level = `h${level}`;

    if (hidden) {
        return null;
    }

    const isRootLevel = level === 2;
    const Container = isRootLevel ? RootGroupContainer : 'div';

    return (
        <Container>
            {title && (
                <Level className={isRootLevel ? 'mb-3.5 font-bold' : ''}>
                    {title}
                    {information?.[name] && (
                        <InformationTooltip
                            information={information[name]}
                            className="ml-2 align-middle"
                        />
                    )}
                </Level>
            )}
            {groups
                ? groups.map((group) => <Group key={group.name} group={group} level={level + 1} />)
                : Object.entries(fields).map(([key, value]) => (
                      <FormFieldLookup
                          key={key}
                          name={key}
                          settings={value}
                          formFieldOverrides={formFieldOverrides}
                      />
                  ))}
        </Container>
    );
}

const groupShape = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    fields: PropTypes.object,
};
groupShape.groups = PropTypes.arrayOf(PropTypes.shape(groupShape));

Group.propTypes = {
    group: PropTypes.shape(groupShape),
    level(props, propName, componentName) {
        const given = props[propName];
        if (!isNil(given) && (!isNumber(given) || given < 2)) {
            return new Error(
                `Invalid prop \`${propName}\` supplied to` +
                    ` \`${componentName}\`. \`${propName}\`` +
                    ` should be 2 or more, but got \`${given}\`.`
            );
        }
        return null;
    },
};

export class LayoutHelper {
    constructor(layout, fields) {
        this.layout = layout;
        this.fields = fields;
    }

    getFieldsStartingAt(name) {
        function mapItems([groupName, groupTitle, groupContent]) {
            if (Array.isArray(groupContent)) {
                return groupContent.flatMap(mapItems);
            }
            return [[groupContent, groupName, groupTitle]];
        }

        const objectLayout = Object.fromEntries(
            this.layout
                .flatMap(mapItems)
                .map(([groupContent, groupName, groupTitle]) => [
                    groupContent,
                    [groupName, groupTitle],
                ])
        );

        let state = 0;
        return Object.fromEntries(
            compact(
                this.fields.map((field) => {
                    // state 0: We have not found the field entry of this group yet
                    // state 1: We are outputting all entry's we find.
                    // state 2: We found a field belonging to another group, stop outputting.
                    if (state === 1 && Object.keys(objectLayout).includes(field.name)) {
                        state = 2;
                    }
                    if (state === 2 || (state === 0 && field.name !== name)) {
                        return null;
                    }
                    if (state === 0) {
                        state = 1;
                    }
                    return [field.name, field];
                })
            )
        );
    }

    createTree() {
        const convertGroup = ([name, title, content]) => {
            if (isArray(content)) {
                return {
                    name,
                    title,
                    groups: content.map((group) => convertGroup(group)),
                };
            }

            return {
                name,
                title,
                fields: this.getFieldsStartingAt(content),
            };
        };

        const treeWithoutFields = this.layout.map((group) => convertGroup(group));

        const collectFieldsInGroupRecursive = (group) => {
            const fieldsInGroups = group.groups
                ? group.groups.map((childGroup) => collectFieldsInGroupRecursive(childGroup))
                : [];

            return Object.assign({}, group?.fields ?? {}, ...fieldsInGroups);
        };

        const addFieldsWhenMissing = (group) => ({
            ...group,
            ...(!group.fields ? { fields: collectFieldsInGroupRecursive(group) } : {}),
            ...(group.groups ? { groups: group.groups.map(addFieldsWhenMissing) } : {}),
        });

        return treeWithoutFields.map(addFieldsWhenMissing);
    }
}

function Layout({ layout, fields, formFieldOverrides }) {
    const treeWithFields = useMemo(() => {
        const layoutHelper = new LayoutHelper(layout, fields);
        return layoutHelper.createTree();
    }, [layout, fields]);

    return (
        <FormFieldOverridesContext.Provider value={formFieldOverrides}>
            {treeWithFields.map((group) => (
                <Group key={group.name} group={group} />
            ))}
        </FormFieldOverridesContext.Provider>
    );
}
Layout.propTypes = {
    layout: PropTypes.array.isRequired,
    fields: PropTypes.array.isRequired,
    formFieldOverrides: PropTypes.objectOf(PropTypes.func.isRequired),
};

export default Layout;
