import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { BehaviorSubject } from 'rxjs';

export const branch$ = new BehaviorSubject();
export const siteTitle$ = new BehaviorSubject();

const generateTitle = (pageTitle) =>
    `${branch$.getValue()}: ${pageTitle} | ${siteTitle$.getValue()}`;

function BasePage(props) {
    useEffect(() => {
        // Change the page title when this component mounts.
        document.querySelector('title').text = generateTitle(props.title);
    }, [props.title]);

    return (
        <>
            <div className={`${!props.legacyCssContainer && 'main-container-new'} flex pb-4 pt-5`}>
                <h1 className="my-0 grow text-2xl font-light leading-[26px] after:mt-[22px] after:block after:w-[60px] after:border-b-4 after:border-primary">
                    {props.title}
                    {props.beta && (
                        <span className="ml-3 after:rounded after:border after:border-black after:px-1 after:py-0.5 after:align-middle after:text-[9px] after:uppercase after:content-['beta']"></span>
                    )}
                </h1>
                {props.besideTitle && <div className="flex-none">{props.besideTitle}</div>}
            </div>
            <>{props.children}</>
        </>
    );
}
BasePage.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    besideTitle: PropTypes.element,
    beta: PropTypes.bool,
    children: PropTypes.node.isRequired,
    legacyCssContainer: PropTypes.bool,
};

export default BasePage;
