import PropTypes from 'prop-types';

const NotificationBadge = ({ number = 0 }) => (
    <span
        className="relative -top-2.5 inline-flex h-5 w-5 items-center justify-center rounded-full bg-warning text-white before:content-[attr(data-number)] data-[number=0]:hidden"
        aria-label="notification-badge"
        id="download-notification-bullet"
        data-number={number}
    />
);

NotificationBadge.propTypes = {
    number: PropTypes.number,
    id: PropTypes.string,
};

export default NotificationBadge;
