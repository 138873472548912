import $ from 'jquery';

import { Form, FormInformation, ProjectOverrides, MultiField } from './forms';
import { findBestMatchingDataTypeForConfig, getDataType } from './graph/data-types';

export default (module) => {
    const {
        showProjectOverrideCheckboxes,
        overriddenFields,
        projectReport,
        formInformation,
        sensorConfig,
    } = module.options;

    // Make top save button click-able.
    $('#button-save-top').on('click', () => {
        $('#save-form').click();
    });

    const form = new Form($('form.form'));

    // Create multi fields.
    const multiFields = [];
    const dataType = findBestMatchingDataTypeForConfig(sensorConfig) ?? getDataType('vtop');

    ['daily', 'weekly'].forEach((interval) => {
        // Add a beta group for the non default templates.
        $(`#id_${interval}-report_template option[value!=""]`).wrapAll(
            '<optgroup label="Beta"></optgroup>'
        );

        const multiField = new MultiField(`#id_${interval}-email_addresses`);
        multiField.create();
        multiFields.push(multiField);

        const fixedScaleField = form.getField(`${interval}-fixed_y_range_max`);
        const scaleEquationField = form.getField(`${interval}-y_range_max_equation`);

        // Make the fixed scale field only appear when needed.
        fixedScaleField.container.wrap('<div></div>');

        scaleEquationField
            .onValueChange((val) => {
                fixedScaleField.container.toggle(val === 'fixed');
            })
            .input.trigger('change');

        fixedScaleField.container
            .children('label')
            .text((index, text) => `${text} (${dataType.unit})`);
    });
    const formInfo = new FormInformation(form, formInformation);
    formInfo.applyInfoIcons();

    // Load project overrides.
    if (!showProjectOverrideCheckboxes) {
        return;
    }

    const projectData = {};

    Object.entries(projectReport).forEach(([intervalKey, intervalData]) => {
        Object.entries(intervalData).forEach(([fieldKey, fieldValue]) => {
            projectData[`${intervalKey}-${fieldKey}`] = fieldValue;
        });
    });

    const projectOverrides = new ProjectOverrides(form, overriddenFields, projectData, multiFields);
    projectOverrides.override();
};
