import Rollbar from 'rollbar';

import { get, matchesProperty, overEvery } from 'lodash-es';

// eslint-disable-next-line import/no-mutable-exports
let rollbar = null;

if (typeof window !== 'undefined' && window.rollbarConfig) {
    const isApolloIgnoredError = overEvery(
        matchesProperty('body.trace.exception.class', 'ApolloError'),
        (payload) =>
            get(payload, 'body.trace.exception.description', '').startsWith('Network error:')
    );

    rollbar = new Rollbar({
        ...window.rollbarConfig,
        checkIgnore(_isUncaught, _args, payload) {
            // Since we have already sent a more detailed version of a network work
            // error in the error handler of the Apollo Client ourselves, we want to
            // stop error propagation. Since it is currently not possible in the
            // Apollo Client to ignore network issues, we filter them here and prevent
            // them from being sent.
            return isApolloIgnoredError(payload);
        },
        scrubFields: [
            ...(window.rollbarConfig.scrubFields || []), // Preserve existing scrubFields
            'auth-password', // Scrub 'auth-password' fields
        ],
    });

    window.Rollbar = rollbar;
}

export default rollbar;
