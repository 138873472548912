/* eslint-disable no-console */
XMLHttpRequest.prototype.failCount = 0;
XMLHttpRequest.prototype.maxFailCount = 5;

XMLHttpRequest.prototype.request = function request(url, readyFunction, failFunction, data) {
    this.abort();
    this.onreadystatechange = function onreadystatechange() {
        // only readyState 4 (request done) and status should be 200
        if (this.readyState === 4) {
            if (this.status === 200) {
                if (this.getResponseHeader('Content-Type') === 'application/json') {
                    readyFunction(this.responseText);
                }
                // call the fail handler is set
                else if (failFunction) {
                    failFunction(url);
                } else {
                    console.error('We got redirected from', url, 'to', this.responseURL);
                }
            }
            // retry some times
            // Using eslint-disable-next-line as this is old code that is being phased out.
            // eslint-disable-next-line no-plusplus
            else if (this.failCount++ < this.maxFailCount) {
                this.request(url, readyFunction, failFunction);
            }
            // call the fail handler is set
            else if (failFunction) {
                failFunction(url);
            }
            // print error
            else {
                console.error("Couldn't get a proper response for", url);
            }
        }
    };
    // open the request
    this.open(data ? 'POST' : 'GET', url, true); // true for asynchronous
    this.setRequestHeader('Content-type', 'application/json; charset=utf-8');
    this.send(data ? JSON.stringify(data) : null);
};

XMLHttpRequest.prototype.newRequest = function newRequest(url, readyFunction, failFunction, data) {
    this.failCount = 0;
    this.request(url, readyFunction, failFunction, data);
};
