import { useQuery, gql, useMutation, NetworkStatus } from '@apollo/client';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DOWNLOAD_STATUS } from '../../enums';
import { updateReadyReportsCount } from '../../utils';

const GRAPH_REPORTS_FRAGMENT = gql`
    fragment GraphReportsTypeFields on GraphReportType {
        id
        url
        name
        lastDownloaded
        status
        size
        category
    }
`;

const GRAPH_REPORTS = gql`
    query graphReports(
        $orderBy: String
        $before: String
        $after: String
        $first: Int
        $last: Int
        $ids: [ID]
    ) {
        graphReports(
            orderBy: $orderBy
            before: $before
            after: $after
            first: $first
            last: $last
            ids: $ids
        ) {
            readyReportsCount
            totalCount
            edges {
                node {
                    ...GraphReportsTypeFields
                }
            }
            pageInfo {
                startCursor
                endCursor
            }
        }
    }
    ${GRAPH_REPORTS_FRAGMENT}
`;

const UPDATE_LAST_DOWNLOAD = gql`
    mutation updateGraphReportLastDownloaded($id: ID!) {
        updateGraphReportLastDownloaded(id: $id) {
            graphReport {
                ...GraphReportsTypeFields
            }
        }
    }
    ${GRAPH_REPORTS_FRAGMENT}
`;

const createAfterCursor = (currentPage, pageSize) => {
    if (currentPage === 1) {
        return null;
    }

    // The cursor is just an base64 encoded string containing the
    // offset counted from zero.
    return btoa(`arrayconnection:${pageSize * (currentPage - 1) - 1}`);
};

const createQueryVariables = ({ currentPage, pageSize, orderBy }) => ({
    first: pageSize,
    after: createAfterCursor(currentPage, pageSize),
    orderBy,
});

const pollInterval = 5000;

export default function useGraphReportQueryGenerator(orderAndPaginationControl) {
    const [graphReports, setGraphReports] = useState([]);

    const queryVariables = useMemo(
        () => createQueryVariables(orderAndPaginationControl),
        [orderAndPaginationControl]
    );

    const isPolling = useRef(false);

    const { data, loading, startPolling, stopPolling, networkStatus, refetch } = useQuery(
        GRAPH_REPORTS,
        {
            variables: queryVariables,
            fetchPolicy: 'network-only',
        }
    );

    const [updateLastDownloadMutation] = useMutation(UPDATE_LAST_DOWNLOAD, {
        onCompleted: () => {
            refetch();
        },
    });

    useEffect(() => {
        if (data) {
            updateReadyReportsCount(data.graphReports?.readyReportsCount);
            setGraphReports(data.graphReports?.edges ?? []);
            orderAndPaginationControl.setTotalCount(data.graphReports?.totalCount);
        }
    }, [data, orderAndPaginationControl]);

    useEffect(() => {
        const shouldBePolling = graphReports.some(
            (edge) => edge.node.status === DOWNLOAD_STATUS.IN_PROGRESS
        );

        if (shouldBePolling) {
            if (isPolling.current) {
                return;
            }
            startPolling(pollInterval);
        } else {
            if (!isPolling.current) {
                return;
            }
            stopPolling();
        }

        isPolling.current = shouldBePolling;
    }, [graphReports, startPolling, stopPolling]);

    const downloadFile = useCallback(
        (id, url) => {
            window.open(url, '_self');
            updateLastDownloadMutation({ variables: { id } });
        },
        [updateLastDownloadMutation]
    );

    return {
        graphReports,
        downloadFile,
        loading: loading && networkStatus === NetworkStatus.loading,
        updating: loading && networkStatus === NetworkStatus.setVariables,
    };
}
