import { gql } from '@apollo/client';

export const MEASURING_POINT_COMMENT_FRAGMENT = gql`
    fragment MeasuringPointCommentFields on MeasuringPointCommentType {
        id
        text
        user {
            id
            username
        }
        startTime
        endTime
        createdAt
        editedAt
        measuringPointId
        parentId
        dataType
    }
`;

export const MEASURING_POINT_COMMENTS_QUERY = gql`
    query measuringPointComments($measuringPointId: ID!, $withReplies: Boolean!) {
        measuringPointComments(measuringPointId: $measuringPointId) {
            ...MeasuringPointCommentFields
            replies @include(if: $withReplies) {
                ...MeasuringPointCommentFields
            }
        }
    }
    ${MEASURING_POINT_COMMENT_FRAGMENT}
`;

export const MEASURING_POINT_COMMENT_QUERY = gql`
    query measuringPointComment($id: ID!) {
        measuringPointComment(id: $id) {
            ...MeasuringPointCommentFields
            replies {
                ...MeasuringPointCommentFields
            }
        }
    }
    ${MEASURING_POINT_COMMENT_FRAGMENT}
`;

export const CREATE_MEASURING_POINT_COMMENT_MUTATION = gql`
    mutation (
        $measuringPointId: ID!
        $text: String!
        $dataType: DataType!
        $startTime: BigInteger!
        $endTime: BigInteger
        $parentId: ID
    ) {
        createMeasuringPointComment(
            measuringPointId: $measuringPointId
            text: $text
            dataType: $dataType
            startTime: $startTime
            endTime: $endTime
            parentId: $parentId
        ) {
            measuringPointComment {
                ...MeasuringPointCommentFields
            }
        }
    }
    ${MEASURING_POINT_COMMENT_FRAGMENT}
`;

export const UPDATE_MEASURING_POINT_COMMENT_MUTATION = gql`
    mutation ($id: ID!, $text: String!) {
        updateMeasuringPointComment(id: $id, text: $text) {
            measuringPointComment {
                ...MeasuringPointCommentFields
            }
        }
    }
    ${MEASURING_POINT_COMMENT_FRAGMENT}
`;

export const DELETE_COMMENT_MUTATION = gql`
    mutation ($id: ID!) {
        deleteMeasuringPointComment(id: $id) {
            measuringPointComment {
                ...MeasuringPointCommentFields
            }
        }
    }
    ${MEASURING_POINT_COMMENT_FRAGMENT}
`;
