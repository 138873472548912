import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import TRANSLATIONS_DE from './de.json';
import TRANSLATIONS_EN from './en.json';
import TRANSLATIONS_FR from './fr.json';
import TRANSLATIONS_NL from './nl.json';

i18n.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',
    resources: {
        de: {
            translation: TRANSLATIONS_DE,
        },
        en: {
            translation: TRANSLATIONS_EN,
        },
        fr: {
            translation: TRANSLATIONS_FR,
        },
        nl: {
            translation: TRANSLATIONS_NL,
        },
    },
});

export default i18n;
