import { forwardRef, useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import Input from './Input';

const MaskedInput = forwardRef(({ type, ...props }, ref) => {
    const [show, setShow] = useState(false);
    const toggleShow = useCallback(() => {
        setShow((current) => !current);
    }, [setShow]);

    return (
        <div className={`relative inline-block w-full text-form-text`}>
            <Input ref={ref} {...props} type={show ? type : 'password'} autoComplete="off" />
            <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center px-4"
                onClick={toggleShow}
                title={show ? gettext('HIDE') : gettext('SHOW')}
            >
                <FontAwesomeIcon
                    icon={show ? faEyeSlash : faEye}
                    className="h-4 w-4 fill-current"
                />
            </button>
        </div>
    );
});

MaskedInput.propTypes = Input.propTypes;

MaskedInput.displayName = 'MaskedInput';

export default MaskedInput;
