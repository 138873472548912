import { useContext, useState, createContext, useEffect, useCallback, useRef } from 'react';
import { get, isArray, isEqual, isNull, isObject } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Observable, distinctUntilChanged, identity, map, pipe, switchMap } from 'rxjs';
import { useObservable } from 'observable-hooks';
import testSome from '../../utils/testSome';
import { NOTIFICATION_VARIANT, useNotifications } from '../notifications/Notifications';

// This is our own form context. Not the `useFormContext` from react-form-hook.
export const FormContext = createContext();

export const FormFieldContext = createContext(null);

export const defaultPropTypes = {
    fieldOptions: PropTypes.shape({
        name: PropTypes.string.isRequired,
        settings: PropTypes.object.isRequired,
    }).isRequired,
};

export const useIsOverridable = (name) => {
    const { parentValues, projectIgnoreFields } = useContext(FormContext);

    // Not overridable when there is no parentValues loaded.
    if (isNull(parentValues)) {
        return false;
    }

    // Not overridable when this field is in the ignore list.
    return !(projectIgnoreFields && testSome(projectIgnoreFields, name));
};

export const useIsOverridden = (name) => {
    const { watch, getValues } = useFormContext();
    const getValue = useCallback(() => getValues(`override.${name}`), [name, getValues]);

    const [overridden, setOverridden] = useState(getValue());

    useEffect(() => {
        const subscription = watch((_values, { name: nameOfUpdated }) => {
            if (nameOfUpdated === `override.${name}`) {
                setOverridden(() => getValue());
            }
        });
        return subscription.unsubscribe;
    }, [watch, getValue, name]);

    return overridden;
};

/**
 * Converts a structure like:
 * {
 *     logFlushInterval: false,
 *     dustSampleTime: true
 * }
 * Into an array of enabled field names:
 * [
 *     'dustSampleTime'
 * ]
 */
export function extractEnabledFields(fields) {
    if (!isObject(fields)) {
        return [];
    }

    return Object.keys(fields).filter((key) => fields[key]);
}

export const useWatchNestedField = (watch, field, defaultValue) => {
    const [fieldValue, setFieldValue] = useState(defaultValue);

    useEffect(() => {
        const subscription = watch((values, { name }) => {
            if (name.startsWith(field)) {
                setFieldValue(() => ({ ...values[field] }));
            }
        });
        return subscription.unsubscribe;
    }, [watch, field]);

    return fieldValue;
};

export const NON_FIELD_ERRORS = 'non_field_errors';

export const ErrorToNotificationsLink = () => {
    const { formState } = useFormContext();

    const previousNoneFieldErrors = useRef([]);

    const { addNotification, clearNotifications } = useNotifications();

    useEffect(() => {
        const noneFieldErrors = formState.errors?.[NON_FIELD_ERRORS] ?? [];

        if (!isEqual(noneFieldErrors, previousNoneFieldErrors.current)) {
            clearNotifications();

            isArray(noneFieldErrors) &&
                noneFieldErrors.forEach((error) => {
                    addNotification({
                        message: error.message,
                        variant: NOTIFICATION_VARIANT.ERROR,
                    });
                });

            previousNoneFieldErrors.current = noneFieldErrors;
        }
    }, [formState, addNotification, clearNotifications]);

    return null;
};

function fromReactFormHookWatch(watch) {
    return new Observable((subscriber) => {
        const subscription = watch((values) => {
            subscriber.next(values);
        });
        return subscription.unsubscribe;
    });
}

export function useWatchObservable(watch, field = null) {
    return useObservable(
        (inputs$) =>
            inputs$.pipe(
                switchMap(([w]) => fromReactFormHookWatch(w)),
                field
                    ? pipe(
                          map((values) => get(values, field)),
                          distinctUntilChanged()
                      )
                    : identity
            ),
        [watch]
    );
}
