import { forwardRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const base = 'no-underline ';
const baseVariant = `${base} px-10 py-3.5 no-underline `;
const baseDownloadButton = `${base} px-6 py-1 rounded-lg text-sm`;

export const VARIANT_CLASSES = {
    base,
    primary: `${baseVariant} bg-primary rounded`,
    secondary: `${baseVariant} bg-secondary rounded`,
    exportButton: `${baseVariant} bg-primary hover:bg-button-hover rounded-none`,
    downloadButton: `${baseDownloadButton} bg-primary`,
    downloadButtonSecondary: `${baseDownloadButton} bg-secondary`,
    outlineButton: `${baseVariant} hover:bg-white hover:text-black border-white border rounded`,
};

const Button = forwardRef(({ variant, flex, className, ...props }, ref) => {
    const ButtonOrAnchor = useMemo(() => (props.href ? 'a' : 'button'), [props.href]);
    return (
        <ButtonOrAnchor
            ref={ref}
            className={`${VARIANT_CLASSES[variant]} ${flex ? 'flex-1' : ''} ${className || ''}`}
            {...props}
        />
    );
});

Button.propTypes = {
    variant: PropTypes.oneOf(Object.keys(VARIANT_CLASSES)),
    href: PropTypes.string,
    className: PropTypes.string,
    flex: PropTypes.bool,
};

Button.defaultProps = {
    variant: 'primary',
};

Button.displayName = 'Button';

export default Button;

const roundedButtonVariant = {
    default: 'text-xs px-7 py-1.5',
    icon: 'text-xs px-3 py-1.5 flex items-center gap-x-0.5',
    big: 'px-8 py-1.5',
};

const roundedButtonColor = {
    primary: 'bg-primary',
    secondary: 'bg-white border border-primary',
};

export function RoundedButton({
    children,
    variant = 'default',
    color = 'primary',
    className,
    ...args
}) {
    return (
        <Button
            {...args}
            variant="base"
            className={`rounded-full text-black ${roundedButtonColor[color]} ${roundedButtonVariant[variant]} ${className}`}
        >
            {children}
        </Button>
    );
}
RoundedButton.propTypes = {
    children: PropTypes.node,
    variant: PropTypes.oneOf(Object.keys(roundedButtonVariant)),
    color: PropTypes.oneOf(Object.keys(roundedButtonColor)),
    className: PropTypes.string,
};

export const ButtonContainer = ({ children }) => <div className="flex space-x-2">{children}</div>;
ButtonContainer.propTypes = {
    children: PropTypes.node,
};

export const IconButton = ({ ariaLabel, onClick, icon, className, ...props }) => (
    <button type="button" onClick={onClick} aria-label={ariaLabel} {...props}>
        <FontAwesomeIcon icon={icon} className={className} />
    </button>
);

IconButton.propTypes = {
    ariaLabel: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.object.isRequired,
    className: PropTypes.string,
};
