import PropTypes from 'prop-types';

const BetaBadge = ({ className = '' }) => (
    <span
        className={`inline-block select-none rounded border border-solid border-menu-color px-1 py-0.5 text-xs uppercase text-menu-color after:content-['beta'] ${className}`}
    />
);
BetaBadge.propTypes = {
    className: PropTypes.string,
};

export default BetaBadge;
