import PropTypes from 'prop-types';

const Pagination = ({ orderAndPaginationControl: { pageCount, currentPage, setCurrentPage } }) => {
    const barStyles =
        'border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700';

    const disabledButtonStyle = 'bg-gray-100';

    return (
        <div className="inline-flex justify-end -space-x-px py-1">
            <button
                disabled={currentPage === 1}
                onClick={() => setCurrentPage((current) => current - 1)}
                className={`ml-0 rounded-l-lg ${barStyles} ${
                    currentPage === 1 && disabledButtonStyle
                }`}
            >
                {gettext('PREVIOUS')}
            </button>
            <label className={barStyles}>
                {interpolate(gettext('PAGE_NUMBER'), [currentPage, pageCount])}
            </label>
            <button
                disabled={currentPage === pageCount}
                onClick={() => setCurrentPage((current) => current + 1)}
                className={`rounded-r-lg ${barStyles} ${
                    currentPage === pageCount && disabledButtonStyle
                }`}
            >
                {gettext('NEXT')}
            </button>
        </div>
    );
};

Pagination.propTypes = {
    orderAndPaginationControl: PropTypes.object.isRequired,
};

export default Pagination;
