import { confirm } from './components/confirm/Confirm';
import getDeleteMessage from './utils/messages';

const reportTemplatesModule = () => {
    const handleClick = (event) => {
        event.preventDefault();
        const target = event.currentTarget;

        const templateIsUsed = target.dataset.isUsed === 'True';
        const templateName = target.parentElement.parentElement.querySelector(
            `[data-label=${gettext('NAME')}]`
        ).textContent;

        const message = templateIsUsed
            ? interpolate(gettext('TEMPLATE_REVERT_MESSAGE'), [templateName])
            : getDeleteMessage(templateName);

        confirm({
            message,
            convertMarkdown: true,
            onConfirm: () => {
                window.location.href = target.getAttribute('href');
            },
        });
    };

    const reportTemplateDeleteIcons = document.querySelectorAll(
        '.report-templates .template-options > a[data-is-used]'
    );
    reportTemplateDeleteIcons.forEach((deleteIcon) => {
        deleteIcon.addEventListener('click', handleClick);
    });
};

export default reportTemplatesModule;
