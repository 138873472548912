import { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { isFunction } from 'lodash-es';
import {
    MEASURING_POINT_COMMENT_FRAGMENT,
    MEASURING_POINT_COMMENTS_QUERY,
    CREATE_MEASURING_POINT_COMMENT_MUTATION,
} from './queries';
import { getClient } from '../../../utils/graphql';

export const useCreateComment = (onSuccess = null) => {
    const [newCommentText, setNewCommentText] = useState('');

    const [createCommentMutation, { loading, error }] = useMutation(
        CREATE_MEASURING_POINT_COMMENT_MUTATION,
        {
            update: (
                cache,
                {
                    data: {
                        createMeasuringPointComment: { measuringPointComment },
                    },
                },
                { variables: { parentId } }
            ) => {
                const parentCommentRef = cache.identify({
                    __typename: measuringPointComment.__typename,
                    id: parentId,
                });

                if (parentId && parentCommentRef) {
                    // Update replies for an existing comment.
                    cache.modify({
                        id: parentCommentRef,
                        fields: {
                            replies(existingReplies = []) {
                                const newCommentRef = cache.writeFragment({
                                    data: measuringPointComment,
                                    fragment: MEASURING_POINT_COMMENT_FRAGMENT,
                                });
                                return [...existingReplies, newCommentRef];
                            },
                        },
                    });
                } else {
                    // Add new top-level comment to the measuringPointComments query.
                    const { measuringPointId } = measuringPointComment;
                    const measuringPointCommentsQuery = cache.readQuery({
                        query: MEASURING_POINT_COMMENTS_QUERY,
                        variables: {
                            measuringPointId: parseInt(measuringPointId, 10),
                            withReplies: false,
                        },
                    });

                    if (
                        measuringPointCommentsQuery &&
                        measuringPointCommentsQuery.measuringPointComments
                    ) {
                        cache.writeQuery({
                            query: MEASURING_POINT_COMMENTS_QUERY,
                            variables: {
                                measuringPointId: parseInt(measuringPointId, 10),
                                withReplies: false,
                            },
                            data: {
                                measuringPointComments: [
                                    ...measuringPointCommentsQuery.measuringPointComments,
                                    {
                                        ...measuringPointComment,
                                        replies: [],
                                    },
                                ],
                            },
                        });
                    }
                }
            },
            client: getClient(),
            onCompleted: ({ createMeasuringPointComment: { measuringPointComment } }) => {
                if (isFunction(onSuccess)) {
                    onSuccess(measuringPointComment);
                }
                setNewCommentText('');
            },
        }
    );

    const createComment = useCallback(
        async (partialComment) => {
            const trimmedText = newCommentText.trim();

            if (!trimmedText.trim()) {
                return;
            }

            await createCommentMutation({
                variables: {
                    ...partialComment,
                    text: trimmedText,
                },
            });
        },
        [createCommentMutation, newCommentText]
    );

    return {
        isLoading: loading,
        error,
        newCommentText,
        setNewCommentText,
        createComment,
    };
};
