import { Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import OverviewPage from './OverviewPage';
import { SwarmType } from '../../../enums';

import '../../../index.css';
import GenericForm from './GenericForm';

const GenericRoutes = ({ isProject }) => (
    <Routes>
        {Object.values(SwarmType).flatMap((swarmType) => [
            <Route
                key={`create_${swarmType}`}
                path={`create/${swarmType}`}
                element={<GenericForm swarmType={swarmType} isProject={isProject} />}
            />,
            <Route
                key={`edit_${swarmType}`}
                path={`edit/${swarmType}/:id`}
                element={<GenericForm swarmType={swarmType} isProject={isProject} />}
            />,
        ])}

        {/**
         * The routes below are not accessible for now because the Django
         * router is not pointing to the JsPageView for this path.
         */}
        <Route index element={<OverviewPage isProject={isProject} />} />
    </Routes>
);
GenericRoutes.propTypes = {
    isProject: PropTypes.bool.isRequired,
};

export const MeasuringPointRoutes = () => <GenericRoutes isProject={false} />;

export const ProjectRoutes = () => <GenericRoutes isProject={true} />;
