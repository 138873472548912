import { gql } from '@apollo/client';

export const soundAnalysisQuery = gql`
    query soundAnalysis($measuringPointId: Int!, $startTime: BigInteger!, $endTime: BigInteger!) {
        soundAnalysis(
            measuringPointId: $measuringPointId
            startTime: $startTime
            endTime: $endTime
        ) {
            startTime
            endTime
            lXEq
            lXtMin
            lXtMax
            lXPeak
            lXn {
                value
                timePercent
            }
        }
    }
`;
