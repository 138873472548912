import { Component } from 'react';
import { line } from 'd3-shape';
import PropTypes from 'prop-types';
import { first, isNil, isNumber, last, map, max, times } from 'lodash-es';
import { of } from 'rxjs';
import { Graph } from './graph';
import { DataTypes, getDataType } from './data-types';

// 25 refers to number_of_freq_to_vkar_items in spectrum.py
export const createEmptyAlarmPoints = () => times(25, () => ({ freq: null, vkar: null }));

const INITIAL_AXIS_DOMAIN = [0, 15];

class CustomAlarmGraph extends Graph {
    createXAxisDomain$() {
        return of(INITIAL_AXIS_DOMAIN);
    }

    createYAxisDomain$() {
        return of(INITIAL_AXIS_DOMAIN);
    }

    createXAxisText$() {
        return getDataType(DataTypes.FDOM).getAxisTitle$();
    }

    createYAxisText$() {
        return getDataType(DataTypes.VTOP).getAxisTitle$();
    }

    updateMaxOnScales() {
        const vkars = this.alarms.flatMap((alarm) => map(alarm.points, 'vkar'));
        const freqs = this.alarms.flatMap((alarm) => map(alarm.points, 'freq'));
        const maxVkar = max(vkars);
        const maxFreq = max(freqs);

        if (!maxVkar || !maxFreq) {
            return;
        }
        this.xAxis.scale.domain([0, maxFreq > 350 ? 350 : maxFreq * 1.2]);
        this.yAxis.scale.domain([0, maxVkar > 120 ? 120 : maxVkar * 1.2]);
    }

    updateAlarms(alarms) {
        this.alarms = alarms;
        this.updateMaxOnScales();
        this.render();
    }

    cleanupLine(dirtyPoints) {
        const lineMakesSense = dirtyPoints.some(({ vkar }) => isNumber(vkar) && vkar > 0);

        if (!lineMakesSense) {
            return [];
        }

        const points = dirtyPoints
            .filter((point) => isNumber(point.vkar) && isNumber(point.freq))
            .map((point) => [point.vkar, point.freq]);

        // Do some visual enhancement of the line.
        if (points.length !== 0) {
            const [firstVkar, firstFreq] = first(points);
            const [lastVkar, lastFreq] = last(points);

            if (firstFreq !== 0) {
                // Prepend line with a point at 0hz and the vkar
                // of the first point in the original line.
                points.unshift([firstVkar, 0]);
            }

            // Create a flat line leaving the screen with the last vkar.
            points.push([lastVkar, lastFreq * 1.2]);
        }

        return points;
    }

    renderAlarmLines() {
        const lines = this.alarms.map((alarm) => ({
            // Cleanup line:
            points: this.cleanupLine(alarm.points),
            cssClass: !isNil(alarm.alarmLevelIndex)
                ? `alarmLine alarmLine${alarm.alarmLevelIndex}`
                : 'catLine usedLine',
        }));

        const drawLine = line()
            .x((d) => this.xAxis.scale(d[1]))
            .y((d) => this.yAxis.scale(d[0]));

        // Render lines.
        this.drawArea
            .selectAll('.catLine, .alarmLine')
            .data(lines)
            .join('svg:path')
            .attr('class', (d) => d.cssClass)
            .attr('d', (d) => drawLine(d.points));
    }

    render() {
        this.renderAlarmLines();
    }
}

class AlarmLinesGraph extends Component {
    componentDidMount() {
        const margin = {
            top: 20,
            right: 0,
            bottom: 40,
            left: 55,
        };

        this.graph = new CustomAlarmGraph({
            margin,
            name: 'bar',
            buildContainer: true,
            container: this.node,
            containerHeight: 300,
            containerWidth: 400,
            containerClass: 'graph',
        });
    }

    shouldComponentUpdate(nextProps) {
        this.graph.ready.then(() => this.graph.updateAlarms(nextProps.alarms));
        return false;
    }

    render() {
        return (
            <div
                ref={(node) => {
                    this.node = node;
                }}
            ></div>
        );
    }
}
AlarmLinesGraph.propTypes = {
    alarms: PropTypes.array.isRequired,
};

export default AlarmLinesGraph;
