import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { BehaviorSubject } from 'rxjs';
import { useObservableEagerState, useObservableState } from 'observable-hooks';
import { plot, text, Mark, cell, identity, valueof, pointer } from '@observablehq/plot';
import { svg } from 'htl';
import { useEffect, useRef } from 'react';

import { utcDay, utcYear, utcMonth, utcMonday, utcYears, utcMonths, utcDays } from 'd3-time';

import { min, max, range } from 'd3-array';
import { findIndex, isEmpty, reverse, sortBy } from 'lodash-es';
import { BaseDataSet } from '../../../graph/dataset';
import SkeletonLoader from '../../skeletonLoader/SkeletonLoader';
import { DAILY_VALUE_FILL_COLORS, dataShape, GRAY_OUTLINE_COLOR } from '../../budgetViewer/utils';
import { format, formatInUtc } from '../../../utils/formatting';
import { getWeekDayWide, useLocale } from '../../../utils/date-fns';
import { highlight } from './highlightTransform';
import { DataTypes, fixFloat } from '../../../graph/data-types';
import { getSoundUnit$ } from '../../../graph/graph-elements/sound-plugin/unit-formatter';
import { RoundedButton } from '../../form/Button';

function moveDataSetToData(dataSet, data) {
    dataSet.setDatatype(DataTypes.LXEQ);
    dataSet.setVisibleMinMax(data.timeSpan.from, data.timeSpan.to);
}

function DailyValueTooltip({ data, dataSet }) {
    const unit = useObservableEagerState(getSoundUnit$(dataSet));
    return (
        <div className="flex flex-col items-center gap-y-1">
            <p>{format(data.Date, { timezone: 'UTC', hideTime: true })}</p>
            <p>
                Leq: {fixFloat(DataTypes.LXN, data.lXEq)}
                {unit}
            </p>
            <RoundedButton
                type="button"
                onClick={() => {
                    moveDataSetToData(dataSet, data);
                }}
                color="primary"
            >
                {gettext('VIEW_IN_GRAPH')}
            </RoundedButton>
        </div>
    );
}
DailyValueTooltip.propTypes = {
    data: dataShape,
    dataSet: PropTypes.instanceOf(BaseDataSet).isRequired,
};

class MonthLine extends Mark {
    static defaults = {
        stroke: 'gray',
        strokeWidth: 1, // px.
    };

    constructor(data, options = {}) {
        const { x, y } = options;
        super(
            data,
            { x: { value: x, scale: 'x' }, y: { value: y, scale: 'y' } },
            options,
            MonthLine.defaults
        );
    }

    render(index, { x, y }, { x: X, y: Y }, dimensions) {
        const { marginTop, marginBottom, height } = dimensions;
        const dx = x.bandwidth();
        const dy = y.bandwidth();

        // Due to the coordinate system and line drawing, the line always extends 2 pixels beyond
        // the top and bottom. We fix this by subtracting those values from the beginning and end
        // of the line.
        const cutOff = 2; // px.

        return svg`<path fill=none stroke=${this.stroke} stroke-width=${
            this.strokeWidth
        } d=${Array.from(
            index,
            (i) =>
                `${
                    Y[i] > marginTop + dy * 1.5 // is the first day a Sunday?
                        ? `M${X[i] + dx},${marginTop + dy + cutOff}V${Y[i]}h${-dx}`
                        : `M${X[i]},${marginTop + dy + cutOff}`
                }V${height - marginBottom - cutOff}`
        ).join('')}>`;
    }
}

function calendar({ date = identity, inset = 0, ...options } = {}) {
    let D;
    return {
        fy: {
            // eslint-disable-next-line no-return-assign
            transform: (data) => (D = valueof(data, date, Array)).map((d) => d.getUTCFullYear()),
        },
        x: {
            transform: () => D.map((d) => utcMonday.count(utcYear(d), d)),
        },
        y: { transform: () => D.map((d) => d.getUTCDay()) },
        inset,
        ...options,
    };
}

function calendarPlot({
    width,
    data: dataRaw,
    triggers,
    locale,
    highlighted$,
    tooltipContentRender,
    onClick,
}) {
    const data = dataRaw.map((value) => ({ Date: new Date(value.date), ...value }));

    function formatWeekday(day) {
        return getWeekDayWide(locale, day, 'abbreviated');
    }

    function formatMonth(date) {
        return formatInUtc(date, 'MMM', { locale });
    }

    function formatYear(date) {
        return formatInUtc(date, 'yyyy', { locale });
    }

    // Highest trigger first.
    const sortedTriggers = reverse(sortBy(triggers, 'trigger'));

    function getColor(value) {
        const triggerIndex = findIndex(
            sortedTriggers,
            (triggerEntry) => value >= triggerEntry.trigger
        );

        return (
            DAILY_VALUE_FILL_COLORS[triggerIndex] ||
            // When `triggerIndex` is null, it means the value has not color but
            // should be shown, so we make it white.
            'white'
        );
    }

    const start = utcYear.floor(utcDay.offset(min(data, (d) => d.Date)));
    const end = utcYear.ceil(utcDay.offset(max(data, (d) => d.Date)));

    const dataCellOptions = {
        date: 'Date',
        fill: valueof(data, 'lXEq').map(getColor),
    };

    const highlightedCellOptions = {
        ...dataCellOptions,
        stroke: 'black',
        strokeWidth: 2, // px.
    };

    const chart = plot({
        // Below 850 px width the graph starts looking wonky.
        width: max([850, width]),
        height: utcYear.count(utcYear.floor(start), utcYear.ceil(end)) * 160,
        axis: null,
        padding: 0, // px.
        x: {
            domain: range(53), // 53 is the ceiled amount of weeks per year (52.143).
        },
        y: {
            axis: 'left',
            domain: [-1, 1, 2, 3, 4, 5, 6, 0], // 1 row for the month name + all the weekdays.
            ticks: [1, 2, 3, 4, 5, 6, 0], // Don’t draw a tick for -1.
            tickSize: 0, // 0 hides the black stripe between the text and the plot.
            tickFormat: formatWeekday,
        },
        fy: {
            padding: 0.1, // px.
        },
        color: {
            domain: undefined, // Not used as we have custom color logic.
            legend: false,
        },
        marks: [
            // Draw year labels, rounding down to draw a year even if the data doesn’t
            // start on January 1. Use y = -1 (i.e., above Sunday) to align the year
            // labels vertically with the month labels, and shift them left to align
            // them horizontally with the weekday labels.
            text(
                utcYears(utcYear(start), end),
                calendar({
                    text: (...args) => formatYear(...args),
                    frameAnchor: 'right',
                    x: 0, // px.
                    y: -1, // px.
                    dx: -22, // px.
                })
            ),

            // Draw month labels at the start of each month, rounding down to draw a
            // month even if the data doesn’t start on the first of the month. As
            // above, use y = -1 to place the month labels above the cells. (If you
            // want to show weekends, round up to Sunday instead of Monday.)
            text(
                utcMonths(utcMonth(start), end).map(utcMonday.ceil),
                calendar({
                    text: formatMonth,
                    frameAnchor: 'left',
                    y: -1, // px.
                })
            ),

            // The backgrounds of each cell.
            cell(
                utcDays(start, end),
                calendar({
                    fill: 'black',
                    fillOpacity: 0.2,
                    stroke: GRAY_OUTLINE_COLOR,
                    strokeWidth: 0.5, // px.
                })
            ),

            // Draw a cell for each day in our dataset. The color of the cell encodes
            // the relative daily change. (The first value is not defined because by
            // definition we don’t have the previous day’s close.).
            cell(
                data,
                calendar({
                    ...dataCellOptions,
                    stroke: GRAY_OUTLINE_COLOR,
                    strokeWidth: 0.5, // px.
                })
            ),

            // Draw a line delineating adjacent months.
            new MonthLine(
                utcMonths(utcMonth(start), end),
                calendar({
                    stroke: 'darkgray',
                    strokeWidth: 1, // px.
                })
            ),

            // The highlighted cell that also shows the tooltip.
            cell(
                data,
                highlight(
                    calendar({
                        highlighted$,
                        tooltipContentRender,
                        onClick,
                        ...highlightedCellOptions,
                    })
                )
            ),

            // The pointer transform that we use to determine which cell is hovered.
            cell(data, pointer(calendar(highlightedCellOptions))),
        ],
    });

    // The pointer transform emits events when data is pointed at. When data is
    // pointed at, we also want it to be highlighted in the other graphs and show
    // a tooltip. To achieve this, we update the highlight behavior subject as well.
    chart.addEventListener('input', () => {
        highlighted$.next(chart.value);
    });

    return chart;
}

export function DailyValuesCalendar({ dataSet, highlighted$ }) {
    const containerRef = useRef();
    const loading = useObservableState(dataSet.dailyValuesLoading$, true);
    const values = useObservableState(dataSet.dailyValues$, []);
    const triggers = useObservableState(dataSet.dailyValuesTriggers$, []);
    const locale = useLocale();

    useEffect(() => {
        if (isEmpty(values)) {
            return null;
        }

        const calendarPlotEl = calendarPlot({
            width: containerRef.current.getBoundingClientRect().width,
            data: values,
            triggers,
            locale,
            highlighted$,
            tooltipContentRender(data) {
                const tempElement = document.createElement('div');
                ReactDOM.render(<DailyValueTooltip data={data} dataSet={dataSet} />, tempElement);
                return tempElement;
            },
            onClick(data) {
                if (!data) {
                    return;
                }
                moveDataSetToData(dataSet, data);
            },
        });
        containerRef.current.append(calendarPlotEl);
        return () => {
            calendarPlotEl.cleanup && calendarPlotEl.cleanup();
            calendarPlotEl.remove();
        };
    }, [values, triggers, locale, highlighted$, dataSet]);

    if (loading) {
        return <SkeletonLoader count={1} />;
    }

    if (isEmpty(values)) {
        return gettext('No data found');
    }

    return <div ref={containerRef} />;
}
DailyValuesCalendar.propTypes = {
    dataSet: PropTypes.instanceOf(BaseDataSet).isRequired,
    highlighted$: PropTypes.instanceOf(BehaviorSubject).isRequired,
};
