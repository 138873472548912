import { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    faAngleDown,
    faAngleUp,
    faUpRightFromSquare,
    faXmark,
    faSpinner,
    faDownload,
    faFileCircleExclamation,
    faCheck,
} from '@fortawesome/free-solid-svg-icons';
import MiddleEllipsis from 'react-middle-ellipsis';
import { DOWNLOAD_STATUS, DOWNLOAD_STATUS_TRANSLATIONS } from '../enums';
import { IconButton } from '../form/Button';

const iconStyle = 'h-6 w-6';

const STATUS_ICON = {
    [DOWNLOAD_STATUS.READY]: {
        icon: faDownload,
        className: iconStyle,
    },
    [DOWNLOAD_STATUS.FAILED]: {
        icon: faFileCircleExclamation,
        className: `${iconStyle} text-red-600`,
    },
    [DOWNLOAD_STATUS.IN_PROGRESS]: {
        icon: faSpinner,
        className: `${iconStyle} animate-spin`,
    },
    [DOWNLOAD_STATUS.DOWNLOADED]: {
        icon: faCheck,
        className: `${iconStyle} text-green-600`,
    },
};

const StatusPopup = ({ files, onClose, updateLastDownloadMutation }) => {
    const [showFiles, setShowFiles] = useState(true);
    const downloadsReady = useMemo(
        () => files?.filter((file) => file.status === DOWNLOAD_STATUS.READY),
        [files]
    );

    const downloadFile = useCallback(
        (url, id) => {
            window.open(url, '_self');
            updateLastDownloadMutation({
                variables: {
                    id,
                },
            });
        },
        [updateLastDownloadMutation]
    );

    const showStatusIcon = useCallback(
        (status, url, id) => {
            const { icon, className } = STATUS_ICON[status];
            const onClick =
                status === Object.keys(STATUS_ICON)[0] ? () => downloadFile(url, id) : undefined;
            return (
                <IconButton
                    ariaLabel={DOWNLOAD_STATUS_TRANSLATIONS[status]}
                    onClick={onClick}
                    icon={icon}
                    className={className}
                />
            );
        },
        [downloadFile]
    );

    const popupHeaderButtons = useMemo(
        () => [
            {
                ariaLabel: showFiles ? gettext('HIDE') : gettext('SHOW'),
                onClick: () => setShowFiles((current) => !current),
                icon: showFiles ? faAngleDown : faAngleUp,
                className: `${iconStyle} px-1.5`,
            },
            {
                ariaLabel: gettext('CLOSE'),
                onClick: onClose,
                icon: faXmark,
                className: iconStyle,
            },
        ],
        [onClose, showFiles]
    );

    return (
        <div className="fixed bottom-0 left-4 z-50 w-90 min-w-80 text-xs font-semibold shadow-448xl">
            <div className="flex h-12 items-center justify-between rounded-t-md bg-hc-dark-grey px-3 text-white">
                {`${gettext('DOWNLOAD_PAGE_TITLE').toUpperCase()} (${downloadsReady.length}/${
                    files.length
                })`}
                <div className="flex">
                    <a href="/reports/downloads" aria-label={gettext('GO_TO_PAGE')}>
                        <IconButton
                            ariaLabel={gettext('GO_TO_PAGE')}
                            icon={faUpRightFromSquare}
                            className={`${iconStyle} text-white`}
                        />
                    </a>
                    {popupHeaderButtons.map(({ ariaLabel, onClick, icon, className }) => (
                        <IconButton
                            ariaLabel={ariaLabel}
                            onClick={onClick}
                            icon={icon}
                            className={className}
                            key={ariaLabel}
                        />
                    ))}
                </div>
            </div>
            <div className="max-h-36 overflow-y-scroll overscroll-contain">
                {showFiles &&
                    files.map(({ name, id, status, url }) => (
                        <div
                            className="flex h-12 items-center justify-between border-t-0.5 border-hc-dark-grey bg-white px-5"
                            key={id}
                        >
                            <div className="w-60 whitespace-nowrap" title={name}>
                                <MiddleEllipsis>
                                    <span>{name}</span>
                                </MiddleEllipsis>
                            </div>
                            {showStatusIcon(status, url, id)}
                        </div>
                    ))}
            </div>
        </div>
    );
};

StatusPopup.propTypes = {
    files: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    updateLastDownloadMutation: PropTypes.func.isRequired,
};

export default StatusPopup;
