import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useNamingConventions } from '../../../utils/naming-conventions';
import Tooltip from '../../../utils/tooltip';
import { defaultPropTypes, FormContext, FormFieldContext, useIsOverridable } from '../utils';
import InformationTooltip from '../InformationTooltip';
import { useHiddenByConfigHelper } from '../../pages/measuringPoint/configHelper';

function OverrideCheckbox({ name }) {
    const { register } = useFormContext();
    const { hover, setHover } = useContext(FormFieldContext);

    return (
        <Tooltip visible={hover} content={gettext('Check to override project settings.')}>
            <input
                type="checkbox"
                name={`override-id_${name}`}
                id={`override-id_${name}`}
                {...register(`override.${name}`)}
                onMouseOver={() => {
                    setHover(true);
                }}
                onMouseLeave={() => {
                    setHover(false);
                }}
            />
        </Tooltip>
    );
}
OverrideCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
};

function TopRightItems({ name, showOverrideCheckbox }) {
    const { information } = useContext(FormContext);
    const overridable = useIsOverridable(name);

    if (!name) {
        return null;
    }

    return (
        <>
            {information?.[name] && <InformationTooltip information={information[name]} />}
            {overridable && showOverrideCheckbox && <OverrideCheckbox name={name} />}
        </>
    );
}
TopRightItems.propTypes = {
    name: PropTypes.string,
    showOverrideCheckbox: PropTypes.bool.isRequired,
};

export function BaseFormFieldContainer({
    name = null,
    Element = 'div',
    widthClass = 'w-[250px]',
    marginClass = 'my-1.5 mr-5',
    label = null,
    showOverrideCheckbox = false,
    error,
    helpText,
    children,
}) {
    return (
        <Element
            className={`relative inline-block list-none align-top text-form-text ${widthClass} ${marginClass}`}
        >
            {label ? (
                // When we need to show a label, we let the top right items
                // float right next to it.
                <>
                    <div>
                        <span className="float-right ml-2 flex gap-x-1">
                            <TopRightItems
                                name={name}
                                showOverrideCheckbox={showOverrideCheckbox}
                            />
                        </span>
                        <label htmlFor={`id_${name}`} className="text-[11px]">
                            {label}
                        </label>
                    </div>
                    {children}
                </>
            ) : (
                // If we don't need to show a label, we shown the top right
                // items in a div next to it.
                <div className="flex items-start gap-x-2">
                    <div>{children}</div>
                    <div className="flex gap-x-1">
                        <TopRightItems name={name} showOverrideCheckbox={showOverrideCheckbox} />
                    </div>
                </div>
            )}

            <span className="text-[11px]">
                {error && <div className="text-form-warning">{error}</div>}
                {helpText}
            </span>
        </Element>
    );
}
BaseFormFieldContainer.propTypes = {
    name: PropTypes.string,
    Element: PropTypes.node,
    widthClass: PropTypes.string,
    marginClass: PropTypes.string,
    label: PropTypes.string,
    showOverrideCheckbox: PropTypes.bool,
    error: PropTypes.string,
    helpText: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default function FormFieldContainer({
    fieldOptions,
    children,
    widthClass = 'w-[250px]',
    marginClass,
    showOverrideCheckbox = true,
    showError = true,
    showLabel = true,
}) {
    const { name, settings } = fieldOptions;
    const hide = useHiddenByConfigHelper(name);
    const label = useNamingConventions(showLabel ? settings.label : null);
    const {
        formState: { errors },
    } = useFormContext();
    const [hover, setHover] = useState(false);

    if (hide) {
        return null;
    }

    return (
        <FormFieldContext.Provider
            value={{
                hover,
                setHover,
            }}
        >
            <BaseFormFieldContainer
                name={name}
                Element="li"
                label={label}
                showOverrideCheckbox={showOverrideCheckbox}
                error={showError ? errors[name] && errors[name].message : null}
                widthClass={widthClass}
                marginClass={marginClass}
                helpText={settings.help_text}
            >
                {children}
            </BaseFormFieldContainer>
        </FormFieldContext.Provider>
    );
}
FormFieldContainer.propTypes = {
    ...defaultPropTypes,
    children: PropTypes.node.isRequired,
    showOverrideCheckbox: PropTypes.bool,
    marginClass: PropTypes.string,
    showError: PropTypes.bool,
    showLabel: PropTypes.bool,
};
