import PropTypes from 'prop-types';

/**
 * Grabbing the `fixFloat` function out of the props instead of calling
 * `fixFloat(DataTypes.LXN, v)` directly here is to prevent circular imports
 * when we import from `data-types.js`.
 * Although the circular import in this case is just a warning and will not cause
 * any harm, this is unfortunately the most correct way. To overcome the circular
 * import problem completely, we could look into splitting the whole
 * `data-types.js` file into files that contain the data types, data tabs, and
 * graphs.
 */
export function LnLegendTable({ lnValues, fixFloat }) {
    const formattedValues = lnValues.map((lxnValue) => [
        `L${lxnValue.timePercent}`,
        // eslint-disable-next-line no-use-before-define
        fixFloat(lxnValue.value),
    ]);

    return (
        <table>
            <tbody>
                {formattedValues.map(([name, value]) => (
                    <tr key={name}>
                        <td className="pr-2">{name}</td>
                        <td>{value}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

LnLegendTable.propTypes = {
    lnValues: PropTypes.array.isRequired,
    fixFloat: PropTypes.func.isRequired,
};
