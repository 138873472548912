import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { isEmpty, some } from 'lodash-es';
import { defaultPropTypes, useIsOverridable, useIsOverridden } from '../../utils';
import FormFieldContainer from '../FormFieldContainer';
import Select from '../../../form/Select';
import { GenericRowField } from '../genericRowField/GenericRowField';
import Input from '../../../form/Input';
import { useLocale, secondsToDurationText } from '../../../../utils/date-fns';
import { extractSecondsFromLeqInterval } from './leqIntervalConverter';
import { checkNumberWithinRange } from '../utils';

function getLeqIntervalOptions(locale) {
    return [900, 1800, 3600, 14400, 28800].map((seconds) => [
        seconds,
        secondsToDurationText(seconds, locale),
    ]);
}

function validateRow(row, leqIntervalInSeconds, locale) {
    const errors = checkNumberWithinRange({ row, fieldName: 'alarmLevel' });

    // Check if row.interval is a multiple of leqIntervalInSeconds.
    const isMultiplication = row.interval % leqIntervalInSeconds === 0;

    if (!isMultiplication) {
        errors.interval = interpolate(gettext('LEQ_INTERVAL_MULTIPLE_ERROR'), [
            `${secondsToDurationText(row.interval, locale)}`,
            `${secondsToDurationText(leqIntervalInSeconds, locale)}`,
        ]);
    }

    return isEmpty(errors) ? null : errors;
}

function LeqAlarmLevelSettingsField({ fieldOptions }) {
    const { name, settings } = fieldOptions;
    const { required } = settings;
    const overridden = useIsOverridden(name);
    const disabled = useIsOverridable(name) && !overridden;
    const locale = useLocale();
    const intervalOptions = useMemo(() => getLeqIntervalOptions(locale), [locale]);

    const fields = useMemo(
        () => [
            {
                name: 'name',
                label: gettext('LEQ_ALARM_NAME'),
                render(props) {
                    return <Input {...props} />;
                },
            },
            {
                name: 'interval',
                label: gettext('LEQ_ALARM_INTERVAL'),
                render(props) {
                    return (
                        <Select
                            data={intervalOptions}
                            {...props}
                            onChange={(e) => {
                                props.onChange({ target: { value: parseInt(e.target.value, 10) } });
                            }}
                        />
                    );
                },
            },
            {
                name: 'alarmLevel',
                label: `${gettext('ALARM_LEVEL')} (dB)`,
                render(props) {
                    return (
                        <Input
                            type="number"
                            {...props}
                            onChange={(e) => {
                                props.onChange({
                                    target: { value: parseInt(e.target.value, 10) },
                                });
                            }}
                        />
                    );
                },
            },
        ],
        [intervalOptions]
    );

    const emptyRow = useMemo(
        () => ({
            name: '',
            interval: intervalOptions[0][0],
            alarmLevel: 0,
        }),
        [intervalOptions]
    );

    return (
        <Controller
            name={name}
            defaultValue={[]}
            rules={{
                required,

                validate(rows, { parent: { lxEqInterval } }) {
                    const leqIntervalInSeconds = extractSecondsFromLeqInterval(lxEqInterval);

                    const errorsPerRow = rows.map((row) =>
                        validateRow(row, leqIntervalInSeconds, locale)
                    );

                    // If one or more rows has an error, we return all the rows, if
                    // not we return `true` to indicate a passed validation.
                    return some(errorsPerRow) ? errorsPerRow : true;
                },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
                <FormFieldContainer
                    fieldOptions={fieldOptions}
                    marginClass=""
                    showLabel={false}
                    showError={false}
                    widthClass={''}
                >
                    <GenericRowField
                        name={name}
                        fields={fields}
                        emptyRow={emptyRow}
                        maxRows={4}
                        value={value}
                        error={error ? error.errors : null}
                        onChange={onChange}
                        disabled={disabled}
                    />
                </FormFieldContainer>
            )}
        />
    );
}
LeqAlarmLevelSettingsField.propTypes = defaultPropTypes;

export default LeqAlarmLevelSettingsField;
