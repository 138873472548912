import { isNull } from 'lodash-es';
import { map } from 'rxjs';
import { DataTypes, getDataType } from '../data-types';
import { DotGraphType } from '../types';
import { SampleAxisGraphElement, SamplesGraphElementsProvider } from './samples-provider';

class FrequencySampleAxisGraphElement extends SampleAxisGraphElement {
    getTooltipVisibleData(_graph) {
        return getDataType(DataTypes.FDOM).visibleData;
    }
}

/**
 * Observables flow diagram: https://miro.com/app/board/uXjVPSSWJQ0=/
 */
export default class FrequencySamplesGraphElementsProvider extends SamplesGraphElementsProvider {
    static getGraphElementClass() {
        return FrequencySampleAxisGraphElement;
    }

    static createGraphElementsObservable(dataSet, legendData) {
        return super.createGraphElementsObservable(dataSet, legendData).pipe(
            map((elements) =>
                elements
                    .filter((element) => element.axis !== 'm')
                    .filter((element) => !isNull(element.getValueForDataType(DataTypes.FDOM)))
            ),
            // Filter out samples/axis that are not visible in the time graph.
            map((elements) => dataSet.filterRecordsWithinView(elements))
        );
    }

    init() {
        this.subscription.add(
            DotGraphType.createRenderObservable(
                this.graphElementPositions$,
                this.graph,
                null,
                this.graph.dotSize
            ).subscribe()
        );
    }

    getShouldCalculateElementPositionsObservable() {
        return this.dataSet.dataType$.pipe(
            map((dataType) => getDataType(dataType).showFrequencyGraph)
        );
    }
}
