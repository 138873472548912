import { useState, useRef, useCallback, useEffect } from 'react';
import { useMutation, gql, useLazyQuery } from '@apollo/client';
import { combineLatest, combineLatestWith, map, of, switchMap, take } from 'rxjs';
import { overrides$ } from '../../../graph/graph-helpers';
import { revertDistanceConverter } from '../../../utils/formatting';
import { DOWNLOAD_STATUS } from '../../enums';
import { updateReadyReportsCount } from '../../utils';
import { addNotificationHack, NOTIFICATION_VARIANT } from '../../notifications/Notifications';

const GRAPH_REPORTS_FRAGMENT = gql`
    fragment GraphReportTypeFields on GraphReportType {
        id
        name
        url
        status
    }
`;

const CREATE_GRAPH_REPORT = gql`
    mutation createGraphReport($fileType: FileType!, $mpId: ID!, $parameters: ParametersInput!) {
        createGraphReport(fileType: $fileType, mpId: $mpId, parameters: $parameters) {
            graphReport {
                ...GraphReportTypeFields
            }
        }
    }
    ${GRAPH_REPORTS_FRAGMENT}
`;

const GRAPH_REPORTS = gql`
    query graphReports($ids: [ID]) {
        graphReports(ids: $ids) {
            readyReportsCount
            totalCount
            edges {
                node {
                    ...GraphReportTypeFields
                }
            }
        }
    }
    ${GRAPH_REPORTS_FRAGMENT}
`;
const UPDATE_LAST_DOWNLOAD = gql`
    mutation updateGraphReportLastDownloaded($id: ID!) {
        updateGraphReportLastDownloaded(id: $id) {
            graphReport {
                ...GraphReportTypeFields
            }
        }
    }
    ${GRAPH_REPORTS_FRAGMENT}
`;

export const EXPORT_TYPES = Object.freeze({
    PDF: 'pdf',
    CSV: 'csv',
    XLSX: 'xlsx',
});

export const EXPORT_TYPES_DISPLAY_TEXT = Object.freeze({
    [EXPORT_TYPES.PDF]: 'PDF',
    [EXPORT_TYPES.CSV]: 'CSV',
    [EXPORT_TYPES.XLSX]: 'Excel (xlsx)',
});

export const useHandleFileTypeExports = (
    dataSet,
    graphType$,
    scales,
    legendData,
    frequencyGraphScaleType$
) => {
    const [reportIds, setReportIds] = useState([]);
    const [showStatusPopup, setShowStatusPopup] = useState(false);
    const [downloadFiles, setDownloadFiles] = useState([]);

    const [fetchGraphReports, { data: reportsData, stopPolling, refetch }] = useLazyQuery(
        GRAPH_REPORTS,
        {
            variables: {
                ids: reportIds,
            },
            pollInterval: 5000,
        }
    );

    const [createGraphReportMutation] = useMutation(CREATE_GRAPH_REPORT, {
        onCompleted: (result) => {
            if (result?.createGraphReport) {
                setShowStatusPopup(true);
                setReportIds((ids) => [...ids, result.createGraphReport.graphReport.id]);
                fetchGraphReports();
            }
        },
        onError: () => {
            addNotificationHack({
                message: gettext('DATA_EXPORT_UNAVAILABLE_ERROR'),
                variant: NOTIFICATION_VARIANT.ERROR,
            });
        },
    });

    const [updateLastDownloadMutation] = useMutation(UPDATE_LAST_DOWNLOAD, {
        onCompleted: () => {
            refetch();
        },
    });

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const datePickerExportType = useRef();

    const openDatePickerForExportType = useCallback((exportType) => {
        datePickerExportType.current = exportType;
        setIsDatePickerOpen(true);
    }, []);

    const createGraphReport = useCallback(
        (exportType, extraParameters = {}, customDateRange = null) => {
            const frequencyScale$ = scales.createObservableForType('fdom');
            const valueScale$ = dataSet.dataType$.pipe(
                switchMap((dataType) => scales.createObservableForType(dataType)),
                map(revertDistanceConverter)
            );

            combineLatest([
                customDateRange ? of(customDateRange) : dataSet.dateRange.observable$,
                graphType$,
                dataSet.dataType$,
                dataSet.currentSensor$,
                overrides$.pipe(
                    combineLatestWith(legendData.hiddenTypes$),
                    map(([overrides, hiddenTypes]) =>
                        JSON.stringify({
                            ...overrides,
                            // Add the hidden lines to the override values.
                            hidden_lines: hiddenTypes,
                        })
                    )
                ),
                frequencyScale$,
                valueScale$,
                frequencyGraphScaleType$,
            ])
                .pipe(take(1))
                .subscribe(
                    ([
                        [min, max],
                        graphType,
                        dataType,
                        currentSensor,
                        overrides,
                        frequencyScale,
                        valueScale,
                        frequencyGraphScaleType,
                    ]) => {
                        const parameters = {
                            viewStart: min,
                            viewEnd: max,
                            dataType,
                            graphType: graphType.toUpperCase(),
                            accelerationScale: valueScale,
                            frequencyScale,
                            frequencyGraphScaleType,
                            ...extraParameters,
                            overrides: JSON.stringify(overrides),
                        };

                        createGraphReportMutation({
                            variables: {
                                fileType: exportType,
                                mpId: currentSensor,
                                parameters,
                            },
                        });
                    }
                );
        },
        [
            createGraphReportMutation,
            dataSet.currentSensor$,
            dataSet.dataType$,
            dataSet.dateRange.observable$,
            graphType$,
            scales,
            legendData.hiddenTypes$,
            frequencyGraphScaleType$,
        ]
    );

    useEffect(() => {
        if (reportsData) {
            updateReadyReportsCount(reportsData.graphReports.readyReportsCount);
        }
        setDownloadFiles(
            reportsData?.graphReports && reportIds.length
                ? reportsData.graphReports.edges.map((edge) => edge.node)
                : []
        );
    }, [reportsData, reportIds]);

    useEffect(() => {
        if (
            !reportIds.length ||
            !reportsData?.graphReports.edges.some(
                (edge) => edge.node.status === DOWNLOAD_STATUS.IN_PROGRESS
            )
        ) {
            stopPolling();
        }
    }, [reportsData, reportIds, stopPolling]);

    useEffect(() => {
        // Reset reportIds when status popup gets closed.
        if (!showStatusPopup && reportIds.length) {
            setReportIds([]);
        }
    }, [reportIds, showStatusPopup]);

    return {
        dataSet,
        createGraphReport,
        datePickerExportType,
        openDatePickerForExportType,
        isDatePickerOpen,
        setIsDatePickerOpen,
        setShowStatusPopup,
        showStatusPopup,
        downloadFiles,
        updateLastDownloadMutation,
    };
};
