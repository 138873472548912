import PropTypes from 'prop-types';

export default function TimeIndicator({ left }) {
    return (
        <div className="absolute z-20" style={{ left }}>
            <div className="-ml-1 flex w-2 flex-col items-center">
                <div className="h-2 w-2 rounded-full bg-graph-blue"></div>
                <div className="h-[72px] w-0.5 bg-graph-blue"></div>
            </div>
        </div>
    );
}
TimeIndicator.propTypes = {
    left: PropTypes.number,
};
