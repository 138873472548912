import { scaleLinear } from 'd3-scale';
import { dropRightWhile, dropWhile } from 'lodash-es';

// JS port of SpectrumConditioner::freq_to_vkar.
// https://github.com/omnidots/shared/blob/718785c2fdcdff5911939ea23aa67432aeb189ae/conditioner/spectrum.py#L359
export const freqToVkar = (freq, vkarToFreqLine) => {
    // Find the slot in which our frequency lies.
    const { itemFreq, itemVkar, prevFreq, prevVkar } = [...vkarToFreqLine].reduce(
        (accumulator, [currentVkar, currentFreq], _index, array) => {
            accumulator.itemFreq = currentFreq;
            accumulator.itemVkar = currentVkar;

            if (freq < currentFreq) {
                // We found our item, eject early.
                array.splice(1);
                return accumulator;
            }

            accumulator.prevFreq = currentFreq;
            accumulator.prevVkar = currentVkar;
            return accumulator;
        },
        {
            prevFreq: null,
            prevVkar: null,
        }
    );

    // In case we lie outside of the range we flat line
    if (prevFreq == null || (prevFreq === itemFreq && prevVkar === itemVkar)) {
        return itemVkar;
    }

    // Otherwise interpolate our frequency
    return scaleLinear().domain([prevFreq, itemFreq]).range([prevVkar, itemVkar])(freq);
};

export const fitVkarToFreqLineIntoRange = (vkarToFreqLine, [start, end]) => [
    [freqToVkar(start, vkarToFreqLine), start],
    ...dropRightWhile(
        dropWhile(vkarToFreqLine, ([_vkar, freq]) => freq < start),
        ([_vkar, freq]) => freq > end
    ),
    [freqToVkar(end, vkarToFreqLine), end],
];
