export const ENTITY_TYPE = Object.freeze({
    USER: 'USER',
    MEASURING_POINT: 'MEASURING_POINT',
    PROJECT: 'PROJECT',
});

export const ENTITY_TYPE_TRANSLATIONS = Object.freeze({
    [ENTITY_TYPE.USER]: gettext('ACCOUNT'),
    [ENTITY_TYPE.MEASURING_POINT]: gettext('MEASURING_POINT'),
    [ENTITY_TYPE.PROJECT]: gettext('PROJECT'),
});

export const PERMISSION = Object.freeze({
    VIEW: 'VIEW',
    FULL_ACCESS: 'FULL_ACCESS',
});

export const PERMISSION_TRANSLATIONS = Object.freeze({
    [PERMISSION.VIEW]: gettext('PERMISSION_VIEW'),
    [PERMISSION.FULL_ACCESS]: gettext('PERMISSION_FULL_ACCESS'),
});

export const INVITE_STATUS = Object.freeze({
    ACCEPTED: 'ACCEPTED',
    PENDING: 'PENDING',
});
